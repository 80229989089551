import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import wrap from '../../../util/wrap_with_intl_provider';
import PageHeader from '../../../../../../components/PageHeader';
import EnvironmentForm from './EnvironmentForm';

const EnvironmentNew = (props) => {
  const intl = useIntl();

  return (
    <div>
      <PageHeader
        previousPages={[
          {
            link: props.environmentsUrl,
            title: intl.formatMessage({ id: 'admin-area.settings.environment.index.title' }),
          },
        ]}
        title={intl.formatMessage({ id: 'admin-area.settings.environment.new.title' })}
        pageDivider={true}
      />
      <EnvironmentForm url={props.environmentsUrl} method="POST" defaultValues={{}} isDarkTheme={props.isDarkTheme} />
    </div>
  );
};

EnvironmentNew.propTypes = {
  environmentsUrl: PropTypes.string.isRequired,
  isDarkTheme: PropTypes.bool
};

export default wrap(EnvironmentNew);
