import React from "react";
import MicroEventForm from "./MicroEventForm";
import PropTypes from "prop-types";

import { IntlProvider } from "react-intl";
import { translations, locale } from "../../../util/i18n";

const EditMicroEventModal = (props) => (
  <IntlProvider locale={window._FLAIM_LOCALE} key={window._FLAIM_LOCALE} messages={translations[window._FLAIM_LOCALE]}>
    <MicroEventForm
      url={props.url}
      method={"PUT"}
      defaultValues={{ ...props.eventDetails }}
      eventCategories={props.eventCategories}
    />
  </IntlProvider>
);

EditMicroEventModal.propTypes = {
  url: PropTypes.string.isRequired,
  eventDetails: PropTypes.object.isRequired,
  eventCategories: PropTypes.array.isRequired,
};

export default EditMicroEventModal;
