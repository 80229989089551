import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import Tooltip from '../Tooltip';
import './ArrowLabel.scss';

const ComponentPropTypes = {
  type: PropTypes.oneOf(['draft', 'active', 'archived']).isRequired,
  label: PropTypes.string.isRequired,
  tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

type ComponentTypes = InferProps<typeof ComponentPropTypes>;

const ArrowLabel = ({ type, label, tooltip }: ComponentTypes) => (
  <div className="arrow-label">
    <div className={`head ${type}`} />
    <div className={`body ${type}`}>
      <div className="label">{label}</div>
      {tooltip && (
        <div className="tooltip">
          <Tooltip type="info" tooltip={tooltip} />
        </div>
      )}
    </div>
  </div>
);

ArrowLabel.propTypes = ComponentPropTypes;
export default ArrowLabel;
