import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { launchParams, runSearch } from './searchUtils';
import { useIntl } from 'react-intl';

const TableSearch = (props) => {
  const intl = useIntl();
  const searchParams = new URLSearchParams(window.location.search);
  const [filtersVisible, setFiltersVisible] = useState(Array.from(searchParams).length > 0);

  let FormComponent = props.form;

  const toggleFilterVisibility = () => {
    setFiltersVisible(!filtersVisible);
  };

  const clearAll = () => {
    let newUrl = window.location.protocol + '//' + window.location.host + window.location.pathname;
    window.location.href = newUrl;
  };

  const getActiveFilters = () => {
    let activeFilters = '';
    searchParams.forEach(function (value, key) {
      const label = getLabelForField(key);
      if (!label) {
        return;
      }
      if (!activeFilters.includes(label)) {
        activeFilters += label + ', ';
      }
    });
    if (activeFilters) {
      activeFilters = activeFilters.slice(0, -2);
      activeFilters = activeFilters.toLowerCase();
      return `${intl.formatMessage({ id: 'filters.filtering-by' })} ${activeFilters}`;
    } else {
      return '';
    }
  };

  const getLabelForField = (key) => {
    let el = document.getElementsByName(key);
    if (el.length > 0) {
      return el[0].closest('.form-group').querySelector('label').innerText;
    } else {
      el = document.getElementById(key);
      if (el) {
        el.closest('.form-group').querySelector('label').innerText;
      } else {
        return '';
      }
    }
  };

  const currentFilters = getActiveFilters();

  return (
    <div className="search-box">
      <div className="filter-header-bar">
        <div className="filters-summary">
          <span className="filters-icon">
            <i className="fal fa-filter"></i>&nbsp; {intl.formatMessage({ id: 'filters.filters' })}
          </span>
          <span>{currentFilters ? currentFilters : intl.formatMessage({ id: 'filters.none-selected' })}</span>
          {currentFilters && (
            <a className="filter-action" onClick={clearAll}>
              <i className="fas fa-times"></i>&nbsp; {intl.formatMessage({ id: 'filters.clear-all' })}
            </a>
          )}
        </div>
        <a className="filter-action" onClick={toggleFilterVisibility}>
          <span className={filtersVisible ? '' : 'hidden'}>
            <i className="fas fa-caret-up"></i>&nbsp; {intl.formatMessage({ id: 'filters.collapse' })}
          </span>

          <span className={!filtersVisible ? '' : 'hidden'}>
            <i className="fas fa-caret-down"></i>&nbsp; {intl.formatMessage({ id: 'filters.expand' })}
          </span>
        </a>
      </div>
      <div className={`inner-items ${filtersVisible ? '' : 'hidden'}`}>
        <FormComponent
          {...props}
          defaultValues={launchParams()}
          onSubmit={(body) => {
            runSearch(body);
          }}
        />
      </div>
    </div>
  );
};

TableSearch.propTypes = {
  form: PropTypes.elementType.isRequired,
};

export default TableSearch;
