import React from 'react';
import PropTypes from 'prop-types';

import './ImageGallery.scss';
import ImagePreview from './ImagePreview';

const ImageGallery = ({ imageUrls, onDelete, onSetPrimaryIndex, primaryIndex }) => {
  return (
    <div className="image-gallery">
      {imageUrls.map((imgSrc, index) => (
        <ImagePreview
          key={index}
          index={index}
          imgSrc={imgSrc}
          onDelete={onDelete}
          onSetPrimaryIndex={onSetPrimaryIndex}
          primaryImage={primaryIndex == index}
        />
      ))}
    </div>
  );
};

ImageGallery.propTypes = {
  imageUrls: PropTypes.arrayOf(PropTypes.string),
  onDelete: PropTypes.func,
  onSetPrimaryIndex: PropTypes.func,
  primaryIndex: PropTypes.number,
};

ImageGallery.defaultProps = {
  imageUrls: [],
};

export default ImageGallery;
