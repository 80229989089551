import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import wrap from '../util/wrap_with_intl_provider';
import Table from '../../../../components/Table';
import ax from '../util/axios';
import { tableParamsBuilder } from '../util/searchUtils';
import UsersRowMenu from './UsersRowMenu';
import SearchBar from '../../../../components/SearchBar';
import { PrimarySearchItems } from './UserSearchForm';
import {
  edit_admin_area_organisation_user_path,
  edit_organisation_area_settings_user_path,
  admin_area_student_path,
  organisation_area_student_path,
  admin_area_settings_user_send_password_path,
  organisation_area_settings_user_send_password_path,
  admin_area_settings_user_remove_from_org_path,
  organisation_area_settings_user_remove_from_org_path,
  admin_area_settings_user_path,
  organisation_area_settings_user_path,
  admin_area_settings_user_revoke_path,
} from '../../../../routes';

const UserTable = (props) => {
  const intl = useIntl();
  const [pagination, setPagination] = useState({});
  const [users, setUsers] = useState([]);
  const [searchParams, setSearchParams] = useState({});

  const fetchUsers = async (page = 1) => {
    const response = await ax.get(props.usersUrl + '.json' + tableParamsBuilder({ page, ...searchParams }));
    setPagination(response.data.pagination);
    setUsers(response.data.users);
  };

  const userColumns = () => {
    const columns = [
      {
        accessor: 'email',
        header: intl.formatMessage({ id: 'users.search.email-field' }),
      },
      {
        accessor: 'firstName',
        header: intl.formatMessage({ id: 'users.search.first-name-field' }),
      },
      {
        accessor: 'lastName',
        header: intl.formatMessage({ id: 'users.search.last-name-field' }),
      },
      {
        accessor: 'lastLogin',
        header: intl.formatMessage({ id: 'users.search.last-login-field' }),
      },
      {
        className: 'actions',
        accessor: 'rowMenu',
      },
    ];

    if (props.forOrgUser) {
      columns.splice(4, 0, { accessor: 'mobile', header: intl.formatMessage({ id: 'students.fields.mobile' }) });
    }

    return columns;
  };

  const renderRowMenu = (user) => {
    if (props.forOrgUser) {
      return (
        <UsersRowMenu
          editUserUrl={
            props.isAdmin
              ? edit_admin_area_organisation_user_path(user.organisationId, user.id)
              : edit_organisation_area_settings_user_path(user.id)
          }
          viewStudentUrl={
            user.studentId &&
            (props.isAdmin ? admin_area_student_path(user.id) : organisation_area_student_path(user.id))
          }
          sendPasswordUrl={
            props.isAdmin
              ? admin_area_settings_user_send_password_path({ user_id: user.id })
              : organisation_area_settings_user_send_password_path({ user_id: user.id })
          }
          removeFromOrgUrl={
            props.isAdmin
              ? admin_area_settings_user_remove_from_org_path({ user_id: user.id })
              : organisation_area_settings_user_remove_from_org_path({ user_id: user.id })
          }
        />
      );
    } else {
      return (
        <UsersRowMenu
          updateUserUrl={
            props.isAdmin ? admin_area_settings_user_path(user.id) : organisation_area_settings_user_path(user.id)
          }
          sendPasswordUrl={
            props.isAdmin
              ? admin_area_settings_user_send_password_path({ user_id: user.id })
              : organisation_area_settings_user_send_password_path({ user_id: user.id })
          }
          revokeUrl={props.isAdmin ? admin_area_settings_user_revoke_path({ user_id: user.id }) : ''}
          removeFromOrgUrl={
            props.isAdmin ? '' : organisation_area_settings_user_remove_from_org_path({ user_id: user.id })
          }
          userDetails={{
            first_name: user.firstName,
            last_name: user.lastName,
            email: user.email,
          }}
        />
      );
    }
  };

  const mappedUsers = users.map((user) => ({
    ...user,
    rowMenu: renderRowMenu(user),
  }));

  useEffect(() => {
    fetchUsers();
  }, [searchParams]);

  return (
    <div>
      <SearchBar primaryItems={<PrimarySearchItems />} onSearch={setSearchParams} />
      {users.length > 0 && (
        <Table
          header={true}
          items={mappedUsers}
          columns={userColumns()}
          pagination={pagination}
          onPageChange={fetchUsers}
        />
      )}
    </div>
  );
};

UserTable.propTypes = {
  usersUrl: PropTypes.string.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  forOrgUser: PropTypes.bool.isRequired,
};

export default wrap(UserTable);
