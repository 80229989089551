import React, { useState, useEffect } from "react";
import ax from "../../../util/axios";
import PropTypes from "prop-types";

const AvailableScenarioTile = (props) => {
  const scenario = props.content;
  const [details, setDetails] = useState(scenario.details);

  useEffect(() => {
    if (!details) {
      ax({
        method: "get",
        url: `/${props.orgArea ? "org" : "admin"}/scenarios/${scenario.id}/preview`,
        responseType: "text",
      })
        .then(function (response) {
          scenario.details = response.data;
          setDetails(scenario.details);
          console.log("get scenario preview successfully");
        })
        .catch((e) => {
          console.log("get scenario preview failed " + e);
        });
    }
  });

  return (
    <div className="scenario-preview">
      {details && <div dangerouslySetInnerHTML={{ __html: details }}></div>}
      {!details && (
        <div className="spinner">
          <i className="fas fa-spinner fa-spin"></i>
        </div>
      )}
    </div>
  );
};

AvailableScenarioTile.propTypes = {
  content: PropTypes.object.isRequired,
  orgArea: PropTypes.bool.isRequired,
};

export default AvailableScenarioTile;
