import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import ServerFormErrors from '../../util/ServerFormErrors';
import ax from '../../util/axios';
import { useIntl } from 'react-intl';

const EditProfileForm = (props) => {
  const intl = useIntl();
  const [errorMessage, setErrorMessages] = useState();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues: { ...props.defaultValues } });
  const onSubmit = (data) => {
    ax({
      method: props.method,
      url: props.url,
      data: { form: data },
    })
      .then(function () {
        window.location = window.location.href;
      })
      .catch((err) => {
        setErrorMessages(err.response.data);
      });
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-group">
          <label>{intl.formatMessage({ id: 'users.first-name-field' })}</label>
          <input className="form-control" {...register('first_name', { required: true })}></input>
          {errors.first_name?.type === 'required' && (
            <div className="field-error">{intl.formatMessage({ id: 'shared.forms.required' })}</div>
          )}
        </div>

        <div className="form-group">
          <label>{intl.formatMessage({ id: 'users.last-name-field' })}</label>
          <input className="form-control" {...register('last_name', { required: true })}></input>
          {errors.last_name?.type === 'required' && (
            <div className="field-error">{intl.formatMessage({ id: 'shared.forms.required' })}</div>
          )}
        </div>

        <div className="form-group">
          <label>{intl.formatMessage({ id: 'users.email-field' })}</label>
          <input
            className="form-control"
            {...register('email', {
              required: true,
              pattern: EMAIL_REGEX,
            })}
          ></input>
          {errors.email?.type === 'required' && (
            <div className="field-error">{intl.formatMessage({ id: 'shared.forms.required' })}</div>
          )}
          {errors.email?.type === 'pattern' && (
            <div className="field-error">{intl.formatMessage({ id: 'shared.forms.invalid-email' })}</div>
          )}
        </div>

        <div className="form-group">
          <label>{intl.formatMessage({ id: 'profile.timezone-field' })}</label>
          <select className="form-control" {...register('timezone')}>
            <option key="0" value="">
              {intl.formatMessage({ id: 'shared.dropdown.please-select' })}
            </option>
            {props.timezones.map((timezone, index) => {
              return <option key={index + 1}>{timezone}</option>;
            })}
          </select>
        </div>

        <div className="form-group">
          <label>{intl.formatMessage({ id: 'profile.date-format-field' })}</label>
          <select className="form-control" {...register('date_format')}>
            <option key="0" value="">
              {intl.formatMessage({ id: 'shared.dropdown.please-select' })}
            </option>
            <option key="1" value="%d/%m/%Y">
              DD/MM/YYYY
            </option>
            <option key="2" value="%m/%d/%Y">
              MM/DD/YYYY
            </option>
          </select>
        </div>

        {props.defaultValues.theme_preference !== null &&
          <div className="form-group">
            <label>{intl.formatMessage({ id: 'profile.theme-preference-field' })}</label>
            <select className="form-control" {...register('theme_preference', { required: true })}>
              <option value="light">{intl.formatMessage({ id: 'profile.theme-preference.light' })}</option>
              <option value="dark">{intl.formatMessage({ id: 'profile.theme-preference.dark' })}</option>
            </select>
            {errors.theme_preference?.type === 'required' && (
              <div className="field-error">{intl.formatMessage({ id: 'shared.forms.required' })}</div>
            )}
          </div>
        }

        <button type="submit" className="btn btn-primary small angled-bottom-right">
          {intl.formatMessage({ id: 'shared.forms.save' })}
        </button>
      </form>

      <ServerFormErrors errorMessages={errorMessage}></ServerFormErrors>
    </div>
  );
};

EditProfileForm.propTypes = {
  url: PropTypes.string.isRequired,
  method: PropTypes.string.isRequired,
  timezones: PropTypes.array,
  defaultValues: PropTypes.object,
};

export default EditProfileForm;
