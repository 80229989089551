import React from "react";
import MicroEventForm from "./MicroEventForm";
import PropTypes from "prop-types";

import { IntlProvider } from "react-intl";
import { translations, locale } from "../../util/i18n";

const EditMicorEventModal = (props) => {
  return (
    <IntlProvider
      locale={window._FLAIM_LOCALE}
      key={window._FLAIM_LOCALE}
      messages={translations[window._FLAIM_LOCALE]}
    >
      <MicroEventForm url={props.url} method={"PUT"} defaultValues={{ ...props.eventDetails }}></MicroEventForm>
    </IntlProvider>
  );
};

EditMicorEventModal.propTypes = {
  url: PropTypes.string.isRequired,
  eventDetails: PropTypes.object.isRequired,
};

export default EditMicorEventModal;
