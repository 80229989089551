import React from "react";
import RowMenu from "../../util/RowMenu";
import PostLink from "../../util/PostLink";
import Link from "../../util/Link";
import PropTypes from "prop-types";

import { IntlProvider } from "react-intl";
import { translations } from "../../util/i18n";

const QuizSettingMenu = (props) => {
  const options = [
    {
      title: "contents.settings.duplicate",
      component: PostLink,
      params: {
        url: props.duplicateUrl,
        notice: "contents.settings.content-duplicated",
      },
    },
  ];

  if (props.editUrl) {
    options.unshift({
      title: "contents.settings.edit",
      component: Link,
      params: {
        url: props.editUrl,
      },
    });
  }
  return (
    <IntlProvider
      locale={window._FLAIM_LOCALE}
      key={window._FLAIM_LOCALE}
      messages={translations[window._FLAIM_LOCALE]}
    >
      <RowMenu
        classNameForButton="btn-secondary angled-top-left"
        classNameForDropdown={"for-btn-secondary"}
        title={"shared.settings"}
        options={options}
        leftIcon="fa-cog"
      ></RowMenu>
    </IntlProvider>
  );
};

QuizSettingMenu.propTypes = {
  editUrl: PropTypes.string.isRequired,
  duplicateUrl: PropTypes.string.isRequired,
};

export default QuizSettingMenu;
