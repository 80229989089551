import React from "react";
import AssignOrganisationForm from "./AssignOrganisationForm";
import PropTypes from "prop-types";

import { IntlProvider } from "react-intl";
import { translations, locale } from "../../util/i18n";

const AssignOrganisationModal = (props) => {
  return (
    <IntlProvider
      locale={window._FLAIM_LOCALE}
      key={window._FLAIM_LOCALE}
      messages={translations[window._FLAIM_LOCALE]}
    >
      <AssignOrganisationForm url={props.url} organisations={props.organisations}></AssignOrganisationForm>
    </IntlProvider>
  );
};

AssignOrganisationModal.propTypes = {
  url: PropTypes.string.isRequired,
  organisations: PropTypes.array.isRequired,
};

export default AssignOrganisationModal;
