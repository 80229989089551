import React from "react";
import RowMenu from "../util/RowMenu";
import Link from "../util/Link";
import PropTypes from "prop-types";

import { IntlProvider, useIntl } from "react-intl";
import { translations } from "../util/i18n";

const MyAccountMenu = (props) => {
  const intl = useIntl();
  const options = [
    {
      title: props.orgAdminView ? "profile.dropdown.student-view" : "profile.dropdown.org-admin-view",
      component: Link,
      params: {
        url: props.orgAdminView ? "/student/dash" : "/org/dash",
      },
    },
  ];

  if (props.noDropdown) {
    return (
      <span>
        {intl.formatMessage({
          id: props.orgAdminView ? "profile.dropdown.org-admin-view" : "profile.dropdown.student-view",
        })}
      </span>
    );
  } else {
    return (
      <RowMenu
        classNameForButton="btn-secondary"
        title={props.orgAdminView ? "profile.dropdown.org-admin-view" : "profile.dropdown.student-view"}
        options={options}
      ></RowMenu>
    );
  }
};

MyAccountMenu.propTypes = {
  orgAdminView: PropTypes.bool,
  noDropdown: PropTypes.bool,
};

const WithIntlProvider = (props) => {
  return (
    <IntlProvider
      locale={window._FLAIM_LOCALE}
      key={window._FLAIM_LOCALE}
      messages={translations[window._FLAIM_LOCALE]}
    >
      <MyAccountMenu {...props} />
    </IntlProvider>
  );
};

export default WithIntlProvider;
