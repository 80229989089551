import React from 'react';
import PropTypes from "prop-types";
import { IntlProvider, useIntl } from "react-intl";
import { translations } from "../../util/i18n";

const UnitTypeSelect = React.forwardRef(({
  emptyOption,
  allowedUnits,
  ...props
}, ref) => {
  const intl = useIntl();

  const allUnits = [
    { value: "extinguisher", id: 'units.unit-type.extinguisher' },
    { value: "trainer", id: 'units.unit-type.trainer' },
    { value: "sweeper", id: 'units.unit-type.sweeper' }
  ];

  return (
    <select ref={ref} className="form-control" {...props}>
      {emptyOption && <option value="">{intl.formatMessage({ id: 'shared.dropdown.please-select' })}</option>}
      {allUnits.map(unit =>
        allowedUnits.includes(unit.value) &&
        <option key={unit.value} value={unit.value}>
          {intl.formatMessage({ id: unit.id })}
        </option>
      )}
    </select>
  );
});

UnitTypeSelect.displayName = 'UnitTypeSelect';

UnitTypeSelect.propTypes = {
  allowedUnits: PropTypes.arrayOf(PropTypes.string),
  emptyOption: PropTypes.bool
};

UnitTypeSelect.defaultProps = {
  allowedUnits: [],
  emptyOption: false
};

export default UnitTypeSelect;

export const WithIntlProvider = (props) => {
  return (
    <IntlProvider
      locale={window._FLAIM_LOCALE}
      key={window._FLAIM_LOCALE}
      messages={translations[window._FLAIM_LOCALE]}
    >
      <UnitTypeSelect {...props} />
    </IntlProvider>
  );
};
