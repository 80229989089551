import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import ServerFormErrors from '../../util/ServerFormErrors';
import ax from '../../util/axios';
import { IntlProvider, useIntl } from 'react-intl';
import { translations } from '../../util/i18n';
import convertToFormData from '../../util/upload';
import UnitTypeSelect from '../units/UnitTypeSelect';

const ScenarioForm = (props) => {
  const intl = useIntl();
  const [errorMessage, setErrorMessages] = useState();
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    watch,
    formState: { errors },
  } = useForm({ defaultValues: { ...props.defaultValues } });
  const onSubmit = (data) => {
    const formData = convertToFormData(data, ['image']);
    ax({
      method: props.method,
      url: props.url,
      data: formData,
    })
      .then(function (response) {
        window.location = response.data.return_url;
      })
      .catch((err) => {
        setErrorMessages(err.response.data);
      });
  };

  watch('image');
  watch('delete_image');

  const changePhoto = () => {
    const imageInput = document.getElementsByName('image')[0];
    imageInput.click();
  };

  const deletePhoto = () => {
    const imageInput = document.getElementsByName('image')[0];
    imageInput.value = '';
    setValue('delete_image', true);
    const event = new Event('change');
    imageInput.dispatchEvent(event);
  };

  const showImage =
    (props.defaultValues.image_url && !getValues('delete_image')) || (getValues('image') && getValues('image')[0]);

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="row">
            <div className="form-group">
              <label>{intl.formatMessage({ id: 'admin-area.scenarios.id-field' })}</label>
              <input
                className="form-control"
                disabled={props.published}
                {...register('scenario_identity', { required: true, pattern: /^[a-zA-Z0-9]+$/ })}
              ></input>
              {errors.scenario_identity?.type === 'required' && (
                <div className="field-error">{intl.formatMessage({ id: 'shared.forms.required' })}</div>
              )}
              {errors.scenario_identity?.type === 'pattern' && (
                <div className="field-error">
                  {intl.formatMessage({ id: 'admin-area.scenarios.errors.scenario_identity_format' })}
                </div>
              )}
            </div>
            <div className="form-group">
              <label>{intl.formatMessage({ id: 'admin-area.scenarios.public-scenario-field' })}</label>
              <select className="form-control" {...register('public_scenario')} disabled={props.published}>
                <option value="true">{intl.formatMessage({ id: 'shared.public' })}</option>
                <option value="false">{intl.formatMessage({ id: 'shared.private' })}</option>
              </select>
            </div>
          </div>

          <div>
            <div className="form-group">
              <label>{intl.formatMessage({ id: 'admin-area.scenarios.name-field' })}</label>
              <input className="form-control" {...register('name', { required: true })} disabled={props.published}></input>
              {errors.name?.type === 'required' && (
                <div className="field-error">{intl.formatMessage({ id: 'shared.forms.required' })}</div>
              )}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="form-group">
            <label>{intl.formatMessage({ id: 'admin-area.scenarios.environment-field' })}</label>
            <input className="form-control" {...register('setting', { required: true })} disabled={props.published}></input>
            {errors.setting?.type === 'required' && (
              <div className="field-error">{intl.formatMessage({ id: 'shared.forms.required' })}</div>
            )}
          </div>

          <div className="form-group">
            <label>{intl.formatMessage({ id: 'admin-area.scenarios.incident-field' })}</label>
            <input className="form-control" {...register('fire_event', { required: true })} disabled={props.published}></input>
            {errors.fire_event?.type === 'required' && (
              <div className="field-error">{intl.formatMessage({ id: 'shared.forms.required' })}</div>
            )}
          </div>

          <div className="form-group">
            <label>{intl.formatMessage({ id: 'admin-area.scenarios.status-field' })}</label>
            <select className="form-control" {...register('status')} disabled={props.published}>
              <option value="draft">{intl.formatMessage({ id: 'shared.status.draft' })}</option>
              <option value="published">{intl.formatMessage({ id: 'shared.status.published' })}</option>
            </select>
          </div>
        </div>

        { props.defaultValues.id &&
          <div className="row">
            <div className="form-group">
              <label>{intl.formatMessage({ id: 'admin-area.scenarios.scenario-type' })}</label>
              <select className="form-control" {...register('device_type')} disabled={true}>
                {props.scenarioDeviceTypes.map((unitType) => (
                  <option key={unitType} value={unitType}>{intl.formatMessage({ id: 'admin-area.scenarios.scenario-type.' + unitType })}</option>
                ))}
              </select>
            </div>
            <div></div>
          </div>
        }

        <div className="row scenario-form-description-row">
          <div className="form-group">
            <label>{intl.formatMessage({ id: 'admin-area.scenarios.description-field' })}</label>
            <textarea rows="5" className="form-control" {...register('description')}></textarea>
          </div>

          <div className="form-group">
            <label>{intl.formatMessage({ id: 'admin-area.scenarios.image-field' })}</label>
            <div className={`form-control image-wrapper feature-image ${showImage ? '' : 'add-image'}`}>
              {showImage && (
                <img
                  src={
                    getValues('image') && getValues('image')[0]
                      ? URL.createObjectURL(new Blob(getValues('image')))
                      : props.defaultValues.image_url
                  }
                />
              )}
              <div className="image-actions">
                {showImage && (
                  <>
                    <a onClick={changePhoto}>
                      <i className="fas fa-pencil" />
                      &nbsp; {intl.formatMessage({ id: 'image-upload.change' })}
                    </a>
                    <a onClick={deletePhoto}>
                      <i className="fas fa-trash" />
                      &nbsp; {intl.formatMessage({ id: 'image-upload.delete' })}
                    </a>
                  </>
                )}
                {!showImage && (
                  <a onClick={changePhoto}>
                    <i className="fas fa-plus" />
                    &nbsp; {intl.formatMessage({ id: 'image-upload.add' })}
                  </a>
                )}
              </div>
            </div>
            <input
              type="file"
              accept="image/jpeg,image/png"
              multiple={false}
              style={{ display: 'none' }}
              className="form-control"
              {...register('image', {
                validate: { lessThan1MB: (files) => !files[0] || files[0]?.size < 1024 * 1024 },
              })}
            ></input>
            {errors.image?.type === 'lessThan1MB' && (
              <div className="field-error">{intl.formatMessage({ id: 'shared.forms.file_too_big' })}</div>
            )}
          </div>
        </div>

        <div className="row">
          <div className="form-group">
            <label>{intl.formatMessage({ id: 'admin-area.scenarios.instruction-video-field' })}</label>
            <input className="form-control" {...register('instruction_video')}></input>
          </div>
          <div></div>
        </div>

        <button type="submit" className="btn btn-primary small angled-bottom-right">
          {intl.formatMessage({ id: 'shared.forms.save' })}
        </button>
      </form>

      <ServerFormErrors errorMessages={errorMessage}></ServerFormErrors>
    </div>
  );
};

ScenarioForm.propTypes = {
  url: PropTypes.string.isRequired,
  method: PropTypes.string.isRequired,
  defaultValues: PropTypes.object,
  scenarioDeviceTypes: PropTypes.object,
  published: PropTypes.bool.isRequired, default: false
};

export default ScenarioForm;

export const WithIntlProvider = (props) => {
  return (
    <IntlProvider
      locale={window._FLAIM_LOCALE}
      key={window._FLAIM_LOCALE}
      messages={translations[window._FLAIM_LOCALE]}
    >
      <ScenarioForm {...props} />
    </IntlProvider>
  );
};
