import React, { useContext } from 'react';
import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import 'moment/locale/en-au';
import moment from 'moment';
import { useIntl } from 'react-intl';
import AsyncSelect from 'react-select/async';
import _ from 'lodash';
import customSelectStyle from '../util/custom_react_select_style';
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { SearchBarContext } from '../../../../components/SearchBar';
import SearchBarRow from '../../../../components/SearchBar/SearchBarRow';

import DatePicker, { registerLocale } from 'react-datepicker';
import de from 'date-fns/locale/de';
registerLocale('de', de);
import es from 'date-fns/locale/es';
registerLocale('es', es);
import fr from 'date-fns/locale/fr';
registerLocale('fr', fr);

const cspNonce = document.querySelector('meta[name="csp-nonce"]').content;
export const styleCache = createCache({
  key: 'capture-prefix',
  nonce: cspNonce,
});

const PrimarySearchItems = (props) => {
  const intl = useIntl();
  const { register } = useContext(SearchBarContext);
  return (
    <SearchBarRow>
      {!props.forStudent && (
        <>
          <div className="form-group" style={{ flex: 1 }}>
            <label>{intl.formatMessage({ id: 'simulations.search.external-employee-id-field' })}</label>
            <input className="form-control" {...register('externalEmployeeId')}></input>
          </div>

          <div className="form-group" style={{ flex: 1 }}>
            <label>{intl.formatMessage({ id: 'simulations.search.student-name-field' })}</label>
            <input className="form-control" {...register('studentName')}></input>
          </div>
        </>
      )}
      <div className="form-group" style={{ flex: 1 }}>
        <label>{intl.formatMessage({ id: 'simulations.search.trainer-name-field' })}</label>
        <input className="form-control" {...register('trainerName')}></input>
      </div>

      <div className="form-group" style={{ flex: 1 }}>
        <label>{intl.formatMessage({ id: 'simulations.search.simulation-name-field' })}</label>
        <input className="form-control" {...register('simulationName')}></input>
      </div>
    </SearchBarRow>
  );
};

PrimarySearchItems.propTypes = {
  forStudent: PropTypes.bool.isRequired,
};

const SecondarySearchItems = (props) => {
  const intl = useIntl();
  const { register, setValue, control } = useContext(SearchBarContext);

  const loadOrgs = (inputValue) => {
    return fetch(`/admin/organisations/dropdown_search?term=${encodeURIComponent(inputValue)}`).then((res) =>
      res.json(),
    );
  };

  const wait = 500;

  const debouncedLoadOptions = React.useCallback(
    _.debounce((inputValue, callback) => {
      loadOrgs(inputValue).then((options) => callback(options));
    }, wait),
    [],
  );

  return (
    <>
      <SearchBarRow>
        <div className="form-group" style={{ flex: 1 }}>
          <label>{intl.formatMessage({ id: 'simulations.search.unit-name-field' })}</label>
          <input className="form-control" {...register('unitName')}></input>
        </div>

        <div className="form-group" style={{ flex: 1 }}>
          <label>{intl.formatMessage({ id: 'simulations.search.total-time-field' })}</label>
          <div className="date-range">
            <input
              type="number"
              step="1"
              className="form-control"
              placeholder={intl.formatMessage({ id: 'simulations.search.select-total-time-start' })}
              {...register('totalTimeStart')}
            ></input>
            <input
              type="number"
              step="1"
              className="form-control"
              placeholder={intl.formatMessage({ id: 'simulations.search.select-total-time-end' })}
              {...register('totalTimeEnd')}
            ></input>
          </div>
        </div>

        <div className="form-group" style={{ flex: 1 }}>
          <label>{intl.formatMessage({ id: 'simulations.search.date-record-field' })}</label>
          <div className="date-range">
            <Controller
              control={control}
              name="dateRecordStart"
              render={({ field }) => (
                <DatePicker
                  name="dateRecordStart"
                  className="form-control"
                  placeholderText={intl.formatMessage({ id: 'shared.forms.start_date' })}
                  selected={field.value ? moment(field.value).toDate() : undefined}
                  onChange={(date) => field.onChange(date)}
                  dateFormat={window._FLAIM_DATE_FORMAT}
                  locale={window._FLAIM_LOCALE}
                />
              )}
            />

            <Controller
              control={control}
              name="dateRecordEnd"
              render={({ field }) => (
                <DatePicker
                  name="dateRecordEnd"
                  className="form-control"
                  placeholderText={intl.formatMessage({ id: 'shared.forms.end_date' })}
                  selected={field.value ? moment(field.value).toDate() : undefined}
                  onChange={(date) => field.onChange(date)}
                  dateFormat={window._FLAIM_DATE_FORMAT}
                />
              )}
            />
          </div>
        </div>

        {!props.forStudent && (
          <div className="form-group" style={{ flex: 1 }}>
            <label>{intl.formatMessage({ id: 'simulations.search.unknown-student-field' })}</label>
            <select className="form-control" {...register('students')}>
              <option value="">{intl.formatMessage({ id: 'shared.all' })}</option>
              <option value="known">{intl.formatMessage({ id: 'shared.known' })}</option>
              <option value="unknown">{intl.formatMessage({ id: 'shared.unknown' })}</option>
            </select>
          </div>
        )}
      </SearchBarRow>
      {props.showOrgFilter && (
        <div className="searchbar-secondary-items extended">
          <SearchBarRow>
            <CacheProvider value={styleCache}>
              <div className="form-group" style={{ width: '30%' }}>
                <label>{intl.formatMessage({ id: 'simulations.search.org-field' })}</label>
                <Controller
                  control={control}
                  name="orgId"
                  render={({ field }) => (
                    <AsyncSelect
                      styles={customSelectStyle}
                      inputId="orgId"
                      name="orgId"
                      inputRef={field.ref}
                      placeholder={
                        <span>{intl.formatMessage({ id: 'simulations.search.org-field-placeholder' })}</span>
                      }
                      onChange={(val) => {
                        setValue('orgName', val && val.label);
                        field.onChange(val && val.value);
                      }}
                      loadOptions={debouncedLoadOptions}
                      getOptionLabel={(e) => e.label}
                      getOptionValue={(e) => e.value}
                      isClearable={true}
                    />
                  )}
                />
              </div>
            </CacheProvider>
          </SearchBarRow>
        </div>
      )}
    </>
  );
};

SecondarySearchItems.propTypes = {
  forStudent: PropTypes.bool.isRequired,
  showOrgFilter: PropTypes.bool.isRequired,
};

export { PrimarySearchItems, SecondarySearchItems };
