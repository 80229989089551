import React from "react";
import ModalLauncher from "../../util/ModalLauncher";
import OrgForm from "./OrgForm";
import PropTypes from "prop-types";

import { IntlProvider } from "react-intl";
import { translations, locale } from "../../util/i18n";

const CreateOrgModal = (props) => {
  const modalContent = () => {
    return <OrgForm url={props.createUrl} method={"POST"}></OrgForm>;
  };

  return (
    <IntlProvider
      locale={window._FLAIM_LOCALE}
      key={window._FLAIM_LOCALE}
      messages={translations[window._FLAIM_LOCALE]}
    >
      <ModalLauncher
        buttonText="admin-area.organisations.create-organisation"
        content={modalContent()}
        title="admin-area.organisations.create-organisation"
      />
    </IntlProvider>
  );
};

CreateOrgModal.propTypes = {
  createUrl: PropTypes.string,
};

export default CreateOrgModal;
