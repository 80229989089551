import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import wrap from '../util/wrap_with_intl_provider';
import Table from '../../../../components/Table';
import ax from '../util/axios';
import { tableParamsBuilder } from '../util/searchUtils';
import StatusLabel from '../../../../components/StatusLabel';
import RightArrowLink from '../../../../components/RightArrowLink';
import SearchBar from '../../../../components/SearchBar';
import { PrimarySearchItems, SecondarySearchItems } from './CourseSearchForm';
import {
  organisation_area_online_courses_course_path,
  admin_area_online_courses_course_path,
} from '../../../../routes';

const CourseTable = (props) => {
  const intl = useIntl();
  const [pagination, setPagination] = useState({});
  const [courses, setCourses] = useState([]);
  const [searchParams, setSearchParams] = useState({});

  const fetchCourses = async (page = 1) => {
    const response = await ax.get(props.coursesUrl + '.json' + tableParamsBuilder({ page, ...searchParams }));
    setPagination(response.data.pagination);
    setCourses(response.data.courses);
  };

  const courseColumns = () => {
    const columns = [
      {
        accessor: 'id',
        header: intl.formatMessage({ id: 'courses.id-field' }),
      },
      {
        accessor: 'title',
        header: intl.formatMessage({ id: 'courses.title-field' }),
      },
      {
        accessor: 'visibility',
        header: intl.formatMessage({ id: 'courses.visibility-field' }),
      },
      {
        accessor: 'currentEnrolments',
        header: intl.formatMessage({ id: 'courses.current-enrolments-field' }),
      },
      {
        accessor: 'completedEnrolments',
        header: intl.formatMessage({ id: 'courses.completed-enrolments-field' }),
      },
      {
        accessor: 'status',
        className: 'status',
        header: intl.formatMessage({ id: 'shared.status' }),
      },
      {
        className: 'actions',
        accessor: 'courseLink',
      },
    ];

    if (props.isOrgArea) {
      columns.splice(5, 0, {
        accessor: 'inCatalogue',
        header: intl.formatMessage({ id: 'courses.in-catalogue-field' }),
        className: 'yes-no-icon',
      });
    }

    return columns;
  };

  const mappedCourses = courses.map((course) => ({
    ...course,
    status: (
      <StatusLabel
        text={intl.formatMessage({ id: `shared.status.${course.status}` })}
        status={course.status === 'published' ? 'passed' : 'failed'}
      />
    ),
    inCatalogue: course.inCatalogue ? <i className="fas fa-check-circle" /> : <i className="fas fa-ban" />,
    courseLink: (
      <RightArrowLink
        url={
          props.isOrgArea
            ? organisation_area_online_courses_course_path(course.id)
            : admin_area_online_courses_course_path(course.id)
        }
      />
    ),
  }));

  useEffect(() => {
    fetchCourses();
  }, [searchParams]);

  return (
    <div>
      <SearchBar
        primaryItems={<PrimarySearchItems />}
        secondaryItems={<SecondarySearchItems organisations={props.organisations} />}
        onSearch={setSearchParams}
      />
      {courses.length > 0 && (
        <Table
          header={true}
          items={mappedCourses}
          columns={courseColumns()}
          pagination={pagination}
          onPageChange={fetchCourses}
        />
      )}
    </div>
  );
};

CourseTable.propTypes = {
  coursesUrl: PropTypes.string.isRequired,
  isOrgArea: PropTypes.bool.isRequired,
  organisations: PropTypes.array.isRequired,
};

export default wrap(CourseTable);
