import React from "react";
import RowMenu from "../../util/RowMenu";
import Link from "../../util/Link";
import PropTypes from "prop-types";

import { IntlProvider } from "react-intl";
import { translations, locale } from "../../util/i18n";

const CreateContentMenu = (props) => {
  const options = [
    {
      title: "contents.kind.presentation",
      component: Link,
      params: {
        url: props.newPresentationUrl,
      },
    },
    {
      title: "contents.kind.articulate",
      component: Link,
      params: {
        url: `${props.newArticulateUrl}`,
      },
    },
    {
      title: "contents.kind.quiz",
      component: Link,
      params: {
        url: props.newQuizUrl,
      },
    },
  ];

  return (
    <IntlProvider locale={locale} key={locale} messages={translations[locale]}>
      <RowMenu title={"contents.create-content"} options={options} classNameForDropdown="for-btn-primary"></RowMenu>
    </IntlProvider>
  );
};

CreateContentMenu.propTypes = {
  newPresentationUrl: PropTypes.string.isRequired,
  newArticulateUrl: PropTypes.string.isRequired,
  newQuizUrl: PropTypes.string.isRequired,
};

export default CreateContentMenu;
