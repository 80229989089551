import ReactOnRails from 'react-on-rails';
import UserTable from '../bundles/main/components/users/UserTable';
import CreateUserModal from '../bundles/main/components/admin_area/users/CreateUserModal';

import OrganisationTable from '../bundles/main/components/admin_area/organisations/OrganisationTable';
import CreateOrgModal from '../bundles/main/components/admin_area/organisations/CreateOrgModal';
import { WithIntlProvider as OrgForm } from '../bundles/main/components/admin_area/organisations/OrgForm';

import { WithIntlProvider as UnitForm } from '../bundles/main/components/admin_area/units/UnitForm';
import AdminAddUnitMenu from '../bundles/main/components/admin_area/units/AdminAddUnitMenu';

import SearchScenario from '../bundles/main/components/admin_area/scenarios/SearchScenario';
import CreateMicroEventModal from '../bundles/main/components/admin_area/scenarios/CreateMicroEventModal';
import AdminMicroEventRowMenu from '../bundles/main/components/admin_area/scenarios/AdminMicroEventRowMenu';
import { WithIntlProvider as ScenarioForm } from '../bundles/main/components/admin_area/scenarios/ScenarioForm';
import AdminScenarioRowMenu from '../bundles/main/components/admin_area/scenarios/AdminScenarioRowMenu';

import EditSimulationModal from '../bundles/main/components/simulations/EditSimulationModal';
import { WithIntlProvider as SimulationEvents } from '../bundles/main/components/simulations/SimulationEvents';

import CreateNoteModal from '../bundles/main/components/admin_area/units/CreateNoteModal';
import AdminNotesRowMenu from '../bundles/main/components/admin_area/units/AdminNotesRowMenu';

import { WithIntlProvider as UsageChart } from '../bundles/main/components/admin_area/units/UsageChart';
import { WithIntlProvider as SimulationCountBarChart } from '../bundles/main/components/charts/SimulationCountBarChart';
import { WithIntlProvider as AverageScoreByScenarioBarChart } from '../bundles/main/components/charts/AverageScoreByScenarioBarChart';

import MyAccountMenu from '../bundles/main/components/admin_area/users/MyAccountMenu';

import { WithIntlProvider as SignUpForm } from '../bundles/main/components/students/SignUpForm';
import AddStudentMenu from '../bundles/main/components/students/AddStudentMenu';
import { WithIntlProvider as ImportStudentsForm } from '../bundles/main/components/students/ImportStudentsForm';

import CreateCustomReportModal from '../bundles/main/components/admin_area/custom_reports/CreateCustomReportModal';
import AdminCustomReportMenu from '../bundles/main/components/admin_area/custom_reports/AdminCustomReportMenu';

import AssignSimulationUserModal from '../bundles/main/components/simulations/AssignSimulationUserModal';
import SimulationTable from '../bundles/main/components/simulations/SimulationTable';

import UnitTable from '../bundles/main/components/units/UnitTable';

import StudentTable from '../bundles/main/components/students/StudentTable';

import UnitSimulationsSettingMenu from '../bundles/main/components/admin_area/units/UnitSimulationsSettingMenu';

import AssignSimulationModalLauncher from '../bundles/main/components/simulations/AssignSimulationModalLauncher';

import CreatePerformanceEventModal from '../bundles/main/components/admin_area/settings/performance_events/CreatePerformanceEventModal';
import AdminPerformanceEventRowMenu from '../bundles/main/components/admin_area/settings/performance_events/AdminPerformanceEventRowMenu';

import AddEventModal from '../bundles/main/components/admin_area/scenarios/AddEventModal';
import AdminEventRowMenu from '../bundles/main/components/admin_area/scenarios/AdminEventRowMenu';

import ProfileMenu from '../bundles/main/components/profiles/ProfileMenu';

import { WithIntlProvider as SimulationPerformanceEvents } from '../bundles/main/components/simulations/SimulationPerformanceEvents';
import { WithIntlProvider as SimulationObservationEvents } from '../bundles/main/components/simulations/SimulationObservationEvents';

import OrgMicroEventRowMenu from '../bundles/main/components/organisation_area/scenarios/OrgMicroEventRowMenu';

import AddApprovedOrganisationModalLauncher from '../bundles/main/components/admin_area/scenarios/AddApprovedOrganisationModalLauncher';
import ApprovedOrganisationRowMenu from '../bundles/main/components/approved_organisaitons/ApprovedOrgRowMenu';
import AddLearningOutcomeModalLauncher from '../bundles/main/components/admin_area/scenarios/AddLearningOutcomeModalLauncher';
import AdminScenarioLearningOutcomeRowMenu from '../bundles/main/components/admin_area/scenarios/AdminScenarioLearningOutcomeRowMenu';

import LanguageMenu from '../bundles/main/components/profiles/LanguageMenu';

import DeleteLinkWithIcon from '../bundles/main/components/util/DeleteLinkWithIcon';
import ContentTable from '../bundles/main/components/online_courses/ContentTable';
import CreateContentMenu from '../bundles/main/components/admin_area/online_courses/CreateContentMenu';
import { WithIntlProvider as QuizForm } from '../bundles/main/components/admin_area/online_courses/QuizForm';
import QuizSettingMenu from '../bundles/main/components/admin_area/online_courses/QuizSettingMenu';
import { WithIntlProvider as QuestionForm } from '../bundles/main/components/admin_area/online_courses/QuestionForm';

import { WithIntlProvider as PresentationForm } from '../bundles/main/components/admin_area/online_courses/PresentationForm';

import CourseTable from '../bundles/main/components/online_courses/CourseTable';
import { WithIntlProvider as CourseForm } from '../bundles/main/components/admin_area/online_courses/courses/CourseForm';
import { WithIntlProvider as CourseContentTab } from '../bundles/main/components/admin_area/online_courses/courses/CourseContentTab';
import SearchEnrolment from '../bundles/main/components/enrolments/SearchEnrolmentForCourseForm';
import AdminEnrolmentRowManual from '../bundles/main/components/admin_area/online_courses/courses/AdminEnrolmentRowMenu';
import AnswerQuestionForm from '../bundles/main/components/students/AnswerQuestionForm';
import { WithIntlProvider as EnrolmentActivity } from '../bundles/main/components/students/enrolments/EnrolmentActivity';
import CourseSettingMenu from '../bundles/main/components/admin_area/online_courses/courses/CourseSettingMenu';
import LoadingBar from '../bundles/main/components/charts/LoadingBar';
import EnrolStudentMenu from '../bundles/main/components/organisation_area/enrolments/EnrolStudentMenu';
import SearchStudentForInvitationForm from '../bundles/main/components/organisation_area/enrolments/SearchStudentForInvitationForm';

import MoreInfo from '../bundles/main/components/util/MoreInfo';
import NewEnrolmentForm from '../bundles/main/components/public_area/enrolments/NewEnrolmentForm';

import AdminEventCategoryRowMenu from '../bundles/main/components/admin_area/settings/event_categories/AdminEventCategoryRowMenu';
import CreateEventCategoryModal from '../bundles/main/components/admin_area/settings/event_categories/CreateEventCategoryModal';

import MicroEventTable from '../bundles/main/components/admin_area/settings/micro_events/MicroEventTable';
import SettingsCreateMicroEventModal from '../bundles/main/components/admin_area/settings/micro_events/CreateMicroEventModal';

import CreateTimelineEventModal from '../bundles/main/components/admin_area/settings/timeline_events/CreateTimelineEventModal';

import CreateObservationEventModal from '../bundles/main/components/admin_area/settings/observation_events/CreateObservationEventModal';
import ObservationEventRowMenu from '../bundles/main/components/admin_area/settings/observation_events/ObservationEventRowMenu';

import EventLibraryRowMenu from '../bundles/main/components/admin_area/settings/event_libraries/EventLibraryRowMenu';
import ImportLibraryEventsForm from '../bundles/main/components/admin_area/settings/event_libraries/ImportLibraryEventsForm';

import AddMicroEventsModal from '../components/AddMicroEventsModal';

import EnvironmentIndex from '../bundles/main/components/admin_area/settings/environments/EnvironmentIndex';
import EnvironmentNew from '../bundles/main/components/admin_area/settings/environments/EnvironmentNew';
import EnvironmentEdit from '../bundles/main/components/admin_area/settings/environments/EnvironmentEdit';
import EnvironmentShow from '../bundles/main/components/admin_area/settings/environments/EnvironmentShow';
import IncidentIndex from '../bundles/main/components/admin_area/settings/incidents/IncidentIndex';
import IncidentShow from '../bundles/main/components/admin_area/settings/incidents/IncidentShow';
import IncidentNew from '../bundles/main/components/admin_area/settings/incidents/IncidentNew';
import IncidentEdit from '../bundles/main/components/admin_area/settings/incidents/IncidentEdit';
import IncidentOrganisations from '../bundles/main/components/admin_area/settings/incidents/IncidentOrganisations';
import IncidentObservationEvents from '../bundles/main/components/admin_area/settings/incidents/IncidentObservationEvents';
import IncidentPerformanceEvents from '../bundles/main/components/admin_area/settings/incidents/IncidentPerformanceEvents';

import StatusLabel from '../components/StatusLabel';
import BooleanIcon from '../components/BooleanIcon';
import Tooltip from '../components/Tooltip';
import OutcomeCard from '../components/OutcomeCard';

import ScenarioIndexPage from '../components/scenarios/ScenarioIndexPage';
import UnifiedScenarioIndexPage from '../components/scenarios/UnifiedScenarioIndexPage';
import NewCustomScenarioPage from '../components/scenarios/NewCustomScenarioPage/NewCustomScenarioPage';

import InjectIndex from '../bundles/main/components/admin_area/settings/injects/InjectIndex';
import InjectNew from '../bundles/main/components/admin_area/settings/injects/InjectNew';
import InjectEdit from '../bundles/main/components/admin_area/settings/injects/InjectEdit';
import InjectShow from '../bundles/main/components/admin_area/settings/injects/InjectShow';
import IncidentInjects from '../bundles/main/components/admin_area/settings/incidents/IncidentInjects';
import CourseUnits from '../bundles/main/components/online_courses/CourseUnits';
import UnitCourses from '../bundles/main/components/units/UnitCourses';

import CourseOrganisations from '../bundles/main/components/admin_area/online_courses/courses/CourseOrganisations';
import EnvironmentOrganisations from '../bundles/main/components/admin_area/settings/environments/EnvironmentOrganisations';

import { WrappedImageAlbum as ImageAlbum } from '../components/ImageAlbum';
import ScenarioInjects from '../bundles/main/components/admin_area/scenarios/ScenarioInjects';
import ScenarioObservationEvents from '../bundles/main/components/admin_area/scenarios/ScenarioObservationEvents';
import ScenarioPerformanceEvents from '../bundles/main/components/admin_area/scenarios/ScenarioPerformanceEvents';
import PlaylistTable from "../bundles/main/components/playlists/PlaylistTable";
import UnitPlaylists from '../bundles/main/components/units/UnitPlaylists';
import {WithIntlProvider as PlaylistForm} from "../bundles/main/components/playlists/PlaylistForm";
import {WithIntlProvider as PlaylistShow} from "../bundles/main/components/playlists/PlaylistShow";

ReactOnRails.register({
  UserTable,
  CreateUserModal,
  OrganisationTable,
  CreateOrgModal,
  SearchScenario,
  OrgForm,
  UnitForm,
  AdminAddUnitMenu,
  CreateMicroEventModal,
  AdminMicroEventRowMenu,
  ScenarioForm,
  AdminScenarioRowMenu,
  SimulationEvents,
  EditSimulationModal,
  CreateNoteModal,
  AdminNotesRowMenu,
  UsageChart,
  SimulationCountBarChart,
  AverageScoreByScenarioBarChart,
  MyAccountMenu,
  SignUpForm,
  AddStudentMenu,
  ImportStudentsForm,
  CreateCustomReportModal,
  AdminCustomReportMenu,
  AssignSimulationUserModal,
  SimulationTable,
  UnitTable,
  StudentTable,
  UnitSimulationsSettingMenu: UnitSimulationsSettingMenu,
  AssignSimulationModalLauncher,
  CreatePerformanceEventModal,
  AdminPerformanceEventRowMenu,
  AddEventModal,
  AdminEventRowMenu,
  ProfileMenu,
  SimulationPerformanceEvents,
  SimulationObservationEvents,
  OrgMicroEventRowMenu,
  AddApprovedOrganisationModalLauncher,
  ApprovedOrganisationRowMenu,
  AddLearningOutcomeModalLauncher,
  AdminScenarioLearningOutcomeRowMenu,
  LanguageMenu,
  DeleteLinkWithIcon,
  ContentTable,
  CreateContentMenu,
  QuizForm,
  QuizSettingMenu,
  QuestionForm,
  PresentationForm,
  CourseTable,
  CourseForm,
  CourseContentTab,
  SearchEnrolment,
  AdminEnrolmentRowManual,
  AnswerQuestionForm,
  EnrolmentActivity,
  CourseSettingMenu,
  LoadingBar,
  EnrolStudentMenu,
  SearchStudentForInvitationForm,
  MoreInfo,
  NewEnrolmentForm,
  AdminEventCategoryRowMenu,
  CreateEventCategoryModal,
  MicroEventTable,
  SettingsCreateMicroEventModal,
  CreateTimelineEventModal,
  CreateObservationEventModal,
  ObservationEventRowMenu,
  EventLibraryRowMenu,
  ImportLibraryEventsForm,
  AddMicroEventsModal,
  StatusLabel,
  BooleanIcon,
  Tooltip,
  OutcomeCard,
  EnvironmentIndex,
  EnvironmentNew,
  EnvironmentEdit,
  EnvironmentShow,
  IncidentIndex,
  IncidentShow,
  IncidentEdit,
  IncidentNew,
  IncidentOrganisations,
  IncidentObservationEvents,
  IncidentPerformanceEvents,
  ScenarioIndexPage,
  UnifiedScenarioIndexPage,
  NewCustomScenarioPage,
  InjectIndex,
  InjectNew,
  InjectEdit,
  InjectShow,
  IncidentInjects,
  CourseUnits,
  UnitCourses,
  CourseOrganisations,
  EnvironmentOrganisations,
  ImageAlbum,
  ScenarioInjects,
  ScenarioObservationEvents,
  ScenarioPerformanceEvents,
  PlaylistTable,
  UnitPlaylists,
  PlaylistForm,
  PlaylistShow
});
