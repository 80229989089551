import React from "react";
import { Line } from "react-chartjs-2";
import PropTypes from "prop-types";
import { IntlProvider, useIntl } from "react-intl";
import { locale, translations } from "../../util/i18n";

const UsageChart = (props) => {
  const intl = useIntl();

  const { gridColor, ticksColor, titleColor } = (() => {
    const theme = document.body.getAttribute('data-theme');
    return theme === 'dark'
      ? { gridColor: '#8a9097', ticksColor: '#e5e7ea', titleColor: '#e5e7ea' }
      : { gridColor: '#64686A', ticksColor: '#252728', titleColor: '#252728' };
  })();

  const data = {
    labels: props.labels,
    datasets: [
      {
        // label: '# of Simulation for last 8 weeks',
        data: props.usages,
        fill: false,
        backgroundColor: props.brandColor,
        borderColor: props.brandColor,
      },
    ],
  };

  const options = {
    aspectRatio: 3,
    scales: {
      y: {
        grid: {
          color: gridColor,
          borderColor: gridColor,
        },
        ticks: {
          stepSize: 1,
          color: ticksColor,
          font: {
            size: 13,
            weight: '700',
            family: "Adrianna",
          },
        },
        title: {
          display: true,
          text: intl.formatMessage({ id: "shared.chart.number-of-simulations" }),
          color: titleColor,
          font: {
            size: 13,
            weight: '700',
            family: "Adrianna",
          },
        },
      },
      x: {
        grid: {
          color: gridColor,
          borderColor: gridColor,
        },
        ticks: {
          color: ticksColor,
          font: {
            size: 13,
            weight: '700',
            family: "Adrianna",
          },
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  return <Line data={data} options={options} />;
};

UsageChart.propTypes = {
  usages: PropTypes.array,
  labels: PropTypes.array,
  brandColor: PropTypes.string,
};

export default UsageChart;

export const WithIntlProvider = (props) => {
  return (
    <IntlProvider
      locale={window._FLAIM_LOCALE}
      key={window._FLAIM_LOCALE}
      messages={translations[window._FLAIM_LOCALE]}
    >
      <UsageChart {...props} />
    </IntlProvider>
  );
};
