import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import wrap from '../util/wrap_with_intl_provider';
import Table from '../../../../components/Table';
import ax from '../util/axios';
import { tableParamsBuilder } from '../util/searchUtils';
import StatusLabel from '../../../../components/StatusLabel';
import RightArrowLink from '../../../../components/RightArrowLink';
import SearchBar from '../../../../components/SearchBar';
import { PrimarySearchItems, SecondarySearchItems } from './ContentSearchForm';
import {
  organisation_area_online_courses_quiz_path,
  admin_area_online_courses_quiz_path,
  organisation_area_online_courses_presentation_path,
  admin_area_online_courses_presentation_path,
  organisation_area_online_courses_articulate_path,
  admin_area_online_courses_articulate_path,
} from '../../../../routes';

const ContentTable = (props) => {
  const intl = useIntl();
  const [pagination, setPagination] = useState({});
  const [contents, setContents] = useState([]);
  const [searchParams, setSearchParams] = useState({});

  const fetchContents = async (page = 1) => {
    const response = await ax.get(props.contentsUrl + '.json' + tableParamsBuilder({ page, ...searchParams }));
    setPagination(response.data.pagination);
    setContents(response.data.contents);
  };

  const contentColumns = [
    {
      accessor: 'title',
      header: intl.formatMessage({ id: 'contents.title-field' }),
    },
    {
      accessor: 'kind',
      header: intl.formatMessage({ id: 'contents.kind-field' }),
    },
    {
      accessor: 'visibility',
      header: intl.formatMessage({ id: 'contents.visibility-field' }),
    },
    {
      accessor: 'courseCounters',
      header: intl.formatMessage({ id: 'contents.number-of-connected-courses' }),
    },
    {
      accessor: 'status',
      className: 'status',
      header: intl.formatMessage({ id: 'shared.status' }),
    },
    {
      className: 'actions',
      accessor: 'contentLink',
    },
  ];

  const renderVisibility = (content) => {
    let owner;
    if (content.createdByOrgId) {
      if (props.isOrganisationAdmin && content.createdByOrgId == props.currentUserOrgId) {
        owner = intl.formatMessage({ id: 'contents.owned-by-you' });
      } else {
        owner = intl.formatMessage({ id: 'contents.owned-by-org' });
      }
    }
    return owner ? content.visibility + ' ' + owner : content.visibility;
  };

  const renderContentLink = (content) => {
    let contentLink;
    switch (content.kind) {
      case 'quiz':
        contentLink = props.isOrganisationAdmin
          ? organisation_area_online_courses_quiz_path(content.id)
          : admin_area_online_courses_quiz_path(content.id);
        break;
      case 'presentation':
        contentLink = props.isOrganisationAdmin
          ? organisation_area_online_courses_presentation_path(content.id)
          : admin_area_online_courses_presentation_path(content.id);
        break;
      case 'articulate':
        contentLink = props.isOrganisationAdmin
          ? organisation_area_online_courses_articulate_path(content.id)
          : admin_area_online_courses_articulate_path(content.id);
        break;
    }

    return <RightArrowLink url={contentLink} />;
  };

  const mappedContents = contents.map((content) => ({
    ...content,
    kind: content.kindI18n,
    visibility: renderVisibility(content),
    status: (
      <StatusLabel
        text={intl.formatMessage({ id: `shared.status.${content.status}` })}
        status={content.status === 'published' ? 'passed' : 'failed'}
      />
    ),
    contentLink: renderContentLink(content),
  }));

  useEffect(() => {
    fetchContents();
  }, [searchParams]);

  return (
    <div>
      <SearchBar
        primaryItems={<PrimarySearchItems />}
        secondaryItems={<SecondarySearchItems organisations={props.organisations} />}
        onSearch={setSearchParams}
      />
      {contents.length > 0 && (
        <Table
          header={true}
          items={mappedContents}
          columns={contentColumns}
          pagination={pagination}
          onPageChange={fetchContents}
        />
      )}
    </div>
  );
};

ContentTable.propTypes = {
  contentsUrl: PropTypes.string.isRequired,
  isOrganisationAdmin: PropTypes.bool.isRequired,
  currentUserOrgId: PropTypes.number.isRequired,
  organisations: PropTypes.array.isRequired,
};

export default wrap(ContentTable);
