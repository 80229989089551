import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import 'moment/locale/en-au';
import { useIntl } from 'react-intl';
import { SearchBarContext } from '../../../../components/SearchBar';
import SearchBarRow from '../../../../components/SearchBar/SearchBarRow';
import { registerLocale } from 'react-datepicker';
import de from 'date-fns/locale/de';
registerLocale('de', de);
import es from 'date-fns/locale/es';
registerLocale('es', es);
import fr from 'date-fns/locale/fr';
import wrap from '../util/wrap_with_intl_provider';
registerLocale('fr', fr);

const PlaylistSearchForm = (props) => {
  const intl = useIntl();
  const { register } = useContext(SearchBarContext);

  return (
    <SearchBarRow>
      <div className="form-group" style={{ flex: 1 }}>
        <label>{intl.formatMessage({ id: 'playlists.search.id-field' })}</label>
        <input className="form-control" {...register('id')}></input>
      </div>

      <div className="form-group" style={{ flex: 1 }}>
        <label>{intl.formatMessage({ id: 'playlists.search.title-field' })}</label>
        <input className="form-control" {...register('title')}></input>
      </div>

      <div className="form-group" style={{ flex: 1 }}>
        <label>{intl.formatMessage({ id: 'playlists.search.visibility-field' })}</label>
        <select defaultValue={props.visibility || ''} autoFocus className="form-control" {...register('visibility')}>
          {!props.visibility && (
              <option key="0" value="">
                {intl.formatMessage({ id: 'shared.dropdown.please-select' })}
              </option>
          )}
          {[intl.formatMessage({ id: 'shared.public' }), intl.formatMessage({ id: 'shared.private' })].map((visible_attr) => (
            <option key={visible_attr} value={visible_attr}>
              {visible_attr}
            </option>
          ))}
        </select>
      </div>

      {props.showOrgFilter &&
        <div className="form-group" style={{ flex: 1 }}>
          <label>{intl.formatMessage({ id: 'playlists.search.organisation-field' })}</label>
          <select defaultValue={props.organisationId || ''} autoFocus className="form-control" {...register('organisation')}>
            {!props.organisationId && (
              <option key="0" value="">
                {intl.formatMessage({ id: 'shared.dropdown.please-select' })}
              </option>
            )}
            {props.organisations.map((org) => (
              <option key={org.id} value={org.id}>
                {org.name}
              </option>
            ))}
          </select>
        </div>
      }
    </SearchBarRow>
  );
};

PlaylistSearchForm.propTypes = {
  showOrgFilter: PropTypes.bool.isRequired,
  organisations: PropTypes.array,
  organisationId: PropTypes.number,
  visibility: PropTypes.string,
};

export default wrap(PlaylistSearchForm);
