import React, { useContext, useEffect, useState } from 'react';
import ModalLauncher from '../util/ModalLauncher';
import { useIntl } from 'react-intl';
import { ModalContext } from '../util/Modal';
import FlexRight from '../../../../components/utils/FlexRight';
import Table from '../../../../components/Table';
import wrap_with_intl_provider from '../util/wrap_with_intl_provider';
import ax from '../util/axios';
import toastr from 'toastr';
import PropTypes from 'prop-types';
import RowMenu from '../util/RowMenu';
import SearchBar, { SearchBarContext } from '../../../../components/SearchBar';
import SearchBarRow from '../../../../components/SearchBar/SearchBarRow';
import _ from 'lodash';
import { tableParamsBuilder } from '../util/searchUtils';
import ConfirmUnassignModal from './ConfirmUnassignModal';
import {
  admin_area_unit_playlists_path,
  organisation_area_unit_playlists_path,
} from '../../../../routes';

const PrimarySearchItems = (props) => {
  const intl = useIntl();
  const { register } = useContext(SearchBarContext);

  return (
    <SearchBarRow>
      <div className="form-group" style={{ flex: 1 }}>
        <label>{intl.formatMessage({ id: 'units.search.name-field' })}</label>
        <input className="form-control" {...register('name')}></input>
      </div>

      {props.isAdmin && (
        <div className="form-group" style={{ flex: 1 }}>
          <label>{intl.formatMessage({ id: 'units.search.organisation-field' })}</label>
          <input className="form-control" {...register('organisation')}></input>
        </div>
      )}

      <div className="form-group" style={{ flex: 1 }}>
        <label>{intl.formatMessage({ id: 'units.search.serial-no-field' })}</label>
        <input className="form-control" {...register('serial_no')}></input>
      </div>
    </SearchBarRow>
  );
};

PrimarySearchItems.propTypes = {
  isAdmin: PropTypes.bool.isRequired,
};

const AddUnitModal = (props) => {
  const intl = useIntl();
  const [data, setData] = useState();
  const [selectedRows, setSelectedRows] = useState([]);
  const [searchParams, setSearchParams] = useState({});
  const modalContext = useContext(ModalContext);

  const loadUnits = (page = 1, playlist_id = undefined) => {
    ax.get(
  props.unitsUrl + '.json' + tableParamsBuilder({ page, playlist_id, ...searchParams })
    ).then(function (response) {
      setData(response.data);
    }).catch((error) => {
      toastr['error'](error);
    });
  };

  useEffect(() => {
    loadUnits(1, props.formData.id);
  }, [searchParams]);

  const onPageChange = (page) => {
    loadUnits(page, props.formData.id);
  };

  const onRowSelect = ({ selected, id }) => {
    if (selected) {
      let elem = data.units.find((item) => item.id === id);
      setSelectedRows([...selectedRows, elem]);
    } else {
      let elems = selectedRows.filter((item) => item.id != id);
      setSelectedRows(elems);
    }
  };

  const onSelectAll = (selected) => {
    if (selected) {
      let elems = _.uniq([...selectedRows, ...(data.units)]);
      setSelectedRows(elems);
    } else {
      let elems = selectedRows.filter((unit) => data.units.find((elem) => elem.id === unit.id) === undefined);
      setSelectedRows(elems);
    }
  };

  const onAddUnits = (units) => {
    if (confirm(intl.formatMessage({ id: 'playlists.confirmation.unit-assign' }))) {
      props.onAddUnits(units);
      modalContext.closeModal();
    }
  };

  const columns = [
    {
      header: intl.formatMessage({ id: 'units.name-field' }),
      accessor: 'name',
    },
    {
      header: intl.formatMessage({ id: 'units.serial-no-field' }),
      accessor: 'serialNo',
    },
    {
      header: intl.formatMessage({ id: 'units.software-version-field' }),
      accessor: 'softwareVersion',
    },
    {
      header: intl.formatMessage({ id: 'units.search.last-used-field' }),
      accessor: 'lastUsedAt',
    },
  ];

  if (props.isAdmin) {
    columns.splice(1, 0, {
      header: intl.formatMessage({ id: 'units.organisation-field' }),
      accessor: 'organisation',
    });
  }

  const onSearch = (data) => {
    setSearchParams(data);
    setSelectedRows([]);
  };

  if (!data) {
    return;
  } else {
    const { units, pagination } = data;
    const mappedUnits = units.map((unit) => ({ ...unit, selected: selectedRows.find((elem) => elem.id === unit.id) !== undefined }));
    return (
      <div className="add-units">
        <SearchBar primaryItems={<PrimarySearchItems isAdmin={props.isAdmin} />} onSearch={onSearch} />
        <Table
          columns={columns}
          items={mappedUnits}
          pagination={pagination}
          onPageChange={onPageChange}
          onRowSelect={onRowSelect}
          rowSelect={true}
          onSelectAll={onSelectAll}
        ></Table>
        <FlexRight className="gap-1 margin-0.5">
          <button className="btn btn-tertiary" onClick={modalContext.closeModal}>
            {intl.formatMessage({ id: 'shared.cancel' })}
          </button>

          <button
            type='button'
            className="btn btn-primary angled-top-left"
            disabled={!selectedRows.length}
            data-disable-with="..."
            onClick={() => onAddUnits(selectedRows)}
          >
            {intl.formatMessage({ id: 'courses.units.assign-units' })}{' '}
            {!!selectedRows.length && <>({selectedRows.length})</>}
          </button>
        </FlexRight>
      </div>
    );
  }
};

AddUnitModal.propTypes = {
  formData: PropTypes.object.isRequired,
  setFormData: PropTypes.func.isRequired,
  unitsUrl: PropTypes.string.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  onAddUnits: PropTypes.func.isRequired,
};

const PlaylistUnitCreateForm = (props) => {
  const intl = useIntl();

  const modalContent = () => {
    return <AddUnitModal
            formData={props.formData}
            setFormData={props.setFormData}
            unitsUrl={props.unitsUrl}
            isAdmin={props.isAdmin}
            onAddUnits={props.onAddUnits} />;
  };

  const options = (unit) => {
    const opts = [
      {
        title: 'shared.unassign',
        component: ConfirmUnassignModal,
        params: {
          unit: unit,
          confirmMessage: 'units.playlists.unassign-modal-message',
          confirmTitle: 'units.playlists.unassign-modal-title',
          formData: props.formData,
          setFormData: props.setFormData,
          onConfirmUnassign: props.onConfirmUnassign,
        },
      },
    ];

    return opts;
  };

  const columns = [
    {
      header: intl.formatMessage({ id: 'units.name-field' }),
      accessor: 'name',
      className: 'link',
    },
    {
      header: intl.formatMessage({ id: 'units.serial-no-field' }),
      accessor: 'serialNo',
    },
    {
      header: intl.formatMessage({ id: 'units.software-version-field' }),
      accessor: 'softwareVersion',
    },
    {
      header: intl.formatMessage({ id: 'units.search.last-used-field' }),
      accessor: 'lastUsedAt',
    },
    {
      header: '',
      accessor: 'actions',
      className: 'actions',
    },
  ];

  if (props.isAdmin) {
    columns.splice(1, 0, {
      header: intl.formatMessage({ id: 'units.organisation-field' }),
      accessor: 'organisation',
    });
  }

  const mappedItems =
          props.formData.units_attributes &&
          props.formData.units_attributes.map((unit) => ({
            ...unit,
            name: <a href={
              props.isAdmin ? admin_area_unit_playlists_path(unit.id) :
                organisation_area_unit_playlists_path(unit.id)}>{unit.name}</a>,
            actions: <RowMenu options={options(unit)}></RowMenu>,
          }));
  return (
    <>
      <div className="page-header playlist-assign-tab-header">
        <div className='assign-unit-hint'
             dangerouslySetInnerHTML={{ __html: intl.formatMessage({id: 'playlists.assign-units-instruction' })}} />
        <ModalLauncher
          buttonId="btn-add-units"
          buttonText="courses.units.assign-units"
          content={modalContent()}
          title="courses.units.assign-units"
          modalVariation="wide"
        />
      </div>
      {props.formData.units_attributes && (
        <Table columns={columns} items={mappedItems}></Table>
      )}
    </>
  );
};

PlaylistUnitCreateForm.propTypes = {
  formData: PropTypes.object.isRequired,
  setFormData: PropTypes.func.isRequired,
  unitsUrl: PropTypes.string.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  onAddUnits: PropTypes.func.isRequired,
  onConfirmUnassign: PropTypes.func.isRequired,
};

export default wrap_with_intl_provider(PlaylistUnitCreateForm);
