export const tableParamsBuilder = (searchQuery) => {
  const searchParams = new URLSearchParams(window.location.search);

  let url = '';

  for (const key in searchQuery) {
    if (searchQuery[key]) {
      const value = encodeURIComponent(searchQuery[key]);
      url = url + `${key}=${value}&`;
      searchParams.set(key, searchQuery[key]);
    } else {
      searchParams.delete(key);
    }
  }

  if (url) {
    url = `?${url}`;
  }

  return url;
};

export const launchParams = () => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  return Object.fromEntries(urlSearchParams.entries());
};

export const runSearch = (data) => {
  delete data.page;
  let url = tableParamsBuilder(data);

  let newUrl = window.location.protocol + '//' + window.location.host + window.location.pathname + url;
  window.location.href = newUrl;
};
