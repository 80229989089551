import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from '../../bundles/main/components/util/Modal';
import './ActionWithConfirmation.scss';
import { useIntl } from 'react-intl';

const ActionWithConfirmation = ({
  onConfirm,
  children,
  icon,
  title,
  description,
  subTitle,
  confirmButtonText,
  skipConfirmation,
}) => {
  const intl = useIntl();
  const [confirmOpen, setConfirmOpen] = useState(false);
  const onClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (skipConfirmation) onConfirm();
    else setConfirmOpen(true);
  };
  const onClose = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setConfirmOpen(false);
  };
  return (
    <div onClick={onClick}>
      {children}
      {confirmOpen && (
        <Modal
          open={confirmOpen}
          onClose={onClose}
          noTitleTranslation
          modalVariation="confirm-modal"
          content={
            <div className="confirm-modal-content">
              <div className="confirm-modal-icon">
                <i className={icon}></i>
              </div>
              <div className="confirm-modal-title">{title}</div>
              <div className="confirm-modal-description">{description}</div>
              <div className="confirm-modal-subtitle">{subTitle}</div>
              <div className="confirm-modal-actions">
                <button className="btn btn-secondary angled-top-left" onClick={onClose}>
                  {intl.formatMessage({ id: 'shared.cancel' })}
                </button>
                <button className="btn btn-primary angled-bottom-right" onClick={onConfirm}>
                  {confirmButtonText || intl.formatMessage({ id: 'shared.confirm' })}
                </button>
              </div>
            </div>
          }
        />
      )}
    </div>
  );
};

ActionWithConfirmation.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  children: PropTypes.node,
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  confirmButtonText: PropTypes.string,
  skipConfirmation: PropTypes.bool,
};

export default ActionWithConfirmation;
