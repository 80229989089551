import React from "react";
import RowMenu from "../../util/RowMenu";
import PostLink from "../../util/PostLink";
import Link from "../../util/Link";
import PropTypes from "prop-types";

import { IntlProvider } from "react-intl";
import { translations, locale } from "../../util/i18n";

const UnitSimulationsSettingMenu = (props) => {
  const options = [
    {
      title: "units.settings.edit",
      component: Link,
      params: {
        url: props.editUrl,
      },
    },
    {
      title: "units.settings.assign_unallocated_simulations",
      component: PostLink,
      params: {
        url: props.assignSimulationsUrl,
        confirmMessage: "shared.irreversible-confirmation",
        refresh: true,
      },
    },
  ];
  return (
    <IntlProvider
      locale={window._FLAIM_LOCALE}
      key={window._FLAIM_LOCALE}
      messages={translations[window._FLAIM_LOCALE]}
    >
      <RowMenu
        classNameForButton="btn-secondary"
        classNameForDropdown={"for-btn-secondary"}
        title={"shared.settings"}
        options={options}
        leftIcon="fa-cog"
      ></RowMenu>
    </IntlProvider>
  );
};

UnitSimulationsSettingMenu.propTypes = {
  options: PropTypes.array.isRequired,
};

export default UnitSimulationsSettingMenu;
