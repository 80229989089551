import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import wrap from '../../../util/wrap_with_intl_provider';
import PageHeader from '../../../../../../components/PageHeader';
import Card from '../../../../../../components/Card';
import ImageAlbum from '../../../../../../components/ImageAlbum';
import {
  edit_admin_area_settings_inject_path,
  admin_area_settings_injects_path,
  admin_area_settings_inject_path,
  admin_area_settings_incident_path,
} from '../../../../../../routes';

const InjectShow = ({ inject, locked }) => {
  const intl = useIntl();

  const settingPages = [
    {
      link: edit_admin_area_settings_inject_path(inject.id),
      title: intl.formatMessage({ id: 'admin-area.settings.inject.edit.title' }),
      className: 'btn btn-primary angle-top-left',
      icon: 'fa-solid fa-pen',
    }
  ];

  if(!locked) {
    settingPages.unshift(
      {
        link: admin_area_settings_inject_path(inject.id),
        title: intl.formatMessage({ id: 'admin-area.settings.inject.delete.title' }),
        className: 'btn btn-tertiary center',
        icon: 'fa-solid fa-box-archive',
        confirm: intl.formatMessage({ id: 'shared.confirm-delete' }),
        method: 'delete',
        disable: inject.is_deleted,
      }
    )
  }

  return (
    <div>
      <PageHeader
        previousPages={[
          {
            link: admin_area_settings_injects_path(),
            title: intl.formatMessage({ id: 'admin-area.settings.inject.index.title' }),
          },
        ]}
        settingPages={settingPages}
        title={inject.title}
        pageDivider={true}
      />
      <div className="row">
        <Card className="detail">
          <div className="content">
            <div className="label">{intl.formatMessage({ id: 'admin-area.settings.inject.fields.title' })}</div>
            <div className="value">{inject.title}</div>
          </div>
          <div className="content">
            <div className="label">{intl.formatMessage({ id: 'admin-area.settings.inject.fields.inject-id' })}</div>
            <div className="value">{inject.injectId}</div>
          </div>
          <div className="content">
            <div className="label">{intl.formatMessage({ id: 'admin-area.settings.inject.fields.environment' })}</div>
            <div className="value">{inject.environment}</div>
          </div>
          <div className="content">
            <div className="label">{intl.formatMessage({ id: 'admin-area.settings.inject.fields.incident' })}</div>
            <div className="value">
              <a href={admin_area_settings_incident_path(inject.incidentId)}>{inject.incident}</a>
            </div>
          </div>
          <div className="content">
            <div className="label">{intl.formatMessage({ id: 'admin-area.settings.inject.fields.description' })}</div>
            <div dangerouslySetInnerHTML={{ __html: inject.description }} />
          </div>
          <div className="content">
            <div className="label">{intl.formatMessage({ id: 'admin-area.settings.inject.fields.visibility' })}</div>
            <div className="value">
              {intl.formatMessage({ id: `shared.${inject.is_public ? 'public' : 'private'}` })}
            </div>
          </div>
          <div className="content">
            <div className="label">{intl.formatMessage({ id: 'admin-area.settings.inject.fields.locked' })}</div>
            <div className="value">{intl.formatMessage({ id: `shared.${inject.locked ? 'yes' : 'no'}` })}</div>
          </div>
          {inject.is_deleted && (
            <div className="content">
              <div className="label">{intl.formatMessage({ id: 'admin-area.settings.inject.fields.is-deleted' })}</div>
              <div className="value">{intl.formatMessage({ id: `shared.${inject.is_deleted ? 'yes' : 'no'}` })}</div>
            </div>
          )}
        </Card>
        <div>
          {inject.images && inject.images.length > 0 && (
            <ImageAlbum images={inject.images} primaryImageIndex={inject.primaryImageIndex} />
          )}
        </div>
      </div>
    </div>
  );
};

InjectShow.propTypes = {
  inject: PropTypes.shape({
    id: PropTypes.number.isRequired,
    injectId: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    environment: PropTypes.string.isRequired,
    incident: PropTypes.string.isRequired,
    incidentId: PropTypes.number.isRequired,
    description: PropTypes.string,
    is_public: PropTypes.bool,
    is_deleted: PropTypes.bool,
    locked: PropTypes.bool,
    images: PropTypes.arrayOf(
      PropTypes.shape({
        url: PropTypes.string.isRequired,
        id: PropTypes.number.isRequired,
      }),
    ),
    primaryImageIndex: PropTypes.number.isRequired,
  }).isRequired,
  locked: PropTypes.bool, default: false
};

export default wrap(InjectShow);
