import React, { useState } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import PropTypes from "prop-types";
import ServerFormErrors from "../../util/ServerFormErrors";
import ax from "../../util/axios";
import { IntlProvider, useIntl } from "react-intl";
import { locale, translations } from "../../util/i18n";
import Froala from "../../util/Froala";
import { add_submit_overlay, remove_submit_overlay } from "../../util/submit_overlay";

const QuestionForm = (props) => {
  const intl = useIntl();
  const [errorMessage, setErrorMessages] = useState();
  const {
    register,
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({ defaultValues: { ...props.defaultValues } });
  const onSubmit = (data) => {
    if (data.options.length < 2) {
      setErrorMessages([intl.formatMessage({ id: "questions.errors.at-least-2-options" })]);
      return;
    }
    if (data.options.filter((option) => option.isCorrect === "true").length === 0) {
      setErrorMessages([intl.formatMessage({ id: "questions.errors.at-least-1-correct-option" })]);
      return;
    }
    add_submit_overlay();
    ax({
      method: props.method,
      url: props.url,
      data: { form: data },
    })
      .then(function (response) {
        window.location = response.data.return_url;
      })
      .catch((err) => {
        remove_submit_overlay();
        setErrorMessages(err.response.data);
      });
  };

  register("body", { required: true });

  const { fields, append, remove } = useFieldArray({
    control: control,
    name: "options",
  });

  const onFroalaModelChange = (val) => {
    setValue("body", val);
  };
  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <fieldset disabled={!props.allowEdit}>
          {!props.allowEdit && (
            <div className="form-group">
              <div className="form-control fr-view" dangerouslySetInnerHTML={{ __html: getValues("body") }}></div>
            </div>
          )}
          {props.allowEdit && (
            <>
              <Froala
                model={getValues("body")}
                onModelChange={onFroalaModelChange}
                s3Fields={props.s3Fields}
                s3UrlReplaceFrom={props.s3UrlReplaceFrom}
                s3UrlReplaceTo={props.s3UrlReplaceTo}
                isDarkTheme={props.isDarkTheme}
              />
            </>
          )}
          <div className="form-group number-of-attempts-field">
            <label>{intl.formatMessage({ id: "questions.number-of-attempts-field" })}</label>
            <input type="number" min="1" step="1" className="form-control number-of-attempts-input-field" {...register("number_of_attempts")} />
            <div className="field-hint">
              <i className="fa far fa-circle-info" />
              &nbsp;&nbsp;
              <small>{intl.formatMessage({ id: "questions.number-of-attempts-field-hint" })}</small>
            </div>
          </div>

          <ul>
            {fields.map((item, index) => {
              return (
                <li className="question-option-section" key={item.id}>
                  <input type="hidden" defaultValue={item.dbId} {...register(`options[${index}].dbId`)} />
                  <div className="top-area">
                    <div className="is-correct-section">
                      <input
                        className="custom-radio"
                        id={`check-correct-${index}`}
                        type="radio"
                        name={`options[${index}].isCorrect`}
                        value="true"
                        defaultChecked={item.isCorrect}
                        {...register(`options[${index}].isCorrect`, { required: true })}
                      />
                      <label htmlFor={`check-correct-${index}`}>
                        {intl.formatMessage({ id: "questions.options.correct" })}
                      </label>
                      &nbsp;&nbsp;
                      <input
                        className="custom-radio"
                        id={`check-incorrect-${index}`}
                        type="radio"
                        name={`options[${index}].isCorrect`}
                        value="false"
                        defaultChecked={!item.isCorrect}
                        {...register(`options[${index}].isCorrect`, { required: true })}
                      />
                      <label htmlFor={`check-incorrect-${index}`}>
                        {intl.formatMessage({ id: "questions.options.incorrect" })}
                      </label>
                    </div>
                    {props.allowEdit && (
                      <a className="delete-option" onClick={() => remove(index)}>
                        <i className="fa far fa-trash" />
                        &nbsp;
                        {intl.formatMessage({ id: "questions.delete-option" })}
                      </a>
                    )}
                  </div>

                  <div className="form-group">
                    <textarea
                      className="form-control question-input-field"
                      name={`options[${index}].body`}
                      defaultValue={item.body}
                      {...register(`options[${index}].body`, { required: true })}
                    ></textarea>
                  </div>
                </li>
              );
            })}
          </ul>

          <button
            className="btn btn-tertiary add-option"
            onClick={() => {
              append({ isCorrect: "false", dbId: "", body: "" });
            }}
          >
            <i className="fa far fa-plus" />&nbsp;
            {intl.formatMessage({ id: "questions.add-option" })}
          </button>

          <div className="btn-group btn-end">
            <button type="submit" className="btn btn-primary small angled-bottom-right">
              <i className="fa fas fa-floppy-disk" />
              &nbsp;
              {intl.formatMessage({ id: "shared.forms.save" })}
            </button>
          </div>
        </fieldset>
      </form>

      <ServerFormErrors errorMessages={errorMessage}></ServerFormErrors>
    </div>
  );
};

QuestionForm.propTypes = {
  url: PropTypes.string.isRequired,
  method: PropTypes.string.isRequired,
  defaultValues: PropTypes.object.isRequired,
  s3Fields: PropTypes.object.isRequired,
  s3UrlReplaceFrom: PropTypes.string.isRequired,
  s3UrlReplaceTo: PropTypes.string.isRequired,
  allowEdit: PropTypes.bool,
  isDarkTheme: PropTypes.bool
};

export default QuestionForm;

export const WithIntlProvider = (props) => {
  return (
    <IntlProvider locale={locale} key={locale} messages={translations[locale]}>
      <QuestionForm {...props} />
    </IntlProvider>
  );
};
