import React from "react";
import RowMenu from "../util/RowMenu";
import PostLink from "../util/PostLink";
import PropTypes from "prop-types";

import { IntlProvider } from "react-intl";
import { translations } from "../util/i18n";

const ApprovedOrganisationRowMenu = (props) => {
  const options = [
    {
      title: "admin-area.scenarios.dropdown.remove-approved-organisation",
      component: PostLink,
      params: {
        method: "delete",
        url: props.removeApprovedOrganisationUrl,
        refresh: true,
        confirmMessage: "admin-area.scenarios.dropdown.remove-approved-organisation.confirm-message",
      },
    },
  ];

  return (
    <IntlProvider
      locale={window._FLAIM_LOCALE}
      key={window._FLAIM_LOCALE}
      messages={translations[window._FLAIM_LOCALE]}
    >
      <RowMenu options={options}></RowMenu>
    </IntlProvider>
  );
};

ApprovedOrganisationRowMenu.propTypes = {
  removeApprovedOrganisationUrl: PropTypes.string,
};

export default ApprovedOrganisationRowMenu;
