import React from 'react';
import PropTypes from 'prop-types';
import './Card.scss';

/**
 * Div styled with box-shadow, border-radius and padding
 */
const Card = ({ className, children }) => {
  return <div className={`card ${className}`}>{children}</div>;
};

Card.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};
export default Card;
