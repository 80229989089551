import React, { useContext, useEffect, useState } from 'react';
import ModalLauncher from './ModalLauncher';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { ModalContext } from './Modal';
import ax from './axios';
import toastr from 'toastr';
import _ from 'lodash';
import SearchBar from '../../../../components/SearchBar';
import Table from '../../../../components/Table';
import FlexRight from '../../../../components/utils/FlexRight';

const ModalContent = ({ id, loadPath, addPath, buttonText, primarySearchItems, cols }) => {
  const intl = useIntl();
  const [data, setData] = useState();
  const [selectedRows, setSelectedRows] = useState([]);
  const [searchParams, setSearchParams] = useState({});
  const modalContext = useContext(ModalContext);

  const loadEvents = (page = 1) => {
    let url = loadPath(id, {
      page: page,
      ...searchParams,
    });
    ax({
      method: 'get',
      url: url,
    })
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        toastr['error'](error);
      });
  };

  useEffect(() => {
    loadEvents();
  }, [searchParams]);

  const onPageChange = (page) => {
    loadEvents(page);
  };

  const onRowSelect = ({ selected, id }) => {
    if (selected) {
      setSelectedRows([...selectedRows, id]);
    } else {
      setSelectedRows(selectedRows.filter((item) => item != id));
    }
  };

  const onSelectAll = (selected) => {
    const ids = data.items.map((item) => item.id);
    if (selected) {
      setSelectedRows(_.uniq([...selectedRows, ...ids]));
    } else {
      setSelectedRows(selectedRows.filter((id) => !ids.includes(id)));
    }
  };

  const onAddEvents = () => {
    ax({
      method: 'post',
      url: addPath(id),
      data: { ids: selectedRows },
    })
      .then(() => {
        window.location.reload();
      })
      .catch((error) => {
        toastr['error'](error);
      });
  };

  const onSearch = (data) => {
    setSearchParams(data);
    setSelectedRows([]);
  };

  if (!data) {
    return;
  } else {
    const { items, pagination } = data;
    const mappedEvents = items.map((org) => ({ ...org, selected: selectedRows.includes(org.id) }));
    return (
      <div className="add-items">
        <SearchBar primaryItems={primarySearchItems} onSearch={onSearch} />
        <Table
          columns={cols}
          items={mappedEvents}
          pagination={pagination}
          onPageChange={onPageChange}
          onRowSelect={onRowSelect}
          rowSelect={true}
          onSelectAll={onSelectAll}
        ></Table>
        <FlexRight className="gap-1 margin-0.5">
          <button className="btn btn-secondary angled-top-left" onClick={modalContext.closeModal}>
            {intl.formatMessage({ id: 'shared.cancel' })}
          </button>

          <button
            className="btn btn-primary angled-bottom-right"
            disabled={!selectedRows.length}
            data-disable-with="..."
            onClick={onAddEvents}
          >
            {intl.formatMessage({ id: buttonText })} {!!selectedRows.length && <>({selectedRows.length})</>}
          </button>
        </FlexRight>
      </div>
    );
  }
};

ModalContent.propTypes = {
  id: PropTypes.number.isRequired,
  buttonText: PropTypes.string.isRequired,
  loadPath: PropTypes.func.isRequired,
  addPath: PropTypes.func.isRequired,
  cols: PropTypes.array.isRequired,
  primarySearchItems: PropTypes.node,
};

const MultiSelectionModal = (props) => {
  const modalContent = () => {
    return <ModalContent {...props} />;
  };

  return (
    <ModalLauncher
      buttonId="add-items"
      buttonText={props.buttonText}
      buttonIcon="far fa-add"
      content={modalContent()}
      title={props.buttonText}
      modalVariation="wide"
    />
  );
};

MultiSelectionModal.propTypes = {
  id: PropTypes.number.isRequired,
  buttonText: PropTypes.string.isRequired,
  loadPath: PropTypes.func.isRequired,
  addPath: PropTypes.func.isRequired,
  cols: PropTypes.array.isRequired,
  primarySearchItems: PropTypes.node,
};

export default MultiSelectionModal;
