import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import wrap from '../../../util/wrap_with_intl_provider';
import PageHeader from '../../../../../../components/PageHeader';
import InjectForm from './InjectForm';
import { admin_area_settings_injects_path } from '../../../../../../routes';

const InjectNew = ({ environments, s3Fields, s3UrlReplaceTo, s3UrlReplaceFrom, defaultInjectId, isDarkTheme }) => {
  const intl = useIntl();

  return (
    <div>
      <PageHeader
        previousPages={[
          {
            link: admin_area_settings_injects_path(),
            title: intl.formatMessage({ id: 'admin-area.settings.inject.index.title' }),
          },
        ]}
        title={intl.formatMessage({ id: 'admin-area.settings.inject.new.title' })}
        pageDivider={true}
      />
      <InjectForm
        environments={environments}
        url={admin_area_settings_injects_path()}
        s3Fields={s3Fields}
        s3UrlReplaceFrom={s3UrlReplaceFrom}
        s3UrlReplaceTo={s3UrlReplaceTo}
        method="POST"
        defaultValues={{ inject_id: defaultInjectId }}
        isDarkTheme={isDarkTheme}
      />
    </div>
  );
};

InjectNew.propTypes = {
  environments: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
    }),
  ).isRequired,
  s3Fields: PropTypes.object.isRequired,
  s3UrlReplaceFrom: PropTypes.string.isRequired,
  s3UrlReplaceTo: PropTypes.string.isRequired,
  defaultInjectId: PropTypes.string.isRequired,
  isDarkTheme: PropTypes.bool
};

export default wrap(InjectNew);
