import React from 'react';
import Card from '../../../../../../components/Card';
import ImageAlbum from '../../../../../../components/ImageAlbum';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';

const EnvironmentDetail = (props) => {
  const intl = useIntl();
  return (
    <div className="row">
      <Card className="detail">
        <div className="content">
          <div className="label">{intl.formatMessage({ id: 'admin-area.settings.environment.fields.title' })}</div>
          <div className="value">{props.title}</div>
        </div>
        <div className="content">
          <div className="label">
            {intl.formatMessage({ id: 'admin-area.settings.environment.fields.environment-id' })}
          </div>
          <div className="value">{props.environmentId}</div>
        </div>
        <div className="content">
          <div className="label">{intl.formatMessage({ id: 'admin-area.settings.environment.fields.status' })}</div>
          <div className="value">{intl.formatMessage({ id: `shared.status.${props.status}` })}</div>
        </div>
        <div className="content">
          <div className="label">
            {intl.formatMessage({ id: 'admin-area.settings.environment.fields.description' })}
          </div>
          <div dangerouslySetInnerHTML={{ __html: props.description }} />
        </div>
        <div className="content">
          <div className="label">
            {intl.formatMessage({ id: 'admin-area.settings.environment.fields.private-incidents-count' })}
          </div>
          <div className="value">{props.privateIncidentsCount}</div>
        </div>
        <div className="content">
          <div className="label">
            {intl.formatMessage({ id: 'admin-area.settings.environment.fields.public-incidents-count' })}
          </div>
          <div className="value">{props.publicIncidentCount}</div>
        </div>
        <div className="content">
          <div className="label">{intl.formatMessage({ id: 'admin-area.settings.environment.fields.visibility' })}</div>
          <div className="value">{intl.formatMessage({ id: `shared.${props.isPublic ? 'public' : 'private'}` })}</div>
        </div>
      </Card>
      <div>
        {props.images && props.images.length > 0 && (
          <ImageAlbum images={props.images} primaryImageIndex={props.primaryImageIndex} />
        )}
      </div>
    </div>
  );
};

EnvironmentDetail.propTypes = {
  title: PropTypes.string.isRequired,
  environmentId: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  description: PropTypes.string,
  isPublic: PropTypes.bool.isRequired,
  images: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
    }),
  ).isRequired,
  primaryImageIndex: PropTypes.number.isRequired,
  privateIncidentsCount: PropTypes.number.isRequired,
  publicIncidentCount: PropTypes.number.isRequired,
};

export default EnvironmentDetail;
