import React, { useEffect, useState, useRef } from 'react';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import ServerFormErrors from '../../../util/ServerFormErrors';
import ax from '../../../util/axios';
import Froala from '../../../util/Froala';
import convertToFormData from '../../../util/upload';
import ImageUploadWithGallery from '../../../../../../components/ImageUploadWithGallery';
import {
  admin_area_settings_environment_incidents_path,
  admin_area_settings_injects_path,
} from '../../../../../../routes';
import toastr from 'toastr';

const InjectForm = ({ defaultValues, environments, url, method, s3Fields, s3UrlReplaceFrom, s3UrlReplaceTo, locked, isDarkTheme }) => {
  const intl = useIntl();
  const [errorMessage, setErrorMessages] = useState();
  const [incidents, setIncidents] = useState([]);
  const isMounted = useRef(false);

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: { errors, isSubmitting },
  } = useForm({ defaultValues: { ...defaultValues } });

  const onSubmit = (data) => {
    if (confirm(intl.formatMessage({ id: 'shared.confirm-save' }))) {
      if (data.images && data.images.length > 0) {
        data.images = data.images.filter((image) => !image.id);
      }
      return ax({
        method: method,
        url: url,
        data: convertToFormData(data, ['images']),
        headers: { 'Content-Type': 'multipart/form-data' },
      })
        .then((response) => (window.location = response.data.redirectUrl))
        .catch((err) => {
          setErrorMessages(err.response.data);
        });
    }
  };

  const fetchIncidents = (env_id) => {
    ax({
      method: 'get',
      url: admin_area_settings_environment_incidents_path(env_id),
    })
      .then((response) => setIncidents(response.data.incidents))
      .catch((err) => {
        console.log(err);
        toastr.error(intl.formatMessage({ id: 'shared.something-wrong' }));
      });
  };

  useEffect(() => {
    const env_id = getValues('environment_id');
    if (env_id) {
      fetchIncidents(env_id);
    } else {
      setIncidents([]);
    }

    if (isMounted.current) {
      setValue('incident_id', undefined);
    } else {
      isMounted.current = true;
    }
  }, [watch('environment_id')]);

  register('description', { required: true });

  const onError = (error) => {
    console.log(error);
  };
  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <div className="row">
          <div className="form-group">
            <label>{intl.formatMessage({ id: 'admin-area.settings.inject.fields.title' })}</label>
            <input className="form-control" {...register('title', { required: true })} disabled={locked}/>
            {errors.title?.type === 'required' && (
              <div className="field-error">{intl.formatMessage({ id: 'shared.forms.required' })}</div>
            )}
          </div>
          <div className="form-group">
            <label>{intl.formatMessage({ id: 'admin-area.settings.inject.fields.inject-id' })}</label>
            <input
              className="form-control"
              {...register('inject_id', {
                required: true,
              })}
              disabled={locked}
            />
            {errors.inject_id?.type === 'required' && (
              <div className="field-error">{intl.formatMessage({ id: 'shared.forms.required' })}</div>
            )}
          </div>
        </div>
        <div className="row">
          <div className="form-group">
            <label>{intl.formatMessage({ id: 'admin-area.settings.inject.fields.environment' })}</label>
            <select className="form-control" {...register('environment_id', { required: true })} disabled={locked}>
              <option key="0" value="">
                {intl.formatMessage({ id: 'shared.dropdown.please-select' })}
              </option>
              {environments.map((env) => (
                <option key={env.id} value={env.id}>
                  {env.title}
                </option>
              ))}
            </select>
            {errors.environment_id?.type === 'required' && (
              <div className="field-error">{intl.formatMessage({ id: 'shared.forms.required' })}</div>
            )}
          </div>
          <div className="form-group">
            <label>{intl.formatMessage({ id: 'admin-area.settings.inject.fields.incident' })}</label>
            <select className="form-control" {...register('incident_id', { required: true })} disabled={locked}>
              <option key="0" value="">
                {intl.formatMessage({ id: 'shared.dropdown.please-select' })}
              </option>
              {incidents.map((inc) => (
                <option key={inc.id} value={inc.id} selected={inc.id == getValues('incident_id')}>
                  {inc.title}
                </option>
              ))}
            </select>
            {errors.incident_id?.type === 'required' && (
              <div className="field-error">{intl.formatMessage({ id: 'shared.forms.required' })}</div>
            )}
          </div>
        </div>
        <div className="row">
          <div className="form-group">
            <label>{intl.formatMessage({ id: 'admin-area.settings.inject.fields.visibility' })}</label>
            <select className="form-control" {...register('is_public', { required: true })} disabled={locked}>
              <option value="true">{intl.formatMessage({ id: 'shared.public' })}</option>
              <option value="false">{intl.formatMessage({ id: 'shared.private' })}</option>
            </select>
          </div>
          <div className="form-group form-check">
            <input type="checkbox" id="locked" {...register('locked')} disabled={locked}></input>
            <label htmlFor="locked">{intl.formatMessage({ id: 'admin-area.settings.inject.fields.locked' })}</label>
          </div>
        </div>
        <div className="form-group">
          <label>{intl.formatMessage({ id: 'admin-area.settings.inject.fields.description' })}</label>
          <Froala
            model={getValues('description')}
            onModelChange={(value) => setValue('description', value)}
            heightMin={150}
            s3Fields={s3Fields}
            s3UrlReplaceFrom={s3UrlReplaceFrom}
            s3UrlReplaceTo={s3UrlReplaceTo}
            isDarkTheme={isDarkTheme}
          />
        </div>
        {errors.description?.type === 'required' && (
          <div className="field-error">{intl.formatMessage({ id: 'shared.forms.required' })}</div>
        )}
        <div className="form-group">
          <label>{intl.formatMessage({ id: 'admin-area.settings.inject.fields.images' })}</label>
          <ImageUploadWithGallery
            originImages={getValues('images')}
            onImagesChange={(images) => setValue('images', images)}
            onDeleteOriginImage={(deletedIds) => {
              setValue('deleted_image_ids', deletedIds);
            }}
            originPrimaryIndex={getValues('primary_image_index')}
            onPrimaryIndexChange={(primaryImageIndex) => setValue('primary_image_index', primaryImageIndex)}
          />
          {errors.images?.type === 'required' && (
            <div className="field-error">{intl.formatMessage({ id: 'shared.forms.required' })}</div>
          )}
        </div>
        <div className="btn-group btn-end">
          <a className="btn btn-secondary small angled-top-left" href={admin_area_settings_injects_path()}>
            {intl.formatMessage({ id: 'shared.cancel' })}
          </a>
          <button type="submit" className="btn btn-primary small angled-bottom-right" disabled={isSubmitting}>
            {intl.formatMessage({ id: 'shared.forms.save' })}
          </button>
        </div>
      </form>

      <ServerFormErrors errorMessages={errorMessage}></ServerFormErrors>
    </div>
  );
};

InjectForm.propTypes = {
  url: PropTypes.string.isRequired,
  method: PropTypes.string.isRequired,
  defaultValues: PropTypes.object,
  environments: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
    }),
  ).isRequired,
  s3Fields: PropTypes.object.isRequired,
  s3UrlReplaceFrom: PropTypes.string.isRequired,
  s3UrlReplaceTo: PropTypes.string.isRequired,
  locked: PropTypes.bool.isRequired,
  isDarkTheme: PropTypes.bool
};

export default InjectForm;
