import React from "react";
import AssignSimulationUserForm from "./AssignSimulationUserForm";
import PropTypes from "prop-types";

import { IntlProvider } from "react-intl";
import { translations } from "../util/i18n";

const AssignSimulationUserModal = (props) => {
  return (
    <IntlProvider
      locale={window._FLAIM_LOCALE}
      key={window._FLAIM_LOCALE}
      messages={translations[window._FLAIM_LOCALE]}
    >
      <AssignSimulationUserForm {...props}></AssignSimulationUserForm>
    </IntlProvider>
  );
};

AssignSimulationUserModal.propTypes = {
  url: PropTypes.string.isRequired,
  assignableStudentsUrl: PropTypes.string.isRequired,
};

export default AssignSimulationUserModal;
