import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import './PageHeader.scss';

const PageHeader = (props) => (
  <div className="entity-page-header">
    {props.previousPages.length > 0 && (
      <div className="breadcrumb-container">
        {props.previousPages.map((previousPage, index) => (
          <span key={`${previousPage.title}-${index}`}>
            {index > 0 && (
              <span className="seperator">
                <i className="fas fa-angle-right" />
              </span>
            )}
            <a href={previousPage.link}>{previousPage.title}</a>
          </span>
        ))}
        <span className="seperator">
          <i className="fas fa-angle-right"></i>
        </span>
        <span className="current-page">{props.title}</span>
      </div>
    )}

    <div className="page-header">
      { props.customTitleSection && props.customTitleSection }
      { !props.customTitleSection &&
        <div className="title-container">
          <h1>{props.title}</h1>
          {props.titleLabel && <div className="title-label">{props.titleLabel}</div>}
        </div>
      }
      <div className="settings-area">
        <div className="btn-group">
          {props.settingPages.map((settingPage) => (
            <a
              key={settingPage.title}
              className={settingPage.className}
              href={settingPage.link}
              onClick={settingPage.onClick}
              disabled={settingPage.disable}
              data-confirm={settingPage.confirm}
              data-method={settingPage.method}
            >
              <i className={settingPage.icon} />
              &nbsp;
              {settingPage.title}
            </a>
          ))}
        </div>
        {props.settingMenu && props.settingMenu}
      </div>
    </div>

    {props.tabs.length > 0 && (
      <ul className="tabs">
        {props.tabs.map((tab) => (
          tab.onClickFunc ?
          <a key={tab.title} onClick={tab.onClickFunc}>
            <li className={classNames('tab', { 'tab-active': tab.active })}>
              <i className={classNames('fas', tab.icon)} />
              {tab.title}
              {tab.additionalInfo !== undefined && (
                <div className="additional-info">
                  <div>{tab.additionalInfo}</div>
                </div>
              )}
            </li>
          </a>
          :
          <a key={tab.title} href={tab.link}>
            <li className={classNames('tab', { 'tab-active': tab.active })}>
              <i className={classNames('fas', tab.icon)} />
              {tab.title}
              {tab.additionalInfo !== undefined && (
                <div className="additional-info">
                  <div>{tab.additionalInfo}</div>
                </div>
              )}
            </li>
          </a>
        ))}
      </ul>
    )}
    {props.pageDivider && <hr />}
  </div>
);

PageHeader.defaultProps = {
  previousPages: [],
  settingPages: [],
  tabs: [],
  pageDivider: false,
  isFunction: false,
};

PageHeader.propTypes = {
  title: PropTypes.string.isRequired,
  titleLabel: PropTypes.node,
  previousPages: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired,
    }).isRequired,
  ),
  settingPages: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      link: PropTypes.string,
      icon: PropTypes.string.isRequired,
      classNames: PropTypes.string,
      onClick: PropTypes.func,
    }).isRequired,
  ),
  settingMenu: PropTypes.node,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      link: PropTypes.string,
      icon: PropTypes.string.isRequired,
      active: PropTypes.bool,
      additionalInfo: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      onClickFunc: PropTypes.func,
    }).isRequired,
  ),
  customTitleSection: PropTypes.node,
  pageDivider: PropTypes.bool,
};

export default PageHeader;
