import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { IntlProvider, useIntl } from 'react-intl';
import { translations } from '../util/i18n';
import Table from '../../../../components/Table';
import StatusLabel from '../../../../components/StatusLabel';
import Card from '../../../../components/Card';
import Message from '../../../../components/Message';
import ax from '../util/axios';

const SimulationObservationEvents = ({ observationEventsUrl, isCaptured, notCapturedText, notRecordedText}) => {
  const intl = useIntl();
  const [pagination, setPagination] = useState({});
  const [observationEvents, setObservationEvents] = useState([]);
  const fetchObservationEvents = async (page = 1) => {
    const response = await ax.get(`${observationEventsUrl}?page=${page}`);
    setPagination(response.data.pagination);
    setObservationEvents(response.data.observationEvents);
  };
  const observationEventsColumns = [
    {
      accessor: 'description',
      className: 'description',
    },
    {
      accessor: 'recordTime',
      className: 'record-time',
    },
    {
      accessor: 'status',
      className: 'status',
    },
  ];
  const mappedObservationEvents = observationEvents.map((event) => ({
    ...event,
    description: event.description ? (
      event.description
    ) : (
      <span className="not-recorded">
        <i className="fa-regular fa-ban" />
        &nbsp;{notRecordedText}
      </span>
    ),
    status: <StatusLabel status={event.pass ? 'passed' : 'failed'} />,
    recordTime: event.record_time && (
      <span>
        <i className="fa-solid fa-clock-five" />
        &nbsp;{intl.formatMessage({ id: 'shared.achieved-at' })} {event.record_time}
      </span>
    ),
  }));

  useEffect(() => {
    fetchObservationEvents();
  }, []);

  return (
    <div className="simulation-events">
      <div className="events-filter">
        <h4>{intl.formatMessage({ id: 'admin-area.simulations.observation-events' })}</h4>
      </div>
      <div>
        {!isCaptured && (
          <div className="version-warning-container">
            <Message type="warning" message={notCapturedText} />
          </div>
        )}
        {observationEvents.length > 0 && (
          <Card>
            <Table
              header={false}
              items={mappedObservationEvents}
              columns={observationEventsColumns}
              pagination={pagination}
              onPageChange={fetchObservationEvents}
            />
          </Card>
        )}
      </div>
    </div>
  );
};

SimulationObservationEvents.propTypes = {
  observationEventsUrl: PropTypes.string.isRequired,
  notCapturedText: PropTypes.string.isRequired,
  notRecordedText: PropTypes.string.isRequired,
  isCaptured: PropTypes.bool.isRequired
};

export default SimulationObservationEvents;

export const WithIntlProvider = (props) => {
  return (
    <IntlProvider
      locale={window._FLAIM_LOCALE}
      key={window._FLAIM_LOCALE}
      messages={translations[window._FLAIM_LOCALE]}
    >
      <SimulationObservationEvents {...props} />
    </IntlProvider>
  );
};
