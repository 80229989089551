import React, { useState } from "react";
import { IntlProvider, useIntl } from "react-intl";
import ax from "../../util/axios";
import PropTypes from "prop-types";
import { translations } from "../../util/i18n";

const EnrolmentActivity = (props) => {
  const intl = useIntl();

  const [activities, setActivities] = useState();
  const [isOpen, setIsOpen] = useState(false);

  const onClick = () => {
    if (activities) {
      setIsOpen(!isOpen);
    } else {
      ax({
        method: "get",
        url: props.url,
      })
        .then(function (response) {
          setActivities(response.data);
          setIsOpen(!isOpen);
        })
        .catch((err) => {
          console.log(err.response);
        });
    }
  };

  return (
    <div className="activity-section">
      <div className="action-bar">
        <a id="view-activity" onClick={onClick}>
          {isOpen && (
            <div className="activity-section-info">
              <i className="fas fa-angle-up"></i>
              &nbsp;
              {intl.formatMessage({ id: "enrolments.activities.close-activity" })}
            </div>
          )}
          {!isOpen && (
            <div className="activity-section-info">
              <i className="fas fa-angle-down"></i>
              &nbsp;
              {intl.formatMessage({ id: "enrolments.activities.view-activity" })}
            </div>
          )}
        </a>
      </div>
      <div className={`activity-list ${isOpen ? "" : "hidden"}`}>
        <table className="entity-content">
          <tbody>
            <tr className="activity">
              <td>{intl.formatMessage({ id: "enrolments.activities.student" })}</td>
              <td>{props.student}</td>
            </tr>
            <tr className="activity">
              <td>{intl.formatMessage({ id: "enrolments.activities.enrolment_created" })}</td>
              <td>{props.enrolmentCreatedAt}</td>
            </tr>
            {activities &&
              activities.map((act, index) => {
                return (
                  <tr key={index} className="activity">
                    <td>{act.action}</td>
                    <td>
                      {act.section && (
                        <>
                          <span>{act.section}</span>
                          <span className="divider">|</span>
                        </>
                      )}
                      <span>{act.created_at}</span>
                      <span className="divider">|</span>
                      <span className={`status ${act.status}`}>
                        <i className={`fas ${act.status == "complete" ? "fa-check" : "fa-diamond-exclamation"}`}></i>
                        &nbsp;
                        {act.status_i18n}
                      </span>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

EnrolmentActivity.propTypes = {
  url: PropTypes.string.isRequired,
  student: PropTypes.string.isRequired,
  enrolmentCreatedAt: PropTypes.string.isRequired,
};
export default EnrolmentActivity;

export const WithIntlProvider = (props) => {
  return (
    <IntlProvider
      locale={window._FLAIM_LOCALE}
      key={window._FLAIM_LOCALE}
      messages={translations[window._FLAIM_LOCALE]}
    >
      <EnrolmentActivity {...props} />
    </IntlProvider>
  );
};
