import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import wrap from '../../../util/wrap_with_intl_provider';
import PageHeader from '../../../../../../components/PageHeader';
import InjectForm from './InjectForm';
import { admin_area_settings_inject_path, admin_area_settings_injects_path } from '../../../../../../routes';

const InjectEdit = ({ defaultValues, environments, s3Fields, s3UrlReplaceFrom, s3UrlReplaceTo, locked, isDarkTheme }) => {
  const intl = useIntl();

  return (
    <div>
      <PageHeader
        previousPages={[
          {
            link: admin_area_settings_injects_path(),
            title: intl.formatMessage({ id: 'admin-area.settings.inject.index.title' }),
          },
          {
            link: admin_area_settings_inject_path(defaultValues.id),
            title: defaultValues.title,
          },
        ]}
        title={intl.formatMessage({ id: 'admin-area.settings.inject.edit.title' })}
        pageDivider={true}
      />
      <InjectForm
        url={admin_area_settings_inject_path(defaultValues.id)}
        method={'PUT'}
        environments={environments}
        defaultValues={{ ...defaultValues }}
        s3Fields={s3Fields}
        s3UrlReplaceFrom={s3UrlReplaceFrom}
        s3UrlReplaceTo={s3UrlReplaceTo}
        locked={locked}
        isDarkTheme={isDarkTheme}
      />
    </div>
  );
};

InjectEdit.propTypes = {
  defaultValues: PropTypes.object.isRequired,
  environments: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
    }),
  ).isRequired,
  s3Fields: PropTypes.object.isRequired,
  s3UrlReplaceFrom: PropTypes.string.isRequired,
  s3UrlReplaceTo: PropTypes.string.isRequired,
  locked: PropTypes.bool, default: false,
  isDarkTheme: PropTypes.bool
};

export default wrap(InjectEdit);
