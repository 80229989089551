import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import toastr from 'toastr';
import { useIntl } from 'react-intl';

import './ImageUpload.scss';

const ImageUpload = ({ allowedTypes, allowedTypesText, multiple, onImagesChange }) => {
  const fileRef = useRef(null);
  const intl = useIntl();

  const allFilesValid = (files) => {
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if (!allowedTypes.includes(file.type)) {
        return false;
      }
    }
    return true;
  };

  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    processFiles(e.dataTransfer.files);
  };

  const handleFileChange = function (e) {
    e.preventDefault();
    e.stopPropagation();
    processFiles(e.target.files);
  };

  const processFiles = (files) => {
    if (files && files[0]) {
      if (allFilesValid(files)) {
        onImagesChange(files);
      } else {
        toastr.error(intl.formatMessage({ id: 'image-upload.select-supported-formats-only' }));
      }
    }
  };

  const onBrowseClicked = () => {
    fileRef.current.click();
  };

  return (
    <div
      className={classNames('image-upload')}
      onDragEnter={handleDrag}
      onDragLeave={handleDrag}
      onDragOver={handleDrag}
      onDrop={handleDrop}
    >
      <input ref={fileRef} type="file" multiple={multiple} accept={allowedTypes} onChange={handleFileChange} />
      <i className="fa-regular fa-upload"></i>
      <div className="image-upload-header">
        {intl.formatMessage({ id: 'image-upload.drag-and-drop-files-or' })}
        <a onClick={onBrowseClicked}>{intl.formatMessage({ id: 'image-upload.browse' })}</a>
      </div>
      <div className="image-upload-supported-format">
        {intl.formatMessage(
          { id: 'image-upload.supported-formats' },
          { supportedFormats: allowedTypesText || allowedTypes },
        )}
      </div>
    </div>
  );
};

export default ImageUpload;

ImageUpload.propTypes = {
  allowedTypes: PropTypes.string.isRequired,
  allowedTypesText: PropTypes.string,
  multiple: PropTypes.bool,
  onImagesChange: PropTypes.func.isRequired,
};

ImageUpload.defaultProps = {
  allowedTypes: 'image/jpeg, image/png, image/gif',
  allowedTypesText: 'JPEG, PNG, GIF',
  multiple: true,
};
