import React, { useState, useEffect } from 'react';
import Pagination from 'rc-pagination';
import { ReactSortable } from 'react-sortablejs';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import './Table.scss';
import TableRow from './TableRow';
import Checkbox from '../Checkbox';

const Table = (props) => {
  const intl = useIntl();
  const [current, setCurrent] = useState(props.pagination?.currentPage);
  const [selectedAll, setSelectedAll] = useState(props.items.every((item) => item.selected));
  const showRangeFrom = () => (props.pagination.total === 0 ? 0 : (current - 1) * props.pagination.perPage + 1);
  const showRangeTo = () =>
    current * props.pagination.perPage > props.pagination.total
      ? props.pagination.total
      : current * props.pagination.perPage;

  /*
   without this, table might show wrong current page eg. current page is 2, but then user trigger to reload the page
   by search, then current page should reset to 1 by search result.

   alternative approach will be not keep current state, always respect props.pagination.currentPage, but it will cause
   pagination stop working in storybook unless we mock a container component there
   */
  useEffect(() => {
    if (props.pagination?.currentPage != current) {
      setCurrent(props.pagination?.currentPage);
    }
  }, [props.pagination?.currentPage]);

  const onPageChange = (page) => {
    setCurrent(page);
    props.onPageChange(page);
    // fetchItems function will be implemented in parent component where data will be fetched from server
    // props.fetchItems(current)
  };

  useEffect(() => {
    setSelectedAll(props.items.every((item) => item.selected));
  }, [props.items]);

  return (
    <div>
      <div className="table__container">
        <table>
          {props.header && (
            <thead>
              <tr>
                {props.rowDrag && <th className="actions" />}
                {props.rowSelect && (
                  <th className="actions">
                    {props.items.length > 0 && props.onSelectAll && (
                      <Checkbox
                        checked={selectedAll}
                        onChange={(event) => {
                          props.onSelectAll(event.target.checked);
                        }}
                      />
                    )}
                  </th>
                )}
                {props.columns.map((column) => (
                  <th key={column.accessor} className={column.className}>
                    {column.header}
                  </th>
                ))}
              </tr>
            </thead>
          )}
          <ReactSortable
            handle=".drag-handler > .fa-grip-vertical"
            tag="tbody"
            list={props.rowDrag ? props.rawItems : props.items}
            setList={props.onRowDrag}
            onEnd={props.onRowDragEnd}
          >
            {props.items.map((item, index) => (
              <TableRow
                key={`index-${index}`}
                columns={props.columns}
                rowSelect={props.rowSelect}
                onRowSelect={props.onRowSelect}
                rowDrag={props.rowDrag}
                onRowDrag={props.onRowDrag}
                item={item}
                rowClass={props.rowClass}
              />
            ))}
          </ReactSortable>
        </table>
      </div>
      {props.pagination && (
        <div className="table__pagination-container">
          <div className="table__pagination-show">
            <span>
              {intl.formatMessage(
                { id: 'table.show' },
                { showRangeFrom: showRangeFrom(), showRangeTo: showRangeTo(), total: props.pagination.total },
              )}
            </span>
          </div>
          <Pagination
            className="table__pagination"
            onChange={onPageChange}
            total={props.pagination.total}
            pageSize={props.pagination.perPage}
            current={current}
            nextIcon={<i className="fa fa-angle-right" />}
            prevIcon={<i className="fa fa-angle-left" />}
            jumpNextIcon={<i className="fa-solid fa-ellipsis" />}
            jumpPrevIcon={<i className="fa-solid fa-ellipsis" />}
            showTitle={false}
          />
        </div>
      )}
    </div>
  );
};

Table.defaultProps = {
  header: true,
  rowDrag: false,
  onRowDrag: () => {},
  onRowDragEnd: () => {},
  rowSelect: false,
  onRowSelect: () => {},
  onPageChange: () => {},
};

Table.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      header: PropTypes.string,
      accessor: PropTypes.string.isRequired,
      className: PropTypes.string,
      render: PropTypes.func,
    }),
  ).isRequired,
  items: PropTypes.array.isRequired,
  pagination: PropTypes.shape({
    currentPage: PropTypes.number.isRequired,
    perPage: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
  }),
  header: PropTypes.bool,
  rowDrag: PropTypes.bool,
  onRowDrag: PropTypes.func,
  onRowDragEnd: PropTypes.func,
  rawItems: PropTypes.array,
  rowSelect: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  onRowSelect: PropTypes.func,
  onPageChange: PropTypes.func,
  rowClass: PropTypes.func,
  onSelectAll: PropTypes.func,
};

export default Table;
