import React from 'react';
import PropTypes from 'prop-types';
import { IntlProvider, useIntl } from 'react-intl';
import { translations } from '../util/i18n';
import Message from '../../../../components/Message';
import PerformanceDataCard from '../../../../components/PerformanceDataCard';
import Card from '../../../../components/Card';

const SimulationPerformanceEvents = ({ events, isCaptured, notCapturedText }) => {
  const intl = useIntl();

  return (
    <div className="simulation-events">
      <hr />
      <div className="events-filter">
        <h4>{intl.formatMessage({ id: 'admin-area.simulations.performance-events' })}</h4>
      </div>
      <div>
        {!isCaptured && (
          <div className="version-warning-container">
            <Message type="warning" message={notCapturedText} />
          </div>
        )}
        {events.length > 0 && (
          <Card className="performance-container">
            {events.map((event, index) => (
              <PerformanceDataCard key={`index-${index}`}
                                   title={event.title}
                                   value={event.isLink ? event.link : event.message}
                                   isLink={event.isLink} />
            ))}
          </Card>
        )}
      </div>
    </div>
  );
};

SimulationPerformanceEvents.propTypes = {
  events: PropTypes.array.isRequired,
  notCapturedText: PropTypes.string,
  isCaptured: PropTypes.bool.isRequired,
};

export default SimulationPerformanceEvents;

export const WithIntlProvider = (props) => {
  return (
    <IntlProvider
      locale={window._FLAIM_LOCALE}
      key={window._FLAIM_LOCALE}
      messages={translations[window._FLAIM_LOCALE]}
    >
      <SimulationPerformanceEvents {...props} />
    </IntlProvider>
  );
};
