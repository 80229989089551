import React, { useState } from "react";
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";
import ServerFormErrors from "../../util/ServerFormErrors";
import ax from "../../util/axios";
import { useIntl } from "react-intl";

const MicroEventFeedbackForm = (props) => {
  const intl = useIntl();
  const [errorMessage, setErrorMessages] = useState();
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    watch,
    formState: { errors },
  } = useForm({ defaultValues: { ...props.defaultValues } });
  const onSubmit = (data) => {
    ax({
      method: "post",
      url: props.url,
      data: { form: data },
    })
      .then(function () {
        window.location = window.location.href;
      })
      .catch((err) => {
        setErrorMessages(err.response.data);
      });
  };

  const returnToDefaultSuccessMsg = () => {
    setValue("success_message", getValues("default_success_message"));
  };

  const returnToDefaultFailureMsg = () => {
    setValue("failure_message", getValues("default_failure_message"));
  };

  const watchSuccessMessage = watch("success_message");
  const watchFailureMessage = watch("failure_message");

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="stack">
          <div>
            <div className="form-group">
              <label>{intl.formatMessage({ id: "admin-area.scenarios.micro-event.success-message-field" })}</label>
              <textarea
                rows="5"
                className="form-control"
                {...register("success_message", { required: true })}
              ></textarea>
              {errors.success_message?.type === "required" && (
                <div className="field-error">{intl.formatMessage({ id: "shared.forms.required" })}</div>
              )}
            </div>

            <div className="display-flex justify-content-flex-end">
              <a
                className="display-flex"
                disabled={watchSuccessMessage && getValues("success_message") === getValues("default_success_message")}
                onClick={returnToDefaultSuccessMsg}
              >
                {intl.formatMessage({ id: "shared.return_to_default" })}
                <i className="fa fa-backward"></i>
              </a>
            </div>
          </div>

          <div>
            <div className="form-group">
              <label>{intl.formatMessage({ id: "admin-area.scenarios.micro-event.failure-message-field" })}</label>
              <textarea
                rows="5"
                className="form-control"
                {...register("failure_message", { required: true })}
              ></textarea>
              {errors.failure_message?.type === "required" && (
                <div className="field-error">{intl.formatMessage({ id: "shared.forms.required" })}</div>
              )}
            </div>

            <div className="display-flex justify-content-flex-end">
              <a
                className="display-flex"
                disabled={watchFailureMessage && getValues("failure_message") === getValues("default_failure_message")}
                onClick={returnToDefaultFailureMsg}
              >
                {intl.formatMessage({ id: "shared.return_to_default" })}
                <i className="fa fa-backward"></i>
              </a>
            </div>
          </div>
        </div>
        <button type="submit" className="btn btn-primary small angled-bottom-right">
          {intl.formatMessage({ id: "shared.forms.save" })}
        </button>
      </form>

      <ServerFormErrors errorMessages={errorMessage}></ServerFormErrors>
    </div>
  );
};

MicroEventFeedbackForm.propTypes = {
  url: PropTypes.string.isRequired,
  defaultValues: PropTypes.object.isRequired,
};

export default MicroEventFeedbackForm;
