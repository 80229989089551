import React from "react";
import ModalLauncher from "../../../util/ModalLauncher";
import TimelineEventForm from "../performance_events/PerformanceEventForm";
import PropTypes from "prop-types";

import { IntlProvider } from "react-intl";
import { translations, locale } from "../../../util/i18n";

const CreateTimelineEventModal = (props) => (
  <IntlProvider locale={window._FLAIM_LOCALE} key={window._FLAIM_LOCALE} messages={translations[window._FLAIM_LOCALE]}>
    <ModalLauncher
      buttonId="create-timeline-event"
      buttonText="admin-area.settings.timeline-event.create"
      content={<TimelineEventForm url={props.createUrl} method={"POST"}></TimelineEventForm>}
      title="admin-area.settings.timeline-event.create"
    />
  </IntlProvider>
);

CreateTimelineEventModal.propTypes = {
  createUrl: PropTypes.string.isRequired,
};

export default CreateTimelineEventModal;
