import React from 'react';
import RowMenu from '../util/RowMenu';
import Modal from '../util/Modal';
import PostLink from '../util/PostLink';
import PropTypes from 'prop-types';

import { IntlProvider } from 'react-intl';
import { translations } from '../util/i18n';
import AssignOrganisationModal from '../admin_area/units/AssignOrganisationModal';

const UnitsRowMenu = (props) => {
  const options = [];
  if (props.organisation) {
    options.push({
      title: 'admin-area.organisations.dropdown.remove-from-org',
      component: PostLink,
      params: {
        url: props.removeFromOrgUrl,
        refresh: true,
        confirmMessage: 'admin-area.organisations.dropdown.remove-from-org-confirm-message',
      },
    });
  } else {
    options.push({
      title: 'admin-area.units.dropdown.assign-org',
      component: Modal,
      params: {
        title: 'admin-area.units.dropdown.assign-org',
        content: AssignOrganisationModal({ url: props.assignOrgUrl, organisations: props.organisations }),
      },
    });
  }

  return (
    <IntlProvider
      locale={window._FLAIM_LOCALE}
      key={window._FLAIM_LOCALE}
      messages={translations[window._FLAIM_LOCALE]}
    >
      <RowMenu options={options}></RowMenu>
    </IntlProvider>
  );
};

UnitsRowMenu.propTypes = {
  organisation: PropTypes.string,
  removeFromOrgUrl: PropTypes.string,
  assignOrgUrl: PropTypes.string,
  organisations: PropTypes.array.isRequired,
};

export default UnitsRowMenu;
