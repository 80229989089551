import React from 'react';
import RowMenu from '../util/RowMenu';
import Modal from '../util/Modal';
import PostLink from '../util/PostLink';
import Link from '../util/Link';
import EditUserModal from '../admin_area/users/EditUserModal';
import PropTypes from 'prop-types';

import { IntlProvider } from 'react-intl';
import { translations } from '../util/i18n';

const UsersRowMenu = (props) => {
  const options = [];

  if (props.updateUserUrl) {
    options.push({
      title: 'users.dropdown.edit-user',
      component: Modal,
      params: {
        title: 'users.dropdown.edit-user',
        content: EditUserModal({ url: props.updateUserUrl, userDetails: { ...props.userDetails } }),
      },
    });
  }

  if (props.editUserUrl) {
    options.push({
      title: 'users.dropdown.edit-user',
      component: Link,
      params: {
        url: props.editUserUrl,
      },
    });
  }

  if (props.viewStudentUrl) {
    options.push({
      title: 'users.dropdown.view-student',
      component: Link,
      params: {
        url: props.viewStudentUrl,
      },
    });
  }

  options.push({
    title: 'users.dropdown.send-password-action',
    component: PostLink,
    params: {
      url: props.sendPasswordUrl,
      notice: 'users.dropdown.send-password-complete',
      refresh: false,
    },
  });

  if (props.revokeUrl) {
    options.push({
      title: 'users.dropdown.revoke',
      component: PostLink,
      params: {
        url: props.revokeUrl,
        refresh: true,
      },
    });
  }

  if (props.removeFromOrgUrl) {
    options.push({
      title: 'users.dropdown.revoke-org-admin',
      component: PostLink,
      params: {
        url: props.removeFromOrgUrl,
        refresh: true,
        confirmMessage: 'users.dropdown.revoke-org-admin-confirm',
      },
    });
  }

  return (
    <IntlProvider
      locale={window._FLAIM_LOCALE}
      key={window._FLAIM_LOCALE}
      messages={translations[window._FLAIM_LOCALE]}
    >
      <RowMenu options={options}></RowMenu>
    </IntlProvider>
  );
};

UsersRowMenu.propTypes = {
  updateUserUrl: PropTypes.string,
  sendPasswordUrl: PropTypes.string,
  revokeUrl: PropTypes.string,
  removeFromOrgUrl: PropTypes.string,
  userDetails: PropTypes.object,
  editUserUrl: PropTypes.string,
  viewStudentUrl: PropTypes.string,
};

export default UsersRowMenu;
