import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import PageHeader from '../../../../../../components/PageHeader';
import {
  admin_area_settings_environment_path,
  admin_area_settings_environment_approved_organisations_path,
  admin_area_settings_environments_path,
  edit_admin_area_settings_environment_path,
} from '../../../../../../routes';

const EnvironmentShowPageHeader = (props) => {
  const intl = useIntl();

  const tabs = () => {
    const tabs = [
      {
        icon: 'fa-solid fa-fire',
        link: admin_area_settings_environment_path(props.id),
        title: intl.formatMessage({ id: 'admin-area.settings.environment.show.tab.detail' }),
        active: props.activeUrl === admin_area_settings_environment_path(props.id),
      },
    ];
    if (!props.isPublic) {
      tabs.push({
        icon: 'fa-regular fa-users',
        link: admin_area_settings_environment_approved_organisations_path(props.id),
        title: intl.formatMessage({ id: 'admin-area.settings.environment.show.tab.approved-organisations' }),
        active: props.activeUrl === admin_area_settings_environment_approved_organisations_path(props.id),
      });
    }

    return tabs;
  };

  return (
    <PageHeader
      previousPages={[
        {
          link: admin_area_settings_environments_path(),
          title: intl.formatMessage({ id: 'admin-area.settings.environment.index.title' }),
        },
      ]}
      settingPages={[
        {
          link: edit_admin_area_settings_environment_path(props.id),
          title: intl.formatMessage({ id: 'admin-area.settings.environment.edit.title' }),
          className: 'btn btn-primary angled-top-left',
          icon: 'fa-solid fa-pen',
        },
      ]}
      title={props.title}
      tabs={tabs()}
    />
  );
};

EnvironmentShowPageHeader.propTypes = {
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  isPublic: PropTypes.bool.isRequired,
  activeUrl: PropTypes.string.isRequired,
};

export default EnvironmentShowPageHeader;
