import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import ServerFormErrors from '../../../util/ServerFormErrors';
import ax from '../../../util/axios';
import Froala from '../../../util/Froala';
import convertToFormData from '../../../util/upload';
import ImageUploadWithGallery from '../../../../../../components/ImageUploadWithGallery';

const EnvironmentForm = (props) => {
  const intl = useIntl();
  const [errorMessage, setErrorMessages] = useState();
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({ defaultValues: { ...props.defaultValues } });
  const onSubmit = (data) => {
    if (data.images && data.images.length > 0) {
      data.images = data.images.filter((image) => !image.id);
    }
    ax({
      method: props.method,
      url: props.url,
      data: convertToFormData(data, ['images']),
      headers: { 'Content-Type': 'multipart/form-data' },
    })
      .then((response) => (window.location = response.data.redirectUrl))
      .catch((err) => {
        setErrorMessages(err.response.data);
      });
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="form-group">
            <label>{intl.formatMessage({ id: 'admin-area.settings.environment.fields.title' })}</label>
            <input
              className="form-control"
              placeholder={intl.formatMessage({ id: 'admin-area.settings.environment.placeholder.title' })}
              {...register('title', { required: true })}
            />
            {errors.title?.type === 'required' && (
              <div className="field-error">{intl.formatMessage({ id: 'shared.forms.required' })}</div>
            )}
          </div>
          <div className="form-group">
            <label>{intl.formatMessage({ id: 'admin-area.settings.environment.fields.environment-id' })}</label>
            <input
              className="form-control"
              placeholder={intl.formatMessage({ id: 'admin-area.settings.environment.placeholder.environment-id' })}
              {...register('environment_id', { required: true })}
            />
            {errors.environment_id?.type === 'required' && (
              <div className="field-error">{intl.formatMessage({ id: 'shared.forms.required' })}</div>
            )}
          </div>
        </div>
        <div className="row">
          <div className="form-group">
            <label htmlFor="status">
              {intl.formatMessage({ id: 'admin-area.settings.environment.fields.status' })}
            </label>
            <select className="form-control" {...register('status')}>
              <option value="draft">{intl.formatMessage({ id: 'shared.status.draft' })}</option>
              <option value="active">{intl.formatMessage({ id: 'shared.status.active' })}</option>
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="visibility">
              {intl.formatMessage({ id: 'admin-area.settings.environment.fields.visibility' })}
            </label>
            <select className="form-control" {...register('is_public')}>
              <option value="true">{intl.formatMessage({ id: 'shared.public' })}</option>
              <option value="false">{intl.formatMessage({ id: 'shared.private' })}</option>
            </select>
          </div>
        </div>
        <div className="form-group">
          <label>{intl.formatMessage({ id: 'admin-area.settings.observation-event.fields.description' })}</label>
          <Froala
            model={getValues('description')}
            onModelChange={(value) => setValue('description', value)}
            heightMin={150}
            isDarkTheme={props.isDarkTheme}
          />
        </div>
        <div className="form-group">
          <label>{intl.formatMessage({ id: 'admin-area.settings.environment.fields.images' })}</label>
          <ImageUploadWithGallery
            originImages={getValues('images')}
            onImagesChange={(images) => setValue('images', images)}
            onDeleteOriginImage={(deletedIds) => {
              setValue('deleted_image_ids', deletedIds);
            }}
            originPrimaryIndex={getValues('primary_image_index')}
            onPrimaryIndexChange={(primaryImageIndex) => setValue('primary_image_index', primaryImageIndex)}
          />
        </div>
        <div className="btn-group btn-end">
          <a className="btn btn-secondary small angled-top-left" href={props.url}>
            <i className="fas fa-times" />&nbsp;
            {intl.formatMessage({ id: 'shared.cancel' })}
          </a>
          <button type="submit" className="btn btn-primary small angled-bottom-right">
            <i className="fas fa-floppy-disk" />&nbsp;
            {intl.formatMessage({ id: 'shared.forms.save' })}
          </button>
        </div>
      </form>

      <ServerFormErrors errorMessages={errorMessage}></ServerFormErrors>
    </div>
  );
};

EnvironmentForm.propTypes = {
  url: PropTypes.string.isRequired,
  method: PropTypes.string.isRequired,
  defaultValues: PropTypes.object,
  isDarkTheme: PropTypes.bool
};

export default EnvironmentForm;
