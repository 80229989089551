import React from "react";
import TableSearch from "../../../util/TableSearch";
import SearchContentForCourseForm from "./SearchContentForCourseForm";
import PropTypes from "prop-types";

const SearchContentForCourse = (props) => {
  return <TableSearch form={SearchContentForCourseForm} lmsContentCourseAccess={props.lmsContentCourseAccess}></TableSearch>;
};

SearchContentForCourse.propTypes = {
  lmsContentCourseAccess: PropTypes.bool.isRequired
};

export default SearchContentForCourse;
