import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import wrap from '../../util/wrap_with_intl_provider';
import Table from '../../../../../components/Table';
import ax from '../../util/axios';
import StatusLabel from '../../../../../components/StatusLabel';
import RightArrowLink from '../../../../../components/RightArrowLink';
import SearchBar from '../../../../../components/SearchBar';
import { PrimarySearchItems } from './OrgSearchForm';
import { admin_area_organisations_path, admin_area_organisation_path } from '../../../../../routes';

const OrganisationTable = (props) => {
  const intl = useIntl();
  const [pagination, setPagination] = useState({});
  const [organisations, setOrganisations] = useState([]);
  const [searchParams, setSearchParams] = useState({});
  const fetchOrganisations = async (page = 1) => {
    const response = await ax.get(admin_area_organisations_path({ format: 'json', page, ...searchParams }));
    setPagination(response.data.pagination);
    setOrganisations(response.data.organisations);
  };

  const organisationColumns = [
    {
      accessor: 'name',
      header: intl.formatMessage({ id: 'admin-area.organisations.name-field' }),
    },
    {
      accessor: 'address',
      header: intl.formatMessage({ id: 'admin-area.organisations.address-field' }),
    },
    {
      accessor: 'createdAt',
      header: intl.formatMessage({ id: 'admin-area.organisations.created-at-field' }),
    },
    {
      accessor: 'unitsCount',
      header: intl.formatMessage({ id: 'admin-area.organisations.units-count-field' }),
    },
    {
      accessor: 'status',
      className: 'status',
      header: intl.formatMessage({ id: 'shared.status' }),
    },
    {
      className: 'actions',
      accessor: 'OrganisationLink',
    },
  ];

  const mappedOrganisations = organisations.map((organisation) => ({
    ...organisation,
    status: <StatusLabel text={organisation.status} status={organisation.status === 'active' ? 'passed' : 'failed'} />,
    OrganisationLink: <RightArrowLink url={admin_area_organisation_path(organisation.id)} />,
  }));

  useEffect(() => {
    fetchOrganisations();
  }, [searchParams]);

  return (
    <div>
      <SearchBar primaryItems={<PrimarySearchItems countries={props.countries} />} onSearch={setSearchParams} />
      {organisations.length > 0 && (
        <Table
          header={true}
          items={mappedOrganisations}
          columns={organisationColumns}
          pagination={pagination}
          onPageChange={fetchOrganisations}
        />
      )}
    </div>
  );
};

OrganisationTable.propTypes = {
  countries: PropTypes.array.isRequired,
};

export default wrap(OrganisationTable);
