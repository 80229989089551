import React from 'react';
import PropTypes, { InferProps } from 'prop-types';

const ComponentPropTypes = {
  url: PropTypes.string.isRequired,
};

type ComponentTypes = InferProps<typeof ComponentPropTypes>;

const RightArrowLink = ({ url }: ComponentTypes) => {
  return (
    <a className="with_icon" href={url}>
      <div className="row-action-container">
        <div className="options-icon-container">
          <i className="far fa-chevron-right"></i>
        </div>
      </div>
    </a>
  );
};

RightArrowLink.propTypes = ComponentPropTypes;
export default RightArrowLink;
