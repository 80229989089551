import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import wrap from '../../../util/wrap_with_intl_provider';
import Card from '../../../../../../components/Card';
import ImageAlbum from '../../../../../../components/ImageAlbum';
import IncidentShowPageHeader from './IncidentShowPageHeader';
import { admin_area_settings_incident_path } from '../../../../../../routes';

const IncidentShow = (props) => {
  const intl = useIntl();

  return (
    <div>
      <IncidentShowPageHeader activeUrl={admin_area_settings_incident_path(props.id)} {...props} />
      <div className="row">
        <Card className="detail">
          <div className="content">
            <div className="label">{intl.formatMessage({ id: 'admin-area.settings.incident.fields.title' })}</div>
            <div className="value">{props.title}</div>
          </div>
          <div className="content">
            <div className="label">{intl.formatMessage({ id: 'admin-area.settings.incident.fields.incident-id' })}</div>
            <div className="value">{props.incidentId}</div>
          </div>
          <div className="content">
            <div className="label">
              {intl.formatMessage({ id: 'admin-area.settings.incident.fields.environment-id' })}
            </div>
            <div className="value">{props.environment}</div>
          </div>
          <div className="content">
            <div className="label">
              {intl.formatMessage({ id: 'admin-area.settings.incident.fields.default-call-out-message' })}
            </div>
            <div className="value">{props.defaultCallOutMessage}</div>
          </div>
          <div className="content">
            <div className="label">{intl.formatMessage({ id: 'admin-area.settings.incident.fields.description' })}</div>
            <div dangerouslySetInnerHTML={{ __html: props.description }} />
          </div>
          <div className="content">
            <div className="label">{intl.formatMessage({ id: 'admin-area.settings.incident.fields.visibility' })}</div>
            <div className="value">
              {props.visibility === 'public_incident'
                ? intl.formatMessage({ id: 'shared.public' })
                : intl.formatMessage({ id: 'shared.private' })}
            </div>
          </div>
          <div className="content">
            <div className="label">{intl.formatMessage({ id: 'shared.device-type' })}</div>
            <div className="value">{intl.formatMessage({ id: `units.unit-type.${props.deviceType}` })}</div>
          </div>
        </Card>
        <div>
          {props.images && props.images.length > 0 && (
            <ImageAlbum images={props.images} primaryImageIndex={props.primaryImageIndex} />
          )}
        </div>
      </div>
    </div>
  );
};

IncidentShow.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  incidentId: PropTypes.string.isRequired,
  environment: PropTypes.string.isRequired,
  environmentId: PropTypes.string.isRequired,
  defaultCallOutMessage: PropTypes.string.isRequired,
  description: PropTypes.string,
  visibility: PropTypes.string.isRequired,
  images: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
    }),
  ),
  primaryImageIndex: PropTypes.number,
  hasObservationEvents: PropTypes.bool.isRequired,
  hasApprovedOrganisations: PropTypes.bool.isRequired,
  status: PropTypes.string.isRequired,
  deviceType: PropTypes.string.isRequired,
};

export default wrap(IncidentShow);
