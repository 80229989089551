import React from "react";
import TableSearch from "./TableSearch";
import { IntlProvider } from "react-intl";
import { translations } from "./i18n";

const wrap = (SearchForm) => {
  const WrapperWithIntlProvider = (props) => {
    const WrapperForm = (props1) => {
      return <SearchForm {...props1} {...props}></SearchForm>;
    };

    return (
      <IntlProvider
        locale={window._FLAIM_LOCALE}
        key={window._FLAIM_LOCALE}
        messages={translations[window._FLAIM_LOCALE]}
      >
        <TableSearch form={WrapperForm}></TableSearch>
      </IntlProvider>
    );
  };

  return WrapperWithIntlProvider;
};

export default wrap;
