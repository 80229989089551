import React from "react";
import MicroEventFeedbackForm from "./MicroEventFeedbackForm";
import PropTypes from "prop-types";

import { IntlProvider } from "react-intl";
import { translations, locale } from "../../util/i18n";

const EditMicroEventFeedbackModal = (props) => {
  return (
    <IntlProvider
      locale={window._FLAIM_LOCALE}
      key={window._FLAIM_LOCALE}
      messages={translations[window._FLAIM_LOCALE]}
    >
      <MicroEventFeedbackForm url={props.url} defaultValues={{ ...props.details }}></MicroEventFeedbackForm>
    </IntlProvider>
  );
};

EditMicroEventFeedbackModal.propTypes = {
  url: PropTypes.string.isRequired,
  details: PropTypes.object.isRequired,
};

export default EditMicroEventFeedbackModal;
