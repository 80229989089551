import React, { useContext, useEffect, useState } from 'react';
import Accordion from '../../Accordion/Accordion';
import classNames from 'classnames';

import { NewCustomScenarioPageContext } from './NewCustomScenarioPage';
import { AccordionContext } from '../../Accordion/Accordion';
import {
  admin_area_custom_scenario_available_environments_path,
  admin_area_custom_scenario_step2_environment_path,
  admin_area_scenarios_path,
  organisation_area_custom_scenario_available_environments_path,
  organisation_area_custom_scenario_step2_environment_path,
  organisation_area_scenarios_path,
} from '../../../routes';
import Table from '../../Table';
import { useIntl } from 'react-intl';
import ax from '../../../bundles/main/components/util/axios';
import toastr from 'toastr';
import Modal from '../../../bundles/main/components/util/Modal';
import EnvironmentDetail from '../../../bundles/main/components/admin_area/settings/environments/EnvironmentDetail';
import { ModalContext } from '../../../bundles/main/components/util/Modal';
import PropTypes from 'prop-types';
import StepActions from './StepActions';

const EnvironmentDetailModalContent = ({ onSelect, environment }) => {
  const intl = useIntl();
  const modalContext = useContext(ModalContext);
  return (
    <div>
      <EnvironmentDetail {...environment} />
      <div className="detail_modal_actions">
        <button className="btn btn-secondary angled-top-left" onClick={() => modalContext.closeModal()}>
          {intl.formatMessage({ id: 'shared.cancel' })}
        </button>
        <button className="btn btn-primary angled-bottom-right" onClick={() => onSelect(environment)}>
          {intl.formatMessage({ id: 'shared.select' })}
        </button>
      </div>
    </div>
  );
};

EnvironmentDetailModalContent.propTypes = {
  onSelect: PropTypes.func.isRequired,
  environment: PropTypes.object.isRequired,
};

const Header = () => {
  const intl = useIntl();
  const [context] = useContext(NewCustomScenarioPageContext);
  const { scenario, activeStep } = context;
  const accordionContext = useContext(AccordionContext);
  const { environment_title } = scenario || {};

  useEffect(() => {
    if (activeStep == 'environment') {
      accordionContext.setExpanded(true);
    } else {
      accordionContext.setExpanded(false);
    }
  }, [activeStep]);

  return (
    <div className={classNames('step-header')}>
      {!environment_title && (
        <div className="title">
          <div className="title-info">
            <div className="title-main">{intl.formatMessage({ id: 'scenarios.new-custom-scenario.step2-header.title' })}</div>
            <div className="title-description">{intl.formatMessage({ id: 'scenarios.new-custom-scenario.step2-header.description' })}</div>
          </div>
        </div>
      )}
      {environment_title && (
        <div className="title">
          <div className="title-info">
            <div className="title-main">{intl.formatMessage({ id: 'admin-area.scenarios.environment-field' })}</div>
            <div className="title-description">{environment_title}</div>
          </div>
          <div>
            <i className="fal fa-circle-check"></i>
          </div>
        </div>
      )}
    </div>
  );
};

const Content = () => {
  const intl = useIntl();
  const [context, setContext] = useContext(NewCustomScenarioPageContext);
  const { scenario, isAdmin } = context;
  const [environments, setEnvironments] = useState([]);
  const [environmentToView, setEnvironmentToView] = useState();
  const [selectedEnv, setSelectedEnv] = useState({ id: scenario.environment_id, title: scenario.environment_title });
  const [availableEnvironmentsPath, step2Path, scenariosPath] = isAdmin
    ? [
        admin_area_custom_scenario_available_environments_path,
        admin_area_custom_scenario_step2_environment_path,
        admin_area_scenarios_path,
      ]
    : [
        organisation_area_custom_scenario_available_environments_path,
        organisation_area_custom_scenario_step2_environment_path,
        organisation_area_scenarios_path,
      ];

  useEffect(() => fetchEnvironments(), []);

  const fetchEnvironments = () => {
    let url = availableEnvironmentsPath(scenario.id);

    ax({
      method: 'get',
      url: url,
    })
      .then((response) => {
        setEnvironments(response.data.environments);
      })
      .catch((error) => {
        toastr['error'](error);
      });
  };

  const save = (backToIndex) => {
    const data = { selectedEnv };
    if (!backToIndex) {
      data.custom_scenario_step = 'incident';
    }

    ax({
      method: 'post',
      url: step2Path(scenario.id),
      data: data,
    })
      .then((res) => {
        if (backToIndex) {
          window.location = scenariosPath();
        } else {
          setContext({
            scenario: { ...scenario, ...res.data },
            activeStep: 'incident',
            isAdmin,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        toastr.error(intl.formatMessage({ id: 'shared.something-wrong' }));
      });
  };

  const saveDraft = () => {
    save(true);
  };

  const next = () => {
    save(false);
  };

  const hasImage = environments.some(env => env.primaryImageIndex != undefined);

  const environmentsColumns = [
    ...(hasImage ? [{
      accessor: 'image',
    }] : []),
    {
      accessor: 'title',
      header: intl.formatMessage({ id: 'admin-area.settings.environment.fields.title' }),
    },
    {
      accessor: 'publicIncidentCount',
      header: intl.formatMessage({ id: 'admin-area.settings.environment.fields.public-incidents-count' }),
    },
    {
      accessor: 'privateIncidentsCount',
      header: intl.formatMessage({ id: 'admin-area.settings.environment.fields.private-incidents-count' }),
    },
    {
      accessor: 'viewDetail',
      className: 'actions',
    },
  ];

  const onRowSelect = ({ id, title, selected }) => {
    if (selected) {
      setSelectedEnv({ id, title });
    } else {
      setSelectedEnv({});
    }
  };

  const mappedEnvironments = environments.map((env) => ({
    ...env,
    image: env.primaryImageIndex != undefined ? <img src={env.images[env.primaryImageIndex].url} /> : undefined,
    viewDetail: (
      <span onClick={() => setEnvironmentToView(env)}>
        <i className="fas fa-eye" />
      </span>
    ),
    selected: env.id == selectedEnv?.id,
  }));

  return (
    <div>
      <Table
        rowSelect={true}
        onRowSelect={onRowSelect}
        columns={environmentsColumns}
        items={mappedEnvironments}
        onPageChange={fetchEnvironments}
      />

      <StepActions onSaveDraft={saveDraft} onNext={next} disabled={!selectedEnv?.id} />

      {environmentToView && (
        <Modal
          open={environmentToView}
          onClose={() => setEnvironmentToView(undefined)}
          title={environmentToView?.title}
          noTitleTranslation
          modalVariation="wide"
          content={
            <EnvironmentDetailModalContent
              environment={environmentToView}
              onSelect={({ id, title }) => {
                setSelectedEnv({ id, title });
                setEnvironmentToView(undefined);
              }}
            />
          }
        />
      )}
    </div>
  );
};
const EnvironmentAccordion = () => {
  const [context, setContext] = useContext(NewCustomScenarioPageContext);
  const { scenario } = context;

  const onToggled = (expanded) => {
    if (expanded) {
      setContext({ ...context, activeStep: 'environment' });
    } else {
      setContext({ ...context, activeStep: '' });
    }
  };

  const isStepOk = () => scenario?.environment_id;

  return (
    <Accordion
      className={classNames({ 'step-ok': isStepOk() })}
      header={<Header />}
      content={<Content />}
      onToggled={onToggled}
      disabled={!context.scenario}
    />
  );
};

export default EnvironmentAccordion;
