import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import ax from '../../bundles/main/components/util/axios';
import customSelectStyle from '../../bundles/main/components/util/custom_react_select_style';

const MultiSelect = ({
  optionsUrl,
  optionsAccessor,
  optionLabelAccessor,
  optionValueAccessor,
  placeholder,
  name,
  onChange,
  defaultValue,
}) => {
  const styleCache = createCache({
    key: 'capture-prefix',
    nonce: document.querySelector('meta[name="csp-nonce"]').content,
  });

  const [isLoading, setIsLoading] = useState(true);
  const [options, setOptions] = useState([]);

  const fetchOptions = async () => {
    const response = await ax.get(optionsUrl + '.json');
    const data = optionsAccessor ? response.data[optionsAccessor] : response.data;
    const mappedOptions = data.map((option) => ({
      label: option[optionLabelAccessor],
      value: option[optionValueAccessor],
    }));
    setOptions(mappedOptions);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchOptions();
  }, []);

  return (
    <CacheProvider value={styleCache}>
      <div className="multi-dropdown">
        <ReactSelect
          isLoading={isLoading}
          closeMenuOnSelect={false}
          placeholder={placeholder}
          styles={customSelectStyle}
          options={options}
          onChange={onChange}
          defaultValue={defaultValue}
          classNamePrefix="react-select"
          className="react-select__menu"
          name={name}
          isMulti={true}
        />
      </div>
    </CacheProvider>
  );
};

MultiSelect.propTypes = {
  optionsUrl: PropTypes.string.isRequired,
  optionsAccessor: PropTypes.string,
  optionValueAccessor: PropTypes.string.isRequired,
  optionLabelAccessor: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  defaultValue: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
    }),
  ),
  placeholder: PropTypes.string,
  name: PropTypes.string,
};

export default MultiSelect;
