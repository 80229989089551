import React from 'react';
import RowMenu from '../../../util/RowMenu';
import PostLink from '../../../util/PostLink';
import PostLinkWithCustomConfirm from '../../../util/PostLinkWithCustomConfirm';
import PropTypes from 'prop-types';

import { IntlProvider } from 'react-intl';
import { translations } from '../../../util/i18n';

const CourseSettingMenu = (props) => {
  const options = [];

  if (!props.onlyShowDuplicate) {
    if (props.status == 'draft' && props.hasContent) {
      options.push({
        title: 'courses.dropdown.change-status-published',
        component: PostLinkWithCustomConfirm,
        params: {
          url: props.updateStatusUrl + '?status=published',
          refresh: true,
          confirmMessage: 'courses.dropdown.change-status-published-confirm',
          confirmTitle: 'courses.dropdown.change-status-published-confirm-title',
        },
      });
    }

    if (props.status == 'draft' || props.status == 'published') {
      options.push({
        title: 'courses.dropdown.change-status-archived',
        component: PostLinkWithCustomConfirm,
        params: {
          url: props.updateStatusUrl + '?status=archived',
          refresh: true,
          confirmMessage: 'courses.dropdown.change-status-archived-confirm',
          confirmTitle: 'courses.dropdown.change-status-archived-confirm-title',
        },
      });
    }

    if (props.status == 'archived' || (props.status === 'published' && !props.hasEnrolments)) {
      options.push({
        title: 'courses.dropdown.change-status-draft',
        component: PostLinkWithCustomConfirm,
        params: {
          url: props.updateStatusUrl + '?status=draft',
          refresh: true,
          confirmMessage: 'courses.dropdown.change-status-draft-confirm',
          confirmTitle: 'courses.dropdown.change-status-draft-confirm-title',
        },
      });
    }
  }
  options.push({
    title: 'courses.dropdown.duplicate-course',
    component: PostLink,
    params: {
      url: props.duplicateCourseUrl,
    },
  });

  return (
    <IntlProvider
      locale={window._FLAIM_LOCALE}
      key={window._FLAIM_LOCALE}
      messages={translations[window._FLAIM_LOCALE]}
    >
      <RowMenu
        classNameForButton="btn-secondary angled-top-left"
        classNameForDropdown={'for-btn-secondary'}
        title={'shared.actions'}
        options={options}
        leftIcon="fa-cog"
      ></RowMenu>
    </IntlProvider>
  );
};

CourseSettingMenu.propTypes = {
  onlyShowDuplicate: PropTypes.bool,
  status: PropTypes.string,
  hasContent: PropTypes.bool,
  updateStatusUrl: PropTypes.string,
  duplicateCourseUrl: PropTypes.string,
  hasEnrolments: PropTypes.bool,
};

export default CourseSettingMenu;
