import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './BooleanIcon.scss';

/**
 * Display check or cross mark icon base on boolean value with red or green color
 */
const BooleanIcon = ({ value }) => {
  return (
    <i
      className={classNames('boolean-icon', 'far', {
        negative: !value,
        'fa-circle-check': value,
        'fa-circle-xmark': !value,
      })}
    ></i>
  );
};

BooleanIcon.propTypes = {
  value: PropTypes.bool.isRequired,
};
export default BooleanIcon;
