import React from "react";
import RowMenu from "../util/RowMenu";
import Modal from "../util/Modal";
import AssignSimulationUserModal from "./AssignSimulationUserModal";
import PropTypes from "prop-types";

import { IntlProvider } from "react-intl";
import { translations } from "../util/i18n";

const SimulationsRowManual = (props) => {
  const options = [
    {
      title: "simulations.dropdown.assign-student",
      component: Modal,
      params: {
        title: "simulations.dropdown.assign-student",
        classNameForContent: "cancel-overflow-auto",
        content: AssignSimulationUserModal({
          url: props.assignUserUrl,
          assignableStudentsUrl: props.assignableStudentsUrl,
        }),
      },
    },
  ];

  return (
    <IntlProvider
      locale={window._FLAIM_LOCALE}
      key={window._FLAIM_LOCALE}
      messages={translations[window._FLAIM_LOCALE]}
    >
      <RowMenu options={options}></RowMenu>
    </IntlProvider>
  );
};

SimulationsRowManual.propTypes = {
  assignUserUrl: PropTypes.string.isRequired,
  assignableStudentsUrl: PropTypes.string.isRequired,
};

export default SimulationsRowManual;
