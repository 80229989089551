import React, { useContext, useEffect, useState } from 'react';
import Accordion from '../../Accordion/Accordion';
import classNames from 'classnames';

import { NewCustomScenarioPageContext } from './NewCustomScenarioPage';
import { AccordionContext } from '../../Accordion/Accordion';
import {
  admin_area_custom_scenario_step3_incident_path,
  admin_area_custom_scenario_available_incidents_path,
  admin_area_scenarios_path,
  admin_area_settings_incident_path,
  organisation_area_custom_scenario_step3_incident_path,
  organisation_area_custom_scenario_available_incidents_path,
  organisation_area_scenarios_path,
  organisation_area_settings_incident_path,
} from '../../../routes';
import Table from '../../Table';
import ax from '../../../bundles/main/components/util/axios';
import toastr from 'toastr';
import Modal, { ModalContext } from '../../../bundles/main/components/util/Modal';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import IncidentDetail from './IncidentDetail';
import StepActions from './StepActions';

const IncidentDetailModalContent = ({ onSelect, incidentId }) => {
  const intl = useIntl();
  const [context] = useContext(NewCustomScenarioPageContext);
  const { isAdmin } = context;
  const modalContext = useContext(ModalContext);
  const [incident, setIncident] = useState();
  useEffect(() => {
    const url = isAdmin
      ? admin_area_settings_incident_path(incidentId, { format: 'json' })
      : organisation_area_settings_incident_path(incidentId);
    ax({
      method: 'get',
      url: url,
    })
      .then((response) => {
        setIncident(response.data.incident);
      })
      .catch((error) => {
        console.log(error);
        toastr.error(intl.formatMessage({ id: 'shared.something-wrong' }));
      });
  }, []);

  if (incident) {
    return (
      <div>
        <IncidentDetail incident={incident} />
        <div className="detail_modal_actions">
          <button className="btn btn-secondary angled-top-left" onClick={() => modalContext.closeModal()}>
            {intl.formatMessage({ id: 'shared.cancel' })}
          </button>
          <button className="btn btn-primary angled-bottom-right" onClick={() => onSelect(incident)}>
            {intl.formatMessage({ id: 'shared.select' })}
          </button>
        </div>
      </div>
    );
  } else {
    return undefined;
  }
};

IncidentDetailModalContent.propTypes = {
  onSelect: PropTypes.func.isRequired,
  incidentId: PropTypes.number.isRequired,
};

const Header = () => {
  const intl = useIntl();
  const [context] = useContext(NewCustomScenarioPageContext);
  const { scenario, activeStep } = context;
  const { incident_title } = scenario || {};
  const accordionContext = useContext(AccordionContext);

  useEffect(() => {
    if (activeStep == 'incident') {
      accordionContext.setExpanded(true);
    } else {
      accordionContext.setExpanded(false);
    }
  }, [activeStep]);

  return (
    <div className={classNames('step-header')}>
      {!incident_title && (
        <div className="title">
          <div className="title-info">
            <div className="title-main">{intl.formatMessage({ id: 'scenarios.new-custom-scenario.step3-header.title' })}</div>
            <div className="title-description">{intl.formatMessage({ id: 'scenarios.new-custom-scenario.step3-header.title' })}</div>
          </div>
        </div>
      )}
      {incident_title && (
        <div className="title">
          <div className="title-info">
            <div className="title-main">{intl.formatMessage({ id: 'admin-area.scenarios.incident-field' })}</div>
            <div className="title-description">{incident_title}</div>
          </div>
          <div>
            <i className="fal fa-circle-check"></i>
          </div>
        </div>
      )}
    </div>
  );
};

const Content = () => {
  const intl = useIntl();
  const [context, setContext] = useContext(NewCustomScenarioPageContext);
  const { scenario, isAdmin } = context;
  const [incidents, setIncidents] = useState([]);
  const [incidentToView, setIncidentToView] = useState();
  const [selectedInc, setSelectedInc] = useState({ id: scenario.incident_id, title: scenario.incident_title });
  const [availableIncidentsPath, step3Path, scenariosPath] = isAdmin
    ? [
        admin_area_custom_scenario_available_incidents_path,
        admin_area_custom_scenario_step3_incident_path,
        admin_area_scenarios_path,
      ]
    : [
        organisation_area_custom_scenario_available_incidents_path,
        organisation_area_custom_scenario_step3_incident_path,
        organisation_area_scenarios_path,
      ];
  const fetchIncidents = () => {
    let url = availableIncidentsPath(scenario.id);

    ax({
      method: 'get',
      url: url,
    })
      .then((response) => {
        setIncidents(response.data.incidents);
      })
      .catch((error) => {
        console.log(error);
        toastr.error(intl.formatMessage({ id: 'shared.something-wrong' }));
      });
  };

  useEffect(() => {
    fetchIncidents();
  }, []);

  const save = (backToIndex) => {
    const data = { selectedInc };
    if (!backToIndex) {
      data.custom_scenario_step = 'injects';
    }

    ax({
      method: 'post',
      url: step3Path(scenario.id),
      data: data,
    })
      .then((res) => {
        if (backToIndex) {
          window.location = scenariosPath();
        } else {
          setContext({
            scenario: { ...scenario, ...res.data },
            isAdmin,
            activeStep: 'injects',
          });
        }
      })
      .catch((error) => {
        console.log(error);
        toastr.error(intl.formatMessage({ id: 'shared.something-wrong' }));
      });
  };

  const saveDraft = () => {
    save(true);
  };

  const next = () => {
    save(false);
  };

  const hasImage = incidents.some(inc => inc.primaryImageIndex != undefined);

  const incidentsColumns = [
    ...(hasImage ? [{
      accessor: 'image',
    }] : []),
    {
      accessor: 'title',
      header: intl.formatMessage({ id: 'admin-area.settings.incident.fields.title' }),
    },
    {
      accessor: 'description',
      header: intl.formatMessage({ id: 'admin-area.settings.incident.fields.description' }),
    },
    {
      accessor: 'viewDetail',
      className: 'actions',
    },
  ];

  const onRowSelect = ({ id, title, selected }) => {
    if (selected) {
      setSelectedInc({ id, title });
    } else {
      setSelectedInc({});
    }
  };

  const mappedIncidents = incidents.map((inc) => ({
    ...inc,
    image: inc.primaryImageIndex != undefined ? <img src={inc.images[inc.primaryImageIndex].url} /> : undefined,
    viewDetail: (
      <span onClick={() => setIncidentToView(inc)}>
        <i className="fas fa-eye" />
      </span>
    ),
    description: <div dangerouslySetInnerHTML={{ __html: inc.description }} />,
    selected: inc.id == selectedInc?.id,
  }));

  return (
    <div>
      <Table rowSelect={true} onRowSelect={onRowSelect} columns={incidentsColumns} items={mappedIncidents} />
      <StepActions onSaveDraft={saveDraft} onNext={next} disabled={!selectedInc?.id} />
      {incidentToView && (
        <Modal
          open={incidentToView}
          onClose={() => setIncidentToView(undefined)}
          title={incidentToView?.title}
          noTitleTranslation
          modalVariation="wide"
          content={
            <IncidentDetailModalContent
              incidentId={incidentToView.id}
              onSelect={({ id, title }) => {
                setSelectedInc({ id, title });
                setIncidentToView(undefined);
              }}
            />
          }
        />
      )}
    </div>
  );
};
const EnvironmentAccordion = () => {
  const [context, setContext] = useContext(NewCustomScenarioPageContext);
  const { scenario } = context;

  const onToggled = (expanded) => {
    if (expanded) {
      setContext({ ...context, activeStep: 'incident' });
    } else {
      setContext({ ...context, activeStep: '' });
    }
  };

  const isStepOk = () => scenario?.incident_id;

  return (
    <Accordion
      className={classNames({ 'step-ok': isStepOk() })}
      header={<Header />}
      content={<Content />}
      onToggled={onToggled}
      disabled={!context.scenario?.environment_title}
    />
  );
};

export default EnvironmentAccordion;
