import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import wrap from '../util/wrap_with_intl_provider';
import Table from '../../../../components/Table';
import ax from '../util/axios';
import { tableParamsBuilder } from '../util/searchUtils';
import PlaylistRowMenu from './PlaylistRowMenu';
import RightArrowLink from '../../../../components/RightArrowLink';
import SearchBar from '../../../../components/SearchBar';
import PlaylistSearchForm from './PlaylistSearchForm';
import {
  admin_area_playlist_path,
  organisation_area_playlist_path,
} from '../../../../routes';

const PlaylistTable = (props) => {
  const intl = useIntl();
  const [pagination, setPagination] = useState({});
  const [playlists, setPlaylists] = useState([]);
  const [searchParams, setSearchParams] = useState({});

  const fetchPlaylists = async (page = 1) => {
    const response = await ax.get(props.playlistsUrl + '.json' + tableParamsBuilder({ page, ...searchParams }));
    setPagination(response.data.pagination);
    setPlaylists(response.data.playlists);
  };

  const playlistColumns = () => {
    const columns = [
      {
        accessor: 'id',
        header: intl.formatMessage({ id: 'playlists.search.id-field' }),
      },
      {
        accessor: 'title',
        header: intl.formatMessage({ id: 'playlists.search.title-field' }),
      },
      {
        accessor: 'author',
        header: intl.formatMessage({ id: 'playlists.author-field' }),
      },
      {
        accessor: 'visibility',
        header: intl.formatMessage({ id: 'playlists.search.visibility-field' }),
      },
      {
        accessor: 'scenarios',
        header: intl.formatMessage({ id: 'playlists.scenarios-field' }),
      },
      {
        accessor: 'units',
        header: intl.formatMessage({ id: 'playlists.units-field' }),
      },
      {
        className: 'actions',
        accessor: 'rowMenu',
      },
      {
        className: 'actions',
        accessor: 'UnitLink',
      },
    ];

    return columns;
  };

  const mappedPlaylists = playlists.map((playlist) => ({
    ...playlist,
    rowMenu: (
      (props.isAdmin || props.currentOrganisationName === playlist.author) &&
        <PlaylistRowMenu
          removeUrl={props.isAdmin ? admin_area_playlist_path(playlist.id) : organisation_area_playlist_path(playlist.id)}
        />
    ),
    UnitLink: (
      <RightArrowLink url={props.isAdmin ? admin_area_playlist_path(playlist.id) : organisation_area_playlist_path(playlist.id)} />
    ),
  }));

  useEffect(() => {
    fetchPlaylists();
  }, [searchParams]);

  return (
    <div>
      <SearchBar
        primaryItems={
          <PlaylistSearchForm
            showOrgFilter={props.showOrgFilter}
            organisations={props.organisations}
            organisationId={props.organisationId}
            visibility={props.visibility}
          />
        }
        onSearch={setSearchParams}
      />
      {playlists.length > 0 && (
        <Table
          header={true}
          items={mappedPlaylists}
          columns={playlistColumns()}
          pagination={pagination}
          onPageChange={fetchPlaylists}
        />
      )}
    </div>
  );
};

PlaylistTable.propTypes = {
  playlistsUrl: PropTypes.string.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  showOrgFilter: PropTypes.bool.isRequired,
  organisations: PropTypes.array,
  organisationId: PropTypes.number,
  visibility: PropTypes.string,
  currentOrganisationName: PropTypes.string
};

export default wrap(PlaylistTable);
