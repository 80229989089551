import React from "react";
import RowMenu from "../util/RowMenu";
import Link from "../util/Link";
import PropTypes from "prop-types";

import { IntlProvider } from "react-intl";
import { translations } from "../util/i18n";

const AddStudentManual = (props) => {
  const options = [
    {
      title: "students.dropdown.create-student",
      component: Link,
      params: {
        url: props.createStudentUrl,
      },
    },
    {
      title: "students.dropdown.import-students",
      component: Link,
      params: {
        url: props.importStudentsUrl,
      },
    },
  ];

  return (
    <IntlProvider
      locale={window._FLAIM_LOCALE}
      key={window._FLAIM_LOCALE}
      messages={translations[window._FLAIM_LOCALE]}
    >
      <RowMenu title={"students.add-student"} options={options} classNameForDropdown="for-btn-primary"></RowMenu>
    </IntlProvider>
  );
};

AddStudentManual.propTypes = {
  createStudentUrl: PropTypes.string.isRequired,
  importStudentsUrl: PropTypes.string.isRequired,
};

export default AddStudentManual;
