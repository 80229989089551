import React from "react";
import RowMenu from "../../util/RowMenu";
import Modal from "../../util/Modal";
import PostLink from "../../util/PostLink";
import EditNoteModal from "./EditNoteModal";
import PropTypes from "prop-types";

import { IntlProvider } from "react-intl";
import { translations, locale } from "../../util/i18n";

const AdminNotesRowManual = (props) => {
  const options = [
    {
      title: "units.notes.dropdown.edit-note",
      component: Modal,
      params: {
        title: "units.notes.dropdown.edit-note",
        content: EditNoteModal({ url: props.updateUrl, details: { ...props.details } }),
      },
    },
    {
      title: "units.notes.dropdown.delete-note",
      component: PostLink,
      params: {
        url: props.updateUrl,
        method: "DELETE",
        refresh: true,
        confirmMessage: "units.notes.dropdown.delete-note-confirm-message",
      },
    },
  ];

  return (
    <IntlProvider
      locale={window._FLAIM_LOCALE}
      key={window._FLAIM_LOCALE}
      messages={translations[window._FLAIM_LOCALE]}
    >
      <RowMenu options={options}></RowMenu>
    </IntlProvider>
  );
};

AdminNotesRowManual.propTypes = {
  updateUrl: PropTypes.string.isRequired,
  details: PropTypes.object,
};

export default AdminNotesRowManual;
