import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { SearchBarContext } from '../../../../../../components/SearchBar';
import {
  admin_area_settings_incident_performance_events_path,
  not_added_events_admin_area_settings_incident_performance_events_path,
} from '../../../../../../routes';
import SearchBarRow from '../../../../../../components/SearchBar/SearchBarRow';
import MultiSelectionModal from '../../../util/MultiSelectionModal';

const PrimarySearchItems = () => {
  const intl = useIntl();
  const { register } = useContext(SearchBarContext);

  return (
    <SearchBarRow>
      <div className="form-group" style={{ flex: 1 }}>
        <label>{intl.formatMessage({ id: 'admin-area.settings.performance-event.title-field' })}</label>
        <input className="form-control" {...register('title')}></input>
      </div>
    </SearchBarRow>
  );
};

const AddPerformanceEventsModal = (props) => {
  const intl = useIntl();

  const columns = [
    {
      header: intl.formatMessage({ id: 'admin-area.settings.performance-event.event-id-field' }),
      accessor: 'eventId',
    },
    {
      header: intl.formatMessage({ id: 'admin-area.settings.performance-event.title-field' }),
      accessor: 'title',
    },
    {
      header: intl.formatMessage({ id: 'admin-area.settings.performance-event.description-field' }),
      accessor: 'description',
    },
  ];

  return (
    <MultiSelectionModal
      id={props.id}
      buttonText="admin-area.scenarios.add-performance-event"
      loadPath={not_added_events_admin_area_settings_incident_performance_events_path}
      addPath={admin_area_settings_incident_performance_events_path}
      cols={columns}
      primarySearchItems={<PrimarySearchItems />}
    />
  );
};

AddPerformanceEventsModal.propTypes = {
  id: PropTypes.number.isRequired,
};

export default AddPerformanceEventsModal;
