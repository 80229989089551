import React, { useState } from "react";
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";
import ServerFormErrors from "../../util/ServerFormErrors";
import ax from "../../util/axios";
import { IntlProvider, useIntl } from "react-intl";
import { locale, translations } from "../../util/i18n";
import convertToFormData from "../../util/upload";
import { add_submit_overlay, remove_submit_overlay } from "../../util/submit_overlay";

const PresentationForm = (props) => {
  const intl = useIntl();
  const [errorMessage, setErrorMessages] = useState();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues: { ...props.defaultValues } });
  const onSubmit = (data) => {
    const formData = convertToFormData(data, ["zip_file"]);
    add_submit_overlay();
    ax({
      method: props.method,
      url: props.url,
      data: formData,
    })
      .then(function (response) {
        window.location = response.data.return_url;
      })
      .catch((err) => {
        remove_submit_overlay();
        setErrorMessages(err.response.data);
      });
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)} className="narrow">
        <div className="form-group">
          <label>{intl.formatMessage({ id: "contents.title-field" })}</label>
          <input className="form-control" {...register("title", { required: true })}></input>
          {errors.title?.type === "required" && (
            <div className="field-error">{intl.formatMessage({ id: "shared.forms.required" })}</div>
          )}
        </div>

        <div className="form-group">
          <label>{intl.formatMessage({ id: "contents.description-field" })}</label>
          <input className="form-control" {...register("description")}></input>
        </div>

        <div className="form-group">
          <label>{intl.formatMessage({ id: "contents.visibility-field" })}</label>
          <select className="form-control" {...register("is_public")}>
            <option value="true">{intl.formatMessage({ id: "shared.public" })}</option>
            <option value="false">{intl.formatMessage({ id: "shared.private" })}</option>
          </select>
        </div>

        <div className="form-group">
          <label>{intl.formatMessage({ id: "presentations.zip-file-field" })}</label>
          <input type="file" accept=".zip" multiple={false} className="form-control" {...register("zip_file")}></input>
        </div>

        {props.defaultValues && props.defaultValues.title && (
          <div className="form-group">
            <label>{intl.formatMessage({ id: "contents.status-field" })}</label>
            <select className="form-control" {...register("status")}>
              <option value="draft">{intl.formatMessage({ id: "shared.status.draft" })}</option>
              <option value="published">{intl.formatMessage({ id: "shared.status.published" })}</option>
              <option value="archived">{intl.formatMessage({ id: "shared.status.archived" })}</option>
            </select>
          </div>
        )}

        <button type="submit" className="btn btn-primary small angled-bottom-right">
          {intl.formatMessage({ id: "shared.forms.save" })}
        </button>
      </form>

      <ServerFormErrors errorMessages={errorMessage}></ServerFormErrors>
    </div>
  );
};

PresentationForm.propTypes = {
  url: PropTypes.string.isRequired,
  method: PropTypes.string.isRequired,
  defaultValues: PropTypes.object,
};

export default PresentationForm;

export const WithIntlProvider = (props) => {
  return (
    <IntlProvider locale={locale} key={locale} messages={translations[locale]}>
      <PresentationForm {...props} />
    </IntlProvider>
  );
};
