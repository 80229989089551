import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import PageHeader from '../../../../components/PageHeader';
import PlaylistScenariosCreateForm from './PlaylistScenariosCreateForm';
import ConfirmModal from '../util/ConfirmModal';
import wrap_with_intl_provider from '../util/wrap_with_intl_provider';

function FormStep1(props) {
  const intl = useIntl();
  const [openCancelModal, setOpenCancelModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues: { ...props.defaultValues } });

  const toggleCancelModal = () => {
    setOpenCancelModal(!openCancelModal);
  }

  const onConfirm = () => {
    history.replaceState({}, '', props.submitUrl);
  }

  const addContent = (content) => {
    setLoading(true);
    let copyFormData = {
      ...props.formData,
      scenarios_attributes: props.formData.scenarios_attributes.concat([{ id: content.id, title: content.title }])
    };
    props.setFormData(copyFormData);
    setLoading(false);
  };

  const removeContent = (content) => {
    let copyFormData = {
      ...props.formData,
      scenarios_attributes: props.formData.scenarios_attributes.filter(function(scenario) {
        return scenario.id !== content.id
      })
    };
    props.setFormData(copyFormData);
  };

  const reorderPlaylistContent = (contents) => {
    let copyFormData = {
      ...props.formData,
      scenarios_attributes: contents
    };
    props.setFormData(copyFormData);
  };

  return (
    <div>
      <PageHeader
        previousPages={[
          {
            link: props.submitUrl,
            title: intl.formatMessage({ id: 'playlists.title' }),
          },
        ]}
        title={intl.formatMessage({ id: 'playlists.actions.create' })}
        pageDivider={true}
        customTitleSection={
          <div className="form-row playlist-create-step-1">
            <div className="form-group required playlist-title-container">
              <label>{intl.formatMessage({ id: 'courses.title-field' })}</label>
              <input
                className="form-control" {...register('title', { required: true })}
                value={props.formData.title}
                onChange={(e) => props.setFormData({...props.formData, title: e.target.value})}>
              </input>
              {errors.title?.type === 'required' && (
                <div className="field-error">{intl.formatMessage({ id: 'shared.forms.required' })}</div>
              )}
            </div>
            { props.isAdmin &&
              <div className="form-group required playlist-visibility-container">
                <label>{intl.formatMessage({ id: 'playlists.search.visibility-field' })}</label>
                <select
                  defaultValue={props.formData.visibility}
                  autoFocus
                  className="form-control" {...register('visibility', { required: true })}
                  onChange={(e) => props.setFormData({...props.formData, visibility: e.target.value})}
                >
                  {!props.formData.visibility && (
                    <option key="0" value="">
                      {intl.formatMessage({ id: 'shared.dropdown.please-select' })}
                    </option>
                  )}
                  <option key="1" value='public'>
                    {intl.formatMessage({ id: 'shared.public' })}
                  </option>
                  <option key="2" value='private'>
                    {intl.formatMessage({ id: 'shared.private' })}
                  </option>
                </select>
                {errors.visibility?.type === 'required' && (
                  <div className="field-error">{intl.formatMessage({ id: 'shared.forms.required' })}</div>
                )}
              </div>
            }
            <div className="form-group actions-buttons">
              <button type="button" className="btn btn-primary small angled-bottom-right" onClick={handleSubmit(props.handleNext)}>
                {intl.formatMessage({ id: 'scenarios.new-custom-scenario.next' })}
              </button>
              <button type="button" className="btn btn-secondary small angled-top-left" onClick={() => toggleCancelModal()}>
                {intl.formatMessage({ id: 'shared.cancel' })}
              </button>
            </div>
          </div>
        }
      />
      {openCancelModal &&
        <ConfirmModal
          title={intl.formatMessage({ id: 'playlists.cancel.modal-title' })}
          text={intl.formatMessage({ id: 'playlists.cancel.modal-message' })}
          onConfirm={onConfirm}
          onCancel={toggleCancelModal}
        ></ConfirmModal>
      }
      <PlaylistScenariosCreateForm
        formData={props.formData}
        setFormData={props.setFormData}
        isAdmin={props.isAdmin}
        canModify={true}
        scenariosUrl={props.scenariosUrl}
        addContent={addContent}
        removeContent={removeContent}
        reorderPlaylistContent={reorderPlaylistContent}
        loading={loading}
      />
    </div>
  );
}

FormStep1.propTypes = {
  formData: PropTypes.object,
  setFormData: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  defaultValues: PropTypes.object,
  handleNext: PropTypes.func.isRequired,
  submitUrl: PropTypes.string.isRequired,
  scenariosUrl: PropTypes.string.isRequired,
};

export default wrap_with_intl_provider(FormStep1);