import React from "react";
import NoteForm from "./NoteForm";
import PropTypes from "prop-types";

import { IntlProvider } from "react-intl";
import { translations, locale } from "../../util/i18n";

const EditNoteModal = (props) => {
  return (
    <IntlProvider
      locale={window._FLAIM_LOCALE}
      key={window._FLAIM_LOCALE}
      messages={translations[window._FLAIM_LOCALE]}
    >
      <NoteForm url={props.url} method={"PUT"} defaultValues={{ ...props.details }}></NoteForm>
    </IntlProvider>
  );
};

EditNoteModal.propTypes = {
  url: PropTypes.string.isRequired,
  details: PropTypes.object,
};

export default EditNoteModal;
