import React, { useState } from 'react';
import SearchContentForCourse from './SearchContentForCourse';
import { IntlProvider, useIntl } from 'react-intl';
import { locale, translations } from '../../../util/i18n';
import PropTypes from 'prop-types';
import _ from 'lodash';
import ax from '../../../util/axios';
import AvailableQuizTile from './AvailableQuizTile';
import AvailableScenarioTile from './AvailableScenarioTile';
import AvailablePresentationTile from './AvailablePresentationTile';
import CourseContentList from './CourseContentList';

const CourseContentTab = (props) => {
  const intl = useIntl();
  const groupedAvailableContent = _.chunk(props.availableContents, 3);
  const [courseContents, setCourseContent] = useState(props.courseContents);
  const [activeContentId, setActiveContentId] = useState();
  const [loading, setLoading] = useState(false);
  const toggleActiveContentId = (id) => {
    if (activeContentId == id) {
      setActiveContentId(null);
    } else {
      setActiveContentId(id);
    }
  };

  const addContent = (content) => {
    setLoading(true);
    ax({
      method: 'post',
      url: props.addContentUrl,
      data: { content_id: content.id, type: content.type, order: courseContents.length },
    })
      .then(function () {
        console.log('Add content to course successfully');

        document.querySelector('.tab-active .additional-info div').innerText = courseContents.length + 1;
        setActiveContentId(null);
        setCourseContent([...courseContents, content]);
      })
      .catch(() => {
        console.log('something went wrong');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getKeyForContent = (c) => `${c.id}${c.type}`;

  const courseContentKeys = courseContents.map((cc) => getKeyForContent(cc));

  return (
    <div className="course-contents">
      <div className="available-contents-section">
        <div className="section-header">{intl.formatMessage({ id: 'courses.available-content' })}</div>
        <SearchContentForCourse lmsContentCourseAccess={props.lmsContentCourseAccess}></SearchContentForCourse>
        {groupedAvailableContent.map((group, i) => {
          let activeContent = null;
          return (
            <div key={i}>
              <div className="available-contents">
                {group.map((content) => {
                  const keyForContent = getKeyForContent(content);
                  if (keyForContent == activeContentId) {
                    activeContent = content;
                  }
                  return (
                    <div
                      key={keyForContent}
                      className={`available-content ${activeContentId == keyForContent ? 'active' : ''}`}
                      onClick={() => toggleActiveContentId(keyForContent)}
                    >
                      <div className="content-title">{content.title}</div>
                      <div className="content-type">
                        <i className={`fal ${content.type_icon}`}></i>&nbsp;
                        {content.type_i18n}
                        {courseContentKeys.includes(keyForContent) && (
                          <span className="info">
                            <i className="fas fa-check"></i> {intl.formatMessage({ id: 'courses.module-added-short' })}
                          </span>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
              {activeContent && (
                <div className={`content-slideout ${activeContent ? 'active' : ''}`}>
                  <div className="body">
                    {activeContent && activeContent.type == 'quiz' && (
                      <AvailableQuizTile
                        key={getKeyForContent(activeContent)}
                        content={activeContent}
                        orgArea={props.orgArea}
                      ></AvailableQuizTile>
                    )}
                    {activeContent && activeContent.type == 'scenario' && (
                      <AvailableScenarioTile
                        key={getKeyForContent(activeContent)}
                        content={activeContent}
                        orgArea={props.orgArea}
                      ></AvailableScenarioTile>
                    )}
                    {activeContent && activeContent.type == 'presentation' && (
                      <AvailablePresentationTile
                        key={getKeyForContent(activeContent)}
                        content={activeContent}
                        orgArea={props.orgArea}
                      ></AvailablePresentationTile>
                    )}

                    {props.canEdit && (
                      <button
                        className="btn btn-primary small angled-top-left"
                        onClick={() => {
                          addContent(activeContent);
                        }}
                        disabled={loading || courseContentKeys.includes(getKeyForContent(activeContent))}
                      >
                        {intl.formatMessage({ id: 'courses.add-to-course' })} <i className="fa fa-plus"></i>
                      </button>
                    )}

                    {courseContentKeys.includes(getKeyForContent(activeContent)) && (
                      <span className="info">
                        <span>
                          <i className="fas fa-check"></i>
                        </span>
                        &nbsp;
                        <span>{intl.formatMessage({ id: 'courses.module-added' })}</span>
                      </span>
                    )}
                  </div>
                </div>
              )}
            </div>
          );
        })}
      </div>
      <CourseContentList
        removeContentUrl={props.removeContentUrl}
        orderContentsUrl={props.orderContentsUrl}
        courseContents={courseContents}
        setCourseContents={setCourseContent}
        canEdit={props.canEdit}
      />
    </div>
  );
};

export default CourseContentTab;

CourseContentTab.propTypes = {
  availableContents: PropTypes.array.isRequired,
  courseContents: PropTypes.array.isRequired,
  addContentUrl: PropTypes.string.isRequired,
  removeContentUrl: PropTypes.string.isRequired,
  orderContentsUrl: PropTypes.string.isRequired,
  canEdit: PropTypes.bool.isRequired,
  orgArea: PropTypes.bool.isRequired,
  lmsContentCourseAccess: PropTypes.bool.isRequired
};

export const WithIntlProvider = (props) => {
  return (
    <IntlProvider locale={locale} key={locale} messages={translations[locale]}>
      <CourseContentTab {...props} />
    </IntlProvider>
  );
};
