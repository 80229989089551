import React from "react";
import PropTypes from "prop-types";

const LoadingBar = (props) => {
  const { fill_percent } = props;

  return (
    <div className="progress-indicator">
      <div className="loading-bar-indicator">
        <div className="in-progress-percentage">{fill_percent}</div>
        <div className="inner-loading-bar">
          <div className="progress" style={{ width: fill_percent }}>
            &nbsp;
          </div>
        </div>
      </div>
    </div>
  );
};

LoadingBar.propTypes = {
  fill_percent: PropTypes.number.isRequired,
};

export default LoadingBar;
