import React from "react";
import EventCategoryForm from "./EventCategoryForm";
import PropTypes from "prop-types";

import { IntlProvider } from "react-intl";
import { translations, locale } from "../../../util/i18n";

const EditEventCategoryModal = (props) => (
  <IntlProvider locale={window._FLAIM_LOCALE} key={window._FLAIM_LOCALE} messages={translations[window._FLAIM_LOCALE]}>
    <EventCategoryForm
      url={props.url}
      method={"PUT"}
      defaultValues={{ ...props.eventCategoryDetails }}
    ></EventCategoryForm>
  </IntlProvider>
);

EditEventCategoryModal.propTypes = {
  url: PropTypes.string.isRequired,
  eventCategoryDetails: PropTypes.object.isRequired,
};

export default EditEventCategoryModal;
