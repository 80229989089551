import React from 'react';
import ModalLauncher from '../util/ModalLauncher';
import PropTypes from 'prop-types';
import AddMultiApprovedOrganisationModal from './AddMultiApprovedOrganisationModal';
import wrap from '../util/wrap_with_intl_provider';

const AddMultiApprovedOrganisationsModalLauncher = (props) => {
  const modalContent = () => {
    return <AddMultiApprovedOrganisationModal {...props} />;
  };

  return (
    <ModalLauncher
      buttonId="add-approved-organisation"
      buttonText="admin-area.scenarios.add-approved-organisation"
      content={modalContent()}
      title="admin-area.scenarios.add-approved-organisation"
      modalVariation="wide"
    />
  );
};

AddMultiApprovedOrganisationsModalLauncher.propTypes = {
  id: PropTypes.number.isRequired,
};

export default wrap(AddMultiApprovedOrganisationsModalLauncher);
