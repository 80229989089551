import React from "react";
import LinkUnitForm from "./LinkUnitForm";
import PropTypes from "prop-types";

import { IntlProvider } from "react-intl";
import { translations, locale } from "../../util/i18n";

const LinkUnitModal = (props) => {
  return (
    <IntlProvider
      locale={window._FLAIM_LOCALE}
      key={window._FLAIM_LOCALE}
      messages={translations[window._FLAIM_LOCALE]}
    >
      <LinkUnitForm url={props.url} method={"POST"}></LinkUnitForm>
    </IntlProvider>
  );
};

LinkUnitModal.propTypes = {
  url: PropTypes.string.isRequired,
};

export default LinkUnitModal;
