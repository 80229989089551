import React from 'react';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './Tooltip.scss';

const Tooltip = ({ type, tooltip, content }) => {
  return (
    <Tippy content={tooltip} theme={'white'}>
      <span className={classNames('tooltip', `tooltip-${type}`)}>
        {(() => {
          if (type === 'info') {
            return <i className="far fa-circle-info"></i>;
          } else {
            return <span>{content}</span>;
          }
        })()}
      </span>
    </Tippy>
  );
};

Tooltip.propTypes = {
  type: PropTypes.oneOf(['info', 'other']),
  tooltip: PropTypes.string.isRequired,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

export default Tooltip;
