import React from "react";
import RowMenu from "../../util/RowMenu";
import Modal from "../../util/Modal";
import EditMicroEventFeedbackModal from "./EditMicroEventFeedbackModal";
import PropTypes from "prop-types";

import { IntlProvider } from "react-intl";
import { translations, locale } from "../../util/i18n";

const OrgMicroEventRowManual = (props) => {
  const options = [
    {
      title: "admin-area.scenarios.dropdown.edit-feedback",
      component: Modal,
      params: {
        title: "admin-area.scenarios.dropdown.edit-feedback",
        content: EditMicroEventFeedbackModal({ url: props.url, details: { ...props.details } }),
      },
    },
  ];

  return (
    <IntlProvider
      locale={window._FLAIM_LOCALE}
      key={window._FLAIM_LOCALE}
      messages={translations[window._FLAIM_LOCALE]}
    >
      <RowMenu options={options}></RowMenu>
    </IntlProvider>
  );
};

OrgMicroEventRowManual.propTypes = {
  url: PropTypes.string.isRequired,
  details: PropTypes.object,
};

export default OrgMicroEventRowManual;
