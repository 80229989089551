import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import wrap from '../../../util/wrap_with_intl_provider';
import Table from '../../../../../../components/Table';
import PageHeader from '../../../../../../components/PageHeader';
import ax from '../../../util/axios';
import SearchBar, { SearchBarContext } from '../../../../../../components/SearchBar';
import SearchBarRow from '../../../../../../components/SearchBar/SearchBarRow';
import StatusLabel from '../../../../../../components/StatusLabel';
import { tableParamsBuilder } from '../../../util/searchUtils';

const PrimarySearchItems = () => {
  const intl = useIntl();
  const { register } = useContext(SearchBarContext);
  return (
    <SearchBarRow>
      <div className="form-group" style={{ flex: 2 }}>
        <label htmlFor="title">{intl.formatMessage({ id: 'shared.search-label' })}</label>
        <input
          className="form-control search"
          placeholder={intl.formatMessage({ id: 'shared.search' })}
          {...register('title')}
        />
      </div>
      <div className="form-group" style={{ flex: 1 }}>
        <label htmlFor="environment_id">
          {intl.formatMessage({ id: 'admin-area.settings.environment.fields.environment-id' })}
        </label>
        <input className="form-control" id="environment_id" {...register('environment_id')} />
      </div>
      <div className="form-group" style={{ flex: 1 }}>
        <label htmlFor="visibility">
          {intl.formatMessage({ id: 'admin-area.settings.environment.fields.visibility' })}
        </label>
        <select className="form-control" {...register('is_public')}>
          <option value="">{intl.formatMessage({ id: 'shared.all' })}</option>
          <option value="true">{intl.formatMessage({ id: 'shared.public' })}</option>
          <option value="false">{intl.formatMessage({ id: 'shared.private' })}</option>
        </select>
      </div>
    </SearchBarRow>
  );
};

const SecondarySearchItems = () => {
  const intl = useIntl();
  const { register } = useContext(SearchBarContext);
  return (
    <SearchBarRow>
      <div className="form-group" style={{ width: '30%' }}>
        <label htmlFor="status">{intl.formatMessage({ id: 'admin-area.settings.environment.fields.status' })}</label>
        <select
          className="form-control"
          placeholder={intl.formatMessage({ id: 'shared.all' })}
          {...register('status')}
          defaultValue=""
        >
          <option value="">{intl.formatMessage({ id: 'shared.all' })}</option>
          <option value="active">{intl.formatMessage({ id: 'shared.status.active' })}</option>
          <option value="draft">{intl.formatMessage({ id: 'shared.status.draft' })}</option>
        </select>
      </div>
    </SearchBarRow>
  );
};
const EnvironmentIndex = (props) => {
  const intl = useIntl();
  const [pagination, setPagination] = useState({});
  const [environments, setEnvironments] = useState([]);
  const [searchParams, setSearchParams] = useState({});

  const fetchEnvironments = async (page = 1) => {
    const response = await ax.get(props.environmentsUrl + '.json' + tableParamsBuilder({ page, ...searchParams }));
    setPagination(response.data.pagination);
    setEnvironments(response.data.environments);
  };

  const environmentsColumns = [
    {
      accessor: 'environmentId',
      className: 'link',
      header: intl.formatMessage({ id: 'admin-area.settings.environment.fields.environment-id' }),
    },
    {
      accessor: 'title',
      header: intl.formatMessage({ id: 'admin-area.settings.environment.fields.title' }),
    },
    {
      accessor: 'imagesCount',
      header: intl.formatMessage({ id: 'admin-area.settings.environment.fields.images-count' }),
    },
    {
      accessor: 'privateIncidentsCount',
      header: intl.formatMessage({ id: 'admin-area.settings.environment.fields.private-incidents-count' }),
    },
    {
      accessor: 'publicIncidentsCount',
      header: intl.formatMessage({ id: 'admin-area.settings.environment.fields.public-incidents-count' }),
    },
    {
      accessor: 'visibility',
      header: intl.formatMessage({ id: 'admin-area.settings.environment.fields.visibility' }),
    },
    {
      accessor: 'status',
      className: 'status',
      header: intl.formatMessage({ id: 'admin-area.settings.environment.fields.status' }),
    },
  ];

  const mappedEnvironments = environments.map((env) => ({
    ...env,
    environmentId: <a href={`${props.environmentsUrl}/${env.id}`}>{env.environmentId}</a>,
    status: <StatusLabel text={env.status} status={env.status === 'active' ? 'passed' : 'failed'} />,
    visibility: intl.formatMessage({ id: `shared.${env.isPublic ? 'public' : 'private'}` }),
  }));

  useEffect(() => {
    fetchEnvironments();
  }, [searchParams]);
  return (
    <div>
      <PageHeader tabs={props.tabs} title={intl.formatMessage({ id: 'admin-area.settings.scenario-assets.title' })} />
      <div className="page-header with-margin-top">
        <h2>{intl.formatMessage({ id: 'admin-area.settings.environment.index.title' })}</h2>
        <a className="btn btn-primary angled-top-left" href={props.newEnvironmentUrl}>
          <i className="fa fa-plus" />
          &nbsp;
          {intl.formatMessage({ id: 'admin-area.settings.environment.new.link' })}
        </a>
      </div>
      <SearchBar
        primaryItems={<PrimarySearchItems />}
        secondaryItems={<SecondarySearchItems />}
        onSearch={setSearchParams}
      />
      {environments.length > 0 && (
        <Table
          header={true}
          items={mappedEnvironments}
          columns={environmentsColumns}
          pagination={pagination}
          onPageChange={fetchEnvironments}
        />
      )}
    </div>
  );
};

EnvironmentIndex.propTypes = {
  environmentsUrl: PropTypes.string.isRequired,
  tabs: PropTypes.array.isRequired,
  newEnvironmentUrl: PropTypes.array.isRequired,
};

export default wrap(EnvironmentIndex);
