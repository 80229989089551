import React, { useState } from "react";
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";
import ServerFormErrors from "../../util/ServerFormErrors";
import ax from "../../util/axios";
import { IntlProvider, useIntl } from "react-intl";
import { locale, translations } from "../../util/i18n";

const LinkUnitForm = (props) => {
  const intl = useIntl();
  const [errorMessage, setErrorMessages] = useState();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues: { ...props.defaultValues } });
  const onSubmit = (data) => {
    ax({
      method: props.method,
      url: props.url,
      data: { form: data },
    })
      .then(function () {
        window.location = window.location.href;
      })
      .catch((err) => {
        setErrorMessages(err.response.data);
      });
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)} className="narrow">
        <div className="form-group">
          <label>{intl.formatMessage({ id: "units.serial-no-field" })}</label>
          <input className="form-control" {...register("serial_no", { required: true })}></input>
          {errors.serial_no?.type === "required" && (
            <div className="field-error">{intl.formatMessage({ id: "shared.forms.required" })}</div>
          )}
        </div>

        <button type="submit" className="btn btn-primary small angled-top-left">
          {intl.formatMessage({ id: "admin-area.organisations.link-unit" })}
        </button>
      </form>

      <ServerFormErrors errorMessages={errorMessage}></ServerFormErrors>
    </div>
  );
};

LinkUnitForm.propTypes = {
  url: PropTypes.string.isRequired,
  method: PropTypes.string.isRequired,
  defaultValues: PropTypes.object,
};

export default LinkUnitForm;

export const WithIntlProvider = (props) => {
  return (
    <IntlProvider
      locale={window._FLAIM_LOCALE}
      key={window._FLAIM_LOCALE}
      messages={translations[window._FLAIM_LOCALE]}
    >
      <LinkUnitForm {...props} />
    </IntlProvider>
  );
};
