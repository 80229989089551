import React, { useState } from "react";
import PropTypes from "prop-types";

const MoreInfo = (props) => {
  const [open, setOpen] = useState();
  return (
    <div className={`more-info ${open ? "open" : ""}`}>
      <div className="more-info-header" onClick={() => setOpen(true)}>
        {props.title}
        <i className="far fa-angle-down"></i>
      </div>
      <div className="info" dangerouslySetInnerHTML={{ __html: props.info }} />
    </div>
  );
};

MoreInfo.propTypes = {
  title: PropTypes.string.isRequired,
  info: PropTypes.string.isRequired,
};

export default MoreInfo;
