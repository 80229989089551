import React from "react";
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import wrap from "../../util/wrap_with_table_search";

const SearchStudentForInvitationForm = (props) => {
  const intl = useIntl();
  const { register, handleSubmit } = useForm({ defaultValues: { ...props.defaultValues } });
  const onSubmit = (data) => {
    props.onSubmit(data);
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="filters">
          <div className="form-group">
            <label>{intl.formatMessage({ id: "students.search.first-name-field" })}</label>
            <input className="form-control" {...register("first_name")}></input>
          </div>

          <div className="form-group">
            <label>{intl.formatMessage({ id: "students.search.last-name-field" })}</label>
            <input className="form-control" {...register("last_name")}></input>
          </div>

          <div className="form-group">
            <label>{intl.formatMessage({ id: "students.search.email-field" })}</label>
            <input className="form-control" {...register("email")}></input>
          </div>
        </div>

        <div>
          <button type="submit" className="btn btn-primary small angled-top-left" id="search">
            <i className="fas fa-filter"></i>&nbsp;
            {intl.formatMessage({ id: "shared.forms.apply_filters" })}
          </button>
        </div>
      </form>
    </div>
  );
};

SearchStudentForInvitationForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  defaultValues: PropTypes.object,
};

export default wrap(SearchStudentForInvitationForm);
