import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import PropTypes from "prop-types";
import ServerFormErrors from "../util/ServerFormErrors";
import ax from "../util/axios";
import { useIntl } from "react-intl";
import AsyncSelect from "react-select/async";
import _ from "lodash";
import customSelectStyle from "../util/custom_react_select_style";
import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";

const cspNonce = document.querySelector('meta[name="csp-nonce"]').content;
export const styleCache = createCache({
  key: "capture-prefix",
  nonce: cspNonce,
});

const AssignSimulationUserForm = (props) => {
  const intl = useIntl();
  const [errorMessage, setErrorMessages] = useState();
  const [studentInfo, setStudentInfo] = useState();
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({ defaultValues: { ...props.defaultValues } });
  const onSubmit = (data) => {
    const warningMsg = intl.formatMessage({ id: "simulations.assign-student-warning" }, { studentInfo: studentInfo });
    if (window.confirm(warningMsg)) {
      ax({
        method: "POST",
        url: props.url,
        data: { form: data },
      })
        .then(function () {
          window.location = window.location.href;
        })
        .catch((err) => {
          setErrorMessages(err.response.data);
        });
    }
  };

  const loadStudents = (inputValue) => {
    return fetch(`${props.assignableStudentsUrl}?term=${inputValue}`).then((res) => res.json());
  };

  const wait = 500;

  const debouncedLoadOptions = React.useCallback(
    _.debounce((inputValue, callback) => {
      loadStudents(inputValue).then((options) => callback(options));
    }, wait),
    []
  );

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <CacheProvider value={styleCache}>
          <div className="form-group">
            <label>{intl.formatMessage({ id: "simulations.assign-student.assign_to" })}</label>
            <Controller
              control={control}
              name="student_training_key"
              rules={{ required: true }}
              render={({ field }) => (
                <AsyncSelect
                  styles={customSelectStyle}
                  inputId="student_training_key"
                  inputRef={field.ref}
                  placeholder={<span>{intl.formatMessage({ id: "simulations.assign-student.placeholder" })}</span>}
                  onChange={(val) => {
                    field.onChange(val.value);
                    setStudentInfo(val.label);
                  }}
                  loadOptions={debouncedLoadOptions}
                  getOptionLabel={(e) => e.label}
                  getOptionValue={(e) => e.value}
                  classNamePrefix={"react-select"}
                />
              )}
            />
            {errors.student_training_key?.type === "required" && (
              <div className="field-error">{intl.formatMessage({ id: "shared.forms.required" })}</div>
            )}
          </div>
        </CacheProvider>

        <button type="submit" className="btn btn-primary small angled-bottom-right">
          {intl.formatMessage({ id: "shared.forms.save" })}
        </button>
      </form>

      <ServerFormErrors errorMessages={errorMessage}></ServerFormErrors>
    </div>
  );
};

AssignSimulationUserForm.propTypes = {
  url: PropTypes.string.isRequired,
  defaultValues: PropTypes.object,
  assignableStudentsUrl: PropTypes.string.isRequired,
};

export default AssignSimulationUserForm;
