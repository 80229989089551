import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import wrap from '../../util/wrap_with_intl_provider';
import Table from '../../../../../components/Table';
import ax from '../../util/axios';
import { admin_area_scenario_injects_path, organisation_area_scenario_injects_path } from '../../../../../routes';

const ScenarioInjects = (props) => {
  const intl = useIntl();

  const [pagination, setPagination] = useState({});
  const [injects, setInjects] = useState([]);

  const fetchInjects = async (page = 1) => {
    const loadUrl = props.isAdmin ? admin_area_scenario_injects_path : organisation_area_scenario_injects_path;
    const response = await ax.get(loadUrl(props.id, { format: 'json', page }));
    setPagination(response.data.pagination);
    setInjects(response.data.items);
  };

  const injectsColumns = [
    {
      accessor: 'injectId',
      className: 'link',
      header: intl.formatMessage({ id: 'admin-area.settings.inject.fields.inject-id' }),
    },
    {
      accessor: 'title',
      header: intl.formatMessage({ id: 'admin-area.settings.inject.fields.title' }),
    },
  ];

  useEffect(() => {
    fetchInjects();
  }, []);

  if (pagination.total != undefined) {
    return <Table items={injects} columns={injectsColumns} pagination={pagination} onPageChange={fetchInjects} />;
  }
};

ScenarioInjects.propTypes = {
  id: PropTypes.number.isRequired,
  isAdmin: PropTypes.bool,
};

export default wrap(ScenarioInjects);
