import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import wrap from '../../../util/wrap_with_intl_provider';
import Table from '../../../../../../components/Table';
import ax from '../../../util/axios';
import IncidentShowPageHeader from './IncidentShowPageHeader';
import EmptyStateHolder from '../../../../../../components/EmptyStateHolder';
import StatusLabel from '../../../../../../components/StatusLabel';
import PostLink from '../../../util/PostLink';
import RowMenu from '../../../util/RowMenu';
import AddPerformanceEventsModal from './AddPerformanceEventsModal';

import {
  admin_area_settings_incident_performance_events_path,
  reorder_admin_area_settings_incident_performance_events_path,
  admin_area_settings_incident_performance_event_path,
} from '../../../../../../routes';

const IncidentPerformanceEvents = (props) => {
  const intl = useIntl();
  const [pagination, setPagination] = useState({});
  const [performanceEvents, setPerformanceEvents] = useState([]);

  const fetchPerformanceEvents = async (page = 1) => {
    const response = await ax.get(
      admin_area_settings_incident_performance_events_path(props.id, { format: 'json', page }),
    );
    setPagination(response.data.pagination);
    setPerformanceEvents(response.data.performanceEvents);
  };

  const onRowDragEnd = (e) => {
    ax({
      method: 'PUT',
      url: reorder_admin_area_settings_incident_performance_events_path(props.id),
      data: { old_index: e.oldIndex, new_index: e.newIndex },
    });
  };

  const performanceEventsColumns = [
    {
      accessor: 'eventId',
      header: intl.formatMessage({ id: 'admin-area.settings.performance-event.event-id-field' }),
    },
    {
      accessor: 'title',
      header: intl.formatMessage({ id: 'admin-area.settings.performance-event.title-field' }),
    },
    {
      accessor: 'type',
      header: intl.formatMessage({ id: 'admin-area.settings.performance-event.type-field' }),
    },
    {
      accessor: 'suffix',
      header: intl.formatMessage({ id: 'admin-area.settings.performance-event.suffix-field' }),
    },
    {
      accessor: 'description',
      header: intl.formatMessage({ id: 'admin-area.settings.performance-event.description-field' }),
    },
    {
      accessor: 'status',
      header: intl.formatMessage({ id: 'admin-area.settings.performance-event.status-field' }),
      className: 'status',
    },
    {
      accessor: 'action',
      className: 'actions',
    },
  ];

  const mappedPerformanceEvents = performanceEvents.map((event) => ({
    ...event,
    status: <StatusLabel text={event.status} status={event.status === 'active' ? 'passed' : 'failed'} />,
    action: (
      <RowMenu
        options={[
          {
            title: 'admin-area.scenarios.dropdown.delete-event',
            component: PostLink,
            params: {
              method: 'DELETE',
              url: admin_area_settings_incident_performance_event_path(props.id, event.id),
              refresh: true,
              confirmMessage: 'admin-area.scenarios.delete-event.confirmation-message',
            },
          },
        ]}
      />
    ),
  }));

  useEffect(() => {
    fetchPerformanceEvents();
  }, []);

  if (pagination.total != undefined) {
    return (
      <div>
        <IncidentShowPageHeader activeUrl={admin_area_settings_incident_performance_events_path(props.id)} {...props} />
        <div className="page-header with-margin-top">
          <h2>{intl.formatMessage({ id: 'admin-area.settings.incident.show.tab.performance-events' })}</h2>
          <div>{performanceEvents.length > 0 && <AddPerformanceEventsModal id={props.id} />}</div>
        </div>
        {performanceEvents.length > 0 ? (
          <Table
            header={true}
            rawItems={performanceEvents}
            items={mappedPerformanceEvents}
            columns={performanceEventsColumns}
            pagination={pagination}
            onPageChange={fetchPerformanceEvents}
            rowDrag={true}
            onRowDragEnd={onRowDragEnd}
            onRowDrag={setPerformanceEvents}
          />
        ) : (
          <EmptyStateHolder
            button={<AddPerformanceEventsModal id={props.id} />}
            heading={intl.formatMessage({ id: 'admin-area.settings.incident.performance-event.empty-state.heading' })}
            description=""
          />
        )}
      </div>
    );
  }
};

IncidentPerformanceEvents.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  visibility: PropTypes.string.isRequired,
  events: PropTypes.array.isRequired,
  hasObservationEvents: PropTypes.bool.isRequired,
  hasApprovedOrganisations: PropTypes.bool.isRequired,
  status: PropTypes.string.isRequired,
};

export default wrap(IncidentPerformanceEvents);
