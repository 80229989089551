import React from 'react';
import { ReactSortable } from 'react-sortablejs';
import ax from '../../../util/axios';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useIntl } from 'react-intl';

const CourseContentList = (props) => {
  const intl = useIntl();
  const courseContents = props.courseContents;
  const setCourseContent = props.setCourseContents;
  const getKeyForContent = (c) => `${c.id}${c.type}`;
  const removeContent = (content) => {
    ax({
      method: 'post',
      url: props.removeContentUrl,
      data: { content_id: content.id, type: content.type },
    })
      .then(function () {
        console.log('Remove content from course successfully');

        document.querySelector('.tab-active .additional-info div').innerText = courseContents.length - 1;
        const leftCourseContents = _.filter(courseContents, (c) => c.id != content.id || c.type != content.type);
        setCourseContent(leftCourseContents);
      })
      .catch(() => {
        console.log('something went wrong');
      });
  };

  const reorderCourseContent = (contents) => {
    if (_.isEqual(contents, courseContents)) {
      return;
    }
    setCourseContent(contents);

    ax({
      method: 'post',
      url: props.orderContentsUrl,
      data: {
        contents: contents.map((c) => {
          return { id: c.id, type: c.type };
        }),
      },
    })
      .then(function () {
        console.log('Order course contents successfully');
      })
      .catch(() => {
        console.log('something went wrong');
      });
  };

  return (
    <div className="course-contents-section">
      <div className="section-header">{intl.formatMessage({ id: 'courses.my-content' })}</div>
      {courseContents.length == 0 && (
        <div className="info">
          <i className="fas fa-info-circle" />
          {intl.formatMessage({ id: 'courses.no-content' })}
        </div>
      )}
      <ReactSortable
        handle=".drag-handler > .fa-grip-vertical"
        tag="ul"
        list={courseContents}
        setList={reorderCourseContent}
      >
        {courseContents.map((content) => {
          return (
            <li key={getKeyForContent(content)}>
              <div className="drag-handler">{props.canEdit && <i className="fas fa-grip-vertical"></i>}</div>
              <div className="course-content" >
                <div className="course-content-left-section">
                  <div>{content.title}</div>
                  <div className="content-type">
                    <i className={`fas ${content.type_icon}`}></i>&nbsp;
                    {content.type_i18n}
                  </div>
                </div>
                {props.canEdit && (
                  <span
                    onClick={() => {
                      removeContent(content);
                    }}
                  >
                    <i className="fal fa-trash"></i>
                  </span>
                )}
              </div>
            </li>
          );
        })}
      </ReactSortable>
    </div>
  );
};

CourseContentList.propTypes = {
  courseContents: PropTypes.array.isRequired,
  setCourseContents: PropTypes.func.isRequired,
  removeContentUrl: PropTypes.string.isRequired,
  orderContentsUrl: PropTypes.string.isRequired,
  canEdit: PropTypes.bool.isRequired,
};

export default CourseContentList;
