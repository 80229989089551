import React, { useState } from "react";
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";
import ServerFormErrors from "../../util/ServerFormErrors";
import ax from "../../util/axios";
import { useIntl } from "react-intl";
import { add_submit_overlay, remove_submit_overlay } from "../../util/submit_overlay";

const CustomReportForm = (props) => {
  const intl = useIntl();
  const [errorMessage, setErrorMessages] = useState();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues: { ...props.defaultValues } });
  const onSubmit = (data) => {
    add_submit_overlay();
    ax({
      method: props.method,
      url: props.url,
      data: { form: data },
    })
      .then(function () {
        window.location = window.location.href;
      })
      .catch((err) => {
        remove_submit_overlay();
        setErrorMessages(err.response.data);
      });
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-group">
          <label>{intl.formatMessage({ id: "custom-reports.title-field" })}</label>
          <input
            className="form-control"
            {...register("title", {
              required: true,
              maxLength: { value: 34 },
            })}
          ></input>
          {errors.title?.type === "required" && (
            <div className="field-error">{intl.formatMessage({ id: "shared.forms.required" })}</div>
          )}
          {errors.title?.type === "maxLength" && (
            <div className="field-error">{intl.formatMessage({ id: "shared.forms.maxLength" }, { maxLength: 34 })}</div>
          )}
        </div>

        <div className="form-group">
          <label>{intl.formatMessage({ id: "custom-reports.description-field" })}</label>
          <textarea
            rows={7}
            className="form-control"
            {...register("description", {
              required: true,
              maxLength: { value: 270 },
            })}
          ></textarea>
          {errors.description?.type === "required" && (
            <div className="field-error">{intl.formatMessage({ id: "shared.forms.required" })}</div>
          )}
          {errors.description?.type === "maxLength" && (
            <div className="field-error">
              {intl.formatMessage({ id: "shared.forms.maxLength" }, { maxLength: 270 })}
            </div>
          )}
        </div>

        <div className="form-group">
          <label>{intl.formatMessage({ id: "custom-reports.question-id-field" })}</label>
          <input className="form-control" {...register("question_id", { required: true })}></input>
          {errors.question_id?.type === "required" && (
            <div className="field-error">{intl.formatMessage({ id: "shared.forms.required" })}</div>
          )}
        </div>

        <button type="submit" className="btn btn-primary small angled-bottom-right">
          {intl.formatMessage({ id: "shared.forms.save" })}
        </button>
      </form>

      <ServerFormErrors errorMessages={errorMessage}></ServerFormErrors>
    </div>
  );
};

CustomReportForm.propTypes = {
  url: PropTypes.string.isRequired,
  method: PropTypes.string.isRequired,
  defaultValues: PropTypes.object,
};

export default CustomReportForm;
