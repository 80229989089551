import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import './StatusLabel.scss';

/**
 * Status label has a colored text and colored border and background for passed/failed status
 */
const StatusLabel = ({ status, text }) => {
  const textToDisplay = text || useIntl().formatMessage({ id: `status-label.${status}` });
  return <span className={`status-label ${status}`}>{textToDisplay}</span>;
};

StatusLabel.propTypes = {
  status: PropTypes.oneOf(['failed', 'passed', 'visibility']).isRequired,
  text: PropTypes.string,
};

export default StatusLabel;
