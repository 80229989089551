import React from 'react';
import ModalLauncher from '../../util/ModalLauncher';
import AddApprovedOrganisationForm from './AddApprovedOrganisationForm';
import PropTypes from 'prop-types';

import { IntlProvider } from 'react-intl';
import { translations } from '../../util/i18n';

const AssignSimulationModalLauncher = (props) => {
  const modalContent = () => {
    return <AddApprovedOrganisationForm {...props} />;
  };

  return (
    <IntlProvider
      locale={window._FLAIM_LOCALE}
      key={window._FLAIM_LOCALE}
      messages={translations[window._FLAIM_LOCALE]}
    >
      <ModalLauncher
        buttonId="add-approved-organisation"
        buttonText="admin-area.scenarios.add-approved-organisation"
        content={modalContent()}
        title="admin-area.scenarios.add-approved-organisation"
      />
    </IntlProvider>
  );
};

AssignSimulationModalLauncher.propTypes = {
  url: PropTypes.string.isRequired,
  organisations: PropTypes.array.isRequired,
};

export default AssignSimulationModalLauncher;
