import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import ax from '../../util/axios';
import { useIntl } from 'react-intl';
import wrap from '../../util/wrap_with_intl_provider';
import { WithIntlProvider as SignUpForm } from '../../students/SignUpForm';

const NewEnrolmentForm = (props) => {
  const intl = useIntl();
  const [needSignUp, setNeedSignUp] = useState(props.signUpForm);
  const {
    register,
    handleSubmit,
    getValues,
    setError,
    formState: { errors },
  } = useForm({ defaultValues: { ...props.defaultValues } });

  const onSubmit = (data) => {
    ax({
      method: 'post',
      url: props.url,
      data: data,
    })
      .then(function (response) {
        const result = response.data;
        if (result.code == 'user_to_be_signup') {
          setNeedSignUp(true);
        } else if (result.code == 'success') {
          window.location = result.return_url;
        } else {
          setError('email', { type: result.code });
        }
      })
      .catch((err) => {
        console.log(err.data);
      });
  };

  return (
    <div>
      {needSignUp && (
        <SignUpForm
          url={props.signUpUrl}
          checkEmailUrl={props.checkEmailUrl}
          locales={props.locales}
          countries={props.countries}
          defaultValues={{
            email: getValues('email'),
            first_name: getValues('first_name'),
            last_name: getValues('last_name'),
            locale: props.locale,
          }}
        />
      )}
      {!needSignUp && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="form-group">
            <label>{intl.formatMessage({ id: 'enrolments.new.email-field' })}</label>
            <input
              type="email"
              className="form-control"
              {...register('email', { required: true, pattern: EMAIL_REGEX })}
            ></input>
            {errors.email?.type === 'required' && (
              <div className="field-error">{intl.formatMessage({ id: 'shared.forms.required' })}</div>
            )}
            {errors.email?.type === 'pattern' && (
              <div className="field-error">{intl.formatMessage({ id: 'shared.forms.invalid-email' })}</div>
            )}
            {errors.email?.type === 'user_need_sign_in' && (
              <div className="info-panel info with-top-border">
                <i className="far fa-exclamation-circle"></i>
                <div>
                  {intl.formatMessage({ id: 'enrolments.new.messages.email-associated-with-account' })}
                  <br />
                  <small>
                    {intl.formatMessage({ id: 'enrolments.new.messages.please' })}&nbsp;
                    <a href={props.signInUrl}>{intl.formatMessage({ id: 'enrolments.new.messages.sign-in-here' })}</a>
                  </small>
                </div>
              </div>
            )}
            {errors.email?.type === 'user_enrolled_need_sign_in' && (
              <div className="info-panel info with-top-border">
                <i className="far fa-exclamation-circle"></i>
                <div>
                  {intl.formatMessage({ id: 'enrolments.new.messages.already-enrolled' })}
                  <br />
                  <small>
                    {intl.formatMessage({ id: 'enrolments.new.messages.please' })}&nbsp;
                    <a href={props.signInUrl}>{intl.formatMessage({ id: 'enrolments.new.messages.sign-in-here' })}</a>
                    &nbsp;
                    {intl.formatMessage({ id: 'enrolments.new.messages.to-continue-course' })}
                  </small>
                </div>
              </div>
            )}
            {errors.email?.type === 'user_from_other_organisation' && (
              <div className="info-panel info">
                <i className="fas fa-exclamation-circle"></i>
                <small>{intl.formatMessage({ id: 'enrolments.new.messages.email-from-other-org' })}</small>
              </div>
            )}
          </div>

          <button type="submit" className="btn btn-primary small angled-top-left" disabled={errors.email}>
            <i className="fas fa-angle-right"></i>&nbsp;
            {intl.formatMessage({ id: 'enrolments.new.next' })}
          </button>
        </form>
      )}
    </div>
  );
};

NewEnrolmentForm.propTypes = {
  url: PropTypes.string.isRequired,
  signInUrl: PropTypes.string.isRequired,
  defaultValues: PropTypes.object.isRequired,
  signUpUrl: PropTypes.string.isRequired,
  checkEmailUrl: PropTypes.string.isRequired,
  locales: PropTypes.array.isRequired,
  locale: PropTypes.string.isRequired,
  countries: PropTypes.object.isRequired,
  signUpForm: PropTypes.object.isRequired,
};

export default wrap(NewEnrolmentForm);
