import React, { useState } from "react";
import ax from "./axios";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import ConfirmModal from "./ConfirmModal";
import toastr from 'toastr';

const PostLinkWithCustomConfirm = (props) => {
  const intl = useIntl();
  const [open, setOpen] = useState(true);
  const [formData, setFormData] = useState({});

  const onCancel = () => {
    setOpen(false);
    props.onClose();
  };

  const onConfirm = () => {
    setOpen(false);
    props.onClose();
    ax({
      method: props.method,
      url: props.url,
      data: formData,
    }).then(function (response) {
      if (props.refresh) {
        window.location.reload();
      } else if (response.data.customResponseURL !== undefined) {
        window.location = response.data.customResponseURL;
      } else if (response.status == 200) {
        window.location = response.request.responseURL;
      }
    }).catch((error) => {
      toastr['error'](error);
    });
  };

  if (open) {
    return (
      <ConfirmModal
        title={intl.formatMessage({ id: props.confirmTitle })}
        text={intl.formatMessage({ id: props.confirmMessage })}
        onConfirm={onConfirm}
        onCancel={onCancel}
        formData={props.hasFormFields ? formData : undefined}
        setFormData={props.hasFormFields ? setFormData : undefined}
        formFields={props.formFields}
      ></ConfirmModal>
    );
  } else {
    return null;
  }
};

PostLinkWithCustomConfirm.propTypes = {
  url: PropTypes.string.isRequired,
  method: PropTypes.string,
  confirmMessage: PropTypes.string.isRequired,
  confirmTitle: PropTypes.string.isRequired,
  refresh: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  hasFormFields: PropTypes.bool,
  formFields: PropTypes.arrayOf(
    PropTypes.shape({
      fieldName: PropTypes.string.isRequired,
      fieldType: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string.isRequired,
          value: PropTypes.string.isRequired,
        }).isRequired,
      ),
    }).isRequired,
  ),
};

PostLinkWithCustomConfirm.defaultProps = {
  method: "POST",
  refresh: true,
  hasFormFields: false,
};
export default PostLinkWithCustomConfirm;
