import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

const RowMenu = (props) => {
  const intl = useIntl();
  const [openMenu, setOpenMenu] = useState(false);
  const [activeComponent, setActiveComponent] = useState();

  const openActiveComponent = (opt) => {
    setOpenMenu(false);
    if (opt.component) {
      setActiveComponent(() => {
        let Component = opt.component;

        return (
          <Component
            {...opt.params}
            onClose={() => {
              setActiveComponent(undefined);
            }}
          ></Component>
        );
      });
    } else {
      opt.onClick && opt.onClick();
    }
  };

  return (
    <div>
      {activeComponent}
      <div className="row-action-container">
        <div
          className={(props.title ? '' : 'options-icon-container') + (openMenu ? ' open' : '')}
          onClick={() => {
            setOpenMenu(true);
          }}
        >
          {!props.title && <i className="far fa-ellipsis-v"></i>}
          {props.title && (
            <a className={`btn ${props.classNameForButton}`}>
              <span style={{ whiteSpace: 'pre' }}>
                {props.leftIcon && (
                  <>
                    <i className={`far ${props.leftIcon}`}></i>&nbsp;
                  </>
                )}
                {props.noTranslation ? props.title : intl.formatMessage({ id: props.title })}
              </span>
              {!props.noArrayDownIcon && <i className="fas fa-angle-down"></i>}
              <span></span>
            </a>
          )}
        </div>

        {openMenu && (
          <div>
            <div
              className="row-dropdown-close"
              onClick={() => {
                setOpenMenu(false);
              }}
            ></div>

            <div className={`row-dropdown ${props.title ? 'for-btn' : ''} ${props.classNameForDropdown}`}>
              <ul>
                {props.options.map((opt, index) => {
                  return (
                    <li
                      style={{ whiteSpace: 'pre-wrap' }}
                      key={index}
                      onClick={() => {
                        openActiveComponent(opt);
                      }}
                    >
                      {props.noTranslation ? opt.title : intl.formatMessage({ id: opt.title })}
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

RowMenu.propTypes = {
  classNameForButton: PropTypes.string,
  classNameForDropdown: PropTypes.string,
  title: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      title: String,
      component: Object,
      params: PropTypes.any,
    }),
  ),
  leftIcon: PropTypes.string,
  noTranslation: PropTypes.bool,
  noArrayDownIcon: PropTypes.bool,
};

RowMenu.defaultProps = {
  classNameForButton: 'btn-primary angled-top-left',
  classNameForDropdown: '',
  noArrayDownIcon: false,
};

export default RowMenu;
