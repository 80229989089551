import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import wrap from '../../../util/wrap_with_intl_provider';
import Table from '../../../../../../components/Table';
import ax from '../../../util/axios';
import StatusLabel from '../../../../../../components/StatusLabel';
import PrimarySearchItems from './MicroEventSearchForm';
import MicroEventRowMenu from './MicroEventRowMenu';
import SearchBar from '../../../../../../components/SearchBar';
import {
  admin_area_settings_micro_events_path,
  admin_area_settings_micro_event_path,
  admin_area_settings_micro_event_toggle_status_path,
} from '../../../../../../routes';

const MicroEventTable = (props) => {
  const intl = useIntl();
  const [pagination, setPagination] = useState({});
  const [microEvents, setMicroEvents] = useState([]);
  const [searchParams, setSearchParams] = useState({});
  const fetchMicroEvents = async (page = 1) => {
    const response = await ax.get(admin_area_settings_micro_events_path({ format: 'json', page, ...searchParams }));
    setPagination(response.data.pagination);
    setMicroEvents(response.data.microEvents);
  };

  const microEventColumns = [
    {
      accessor: 'eventId',
      className: 'small',
      header: intl.formatMessage({ id: 'admin-area.settings.micro-event.fields.event-id' }),
    },
    {
      accessor: 'deviceType',
      className: 'small',
      header: intl.formatMessage({ id: 'admin-area.settings.micro-event.fields.device-type' }),
    },
    {
      accessor: 'title',
      header: intl.formatMessage({ id: 'admin-area.settings.micro-event.fields.title' }),
    },
    {
      accessor: 'description',
      header: intl.formatMessage({ id: 'admin-area.settings.micro-event.fields.description' }),
    },
    {
      accessor: 'eventCategoryName',
      header: intl.formatMessage({ id: 'admin-area.settings.micro-event.fields.event-category' }),
    },
    {
      accessor: 'connectedScenarios',
      className: 'small',
      header: intl.formatMessage({ id: 'admin-area.settings.micro-event.fields.connected-scenarios' }),
    },
    {
      accessor: 'status',
      className: 'status',
      header: intl.formatMessage({ id: 'shared.status' }),
    },
    {
      className: 'actions',
      accessor: 'rowMenu',
    },
  ];

  const mappedMicroEvents = microEvents.map((microEvent) => ({
    ...microEvent,
    deviceType: intl.formatMessage({ id: `units.unit-type.${microEvent.deviceType}` }),
    status: <StatusLabel text={microEvent.status} status={microEvent.status === 'active' ? 'passed' : 'failed'} />,
    rowMenu: (
      <MicroEventRowMenu
        updateEventUrl={admin_area_settings_micro_event_path(microEvent.id)}
        toggleEventStatusUrl={admin_area_settings_micro_event_toggle_status_path(microEvent.id)}
        deleteEventUrl={!microEvent.connectedScenarios && admin_area_settings_micro_event_path(microEvent.id)}
        eventCategories={props.eventCategories}
        eventDetails={{
          event_id: microEvent.eventId,
          title: microEvent.title,
          default_success_message: microEvent.defaultSuccessMessage,
          default_failure_message: microEvent.defaultFailureMessage,
          status: microEvent.status,
          device_type: microEvent.deviceType,
          description: microEvent.description,
          event_category_id: microEvent.eventCategoryId,
        }}
      />
    ),
  }));

  useEffect(() => {
    fetchMicroEvents();
  }, [searchParams]);

  return (
    <div>
      <SearchBar
        primaryItems={<PrimarySearchItems eventCategories={props.eventCategories} />}
        onSearch={setSearchParams}
      />
      {microEvents.length > 0 && (
        <Table
          header={true}
          items={mappedMicroEvents}
          columns={microEventColumns}
          pagination={pagination}
          onPageChange={fetchMicroEvents}
        />
      )}
    </div>
  );
};

MicroEventTable.propTypes = {
  eventCategories: PropTypes.array.isRequired,
};

export default wrap(MicroEventTable);
