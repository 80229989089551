import React from "react";
import TableSearch from "../../util/TableSearch";
import ScenarioSearchForm from "./ScenarioSearchForm";

import { IntlProvider } from "react-intl";
import { translations, locale } from "../../util/i18n";
import PropTypes from "prop-types";

const SearchScenario = (props) => {
  const ScenarioSearchFormWrapper = (props1) => {
    return <ScenarioSearchForm {...props1} for_org_user={props.for_org_user}></ScenarioSearchForm>;
  };
  return (
    <IntlProvider
      locale={window._FLAIM_LOCALE}
      key={window._FLAIM_LOCALE}
      messages={translations[window._FLAIM_LOCALE]}
    >
      <TableSearch form={ScenarioSearchFormWrapper}></TableSearch>
    </IntlProvider>
  );
};

export default SearchScenario;

SearchScenario.propTypes = {
  for_org_user: PropTypes.bool,
};
