import { useIntl } from 'react-intl';
import Card from '../../Card';
import ImageAlbum from '../../ImageAlbum';
import React from 'react';
import PropTypes from 'prop-types';

const IncidentDetail = ({ incident }) => {
  const intl = useIntl();

  return (
    <div>
      <div className="row">
        <Card className="detail">
          <div className="content">
            <div className="label">{intl.formatMessage({ id: 'admin-area.settings.incident.fields.description' })}</div>
            <div dangerouslySetInnerHTML={{ __html: incident.description }} />
          </div>
          <div className="content">
            <div className="label">{intl.formatMessage({ id: 'admin-area.settings.incident.fields.visibility' })}</div>
            <div className="value">
              {incident.visibility === 'public_incident'
                ? intl.formatMessage({ id: 'shared.public' })
                : intl.formatMessage({ id: 'shared.private' })}
            </div>
          </div>
          {incident.approvedOrganisations.length > 0 && (
            <div className="content">
              <div className="label">
                {intl.formatMessage({ id: 'admin-area.settings.incident.fields.approved-organisations' })}
              </div>
              <div className="value">
                <ul className="bullet-point">
                  {incident.approvedOrganisations.map((org) => (
                    <li key={org}>{org}</li>
                  ))}
                </ul>
              </div>
            </div>
          )}

          {incident.performanceEvents.length > 0 && (
            <div className="content">
              <div className="label">
                {intl.formatMessage({ id: 'admin-area.settings.incident.fields.performance-events' })}
              </div>
              <div className="value">
                <ul className="bullet-point">
                  {incident.performanceEvents.map((event) => (
                    <li key={event}>{event}</li>
                  ))}
                </ul>
              </div>
            </div>
          )}
        </Card>
        <div>
          {incident.images && incident.images.length > 0 && (
            <ImageAlbum images={incident.images} primaryImageIndex={incident.primaryImageIndex} />
          )}
        </div>
      </div>
    </div>
  );
};

IncidentDetail.propTypes = {
  incident: PropTypes.object.isRequired,
};

export default IncidentDetail;
