import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import wrap from '../util/wrap_with_intl_provider';
import Table from '../../../../components/Table';
import ax from '../util/axios';
import StatusLabel from '../../../../components/StatusLabel';
import { tableParamsBuilder } from '../util/searchUtils';
import UnitsRowMenu from './UnitsRowMenu';
import RightArrowLink from '../../../../components/RightArrowLink';
import SearchBar from '../../../../components/SearchBar';
import { PrimarySearchItems, SecondarySearchItems } from './UnitSearchForm';
import {
  admin_area_unit_assign_org_path,
  admin_area_unit_remove_from_org_path,
  admin_area_unit_path,
  organisation_area_unit_path,
} from '../../../../routes';

const UnitTable = (props) => {
  const intl = useIntl();
  const [pagination, setPagination] = useState({});
  const [units, setUnits] = useState([]);
  const [searchParams, setSearchParams] = useState({});

  const fetchUnits = async (page = 1) => {
    const response = await ax.get(props.unitsUrl + '.json' + tableParamsBuilder({ page, ...searchParams }));
    setPagination(response.data.pagination);
    setUnits(response.data.units);
  };

  const unitColumns = () => {
    const columns = [
      {
        accessor: 'name',
        header: intl.formatMessage({ id: 'units.search.name-field' }),
      },
      {
        accessor: 'serialNumber',
        header: intl.formatMessage({ id: 'units.search.serial-no-field' }),
      },
      {
        accessor: 'unitType',
        header: intl.formatMessage({ id: 'units.unit-type-field' }),
      },
      {
        accessor: 'softwareVersion',
        header: intl.formatMessage({ id: 'units.search.software-version-field' }),
      },
      {
        accessor: 'lastUsedAt',
        header: intl.formatMessage({ id: 'units.search.last-used-field' }),
      },
      {
        accessor: 'organisation',
        header: intl.formatMessage({ id: 'units.search.organisation-field' }),
      },
      {
        accessor: 'status',
        className: 'status',
        header: intl.formatMessage({ id: 'shared.status' }),
      },
      {
        className: 'actions',
        accessor: 'UnitLink',
      },
    ];

    if (props.isAdmin) {
      columns.splice(7, 0, { className: 'actions', accessor: 'rowMenu' });
    }

    return columns;
  };

  const mappedUnits = units.map((unit) => ({
    ...unit,
    status: <StatusLabel text={unit.status} status={unit.status === 'active' ? 'passed' : 'failed'} />,
    rowMenu: (
      <UnitsRowMenu
        assignOrgUrl={admin_area_unit_assign_org_path(unit.id)}
        organisation={unit.organisation}
        organisations={props.organisations}
        removeFromOrgUrl={admin_area_unit_remove_from_org_path(unit.id)}
      />
    ),
    UnitLink: (
      <RightArrowLink url={props.isAdmin ? admin_area_unit_path(unit.id) : organisation_area_unit_path(unit.id)} />
    ),
  }));

  useEffect(() => {
    fetchUnits();
  }, [searchParams]);

  return (
    <div>
      <SearchBar
        primaryItems={<PrimarySearchItems />}
        secondaryItems={<SecondarySearchItems showOrgFilter={props.showOrgFilter} />}
        onSearch={setSearchParams}
      />
      {units.length > 0 && (
        <Table
          header={true}
          items={mappedUnits}
          columns={unitColumns()}
          pagination={pagination}
          onPageChange={fetchUnits}
        />
      )}
    </div>
  );
};

UnitTable.propTypes = {
  unitsUrl: PropTypes.string.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  showOrgFilter: PropTypes.bool.isRequired,
  organisations: PropTypes.array,
};

export default wrap(UnitTable);
