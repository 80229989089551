import React from 'react';
import PropTypes from 'prop-types';

import ApprovedOrganisations from '../../../approved_organisaitons/ApprovedOrganisations';
import {
  admin_area_settings_environment_approved_organisations_path,
  admin_area_settings_environment_not_approved_organisations_path,
  admin_area_settings_environment_organisation_path,
  admin_area_settings_environment_organisations_path,
} from '../../../../../../routes';
import EnvironmentShowPageHeader from './EnvironmentShowPageHeader';

const EnvironmentOrganisations = ({ id, title }) => {
  return (
    <ApprovedOrganisations
      id={id}
      loadApprovedPath={admin_area_settings_environment_approved_organisations_path}
      loadNotApprovedPath={admin_area_settings_environment_not_approved_organisations_path}
      addPath={admin_area_settings_environment_organisations_path}
      destroyPath={admin_area_settings_environment_organisation_path}
      header={
        <EnvironmentShowPageHeader
          id={id}
          title={title}
          isPublic={false}
          activeUrl={admin_area_settings_environment_approved_organisations_path(id)}
        />
      }
    />
  );
};

EnvironmentOrganisations.propTypes = {
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  isPublic: PropTypes.bool.isRequired,
};

export default EnvironmentOrganisations;
