import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import wrap from '../util/wrap_with_intl_provider';
import Table from '../../../../components/Table';
import ax from '../util/axios';
import StatusLabel from '../../../../components/StatusLabel';
import {
  organisation_area_online_courses_course_units_path,
  organisation_area_unit_courses_path,
  admin_area_online_courses_course_units_path,
  admin_area_unit_courses_path,
  admin_area_online_courses_course_unassign_unit_path,
  organisation_area_online_courses_course_unassign_unit_path
} from '../../../../routes';
import toastr from 'toastr';
import UnassignRowMenu from './UnassignRowMenu';

const CourseTable = ({ unit_id, isAdmin }) => {
  const intl = useIntl();
  const [pagination, setPagination] = useState({});
  const [courses, setCourses] = useState([]);

  const [courseUnitsPath, unitCoursesPath] = isAdmin
    ? [admin_area_online_courses_course_units_path, admin_area_unit_courses_path]
    : [organisation_area_online_courses_course_units_path, organisation_area_unit_courses_path];

  const fetchCourses = async (page = 1) => {
    let url = unitCoursesPath(unit_id, { page: page, format: 'json' });
    ax({
      method: 'get',
      url: url,
    })
      .then((response) => {
        setPagination(response.data.pagination);
        setCourses(response.data.courses);
      })
      .catch((error) => {
        toastr['error'](error);
      });
  };

  const courseColumns = () => {
    const columns = [
      {
        accessor: 'id',
        header: intl.formatMessage({ id: 'courses.id-field' }),
        className: 'link',
      },
      {
        accessor: 'title',
        header: intl.formatMessage({ id: 'courses.title-field' }),
      },
      {
        accessor: 'visibility',
        header: intl.formatMessage({ id: 'courses.visibility-field' }),
      },
      {
        accessor: 'currentEnrolments',
        header: intl.formatMessage({ id: 'courses.current-enrolments-field' }),
      },
      {
        accessor: 'completedEnrolments',
        header: intl.formatMessage({ id: 'courses.completed-enrolments-field' }),
      },
      {
        accessor: 'status',
        className: 'status',
        header: intl.formatMessage({ id: 'shared.status' }),
      },
      {
        accessor: 'rowMenu',
        header: intl.formatMessage({ id: 'shared.unassign' }),
      },
    ];

    return columns;
  };

  const mappedCourses = courses.map((course) => ({
    ...course,
    id: <a href={courseUnitsPath(course.id)}>{course.id}</a>,
    visibility: intl.formatMessage({ id: `shared.${course.is_public ? 'public' : 'private'}` }),
    status: (
      <StatusLabel
        text={intl.formatMessage({ id: `shared.status.${course.status}` })}
        status={course.status === 'published' ? 'passed' : 'failed'}
      />
    ),
    rowMenu: (
      <UnassignRowMenu
        unassignUrl={
          isAdmin ?
            admin_area_online_courses_course_unassign_unit_path(course.id, {unit_id: unit_id}) :
            organisation_area_online_courses_course_unassign_unit_path(course.id, {unit_id: unit_id})
        }
        confirmMessage={intl.formatMessage({ id: 'units.courses.unassign-modal-message' })}
        confirmTitle={intl.formatMessage({ id: 'units.playlists.unassign-modal-title' })}
      />
    ),
  }));

  useEffect(() => {
    fetchCourses();
  }, []);

  return (
    <div className="stack">
      {courses.length > 0 && (
        <>
          <div>{intl.formatMessage({ id: 'units.courses.to-unassign-course' })}</div>
          <Table
            header={true}
            items={mappedCourses}
            columns={courseColumns()}
            pagination={pagination}
            onPageChange={fetchCourses}
          />
        </>
      )}
      {courses.length == 0 && intl.formatMessage({ id: 'units.courses.no-courses-yet' })}
    </div>
  );
};

CourseTable.propTypes = {
  unit_id: PropTypes.number.isRequired,
  isAdmin: PropTypes.bool.isRequired,
};

export default wrap(CourseTable);
