import React from "react";
import RowMenu from "../../../util/RowMenu";
import Modal from "../../../util/Modal";
import PostLink from "../../../util/PostLink";
import EditPerformanceEventModal from "./EditPerformanceEventModal";
import PropTypes from "prop-types";

import { IntlProvider } from "react-intl";
import { translations, locale } from "../../../util/i18n";

const AdminPerformanceEventRowManual = (props) => {
  const options = [
    {
      title: "admin-area.event-library.dropdown.edit-event",
      component: Modal,
      params: {
        title: "admin-area.event-library.dropdown.edit-event",
        content: EditPerformanceEventModal({ url: props.updateEventUrl, eventDetails: { ...props.eventDetails } }),
      },
    },
    {
      title:
        props.eventDetails.status == "active"
          ? "admin-area.event-library.dropdown.disable-event"
          : "admin-area.event-library.dropdown.enable-event",
      component: PostLink,
      params: {
        url: props.toggleEventStatusUrl,
        refresh: true,
      },
    },
  ];

  if (props.deleteEventUrl) {
    options.push({
      title: "admin-area.scenarios.dropdown.delete-event",
      component: PostLink,
      params: {
        method: "delete",
        url: props.deleteEventUrl,
        refresh: true,
        confirmMessage: "admin-area.scenarios.delete-event.confirmation-message",
      },
    });
  }

  return (
    <IntlProvider
      locale={window._FLAIM_LOCALE}
      key={window._FLAIM_LOCALE}
      messages={translations[window._FLAIM_LOCALE]}
    >
      <RowMenu options={options}></RowMenu>
    </IntlProvider>
  );
};

AdminPerformanceEventRowManual.propTypes = {
  updateEventUrl: PropTypes.string.isRequired,
  toggleEventStatusUrl: PropTypes.string.isRequired,
  deleteEventUrl: PropTypes.string,
  eventDetails: PropTypes.object,
};

export default AdminPerformanceEventRowManual;
