import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import wrap from '../../../util/wrap_with_intl_provider';
import Table from '../../../../../../components/Table';
import ax from '../../../util/axios';
import IncidentShowPageHeader from './IncidentShowPageHeader';
import AddObservationEventsModal from './AddObservationEventsModal';
import EmptyStateHolder from '../../../../../../components/EmptyStateHolder';
import StatusLabel from '../../../../../../components/StatusLabel';
import PostLink from '../../../util/PostLink';
import RowMenu from '../../../util/RowMenu';

import {
  admin_area_settings_incident_observation_events_path,
  reorder_admin_area_settings_incident_observation_events_path,
  admin_area_settings_incident_observation_event_path,
} from '../../../../../../routes';

const IncidentObservationEvents = (props) => {
  const intl = useIntl();
  const [pagination, setPagination] = useState({});
  const [observationEvents, setObservationEvents] = useState([]);

  const fetchObservationEvents = async (page = 1) => {
    const response = await ax.get(
      admin_area_settings_incident_observation_events_path(props.id, { format: 'json', page }),
    );
    setPagination(response.data.pagination);
    setObservationEvents(response.data.observationEvents);
  };

  const onRowDragEnd = (e) => {
    ax({
      method: 'PUT',
      url: reorder_admin_area_settings_incident_observation_events_path(props.id),
      data: { old_index: e.oldIndex, new_index: e.newIndex },
    });
  };

  const observationEventsColumns = [
    {
      accessor: 'eventId',
      header: intl.formatMessage({ id: 'admin-area.settings.observation-event.fields.event-id' }),
    },
    {
      accessor: 'description',
      header: intl.formatMessage({ id: 'admin-area.settings.observation-event.fields.description' }),
    },
    {
      accessor: 'status',
      header: intl.formatMessage({ id: 'admin-area.settings.incident.fields.status' }),
      className: 'status',
    },
    {
      accessor: 'action',
      className: 'actions',
    },
  ];

  const mappedObservationEvents = observationEvents.map((event) => ({
    ...event,
    status: <StatusLabel text={event.status} status={event.status === 'active' ? 'passed' : 'failed'} />,
    action: (
      <RowMenu
        options={[
          {
            title: 'admin-area.settings.observation-event.dropdown.delete',
            component: PostLink,
            params: {
              method: 'delete',
              url: admin_area_settings_incident_observation_event_path(props.id, event.id),
              refresh: true,
              confirmMessage: 'admin-area.settings.observation-event.confirmation-message',
            },
          },
        ]}
      />
    ),
  }));

  useEffect(() => {
    fetchObservationEvents();
  }, []);

  if (pagination.total != undefined) {
    return (
      <div>
        <IncidentShowPageHeader activeUrl={admin_area_settings_incident_observation_events_path(props.id)} {...props} />
        <div className="page-header with-margin-top">
          <h2>{intl.formatMessage({ id: 'admin-area.settings.incident.show.tab.observation-events' })}</h2>
          <div>{observationEvents.length > 0 && <AddObservationEventsModal incidentId={props.id} />}</div>
        </div>
        {observationEvents.length > 0 ? (
          <Table
            header={true}
            rawItems={observationEvents}
            items={mappedObservationEvents}
            columns={observationEventsColumns}
            pagination={pagination}
            onPageChange={fetchObservationEvents}
            rowDrag={true}
            onRowDragEnd={onRowDragEnd}
            onRowDrag={setObservationEvents}
          />
        ) : (
          <EmptyStateHolder
            button={<AddObservationEventsModal incidentId={props.id} />}
            heading={intl.formatMessage({ id: 'admin-area.settings.incident.observation-event.empty-state.heading' })}
            description=""
          />
        )}
      </div>
    );
  }
};

IncidentObservationEvents.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  visibility: PropTypes.string.isRequired,
  hasObservationEvents: PropTypes.bool.isRequired,
  hasApprovedOrganisations: PropTypes.bool.isRequired,
  status: PropTypes.string.isRequired,
};

export default wrap(IncidentObservationEvents);
