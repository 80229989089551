import React from "react";
import RowMenu from "../../../util/RowMenu";
import Link from "../../../util/Link";
import PropTypes from "prop-types";

import { IntlProvider } from "react-intl";
import { translations, locale } from "../../../util/i18n";

const EventLibraryRowMenu = (props) => {
  const options = [
    {
      title: "admin-area.settings.event-libraries.dropdown.export-event",
      component: Link,
      params: {
        method: "get",
        url: props.exportUrl,
      },
    },
    {
      title: "admin-area.settings.event-libraries.dropdown.import-event",
      component: Link,
      params: {
        method: "get",
        url: props.importUrl,
      },
    },
  ];

  return (
    <IntlProvider
      locale={window._FLAIM_LOCALE}
      key={window._FLAIM_LOCALE}
      messages={translations[window._FLAIM_LOCALE]}
    >
      <RowMenu options={options}></RowMenu>
    </IntlProvider>
  );
};

EventLibraryRowMenu.propTypes = {
  exportUrl: PropTypes.string,
  importUlr: PropTypes.string,
};

export default EventLibraryRowMenu;
