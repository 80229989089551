import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";
import DatePicker from "react-datepicker";
import moment from "moment";
import wrap from "../util/wrap_with_table_search";

const SearchEnrolmentForm = (props) => {
  const intl = useIntl();
  const { register, handleSubmit, control } = useForm({ defaultValues: { ...props.defaultValues } });
  const onSubmit = (data) => {
    props.onSubmit(data);
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="filters">
          {props.courses && (
            <div className="form-group">
              <label>{intl.formatMessage({ id: "enrolments.course-field" })}</label>
              <select className="form-control" {...register("courseId")}>
                <option value="">{intl.formatMessage({ id: "shared.dropdown.please-select" })}</option>
                {props.courses.map((course) => (
                  <option key={course.id} value={course.id}>
                    {course.title}
                  </option>
                ))}
              </select>
            </div>
          )}

          {props.users && (
            <div className="form-group">
              <label>{intl.formatMessage({ id: "enrolments.user-field" })}</label>
              <select className="form-control" {...register("userId")}>
                <option value="">{intl.formatMessage({ id: "shared.dropdown.please-select" })}</option>
                {props.users.map((user) => (
                  <option key={user.id} value={user.id}>
                    {user.name}
                  </option>
                ))}
              </select>
            </div>
          )}

          {props.organisations && (
            <div className="form-group">
              <label>{intl.formatMessage({ id: "enrolments.organisation-field" })}</label>
              <select className="form-control" {...register("organisationId")}>
                <option value="">{intl.formatMessage({ id: "shared.dropdown.please-select" })}</option>
                {props.organisations.map((org) => (
                  <option key={org.id} value={org.id}>
                    {org.name}
                  </option>
                ))}
              </select>
            </div>
          )}

          <div className="form-group">
            <label>{intl.formatMessage({ id: "enrolments.created-at-field" })}</label>
            <div className="date-range">
              <Controller
                control={control}
                name="createdAtStart"
                render={({ field }) => (
                  <DatePicker
                    name="createdAtStart"
                    className="form-control"
                    placeholderText={intl.formatMessage({ id: "shared.forms.start_date" })}
                    selected={field.value ? moment(field.value).toDate() : undefined}
                    onChange={(date) => field.onChange(date)}
                    dateFormat={window._FLAIM_DATE_FORMAT}
                    locale={window._FLAIM_LOCALE}
                  />
                )}
              />

              <Controller
                control={control}
                name="createdAtEnd"
                render={({ field }) => (
                  <DatePicker
                    name="createdAtEnd"
                    className="form-control"
                    placeholderText={intl.formatMessage({ id: "shared.forms.end_date" })}
                    selected={field.value ? moment(field.value).toDate() : undefined}
                    onChange={(date) => field.onChange(date)}
                    dateFormat={window._FLAIM_DATE_FORMAT}
                  />
                )}
              />
            </div>
          </div>

          <div className="form-group">
            <label>{intl.formatMessage({ id: "enrolments.last-activity-field" })}</label>
            <div className="date-range">
              <Controller
                control={control}
                name="lastActivityStart"
                render={({ field }) => (
                  <DatePicker
                    name="lastActivityStart"
                    className="form-control"
                    placeholderText={intl.formatMessage({ id: "shared.forms.start_date" })}
                    selected={field.value ? moment(field.value).toDate() : undefined}
                    onChange={(date) => field.onChange(date)}
                    dateFormat={window._FLAIM_DATE_FORMAT}
                    locale={window._FLAIM_LOCALE}
                  />
                )}
              />

              <Controller
                control={control}
                name="lastActivityEnd"
                render={({ field }) => (
                  <DatePicker
                    name="lastActivityEnd"
                    className="form-control"
                    placeholderText={intl.formatMessage({ id: "shared.forms.end_date" })}
                    selected={field.value ? moment(field.value).toDate() : undefined}
                    onChange={(date) => field.onChange(date)}
                    dateFormat={window._FLAIM_DATE_FORMAT}
                  />
                )}
              />
            </div>
          </div>

          <div className="form-group">
            <label>{intl.formatMessage({ id: "enrolments.status-field" })}</label>
            <select className="form-control" {...register("status")}>
              <option value="">{intl.formatMessage({ id: "shared.dropdown.please-select" })}</option>
              <option value="not_started">{intl.formatMessage({ id: "enrolments.status.not-started" })}</option>
              <option value="underway">{intl.formatMessage({ id: "enrolments.status.underway" })}</option>
              <option value="complete">{intl.formatMessage({ id: "enrolments.status.complete" })}</option>
              <option value="fail">{intl.formatMessage({ id: "enrolments.status.fail" })}</option>
              <option value="disabled">{intl.formatMessage({ id: "enrolments.status.disabled" })}</option>
            </select>
          </div>
        </div>

        <button type="submit" className="btn btn-primary small angled-top-left">
          <i className="fas fa-filter"></i>&nbsp;
          {intl.formatMessage({ id: "shared.forms.apply_filters" })}
        </button>
      </form>
    </div>
  );
};

SearchEnrolmentForm.propTypes = {
  users: PropTypes.array,
  organisations: PropTypes.array,
  courses: PropTypes.array,
};

export default wrap(SearchEnrolmentForm);
