import React from 'react';
import RowMenu from '../../util/RowMenu';
import PostLink from '../../util/PostLink';
import PropTypes from 'prop-types';

import { IntlProvider } from 'react-intl';
import { translations } from '../../util/i18n';

const AdminScenarioRowManual = (props) => {
  const options = [
    {
      title:
        props.scenarioDetails.status == 'draft'
          ? 'admin-area.scenarios.dropdown.publish-scenario'
          : 'admin-area.scenarios.dropdown.unpublish-scenario',
      component: PostLink,
      params: {
        url: props.toggleScenarioStatusUrl,
        refresh: true,
      },
    },
  ];

  return (
    <IntlProvider
      locale={window._FLAIM_LOCALE}
      key={window._FLAIM_LOCALE}
      messages={translations[window._FLAIM_LOCALE]}
    >
      <RowMenu options={options}></RowMenu>
    </IntlProvider>
  );
};

AdminScenarioRowManual.propTypes = {
  toggleScenarioStatusUrl: PropTypes.string.isRequired,
  scenarioDetails: PropTypes.object,
};

export default AdminScenarioRowManual;
