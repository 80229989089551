import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import wrap from '../../../util/wrap_with_intl_provider';
import PageHeader from '../../../../../../components/PageHeader';
import IncidentForm from './IncidentForm';

const IncidentEdit = (props) => {
  const intl = useIntl();

  return (
    <div>
      <PageHeader
        previousPages={[
          {
            link: props.incidentsUrl,
            title: intl.formatMessage({ id: 'admin-area.settings.incident.index.title' }),
          },
          {
            link: props.incidentUrl,
            title: props.defaultValues.title,
          },
        ]}
        title={intl.formatMessage({ id: 'admin-area.settings.incident.edit.title' })}
        pageDivider={true}
      />
      <IncidentForm url={props.incidentUrl} method={'PUT'} defaultValues={{ ...props.defaultValues }} locked={props.locked} isDarkTheme={props.isDarkTheme} />
    </div>
  );
};

IncidentEdit.propTypes = {
  incidentsUrl: PropTypes.string.isRequired,
  incidentUrl: PropTypes.string.isRequired,
  defaultValues: PropTypes.object.isRequired,
  locked: PropTypes.bool.isRequired,
  isDarkTheme: PropTypes.bool,
};

export default wrap(IncidentEdit);
