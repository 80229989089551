import React, { useState } from "react";
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";
import ServerFormErrors from "../util/ServerFormErrors";
import ax from "../util/axios";
import { useIntl } from "react-intl";

const EditProfileForm = (props) => {
  const intl = useIntl();
  const [errorMessage, setErrorMessages] = useState();
  const [toUserName, setToUserName] = useState();
  const [toUserEmail, setToUserEmail] = useState();
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({});
  const onSubmit = (data) => {
    if (toUserName) {
      ax({
        method: "POST",
        url: props.mergeStudentUrl,
        data: { form: data },
      })
        .then(function () {
          window.location = window.location.href;
        })
        .catch((err) => {
          setErrorMessages(err.response.data);
        });
    } else {
      ax({
        method: "get",
        url: props.checkStudentForMergeUrl + "?training_key=" + data.training_key,
      })
        .then(function (response) {
          setToUserName(response.data.toUserName);
          setToUserEmail(response.data.toUserEmail);
        })
        .catch((err) => {
          setError("training_key", { type: "other", message: err.response.data });
        });
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        {!toUserName && (
          <div className="form-group">
            <label>{intl.formatMessage({ id: "students.merge-student.training-key-field" })}</label>
            <input className="form-control" {...register("training_key", { required: true })}></input>
            {errors.training_key?.type === "required" && (
              <div className="field-error">{intl.formatMessage({ id: "shared.forms.required" })}</div>
            )}
            {errors.training_key?.type === "other" && <div className="field-error">{errors.training_key?.message}</div>}
          </div>
        )}

        {toUserName && (
          <div className="stack">
            <div>
              Original student: {props.fromUserName} ({props.fromUserEmail})
            </div>

            <div>
              Destination student: {toUserName} ({toUserEmail})
            </div>

            <div>The origin student will be deleted with the simulation records updated to the destination student</div>

            <div className="form-group">
              <input type="checkbox" id="confirmMerge" {...register("confirmMerge", { required: true })}></input>
              <label htmlFor="confirmMerge" className="warning">
                {intl.formatMessage({ id: "shared.irreversible-confirmation" })}
              </label>
            </div>
          </div>
        )}

        <button type="submit" className="btn btn-primary small angled-top-left">
          {!toUserName && intl.formatMessage({ id: "students.merge-student.merge" })}
          {toUserName && intl.formatMessage({ id: "students.merge-student.proceed" })}
        </button>
      </form>

      <ServerFormErrors errorMessages={errorMessage}></ServerFormErrors>
    </div>
  );
};

EditProfileForm.propTypes = {
  checkStudentForMergeUrl: PropTypes.string.isRequired,
  mergeStudentUrl: PropTypes.string.isRequired,
  fromUserName: PropTypes.string.isRequired,
  fromUserEmail: PropTypes.string.isRequired,
};

export default EditProfileForm;
