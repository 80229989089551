import { useEffect } from "react";
import PropTypes from "prop-types";

const Link = (props) => {
  useEffect(() => {
    if (props.openInNewTab) {
      window.open(props.url);
    } else {
      window.location = props.url;
    }
  });
  return null;
};

Link.propTypes = {
  url: PropTypes.string.isRequired,
  openInNewTab: PropTypes.bool,
};

export default Link;
