import React, { useState } from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import ConfirmModal from '../util/ConfirmModal';

const ConfirmUnassignModal = (props) => {
  const intl = useIntl();
  const [open, setOpen] = useState(true);

  const onCancel = () => {
    setOpen(false);
    props.onClose();
  };

  const onConfirm = () => {
    props.onConfirmUnassign(props.unit);
    setOpen(false);
    props.onClose();
  };

  if (open) {
    return (
      <ConfirmModal
        title={intl.formatMessage({ id: props.confirmTitle })}
        text={intl.formatMessage({ id: props.confirmMessage })}
        onConfirm={onConfirm}
        onCancel={onCancel}
        noRefresh={true}
      ></ConfirmModal>
    );
  } else {
    return null;
  }
};

ConfirmUnassignModal.propTypes = {
  unit: PropTypes.object.isRequired,
  confirmMessage: PropTypes.string.isRequired,
  confirmTitle: PropTypes.string.isRequired,
  formData: PropTypes.object.isRequired,
  setFormData: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  onConfirmUnassign: PropTypes.func.isRequired,
};
export default ConfirmUnassignModal;
