import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import ServerFormErrors from '../../../util/ServerFormErrors';
import ax from '../../../util/axios';
import { useIntl } from 'react-intl';
import UnitTypeSelect from '../../units/UnitTypeSelect';

const MicroEventForm = (props) => {
  const intl = useIntl();
  const [errorMessage, setErrorMessages] = useState();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues: { ...props.defaultValues } });
  const onSubmit = (data) => {
    ax({
      method: props.method,
      url: props.url,
      data: { form: data },
    })
      .then(function () {
        window.location = window.location.href;
      })
      .catch((err) => {
        setErrorMessages(err.response.data);
      });
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-row">
          <div className="form-group">
            <label>{intl.formatMessage({ id: 'admin-area.settings.micro-event.fields.event-id' })}</label>
            <input className="form-control" {...register('event_id', { required: true })}></input>
            {errors.event_id?.type === 'required' && (
              <div className="field-error">{intl.formatMessage({ id: 'shared.forms.required' })}</div>
            )}
          </div>
          <div className="form-group">
            <label>{intl.formatMessage({ id: 'admin-area.settings.micro-event.fields.title' })}</label>
            <input className="form-control" {...register('title', { required: true })}></input>
            {errors.title?.type === 'required' && (
              <div className="field-error">{intl.formatMessage({ id: 'shared.forms.required' })}</div>
            )}
          </div>
        </div>

        <div className="form-row">
          <div className="form-group">
            <label>{intl.formatMessage({ id: 'admin-area.settings.micro-event.fields.device-type' })}</label>
            <UnitTypeSelect allowedUnits= {["extinguisher", "trainer", "sweeper"]} {...register('device_type', { required: true })} />
            {errors.device_type?.type === 'required' && (
              <div className="field-error">{intl.formatMessage({ id: 'shared.forms.required' })}</div>
            )}
          </div>
          <div className="form-group">
            <label>{intl.formatMessage({ id: 'admin-area.settings.micro-event.fields.event-category' })}</label>
            <select className="form-control" {...register('event_category_id')}>
              <option value="">{intl.formatMessage({ id: 'shared.dropdown.please-select' })}</option>
              {props.eventCategories.map((eventCategory) => (
                <option key={eventCategory.id} value={eventCategory.id}>
                  {eventCategory.name}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="form-row">
          <div className="form-group">
            <label>{intl.formatMessage({ id: 'admin-area.settings.micro-event.fields.description' })}</label>
            <textarea rows="3" className="form-control" {...register('description')}></textarea>
          </div>
        </div>

        <div className="form-row">
          <div className="form-group">
            <label>
              {intl.formatMessage({ id: 'admin-area.settings.micro-event.fields.default-success-message' })}
            </label>
            <textarea
              rows="3"
              className="form-control"
              {...register('default_success_message', { required: true })}
            ></textarea>
            {errors.default_success_message?.type === 'required' && (
              <div className="field-error">{intl.formatMessage({ id: 'shared.forms.required' })}</div>
            )}
          </div>
          <div className="form-group">
            <label>
              {intl.formatMessage({ id: 'admin-area.settings.micro-event.fields.default-failure-message' })}
            </label>
            <textarea
              rows="3"
              className="form-control"
              {...register('default_failure_message', { required: true })}
            ></textarea>
            {errors.default_failure_message?.type === 'required' && (
              <div className="field-error">{intl.formatMessage({ id: 'shared.forms.required' })}</div>
            )}
          </div>
        </div>

        <div className="form-group">
          <label>{intl.formatMessage({ id: 'units.status-field' })}</label>
          <select className="form-control" {...register('status')}>
            <option value="active">{intl.formatMessage({ id: 'shared.status.active' })}</option>
            <option value="inactive">{intl.formatMessage({ id: 'shared.status.inactive' })}</option>
          </select>
        </div>
        <div className="btn-group btn-end">
          <button type="submit" className="btn btn-primary small angled-bottom-right">
            <i className="fas fa-check" />&nbsp;
            {intl.formatMessage({ id: 'shared.forms.confirm' })}
          </button>
        </div>
      </form>

      <ServerFormErrors errorMessages={errorMessage}></ServerFormErrors>
    </div>
  );
};

MicroEventForm.propTypes = {
  url: PropTypes.string.isRequired,
  method: PropTypes.string.isRequired,
  defaultValues: PropTypes.object,
  eventCategories: PropTypes.array.isRequired,
};

export default MicroEventForm;
