import React from "react";
import MergeStudentForm from "./MergeStudentForm";
import PropTypes from "prop-types";

import { IntlProvider } from "react-intl";
import { translations } from "../util/i18n";

const MergeStudentModal = (props) => {
  return (
    <IntlProvider
      locale={window._FLAIM_LOCALE}
      key={window._FLAIM_LOCALE}
      messages={translations[window._FLAIM_LOCALE]}
    >
      <MergeStudentForm
        checkStudentForMergeUrl={props.checkStudentForMergeUrl}
        mergeStudentUrl={props.mergeStudentUrl}
        fromUserEmail={props.fromUserEmail}
        fromUserName={props.fromUserName}
      ></MergeStudentForm>
    </IntlProvider>
  );
};

MergeStudentModal.propTypes = {
  checkStudentForMergeUrl: PropTypes.string.isRequired,
  mergeStudentUrl: PropTypes.string.isRequired,
  fromUserName: PropTypes.string.isRequired,
  fromUserEmail: PropTypes.string.isRequired,
};

export default MergeStudentModal;
