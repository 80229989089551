import React, { useContext, useEffect, useState } from 'react';
import PageHeader from '../PageHeader';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import SearchBar, { SearchBarContext } from '../SearchBar';
import Table from '../Table';
import SearchBarRow from '../SearchBar/SearchBarRow';
import ax from '../../bundles/main/components/util/axios';
import { tableParamsBuilder } from '../../bundles/main/components/util/searchUtils';
import StatusLabel from '../StatusLabel';
import wrap from '../../bundles/main/components/util/wrap_with_intl_provider';
import toastr from 'toastr';
import Link from '../../bundles/main/components/util/Link';
import RowMenu from '../../bundles/main/components/util/RowMenu';
import AdminScenarioRowMenu from '../../bundles/main/components/admin_area/scenarios/AdminScenarioRowMenu';
import RightArrowLink from '../RightArrowLink';
import {
  new_admin_area_scenario_path,
  admin_area_scenarios_path,
  admin_area_scenario_toggle_status_path,
  admin_area_scenario_path,
  organisation_area_scenarios_path,
  organisation_area_scenario_path,
  new_organisation_area_scenario_path,
} from '../../routes';

const CreateScenarioMenu = ({ isAdmin }) => {
  const new_scenario_path = isAdmin ? new_admin_area_scenario_path : new_organisation_area_scenario_path;
  const options = [
    {
      title: 'admin-area.scenarios.custom-scenario',
      component: Link,
      params: {
        url: new_scenario_path({ custom_scenario: true }),
      },
    },
  ];

  if (isAdmin) {
    options.unshift({
      title: 'admin-area.scenarios.standard-scenario',
      component: Link,
      params: {
        url: new_scenario_path(),
      },
    });
  }

  return (
    <RowMenu
      title={'admin-area.scenarios.create-scenario'}
      options={options}
      classNameForDropdown="for-btn-primary"
    ></RowMenu>
  );
};

CreateScenarioMenu.propTypes = {
  isAdmin: PropTypes.bool.isRequired,
};

const PrimarySearchItems = () => {
  const intl = useIntl();
  const { register } = useContext(SearchBarContext);
  return (
    <SearchBarRow>
      <div className="form-group" style={{ flex: 2 }}>
        <label htmlFor="name">{intl.formatMessage({ id: 'admin-area.scenarios.name-field' })}</label>
        <input className="form-control" id="name" {...register('name')} />
      </div>
      <div className="form-group" style={{ flex: 1 }}>
        <label htmlFor="scenario_identity">{intl.formatMessage({ id: 'admin-area.scenarios.id-field' })}</label>
        <input className="form-control" id="scenario_identity" {...register('scenarioIdentity')} />
      </div>
      <div className="form-group" style={{ flex: 1 }}>
        <label htmlFor="status">{intl.formatMessage({ id: 'admin-area.scenarios.status-field' })}</label>
        <select className="form-control" id="status" {...register('status')}>
          <option value="">{intl.formatMessage({ id: 'shared.all' })}</option>
          <option value="0">{intl.formatMessage({ id: 'shared.status.draft' })}</option>
          <option value="1">{intl.formatMessage({ id: 'shared.status.published' })}</option>
        </select>
      </div>
    </SearchBarRow>
  );
};

const SecondarySearchItems = () => {
  const intl = useIntl();
  const { register } = useContext(SearchBarContext);
  return (
    <SearchBarRow>
      <div className="form-group" style={{ width: '30%' }}>
        <label htmlFor="setting">{intl.formatMessage({ id: 'admin-area.scenarios.environment-field' })}</label>
        <input className="form-control" id="setting" {...register('setting')} />
      </div>
      <div className="form-group" style={{ width: '30%' }}>
        <label htmlFor="fireEvent">{intl.formatMessage({ id: 'admin-area.scenarios.incident-field' })}</label>
        <input className="form-control" id="fireEvent" {...register('fireEvent')} />
      </div>
    </SearchBarRow>
  );
};

const ScenarioIndexPage = ({ tabs, defaultSearchValues, isAdmin }) => {
  const scenariosUrl = isAdmin ? admin_area_scenarios_path() : organisation_area_scenarios_path();
  const intl = useIntl();
  const [pagination, setPagination] = useState({});
  const [scenarios, setScenarios] = useState([]);
  const [searchParams, setSearchParams] = useState({ ...defaultSearchValues });

  const fetchScenarios = async (page = 1) => {
    try {
      const response = await ax.get(scenariosUrl + tableParamsBuilder({ format: 'json', page, ...searchParams }));
      setPagination(response.data.pagination);
      setScenarios(response.data.scenarios);
    } catch (e) {
      console.log(e);
      toastr.error(intl.formatMessage({ id: 'shared.something-wrong' }));
    }
  };

  useEffect(() => {
    fetchScenarios();
  }, [searchParams]);

  const mappedScenarios = scenarios.map((scenario) => ({
    ...scenario,
    status: <StatusLabel text={scenario.status} status={scenario.status === 'published' ? 'passed' : 'failed'} />,
    publicScenario: intl.formatMessage({ id: scenario.publicScenario ? 'shared.public' : 'shared.private' }),
    actions:
      scenario.custom_scenario && scenario.status != 'published' ? undefined : (
        <AdminScenarioRowMenu
          toggleScenarioStatusUrl={admin_area_scenario_toggle_status_path(scenario.id)}
          scenarioDetails={{ status: scenario.status }}
        ></AdminScenarioRowMenu>
      ),
    openScenario: (
      <RightArrowLink
        url={isAdmin ? admin_area_scenario_path(scenario.id) : organisation_area_scenario_path(scenario.id)}
      />
    ),
  }));

  const scenarioColumns = [
    { header: intl.formatMessage({ id: 'admin-area.scenarios.id-field' }), accessor: 'scenarioIdentity' },
    { header: intl.formatMessage({ id: 'admin-area.scenarios.name-field' }), accessor: 'name' },
    { header: intl.formatMessage({ id: 'admin-area.scenarios.status-field' }), accessor: 'status' },
    { header: intl.formatMessage({ id: 'admin-area.scenarios.environment-field' }), accessor: 'setting' },
    { header: intl.formatMessage({ id: 'admin-area.scenarios.incident-field' }), accessor: 'fireEvent' },
    { header: intl.formatMessage({ id: 'admin-area.scenarios.public-scenario-field' }), accessor: 'publicScenario' },
    { header: intl.formatMessage({ id: 'admin-area.scenarios.author-field' }), accessor: 'author' },
  ];

  if (isAdmin) {
    scenarioColumns.push({ header: '', accessor: 'actions', className: 'actions' });
  }

  scenarioColumns.push({ header: '', accessor: 'openScenario', className: 'actions' });

  return (
    <div>
      <PageHeader
        tabs={tabs}
        title={intl.formatMessage({ id: 'scenarios.all-scenarios' })}
        settingMenu={<CreateScenarioMenu isAdmin={isAdmin} />}
      />
      <SearchBar
        primaryItems={<PrimarySearchItems />}
        secondaryItems={<SecondarySearchItems />}
        onSearch={setSearchParams}
        defaultValues={defaultSearchValues}
      />
      {scenarios.length > 0 && (
        <Table
          items={mappedScenarios}
          columns={scenarioColumns}
          pagination={pagination}
          onPageChange={fetchScenarios}
        />
      )}
    </div>
  );
};

ScenarioIndexPage.propTypes = {
  tabs: PropTypes.array.isRequired,
  defaultSearchValues: PropTypes.object,
  isAdmin: PropTypes.bool.isRequired,
};

export default wrap(ScenarioIndexPage);
