import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import wrap from '../../../util/wrap_with_intl_provider';
import PageHeader from '../../../../../../components/PageHeader';
import IncidentForm from './IncidentForm';

const IncidentNew = (props) => {
  const intl = useIntl();

  return (
    <div>
      <PageHeader
        previousPages={[
          {
            link: props.incidentsUrl,
            title: intl.formatMessage({ id: 'admin-area.settings.incident.index.title' }),
          },
        ]}
        title={intl.formatMessage({ id: 'admin-area.settings.incident.new.title' })}
        pageDivider={true}
      />
      <IncidentForm url={props.incidentsUrl} method="POST" isDarkTheme={props.isDarkTheme} />
    </div>
  );
};

IncidentNew.propTypes = {
  incidentsUrl: PropTypes.string.isRequired,
  isDarkTheme: PropTypes.bool
};

export default wrap(IncidentNew);
