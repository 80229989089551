import React, { useState } from "react";
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";
import ServerFormErrors from "../../util/ServerFormErrors";
import ax from "../../util/axios";
import { IntlProvider, useIntl } from "react-intl";
import { translations } from "../../util/i18n";
import UnitTypeSelect from './UnitTypeSelect';

const UnitForm = (props) => {
  const intl = useIntl();
  const [errorMessage, setErrorMessages] = useState();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues: { ...props.defaultValues } });
  const onSubmit = (data) => {
    ax({
      method: props.method,
      url: props.url,
      data: { form: data },
    })
      .then(function (response) {
        window.location = response.data.return_url;
      })
      .catch((err) => {
        setErrorMessages(err.response.data);
      });
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)} className="narrow">
        <input type="hidden" {...register("org_id")} />
        <div className="form-group">
          <label>{intl.formatMessage({ id: "units.name-field" })}</label>
          <input className="form-control" {...register("name", { required: true })}></input>
          {errors.name?.type === "required" && (
            <div className="field-error">{intl.formatMessage({ id: "shared.forms.required" })}</div>
          )}
        </div>

        <div className="form-group">
          <label>{intl.formatMessage({ id: "units.serial-no-field" })}</label>
          <input className="form-control" {...register("serial_no", { required: true })}></input>
          {errors.serial_no?.type === "required" && (
            <div className="field-error">{intl.formatMessage({ id: "shared.forms.required" })}</div>
          )}
        </div>

        <div className="form-group">
          <label>{intl.formatMessage({ id: "units.unit-type-field" })}</label>
          <UnitTypeSelect allowedUnits={props.allowedUnits} {...register('unit_type', { required: true })} />
        </div>

        <div className="form-group">
          <label>{intl.formatMessage({ id: "units.status-field" })}</label>
          <select className="form-control" {...register("status")}>
            <option value="active">{intl.formatMessage({ id: "shared.status.active" })}</option>
            <option value="inactive">{intl.formatMessage({ id: "shared.status.inactive" })}</option>
          </select>
        </div>

        <button type="submit" className="btn btn-primary small angled-bottom-right">
          {intl.formatMessage({ id: "shared.forms.save" })}
        </button>
      </form>

      <ServerFormErrors errorMessages={errorMessage}></ServerFormErrors>
    </div>
  );
};

UnitForm.propTypes = {
  url: PropTypes.string.isRequired,
  method: PropTypes.string.isRequired,
  defaultValues: PropTypes.object,
  allowedUnits: PropTypes.arrayOf(PropTypes.string)
};

export default UnitForm;

export const WithIntlProvider = (props) => {
  return (
    <IntlProvider
      locale={window._FLAIM_LOCALE}
      key={window._FLAIM_LOCALE}
      messages={translations[window._FLAIM_LOCALE]}
    >
      <UnitForm {...props} />
    </IntlProvider>
  );
};
