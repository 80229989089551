import React from "react";
import RowMenu from "../../../util/RowMenu";
import PostLink from "../../../util/PostLink";
import Link from "../../../util/Link";
import PropTypes from "prop-types";

import { IntlProvider } from "react-intl";
import { translations } from "../../../util/i18n";

const AdminEnrolmentRowManual = (props) => {
  const options = [];

  if (props.viewStudentUrl) {
    options.push({
      title: "enrolments.dropdown.view-student",
      component: Link,
      params: {
        url: props.viewStudentUrl,
        openInNewTab: true,
      },
    });
  }

  if (props.disableEnrolmentUrl) {
    options.push({
      title: "enrolments.dropdown.disable-enrolment",
      component: PostLink,
      params: {
        url: props.disableEnrolmentUrl,
        refresh: true,
        confirmMessage: "enrolments.dropdown.disable-enrolment-confirm",
      },
    });
  }

  if (props.downloadCertificateUrl) {
    options.push({
      title: "enrolments.dropdown.download-certificate",
      component: Link,
      params: {
        url: props.downloadCertificateUrl,
      },
    });
  }

  if (props.viewCourseUrl) {
    options.push({
      title: "enrolments.dropdown.view-course",
      component: Link,
      params: {
        url: props.viewCourseUrl,
      },
    });
  }

  if (props.viewCourseReportUrl) {
    options.push({
      title: "enrolments.dropdown.view-course-report",
      component: Link,
      params: {
        url: props.viewCourseReportUrl
      },
    });
  }

  return (
    <IntlProvider
      locale={window._FLAIM_LOCALE}
      key={window._FLAIM_LOCALE}
      messages={translations[window._FLAIM_LOCALE]}
    >
      <RowMenu options={options}></RowMenu>
    </IntlProvider>
  );
};

AdminEnrolmentRowManual.propTypes = {
  viewStudentUrl: PropTypes.string,
  disableEnrolmentUrl: PropTypes.string,
  downloadCertificateUrl: PropTypes.string,
  viewCourseReportUrl: PropTypes.string,
  viewCourseUrl: PropTypes.string,
};

export default AdminEnrolmentRowManual;
