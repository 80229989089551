import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import wrap from '../util/wrap_with_intl_provider';
import Table from '../../../../components/Table';
import ax from '../util/axios';
import SearchBar from '../../../../components/SearchBar';
import { PrimarySearchItems, SecondarySearchItems } from './SimulationSearchForm';
import { tableParamsBuilder } from '../util/searchUtils';
import SimulationsRowMenu from './SimulationsRowMenu';
import RightArrowLink from '../../../../components/RightArrowLink';
import {
  admin_area_student_path,
  organisation_area_student_path,
  admin_area_simulation_assign_student_path,
  organisation_area_simulation_assign_student_path,
  assignable_students_admin_area_simulations_path,
  assignable_students_organisation_area_simulations_path,
  student_area_simulation_path,
  admin_area_simulation_path,
  organisation_area_simulation_path,
} from '../../../../routes';

const SimulationTable = (props) => {
  const intl = useIntl();
  const [pagination, setPagination] = useState({});
  const [simulations, setSimulations] = useState([]);
  const [searchParams, setSearchParams] = useState({});

  const fetchSimulations = async (page = 1) => {
    const response = await ax.get(props.simulationsUrl + '.json' + tableParamsBuilder({ page, ...searchParams }));
    setPagination(response.data.pagination);
    setSimulations(response.data.simulations);
  };

  const simulationColumns = () => {
    const columns = [
      {
        accessor: 'externalEmployeeId',
        className: props.isStudent ? '' : 'link',
        header: intl.formatMessage({ id: 'simulations.search.external-employee-id-field' }),
      },
      {
        accessor: 'dateRecorded',
        header: intl.formatMessage({ id: 'simulations.search.date-record-field' }),
      },
      {
        accessor: 'studentTrainingKey',
        header: intl.formatMessage({ id: 'simulations.fields.student-training-id' }),
      },
      {
        accessor: 'studentName',
        header: intl.formatMessage({ id: 'simulations.search.student-name-field' }),
      },
      {
        accessor: 'totalTime',
        header: intl.formatMessage({ id: 'simulations.fields.total-time' }),
      },
      {
        accessor: 'scenarioName',
        header: intl.formatMessage({ id: 'simulations.search.simulation-name-field' }),
      },
      {
        accessor: 'unitName',
        header: intl.formatMessage({ id: 'simulations.fields.unit' }),
      },
      {
        accessor: 'unitType',
        header: intl.formatMessage({ id: 'units.unit-type-field' }),
      },
      {
        className: 'actions',
        accessor: 'simulationLink',
      },
    ];

    if ((props.isAdmin || props.isOrganisationAdmin)) {
      columns.splice(8, 0, { className: 'actions', accessor: 'rowMenu' });
    }

    return columns;
  };

  const mappedSimulations = simulations.map((sim) => ({
    ...sim,
    externalEmployeeId:
      !props.isStudent && sim.studentExternalEmployeeId ? (
        <a
          href={props.isAdmin ? admin_area_student_path(sim.studentId) : organisation_area_student_path(sim.studentId)}
        >
          {sim.studentExternalEmployeeId}
        </a>
      ) : (
        sim.simulationExternalEmployeeId || sim.studentExternalEmployeeId
      ),
    studentTrainingKey:
      !props.isStudent && sim.studentTrainingKey ? (
        <a
          href={props.isAdmin ? admin_area_student_path(sim.studentId) : organisation_area_student_path(sim.studentId)}
        >
          {sim.studentTrainingKey}
        </a>
      ) : (
        sim.simulationStudentTrainingKey || sim.studentTrainingKey
      ),
    rowMenu: props.isAdmin ? (
      <SimulationsRowMenu
        assignUserUrl={admin_area_simulation_assign_student_path(sim.id)}
        assignableStudentsUrl={assignable_students_admin_area_simulations_path()}
      />
    ) : (
      <SimulationsRowMenu
        assignUserUrl={organisation_area_simulation_assign_student_path(sim.id)}
        assignableStudentsUrl={assignable_students_organisation_area_simulations_path()}
      />
    ),
    simulationLink: (
      <RightArrowLink
        url={
          props.isAdmin
            ? admin_area_simulation_path(sim.id)
            : props.isStudent
            ? student_area_simulation_path(sim.id)
            : organisation_area_simulation_path(sim.id)
        }
      />
    ),
  }));

  useEffect(() => {
    fetchSimulations();
  }, [searchParams]);

  return (
    <div>
      <SearchBar
        primaryItems={<PrimarySearchItems forStudent={props.forStudent} />}
        secondaryItems={<SecondarySearchItems forStudent={props.forStudent} showOrgFilter={props.showOrgFilter} />}
        onSearch={setSearchParams}
      />
      <div className="table-header-actions">
        <a href={props.simulationsUrl + '.csv' + tableParamsBuilder({ ...searchParams })}>
          <i className="fas fa-file-pdf" />
          &nbsp;
          {intl.formatMessage({ id: 'shared.forms.export_as_csv' })}
        </a>
      </div>
      {simulations.length > 0 && (
        <Table
          header={true}
          items={mappedSimulations}
          columns={simulationColumns()}
          pagination={pagination}
          onPageChange={fetchSimulations}
        />
      )}
    </div>
  );
};

SimulationTable.propTypes = {
  simulationsUrl: PropTypes.string.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  isStudent: PropTypes.bool.isRequired,
  forStudent: PropTypes.bool.isRequired,
  showOrgFilter: PropTypes.bool.isRequired,
  isOrganisationAdmin: PropTypes.bool.isRequired,
};

export default wrap(SimulationTable);
