import React, { useContext } from 'react';
import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import 'moment/locale/en-au';
import moment from 'moment';
import { useIntl } from 'react-intl';
import { SearchBarContext } from '../../../../components/SearchBar';
import SearchBarRow from '../../../../components/SearchBar/SearchBarRow';
import DatePicker, { registerLocale } from 'react-datepicker';
import de from 'date-fns/locale/de';
registerLocale('de', de);
import es from 'date-fns/locale/es';
registerLocale('es', es);
import fr from 'date-fns/locale/fr';
registerLocale('fr', fr);

const PrimarySearchItems = () => {
  const intl = useIntl();
  const { register, control } = useContext(SearchBarContext);

  return (
    <SearchBarRow>
      <div className="form-group" style={{ flex: 1 }}>
        <label>{intl.formatMessage({ id: 'units.search.name-field' })}</label>
        <input className="form-control" {...register('name')}></input>
      </div>

      <div className="form-group" style={{ flex: 1 }}>
        <label>{intl.formatMessage({ id: 'units.search.serial-no-field' })}</label>
        <input className="form-control" {...register('serialNo')}></input>
      </div>

      <div className="form-group" style={{ flex: 1 }}>
        <label>{intl.formatMessage({ id: 'units.search.software-version-field' })}</label>
        <input className="form-control" {...register('softwareVersion')}></input>
      </div>

      <div className="form-group" style={{ flex: 1 }}>
        <label>{intl.formatMessage({ id: 'units.search.last-used-field' })}</label>
        <div className="date-range">
          <Controller
            control={control}
            name="lastUsedStart"
            render={({ field }) => (
              <DatePicker
                name="lastUsedStart"
                className="form-control"
                placeholderText={intl.formatMessage({ id: 'shared.forms.start_date' })}
                selected={field.value ? moment(field.value).toDate() : undefined}
                onChange={(date) => field.onChange(date)}
                dateFormat={window._FLAIM_DATE_FORMAT}
                locale={window._FLAIM_LOCALE}
              />
            )}
          />

          <Controller
            control={control}
            name="lastUsedEnd"
            render={({ field }) => (
              <DatePicker
                name="lastUsedEnd"
                className="form-control"
                placeholderText={intl.formatMessage({ id: 'shared.forms.end_date' })}
                selected={field.value ? moment(field.value).toDate() : undefined}
                onChange={(date) => field.onChange(date)}
                dateFormat={window._FLAIM_DATE_FORMAT}
              />
            )}
          />
        </div>
      </div>
    </SearchBarRow>
  );
};

const SecondarySearchItems = (props) => {
  const intl = useIntl();
  const { register } = useContext(SearchBarContext);

  return (
    <SearchBarRow>
      {props.showOrgFilter && (
        <div className="form-group" style={{ flex: 1 }}>
          <label>{intl.formatMessage({ id: 'units.search.organisation-field' })}</label>
          <input className="form-control" {...register('organisation')}></input>
        </div>
      )}

      <div className="form-group" style={{ flex: 1 }}>
        <label>{intl.formatMessage({ id: 'units.search.only-unassigned-field' })}</label>
        <select className="form-control" {...register('onlyUnassigned')}>
          <option value="">{intl.formatMessage({ id: 'shared.no' })}</option>
          <option value="yes">{intl.formatMessage({ id: 'shared.yes' })}</option>
        </select>
      </div>

      <div className="form-group" style={{ flex: 1 }}>
        <label>{intl.formatMessage({ id: 'units.search.status-field' })}</label>
        <select className="form-control" {...register('showArchived')}>
          <option value="">{intl.formatMessage({ id: 'shared.no' })}</option>
          <option value="yes">{intl.formatMessage({ id: 'shared.yes' })}</option>
        </select>
      </div>
    </SearchBarRow>
  );
};

SecondarySearchItems.propTypes = {
  showOrgFilter: PropTypes.bool,
};

export { PrimarySearchItems, SecondarySearchItems };
