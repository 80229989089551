import React, { useState } from "react";
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import ServerFormErrors from "../../../util/ServerFormErrors";
import ax from "../../../util/axios";
import convertToFormData from "../../../util/upload";
import { add_submit_overlay, remove_submit_overlay } from "../../../util/submit_overlay";
import wrap from "../../../util/wrap_with_intl_provider";
import ConfirmModal from "../../../util/ConfirmModal";

const ImportLibraryEventsForm = (props) => {
  const intl = useIntl();
  const [errorMessage, setErrorMessages] = useState();
  const [responseData, setResponseData] = useState();
  const [isSave, setIsSave] = useState(false);
  const [formData, setFormData] = useState();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    add_submit_overlay();
    setFormData(data);
    const formData = convertToFormData(data, ["file"]);
    ax({
      method: "POST",
      url: isSave ? props.importUrl + "?is_save=true" : props.importUrl + "?is_save=false",
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(function (response) {
        if (isSave) {
          window.location = response.data.redirectUrl;
        } else {
          setResponseData(response.data);
          setErrorMessages();
          setIsSave(true);
        }
      })
      .catch((err) => {
        if (err.toString() == "Error: Network Error") {
          setErrorMessages([intl.formatMessage({ id: "students.import-students.refresh-page" })]);
        } else {
          setErrorMessages(err.response.data);
        }
      });
    remove_submit_overlay();
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)} className="narrow">
        <div className="form-group">
          <label>{intl.formatMessage({ id: "admin-area.settings.event-libraries.import.file" })}</label>
          <input
            type="file"
            accept="text/csv"
            multiple={false}
            className="form-control"
            {...register("file", { required: true })}
          ></input>
          {errors.file?.type === "required" && (
            <div className="field-error">{intl.formatMessage({ id: "shared.forms.required" })}</div>
          )}
        </div>

        <button type="submit" className="btn btn-primary small angled-top-left">
          {intl.formatMessage({ id: "admin-area.settings.event-libraries.import.button" })}
        </button>
      </form>

      {errorMessage && <ServerFormErrors errorMessages={errorMessage}></ServerFormErrors>}
      {isSave && (
        <ConfirmModal
          title={intl.formatMessage({ id: "admin-area.settings.event-libraries.import.confirm.title" })}
          text={intl.formatMessage(
            {
              id: "admin-area.settings.event-libraries.import.confirm.text",
            },
            { newEventsCount: responseData.newEventsCount, updateEventsCount: responseData.updateEventsCount }
          )}
          onConfirm={() => onSubmit(formData)}
          onCancel={() => setIsSave(false)}
        />
      )}
    </div>
  );
};

ImportLibraryEventsForm.propTypes = {
  importUrl: PropTypes.string.isRequired,
};

export default wrap(ImportLibraryEventsForm);
