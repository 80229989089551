import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Checkbox from '../Checkbox';

const TableCell = ({ colDef, item }) => {
  const { className, accessor, render } = colDef;

  return (
    <td className={className}>
      {render && render(item[accessor], item)}
      {!render && item[accessor]}
    </td>
  );
};

TableCell.propTypes = {
  colDef: PropTypes.shape({
    header: PropTypes.string,
    accessor: PropTypes.string.isRequired,
    className: PropTypes.string,
    render: PropTypes.func,
  }).isRequired,
  item: PropTypes.object.isRequired,
};

const TableRow = (props) => {
  const [selected, setSelected] = useState(props.item.selected);

  /*
   without this, table row might show wrong selected status when navigates throw pages

   alternative approach will be not keep selected state, always respect props.item.selected, but it will cause
   selecting row stop working in storybook unless we mock a container component there
   */
  useEffect(() => {
    setSelected(props.item.selected);
  }, [props.item.selected]);

  const showRowSelectCheckbox = props.rowSelect instanceof Function ? props.rowSelect(props.item) : props.rowSelect;
  return (
    <tr className={classNames({ selected }, props.rowClass && props.rowClass(props.item))}>
      {props.rowDrag && (
        <td className="drag-handler">
          <i className="fas fa-grip-vertical" />
        </td>
      )}
      {props.rowSelect && (
        <td className="actions">
          {showRowSelectCheckbox && (
            <Checkbox
              checked={selected}
              onChange={(event) => {
                setSelected(event.target.checked);
                props.onRowSelect({ ...props.item, selected: event.target.checked });
              }}
            />
          )}
        </td>
      )}
      {props.columns.map((column) => (
        <TableCell key={column.accessor} colDef={column} item={props.item} />
      ))}
    </tr>
  );
};

TableRow.defaultProps = {
  header: true,
  rowDrag: false,
  onRowDrag: () => {},
  rowSelect: false,
  onRowSelect: () => {},
};

TableRow.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      header: PropTypes.string,
      accessor: PropTypes.string.isRequired,
      className: PropTypes.string,
      render: PropTypes.func,
    }),
  ).isRequired,
  item: PropTypes.object,
  rowDrag: PropTypes.bool,
  onRowDrag: PropTypes.func,
  rowSelect: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  onRowSelect: PropTypes.func,
  rowClass: PropTypes.func,
};

export default TableRow;
