import React from 'react';
import { ReactSortable } from 'react-sortablejs';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import StatusLabel from '../../../../components/StatusLabel';

const PlaylistScenariosList = (props) => {
  const intl = useIntl();
  const getKeyForContent = (c) => `${c.id}`;

  return (
    <div className="course-contents-section">
      <div className="section-header playlist-my-list-header">
        {intl.formatMessage({ id: 'playlists.content.available-title' })}
        {props.formData.id && (props.formData.visibility == 'public' ?
          <StatusLabel status='visibility' text={intl.formatMessage({ id: 'shared.public' })}/> :
          <StatusLabel status='visibility' text={intl.formatMessage({ id: 'shared.private' })}/>)
        }
      </div>
      {props.formData.scenarios_attributes.length == 0 && (
        <div className="info">
          <i className="fas fa-info-circle" />
          {intl.formatMessage({ id: 'courses.no-content' })}
        </div>
      )}
      <ReactSortable
        handle=".drag-handler > .fa-grip-vertical"
        tag="ul"
        list={props.formData.scenarios_attributes}
        setList={props.reorderPlaylistContent}
      >
        {props.formData.scenarios_attributes.map((content) => {
          return (
            <li key={getKeyForContent(content)}>
              {props.canModify && (
                <div className="drag-handler">{<i className="fas fa-grip-vertical"></i>}</div>
              )}
              <div className="course-content" >
                <div className="course-content-left-section">
                  <div>{content.title}</div>
                  <div className="content-type">
                    <i className={`fas fa-mask`}></i>&nbsp;
                    {intl.formatMessage({ id: 'courses.content-type.scenario' })}
                  </div>
                </div>
                {props.canModify && (
                  <span onClick={() => props.removeContent(content)}>
                    <i className="fal fa-trash"></i>
                  </span>
                )}
              </div>
            </li>
          );
        })}
      </ReactSortable>
    </div>
  );
};

PlaylistScenariosList.propTypes = {
  formData: PropTypes.object,
  setFormData: PropTypes.func.isRequired,
  removeContent: PropTypes.func.isRequired,
  reorderPlaylistContent: PropTypes.func.isRequired,
  canModify: PropTypes.bool.isRequired,
};

export default PlaylistScenariosList;
