import React, { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

const ImagePreview = ({ onDelete, onSetPrimaryIndex, imgSrc, index, primaryImage }) => {
  const intl = useIntl();
  const [showActions, setShowActions] = useState(false);
  const onMouseOver = () => {
    setShowActions(true);
  };

  const onMouseLeave = () => {
    setShowActions(false);
  };
  return (
    <div
      className={classNames('img-wrapper', { 'show-actions': showActions, 'primary-image': primaryImage })}
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
    >
      {primaryImage && <span className="primary-flag">{intl.formatMessage({ id: 'image-upload.primary' })}</span>}
      <img src={imgSrc} />
      <div className="actions">
        <span
          onClick={() => {
            onSetPrimaryIndex && onSetPrimaryIndex(index);
          }}
        >
          {primaryImage && (
            <span key="fas">
              <i className="fas fa-star" />
            </span>
          )}
          {!primaryImage && (
            <span key="far">
              <i className="far fa-star" />
            </span>
          )}
        </span>
        <span
          onClick={() => {
            onDelete && onDelete(imgSrc);
          }}
        >
          <i className="far fa-trash" />
        </span>
      </div>
    </div>
  );
};

ImagePreview.propTypes = {
  imgSrc: PropTypes.string.isRequired,
  onDelete: PropTypes.func,
  onSetPrimaryIndex: PropTypes.func,
  primaryImage: PropTypes.bool,
  index: PropTypes.number.isRequired,
};

export default ImagePreview;
