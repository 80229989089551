import React from "react";
import ObservationEventForm from "./ObservationEventForm";
import PropTypes from "prop-types";

import { IntlProvider } from "react-intl";
import { translations, locale } from "../../../util/i18n";

const EditObservationEventModal = (props) => (
  <IntlProvider locale={window._FLAIM_LOCALE} key={window._FLAIM_LOCALE} messages={translations[window._FLAIM_LOCALE]}>
    <ObservationEventForm url={props.url} method={"PUT"} defaultValues={{ ...props.eventDetails }} />
  </IntlProvider>
);

EditObservationEventModal.propTypes = {
  url: PropTypes.string.isRequired,
  eventDetails: PropTypes.object.isRequired,
};

export default EditObservationEventModal;
