import React from "react";
import RowMenu from "../../../util/RowMenu";
import Modal from "../../../util/Modal";
import PostLink from "../../../util/PostLink";
import EditObservationEventModal from "./EditObservationEventModal";
import PropTypes from "prop-types";

import { IntlProvider } from "react-intl";
import { translations, locale } from "../../../util/i18n";

const ObservationEventRowMenu = (props) => {
  const options = [
    {
      title: "admin-area.settings.observation-event.dropdown.edit",
      component: Modal,
      params: {
        title: "admin-area.settings.observation-event.dropdown.edit",
        content: (
          <EditObservationEventModal
            url={props.updateEventUrl}
            eventDetails={props.eventDetails}
          />
        ),
      },
    },
    {
      title:
        props.eventDetails.status == "active"
          ? "admin-area.settings.observation-event.dropdown.disable"
          : "admin-area.settings.observation-event.dropdown.enable",
      component: PostLink,
      params: {
        url: props.toggleEventStatusUrl,
        refresh: true,
      },
    },
  ];

  if (props.deleteEventUrl) {
    options.push({
      title: "admin-area.settings.observation-event.dropdown.delete",
      component: PostLink,
      params: {
        method: "delete",
        url: props.deleteEventUrl,
        refresh: true,
        confirmMessage: "admin-area.settings.observation-event.confirmation-message",
      },
    });
  }

  return (
    <IntlProvider
      locale={window._FLAIM_LOCALE}
      key={window._FLAIM_LOCALE}
      messages={translations[window._FLAIM_LOCALE]}
    >
      <RowMenu options={options}></RowMenu>
    </IntlProvider>
  );
};

ObservationEventRowMenu.propTypes = {
  updateEventUrl: PropTypes.string.isRequired,
  toggleEventStatusUrl: PropTypes.string.isRequired,
  deleteEventUrl: PropTypes.string,
  eventDetails: PropTypes.object,
};

export default ObservationEventRowMenu;
