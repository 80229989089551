import React from "react";
import ModalLauncher from "../util/ModalLauncher";
import SimulationForm from "./SimulationForm";
import PropTypes from "prop-types";

import { IntlProvider } from "react-intl";
import { translations } from "../util/i18n";

const EditSimulationModal = (props) => {
  const modalContent = () => {
    return <SimulationForm url={props.updateUrl} method={"PUT"} defaultValues={{ ...props.simulationDetails }} />;
  };

  return (
    <IntlProvider
      locale={window._FLAIM_LOCALE}
      key={window._FLAIM_LOCALE}
      messages={translations[window._FLAIM_LOCALE]}
    >
      <ModalLauncher
        buttonId="edit-simulation"
        buttonText="admin-area.simulations.edit-simulation"
        content={modalContent()}
        title="admin-area.simulations.edit-simulation"
      />
    </IntlProvider>
  );
};

EditSimulationModal.propTypes = {
  updateUrl: PropTypes.string,
  simulationDetails: PropTypes.object.isRequired,
};

export default EditSimulationModal;
