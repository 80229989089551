import React from 'react';
import PropTypes from 'prop-types';
import wrap from '../../../util/wrap_with_intl_provider';
import EnvironmentDetail from './EnvironmentDetail';
import EnvironmentShowPageHeader from './EnvironmentShowPageHeader';
import { admin_area_settings_environment_path } from '../../../../../../routes';

const EnvironmentShow = (props) => {
  return (
    <div>
      <EnvironmentShowPageHeader {...props} activeUrl={admin_area_settings_environment_path(props.id)} />
      <EnvironmentDetail {...props} />
    </div>
  );
};

EnvironmentShow.propTypes = {
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  environmentId: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  description: PropTypes.string,
  isPublic: PropTypes.bool.isRequired,
  images: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
    }),
  ).isRequired,
  primaryImageIndex: PropTypes.number.isRequired,
  privateIncidentsCount: PropTypes.number.isRequired,
  publicIncidentCount: PropTypes.number.isRequired,
};

export default wrap(EnvironmentShow);
