import React from "react";

import "froala-editor/js/plugins/table.min";
import "froala-editor/js/plugins/font_family.min";
import "froala-editor/js/plugins/colors.min";
import "froala-editor/js/plugins/paragraph_format.min";
import "froala-editor/js/plugins/image.min";
import "froala-editor/js/plugins/image_manager.min";
import "froala-editor/js/plugins/file.min";
import "froala-editor/js/plugins/align.min";
import "froala-editor/js/plugins/draggable.min";
import "froala-editor/js/plugins/link.min";
import 'froala-editor/css/themes/dark.min.css';

import FroalaEditor from "react-froala-wysiwyg";
import PropTypes from "prop-types";

const FroalaInput = (props) => {
  return (
    <FroalaEditor
      tag="textarea"
      config={{
        id: "body",
        theme: props.isDarkTheme && 'dark',
        placeholderText: "Type your content here",
        attribution: false,
        key: "re1H1qB1D2E7C7D6C4F4iAa1Tb1YZNYAh1CUKUEQOHFVANUqD1G1F4J4B1C8E7D2G5G3==",
        imageUploadToS3: props.s3Fields,
        fileMaxSize: 20 * 1024 * 1024,
        fileUploadToS3: props.s3Fields,
        events: {
          "image.uploadedToS3": function (origin_url, file_key, response) {
            let img_url = origin_url.replace(props.s3UrlReplaceFrom, props.s3UrlReplaceTo);
            img_url = img_url.replaceAll("%2F", "/");
            this.image.insert(img_url, false, null, this.image.get(), response);

            return false;
          },
        },
        heightMin: props.heightMin,
      }}
      onModelChange={props.onModelChange}
      model={props.model}
    ></FroalaEditor>
  );
};

FroalaInput.propTypes = {
  onModelChange: PropTypes.func.isRequired,
  model: PropTypes.string.isRequired,
  s3Fields: PropTypes.object.isRequired,
  s3UrlReplaceFrom: PropTypes.string.isRequired,
  s3UrlReplaceTo: PropTypes.string.isRequired,
  heightMin: PropTypes.number,
  isDarkTheme: PropTypes.bool
};

export default FroalaInput;
