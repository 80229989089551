import React from "react";
import RowMenu from "../../util/RowMenu";
import Modal from "../../util/Modal";
import Link from "../../util/Link";
import PropTypes from "prop-types";

import { IntlProvider } from "react-intl";
import { translations, locale } from "../../util/i18n";
import LinkUnitModal from "../organisations/LinkUnitModal";

const AdminUnitsRowManual = (props) => {
  const options = [
    {
      title: "admin-area.organisations.dropdown.create-unit",
      component: Link,
      params: {
        url: props.createUnitUrl,
      },
    },
    {
      title: "admin-area.organisations.dropdown.link-unit",
      component: Modal,
      params: {
        title: "admin-area.organisations.dropdown.link-unit",
        content: LinkUnitModal({ url: props.linkUnitUrl }),
      },
    },
  ];

  return (
    <IntlProvider
      locale={window._FLAIM_LOCALE}
      key={window._FLAIM_LOCALE}
      messages={translations[window._FLAIM_LOCALE]}
    >
      <RowMenu
        title={"admin-area.organisations.add-unit"}
        options={options}
        classNameForDropdown="for-btn-primary"
      ></RowMenu>
    </IntlProvider>
  );
};

AdminUnitsRowManual.propTypes = {
  createUnitUrl: PropTypes.string.isRequired,
  linkUnitUrl: PropTypes.string.isRequired,
};

export default AdminUnitsRowManual;
