import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import wrap from '../../../util/wrap_with_intl_provider';
import Table from '../../../../../../components/Table';
import ax from '../../../util/axios';
import IncidentShowPageHeader from './IncidentShowPageHeader';

import {
  admin_area_settings_incident_injects_path,
  admin_area_settings_inject_path,
  admin_area_settings_injects_path,
  new_admin_area_settings_inject_path,
} from '../../../../../../routes';
import EmptyStateHolder from '../../../../../../components/EmptyStateHolder';

const IncidentInjects = (props) => {
  const intl = useIntl();
  const [pagination, setPagination] = useState({});
  const [injects, setInjects] = useState([]);

  const fetchInjects = async (page = 1) => {
    const response = await ax.get(admin_area_settings_injects_path({ format: 'json', page, incident_id: props.id }));
    setPagination(response.data.pagination);
    setInjects(response.data.injects);
  };

  const injectsColumns = [
    {
      accessor: 'injectId',
      className: 'link',
      header: intl.formatMessage({ id: 'admin-area.settings.inject.fields.inject-id' }),
    },
    {
      accessor: 'title',
      header: intl.formatMessage({ id: 'admin-area.settings.inject.fields.title' }),
    },
  ];

  useEffect(() => {
    fetchInjects();
  }, []);

  const mappedInjects = injects.map((inj) => ({
    ...inj,
    injectId: <a href={admin_area_settings_inject_path(inj.id)}>{inj.injectId}</a>,
  }));

  if (pagination.total != undefined) {
    return (
      <div>
        <IncidentShowPageHeader activeUrl={admin_area_settings_incident_injects_path(props.id)} {...props} />
        {injects.length > 0 ? (
          <Table items={mappedInjects} columns={injectsColumns} pagination={pagination} onPageChange={fetchInjects} />
        ) : (
          <EmptyStateHolder
            button={
              <a className="btn btn-primary angled-top-left" href={new_admin_area_settings_inject_path()}>
                <i className="far fa-plus" /> &nbsp;
                {intl.formatMessage({ id: 'admin-area.settings.incident.inject.create.title' })}
              </a>
            }
            heading={intl.formatMessage({ id: 'admin-area.settings.incident.inject.empty-state.heading' })}
            description=""
          />
        )}
      </div>
    );
  }
};

IncidentInjects.propTypes = {
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  visibility: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
};

export default wrap(IncidentInjects);
