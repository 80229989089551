import React, { useEffect, useState } from 'react';
import { IntlProvider, useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import PageHeader from '../../../../components/PageHeader';
import PlaylistScenariosCreateForm from './PlaylistScenariosCreateForm';
import ax from '../util/axios';
import ServerFormErrors from '../util/ServerFormErrors';
import { locale, translations } from '../util/i18n';
import _ from 'lodash';
import PlaylistUnitCreateForm from './PlaylistUnitCreateForm';
import { useForm } from 'react-hook-form';
import ConfirmModal from '../util/ConfirmModal';
import PlaylistSettingsMenu from './PlaylistSettingsMenu';
import ApprovedOrganisations from '../approved_organisaitons/ApprovedOrganisations';
import {
  admin_area_playlist_approved_organisations_path,
  admin_area_playlist_not_approved_organisations_path,
  admin_area_playlist_organisations_path,
  admin_area_playlist_organisation_path
} from '../../../../routes';

function PlaylistShow(props) {
  const intl = useIntl();
  const [formData, setFormData] = useState({...props.defaultValues});
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessages] = useState();
  const [willUpdate, setWillUpdated] = useState(false);
  const [willUpdateUnit, setWillUpdateUnit] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [openCancelModal, setOpenCancelModal] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues: { ...props.defaultValues } });

  const toggleCancelModal = () => {
    setOpenCancelModal(!openCancelModal);
  }

  const onCancelConfirm = () => {
    toggleCancelModal();
    setIsEdit(!isEdit);
  }

  const onEdit = () => {
    setIsEdit(true);
  }

  const onSave = (data) => {
    ax({
      method: 'patch',
      url: props.playlistUrl,
      data: {
        playlist: {
          id: formData.id,
          title: data.title,
          visibility: data.visibility,
        }
      },
    })
      .then(function () {
        let copyFormData = {
          ...formData,
          title: data.title,
          visibility: data.visibility,
        };
        setFormData(copyFormData);
        setIsEdit(!isEdit);
      })
      .catch((err) => {
        setErrorMessages(err.response.data);
      });
  };

  const addContent = (content) => {
    setLoading(true);
    let copyFormData = {
      ...formData,
      scenarios_attributes: formData.scenarios_attributes.concat([{ id: content.id, title: content.title }])
    };
    setFormData(copyFormData);
    setWillUpdated(true);
    setLoading(false);
  };

  const removeContent = (content) => {
    let copyFormData = {
      ...formData,
      scenarios_attributes: formData.scenarios_attributes.filter(function(scenario) {
        return scenario.id !== content.id
      })
    };
    setFormData(copyFormData);
    setWillUpdated(true);
  };

  const reorderPlaylistContent = (contents) => {
    let content_ids = contents.map((c) => { return c.id });
    let scenario_ids = formData.scenarios_attributes.map((c) => { return c.id });
    if(!_.isEqual(content_ids, scenario_ids)) {
      let copyFormData = {
        ...formData,
        scenarios_attributes: contents
      };
      setFormData(copyFormData);
      setWillUpdated(true);
    }
  };

  const onAddUnits = (units) => {
    let copyFormData = {
      ...formData,
      units_attributes: formData.units_attributes.concat(units)
    };
    setFormData(copyFormData);
    setWillUpdateUnit(true);
  };

  const onConfirmUnassign = (unit_param) => {
    let copyFormData = {
      ...formData,
      units_attributes: formData.units_attributes.filter(function(unit) {
        return unit.id !== unit_param.id
      })
    };
    setFormData(copyFormData);
    setWillUpdateUnit(true);
  };

  const sendScenariosUpdateRequest = () => {
    ax({
      method: 'patch',
      url: props.playlistUrl,
      data: {
        playlist: {
          id: formData.id,
          scenario_ids: formData.scenarios_attributes.map((c) => { return c.id }),
        }
      },
    })
      .then(function () {
        setWillUpdated(false);
      })
      .catch((err) => {
        setWillUpdated(false);
        setErrorMessages(err.response.data);
      });
  }

  const sendUnitsUpdateRequest = () => {
    ax({
      method: 'patch',
      url: props.playlistUrl,
      data: {
        playlist: {
          id: formData.id,
          unit_ids: formData.units_attributes.map((c) => { return c.id }),
        }
      },
    })
      .then(function () {
        setWillUpdateUnit(false);
      })
      .catch((err) => {
        setWillUpdateUnit(false);
        setErrorMessages(err.response.data);
      });
  }

  const headerTabs = () => {
    let tabs = [
      {
        icon: 'fa-list-check',
        title: intl.formatMessage({ id: 'playlists.tabs.content' }),
        active: props.currentTab === 'playlists',
        link: props.playlistUrl,
      },
      {
        icon: 'fa-box-open',
        link: props.playlistUnitUrl,
        title: intl.formatMessage({ id: 'playlists.tabs.unit' }),
        active: props.currentTab === 'units',
      },
    ];
    if (props.isAdmin && props.defaultValues.visibility === 'private') {
      tabs = tabs.concat([{
        icon: 'fa-users',
        link: admin_area_playlist_approved_organisations_path(formData.id),
        title: intl.formatMessage({ id: 'admin-area.settings.environment.show.tab.approved-organisations' }),
        active: props.currentTab === 'approved_organisations',
      }]);
    }
    return tabs;
  }

  useEffect(() => {
    if (willUpdate) {
      sendScenariosUpdateRequest();
    } else if (willUpdateUnit) {
      sendUnitsUpdateRequest();
    }
  }, [willUpdate, willUpdateUnit]);

  return (
    <div className="playlist-view-page">
      <PageHeader
        previousPages={[
          {
            link: props.playlistsUrl,
            title: intl.formatMessage({ id: 'playlists.title' }),
          },
        ]}
        title={formData.title}
        tabs={headerTabs()}
        customTitleSection={ isEdit &&
          <form className="playlist-edit-form">
            <div className="form-row">
              <div className="form-group required playlist-title-container">
                <label>{intl.formatMessage({ id: 'courses.title-field' })}</label>
                <input
                  className="form-control" {...register('title', { required: true })}
                  value={formData.title}
                  onChange={(e) => setFormData({...formData, title: e.target.value})}>
                </input>
                {errors.title?.type === 'required' && (
                  <div className="field-error">{intl.formatMessage({ id: 'shared.forms.required' })}</div>
                )}
              </div>
              { props.isAdmin &&
                <div className="form-group required playlist-visibility-container">
                  <label>{intl.formatMessage({ id: 'playlists.search.visibility-field' })}</label>
                  <select
                    defaultValue={formData.visibility}
                    autoFocus
                    className="form-control" {...register('visibility', { required: true })}
                    onChange={(e) => setFormData({...formData, visibility: e.target.value})}
                  >
                    <option key="1" value='public'>
                      {intl.formatMessage({ id: 'shared.public' })}
                    </option>
                    <option key="2" value='private'>
                      {intl.formatMessage({ id: 'shared.private' })}
                    </option>
                  </select>
                  {errors.visibility?.type === 'required' && (
                    <div className="field-error">{intl.formatMessage({ id: 'shared.forms.required' })}</div>
                  )}
                </div>
              }
              <div className="form-group actions-buttons">
                <button type="button" className="btn btn-primary small angled-bottom-right" onClick={handleSubmit(onSave)}>
                  {intl.formatMessage({ id: 'shared.forms.save' })}
                </button>
                <button type="button" className="btn btn-secondary small angled-top-left" onClick={() => toggleCancelModal()}>
                  {intl.formatMessage({ id: 'shared.cancel' })}
                </button>
              </div>
            </div>
          </form>
        }
        settingMenu={!isEdit && <PlaylistSettingsMenu
                                  deleteUrl={props.playlistUrl}
                                  onEdit={onEdit}
                                  isAdmin={props.isAdmin}
                                  duplicateUrl={props.playlistDuplicateUrl}
                                  canModify={props.canModify} />}
      />
      {openCancelModal &&
        <ConfirmModal
          title={intl.formatMessage({ id: 'playlists.cancel.modal-title' })}
          text={intl.formatMessage({ id: 'playlists.cancel.modal-message' })}
          onConfirm={onCancelConfirm}
          onCancel={toggleCancelModal}
        ></ConfirmModal>
      }
      {props.currentTab === 'playlists' &&
        <PlaylistScenariosCreateForm
          formData={formData}
          setFormData={setFormData}
          isAdmin={props.isAdmin}
          canModify={props.canModify}
          scenariosUrl={props.scenariosUrl}
          addContent={addContent}
          removeContent={removeContent}
          reorderPlaylistContent={reorderPlaylistContent}
          loading={loading}
        />
      }
      {props.currentTab === 'units' &&
        <PlaylistUnitCreateForm
          formData={formData}
          setFormData={setFormData}
          unitsUrl={props.unitsUrl}
          isAdmin={props.isAdmin}
          onAddUnits={onAddUnits}
          onConfirmUnassign={onConfirmUnassign}
        />
      }
      {props.currentTab === 'approved_organisations' &&
        <ApprovedOrganisations
          id={formData.id}
          loadApprovedPath={admin_area_playlist_approved_organisations_path}
          loadNotApprovedPath={admin_area_playlist_not_approved_organisations_path}
          addPath={admin_area_playlist_organisations_path}
          destroyPath={admin_area_playlist_organisation_path}
          showAuthor
        />
      }
      <ServerFormErrors errorMessages={errorMessage}></ServerFormErrors>
    </div>
  );
}

PlaylistShow.propTypes = {
  defaultValues: PropTypes.object,
  playlistUrl: PropTypes.string.isRequired,
  playlistUnitUrl: PropTypes.string.isRequired,
  playlistsUrl: PropTypes.string.isRequired,
  playlistDuplicateUrl: PropTypes.string.isRequired,
  scenariosUrl: PropTypes.string.isRequired,
  unitsUrl: PropTypes.string.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  canModify: PropTypes.bool.isRequired,
  currentTab: PropTypes.string.isRequired,
};

export const WithIntlProvider = (props) => {
  return (
    <IntlProvider locale={locale} key={locale} messages={translations[locale]}>
      <PlaylistShow {...props} />
    </IntlProvider>
  );
};
