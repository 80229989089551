import React, { useState, useContext, useEffect } from 'react';
import Accordion from '../../Accordion/Accordion';
import { useIntl } from 'react-intl';
import { useForm, Controller } from 'react-hook-form';
import MultiSelect from '../../MultiSelect';
import {
  dropdown_search_admin_area_organisations_path,
  admin_area_scenarios_path,
  edit_admin_area_scenario_path,
  step1_detail_admin_area_custom_scenarios_path,
  organisation_area_scenarios_path,
  edit_organisation_area_scenario_path,
  step1_detail_organisation_area_custom_scenarios_path,
} from '../../../routes';
import ax from '../../../bundles/main/components/util/axios';
import classNames from 'classnames';
import ServerFormErrors from '../../../bundles/main/components/util/ServerFormErrors';
import { NewCustomScenarioPageContext } from './NewCustomScenarioPage';
import { AccordionContext } from '../../Accordion/Accordion';
import toastr from 'toastr';
import StepActions from './StepActions';

const Header = () => {
  const intl = useIntl();
  const [context] = useContext(NewCustomScenarioPageContext);
  const { scenario, activeStep } = context;
  const accordionContext = useContext(AccordionContext);

  useEffect(() => {
    if (activeStep == 'detail') {
      accordionContext.setExpanded(true);
    } else {
      accordionContext.setExpanded(false);
    }
  }, [activeStep]);

  return (
    <div className={classNames('step-header')}>
      {!scenario && (
        <div className="title">
          <div className="title-info">
            <div className="title-main">{intl.formatMessage({ id: 'scenarios.new-custom-scenario.scenario-details' })}</div>
            <div className="title-description">{intl.formatMessage({ id: 'scenarios.new-custom-scenario.enter-title-description' })}</div>
          </div>
        </div>
      )}
      {scenario && (
        <div className="title">
          <div className="title-info">
            <div className="title-main">{intl.formatMessage({ id: 'admin-area.scenarios.name-field' })}</div>
            <div className="title-description">{scenario.name}</div>
          </div>
          <div>
            <i className="fal fa-circle-check"></i>
          </div>
        </div>
      )}
    </div>
  );
};

const Content = () => {
  const intl = useIntl();
  const [context, setContext] = useContext(NewCustomScenarioPageContext);
  const { isAdmin, scenario } = context;
  const [errorMessage, setErrorMessages] = useState();
  const [step1Path, scenariosPath, editScenarioPath, dropdownOrganisationsPath] = isAdmin
    ? [
        step1_detail_admin_area_custom_scenarios_path,
        admin_area_scenarios_path,
        edit_admin_area_scenario_path,
        dropdown_search_admin_area_organisations_path,
      ]
    : [
        step1_detail_organisation_area_custom_scenarios_path,
        organisation_area_scenarios_path,
        edit_organisation_area_scenario_path,
        undefined,
      ];

  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      id: scenario?.id,
      scenario_identity: scenario?.scenario_identity,
      name: scenario?.name,
      description: scenario?.description,
      organisation_ids: scenario?.organisation_ids,
      device_type: scenario?.device_type,
    },
  });

  const save = (data, backToIndex) => {
    if (!backToIndex) {
      data.custom_scenario_step = 'environment';
    }

    ax({
      method: 'post',
      url: step1Path(),
      data: { form: data },
    })
      .then((res) => {
        if (backToIndex) {
          window.location = scenariosPath();
        } else {
          const newScenario = { ...scenario, ...data, ...res.data };
          if (!scenario?.id) history.replaceState({}, '', editScenarioPath(newScenario.id));

          setContext({ scenario: newScenario, activeStep: 'environment', isAdmin });
        }
      })
      .catch((error) => {
        if (error.response && error.response.status == '400') {
          setErrorMessages(error.response.data);
        } else {
          console.log(error);
          toastr.error(intl.formatMessage({ id: 'shared.something-wrong' }));
        }
      });
  };

  const saveDraft = (data) => {
    save(data, true);
  };

  const next = (data) => {
    save(data, false);
  };

  return (
    <form>
      <ServerFormErrors errorMessages={errorMessage}></ServerFormErrors>
      <div className="row">
        <div className="form-group required">
          <label>{intl.formatMessage({ id: 'admin-area.scenarios.id-field' })}</label>
          <input
            className="form-control"
            {...register('scenario_identity', { required: true, pattern: /^[a-zA-Z0-9]+$/ })}
          ></input>
          {errors.scenario_identity?.type === 'required' && (
            <div className="field-error">{intl.formatMessage({ id: 'shared.forms.required' })}</div>
          )}
          {errors.scenario_identity?.type === 'pattern' && (
            <div className="field-error">
              {intl.formatMessage({ id: 'admin-area.scenarios.errors.scenario_identity_format' })}
            </div>
          )}
        </div>
        <div className="form-group required">
          <label>{intl.formatMessage({ id: 'admin-area.scenarios.name-field' })}</label>
          <input className="form-control" {...register('name', { required: true })} />
          {errors.name?.type === 'required' && (
            <div className="field-error">{intl.formatMessage({ id: 'shared.forms.required' })}</div>
          )}
        </div>
      </div>
      <div className="form-group">
        <label>{intl.formatMessage({ id: 'admin-area.scenarios.description-field' })}</label>
        <textarea rows="5" className="form-control" {...register('description')}></textarea>
      </div>
      {isAdmin && (
        <div className='row'>
          <div className="form-group">
            <label>Add Organisation</label>
            <Controller
              control={control}
              name="organisation_ids"
              render={({ field }) => (
                <MultiSelect
                  optionsUrl={dropdownOrganisationsPath()}
                  optionValueAccessor="value"
                  optionLabelAccessor="label"
                  defaultValue={field.value}
                  onChange={field.onChange}
                />
              )}
            />
          </div>
          <div className="form-group required">
            <label htmlFor="scenario_type">{intl.formatMessage({ id: 'admin-area.scenarios.scenario-type' })}</label>
            <select className="form-control" id="device_type" disabled={scenario?.id !== undefined} {...register('device_type', { required: true })}>
              <option value="">{intl.formatMessage({ id: 'shared.dropdown.please-select' })}</option>
              <option value="trainer">{intl.formatMessage({ id: 'admin-area.scenarios.scenario-type.trainer' })}</option>
              <option value="sweeper">{intl.formatMessage({ id: 'admin-area.scenarios.scenario-type.sweeper' })}</option>
            </select>
            {errors.device_type?.type === 'required' && (
              <div className="field-error">{intl.formatMessage({ id: 'shared.forms.required' })}</div>
            )}
          </div>
        </div>
      )}
      <StepActions onSaveDraft={handleSubmit(saveDraft)} onNext={handleSubmit(next)} disabled={isSubmitting} />
    </form>
  );
};

const DetailAccordion = () => {
  const [context, setContext] = useContext(NewCustomScenarioPageContext);
  const { scenario } = context;

  const onToggled = (expanded) => {
    if (expanded) {
      setContext({ ...context, activeStep: 'detail' });
    } else {
      setContext({ ...context, activeStep: '' });
    }
  };

  const isStepOk = () => scenario;

  return (
    <Accordion
      className={classNames({ 'step-ok': isStepOk() })}
      header={<Header />}
      content={<Content />}
      onToggled={onToggled}
    />
  );
};

export default DetailAccordion;
