import React, { useState, useEffect, useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import wrap from '../../../util/wrap_with_intl_provider';
import Table from '../../../../../../components/Table';
import PageHeader from '../../../../../../components/PageHeader';
import ax from '../../../util/axios';
import SearchBar, { SearchBarContext } from '../../../../../../components/SearchBar';
import SearchBarRow from '../../../../../../components/SearchBar/SearchBarRow';
import { tableParamsBuilder } from '../../../util/searchUtils';
import {
  admin_area_settings_environment_incidents_path,
  admin_area_settings_inject_path,
  admin_area_settings_injects_path,
  new_admin_area_settings_inject_path,
  admin_area_settings_incident_injects_path,
} from '../../../../../../routes';
import toastr from 'toastr';

const PrimarySearchItems = () => {
  const intl = useIntl();
  const { register } = useContext(SearchBarContext);
  return (
    <SearchBarRow>
      <div className="form-group" style={{ flex: 2 }}>
        <label htmlFor="title">{intl.formatMessage({ id: 'admin-area.settings.inject.fields.title' })}</label>
        <input
          className="form-control"
          placeholder={intl.formatMessage({ id: 'shared.search' })}
          {...register('title')}
        />
      </div>
      <div className="form-group" style={{ flex: 1 }}>
        <label htmlFor="inject_id">{intl.formatMessage({ id: 'admin-area.settings.inject.fields.inject-id' })}</label>
        <input className="form-control" id="environment_id" {...register('inject_id')} />
      </div>
    </SearchBarRow>
  );
};

const SecondarySearchItems = ({ environments }) => {
  const intl = useIntl();
  const { register, getValues, setValue, watch } = useContext(SearchBarContext);
  const [incidents, setIncidents] = useState([]);
  const isMounted = useRef(false);

  const fetchIncidents = (env_id) => {
    ax({
      method: 'get',
      url: admin_area_settings_environment_incidents_path(env_id),
    })
      .then((response) => setIncidents(response.data.incidents))
      .catch((err) => {
        console.log(err);
        toastr.error(intl.formatMessage({ id: 'shared.something-wrong' }));
      });
  };

  useEffect(() => {
    const env_id = getValues('environment_id');
    if (env_id) {
      fetchIncidents(env_id);
    } else {
      setIncidents([]);
    }

    if (isMounted.current) {
      setValue('incident_id', undefined);
    } else {
      isMounted.current = true;
    }
  }, [watch('environment_id')]);

  return (
    <SearchBarRow>
      <div className="form-group" style={{ flex: 1 }}>
        <label>{intl.formatMessage({ id: 'admin-area.settings.inject.fields.environment' })}</label>
        <select className="form-control" {...register('environment_id')}>
          <option key="0" value="">
            {intl.formatMessage({ id: 'shared.dropdown.please-select' })}
          </option>
          {environments.map((env) => (
            <option key={env.id} value={env.id}>
              {env.title}
            </option>
          ))}
        </select>
      </div>
      <div className="form-group" style={{ flex: 1 }}>
        <label>{intl.formatMessage({ id: 'admin-area.settings.inject.fields.incident' })}</label>
        <select className="form-control" {...register('incident_id')}>
          <option key="0" value="">
            {intl.formatMessage({ id: 'shared.dropdown.please-select' })}
          </option>
          {incidents.map((inc) => (
            <option key={inc.id} value={inc.id} selected={inc.id == getValues('incident_id')}>
              {inc.title}
            </option>
          ))}
        </select>
      </div>
      <div style={{ flex: 2 }}></div>
    </SearchBarRow>
  );
};

SecondarySearchItems.propTypes = {
  environments: PropTypes.array.isRequired,
};

const InjectIndex = (props) => {
  const intl = useIntl();
  const [pagination, setPagination] = useState({});
  const [injects, setInjects] = useState([]);
  const [searchParams, setSearchParams] = useState({});

  const fetchInjects = async (page = 1) => {
    const response = await ax.get(
      admin_area_settings_injects_path() + '.json' + tableParamsBuilder({ page, ...searchParams }),
    );
    setPagination(response.data.pagination);
    setInjects(response.data.injects);
  };

  const injectsColumns = [
    {
      accessor: 'injectId',
      className: 'link',
      header: intl.formatMessage({ id: 'admin-area.settings.inject.fields.inject-id' }),
    },
    {
      accessor: 'title',
      header: intl.formatMessage({ id: 'admin-area.settings.inject.fields.title' }),
    },
    {
      accessor: 'environment',
      header: intl.formatMessage({ id: 'admin-area.settings.inject.fields.environment' }),
    },
    {
      accessor: 'incident',
      header: intl.formatMessage({ id: 'admin-area.settings.inject.fields.incident' }),
    },
  ];

  const mappedInjects = injects.map((inj) => ({
    ...inj,
    injectId: <a href={admin_area_settings_inject_path(inj.id)}>{inj.injectId}</a>,
    incident: <a href={admin_area_settings_incident_injects_path(inj.incidentId)}>{inj.incident}</a>,
  }));

  useEffect(() => {
    fetchInjects();
  }, [searchParams]);
  return (
    <div>
      <PageHeader tabs={props.tabs} title={intl.formatMessage({ id: 'admin-area.settings.scenario-assets.title' })} />
      <div className="page-header with-margin-top">
        <h2>{intl.formatMessage({ id: 'admin-area.settings.inject.index.title' })}</h2>
        <a className="btn btn-primary angled-top-left" href={new_admin_area_settings_inject_path()}>
          <i className="fa fa-plus" />
          &nbsp;
          {intl.formatMessage({ id: 'admin-area.settings.inject.new.link' })}
        </a>
      </div>
      <SearchBar
        primaryItems={<PrimarySearchItems />}
        secondaryItems={<SecondarySearchItems environments={props.environments} />}
        onSearch={setSearchParams}
      />
      {injects.length > 0 && (
        <Table items={mappedInjects} columns={injectsColumns} pagination={pagination} onPageChange={fetchInjects} />
      )}
    </div>
  );
};

InjectIndex.propTypes = {
  tabs: PropTypes.array.isRequired,
  environments: PropTypes.array.isRequired,
};

export default wrap(InjectIndex);
