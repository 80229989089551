import React from "react";
import RowMenu from "../util/RowMenu";
import Link from "../util/PostLink";
import PropTypes from "prop-types";
import { IntlProvider } from "react-intl";
import { translations } from "../util/i18n";
import getFlagEmoji from "../util/getFlagEmoji";

const LanguageMenu = (props) => {
  const options = props.locales.map((locale) => {
    return {
      title: `${getFlagEmoji(locale.country_code.toUpperCase())}  ${locale.language}`,
      component: Link,
      params: {
        url: props.updateLocaleUrl + "?locale=" + locale.locale,
        refresh: true,
      },
    };
  });

  return (
    <div className="locale-selector">
      <RowMenu
        classNameForButton="btn-primary"
        title={`${getFlagEmoji(props.active_locale.country_code.toUpperCase())}  ${props.active_locale.language}`}
        options={options}
        noTranslation={true}
      ></RowMenu>
    </div>
  );
};

const WithIntlProvider = (props) => {
  return (
    <IntlProvider
      locale={window._FLAIM_LOCALE}
      key={window._FLAIM_LOCALE}
      messages={translations[window._FLAIM_LOCALE]}
    >
      <LanguageMenu {...props} />
    </IntlProvider>
  );
};

LanguageMenu.propTypes = {
  active_locale: PropTypes.object.isRequired,
  locales: PropTypes.array.isRequired,
  updateLocaleUrl: PropTypes.string.isRequired,
};

export default WithIntlProvider;
