const customSelectStyle = {
  control: (provided, state) => ({
    ...provided,
    boxShadow: state.isFocused ? "0 0 0 0.2rem var(--flaim2-neutral-9)" : "none",
    borderRadius: "0",
    border: state.isFocused ? "1px solid var(--flaim2-neutral-8)" : "1px solid var(--flaim2-neutral-6)",
    backgroundColor: "var(--flaim2-neutral-10)",
    fontSize: "0.9375rem",
    minHeight: "2.625rem",
    '&:hover': {
      border: state.isFocused ? "1px solid var(--flaim2-neutral-8)" : "1px solid var(--flaim2-neutral-6)",
    }
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "var(--flaim2-neutral-1)",
  }),
  menu: (provided) => ({
    ...provided,
    backgroundColor: "var(--flaim2-neutral-10)",
    border: "1px solid var(--flaim2-neutral-9)",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? "var(--flaim2-neutral-8)" : "transparent",
    color: "var(--flaim2-neutral-1)",
    ':active': {
      backgroundColor: "var(--flaim2-neutral-6)",
    },
  }),
};

export default customSelectStyle;
