import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './FlexRight.scss';

const FlexRight = ({ children, className }) => {
  return <div className={classNames('flex-right', className)}>{children}</div>;
};

FlexRight.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};
export default FlexRight;
