import React, { useContext, useEffect } from 'react';
import { NewCustomScenarioPageContext } from './NewCustomScenarioPage';
import Accordion, { AccordionContext } from '../../Accordion/Accordion';
import Message from '../../Message';
import classNames from 'classnames';
import { useIntl } from 'react-intl';
import { useForm } from 'react-hook-form';
import {
  admin_area_custom_scenario_step4_callout_message_path,
  admin_area_scenarios_path,
  organisation_area_custom_scenario_step4_callout_message_path,
  organisation_area_scenarios_path,
} from '../../../routes';
import ax from '../../../bundles/main/components/util/axios';
import toastr from 'toastr';
import StepActions from './StepActions';

const Header = () => {
  const intl = useIntl();
  const [context] = useContext(NewCustomScenarioPageContext);
  const { scenario, activeStep } = context;
  const accordionContext = useContext(AccordionContext);
  const { callout_message } = scenario || {};

  useEffect(() => {
    if (activeStep == 'callout-message') {
      accordionContext.setExpanded(true);
    } else {
      accordionContext.setExpanded(false);
    }
  }, [activeStep]);

  return (
    <div className={classNames('step-header')}>
      {!callout_message && (
        <div className="title">
          <div className="title-info">
            <div className="title-main">{intl.formatMessage({ id: 'scenarios.new-custom-scenario.step4-header.title' })}</div>
          </div>
        </div>
      )}
      {callout_message && (
        <div className="title">
          <div className="title-info">
            <div className="title-main">{intl.formatMessage({ id: 'admin-area.scenarios.callout-message-field' })}</div>
            <div className="title-description long-text">{callout_message}</div>
          </div>
          <div>
            <i className="fal fa-circle-check"></i>
          </div>
        </div>
      )}
    </div>
  );
};

const Content = () => {
  const intl = useIntl();
  const [context, setContext] = useContext(NewCustomScenarioPageContext);
  const { scenario, isAdmin } = context;
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      callout_message: scenario.callout_message || scenario.default_callout_message,
    },
  });

  const [step4Path, scenariosPath] = isAdmin
    ? [admin_area_custom_scenario_step4_callout_message_path, admin_area_scenarios_path]
    : [organisation_area_custom_scenario_step4_callout_message_path, organisation_area_scenarios_path];

  const save = (data, backToIndex) => {
    if (!backToIndex) {
      data.custom_scenario_step = 'after-action-review';
    }

    ax({
      method: 'post',
      url: step4Path(scenario.id),
      data: data,
    })
      .then(() => {
        if (backToIndex) {
          window.location = scenariosPath();
        } else {
          setContext({
            scenario: { ...scenario, callout_message: data.callout_message },
            activeStep: 'after-action-review',
            isAdmin,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        toastr.error(intl.formatMessage({ id: 'shared.something-wrong' }));
      });
  };

  const onNext = (data) => save(data, false);
  const onSaveDraft = (data) => save(data, true);

  return (
    <div className="stack">
      <Message
        message={intl.formatMessage({ id: 'scenarios.new-custom-scenario.callout-message.change-callout-message' })}
        type="info"
        icon="fas fa-ellipsis-vertical"
      />

      <form>
        <div className="form-group required">
          <label>{intl.formatMessage({ id: 'admin-area.scenarios.callout-message-field' })}</label>
          <textarea className="form-control" {...register('callout_message', { required: true })}></textarea>
          {errors.callout_message?.type === 'required' && (
            <div className="field-error">{intl.formatMessage({ id: 'shared.forms.required' })}</div>
          )}
        </div>
      </form>

      <StepActions onSaveDraft={handleSubmit(onSaveDraft)} onNext={handleSubmit(onNext)} disabled={isSubmitting} />
    </div>
  );
};

const CalloutMessageAccordion = () => {
  const [context, setContext] = useContext(NewCustomScenarioPageContext);
  const { scenario } = context;

  const onToggled = (expanded) => {
    if (expanded) {
      setContext({ ...context, activeStep: 'callout-message' });
    } else {
      setContext({ ...context, activeStep: '' });
    }
  };

  const isStepOk = () => scenario?.callout_message;

  return (
    <Accordion
      className={classNames({ 'step-ok': isStepOk() })}
      header={<Header />}
      content={<Content />}
      onToggled={onToggled}
      disabled={!context.scenario?.editable_inject_ids}
    />
  );
};

export default CalloutMessageAccordion;
