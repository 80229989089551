import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import ServerFormErrors from '../../../util/ServerFormErrors';
import ax from '../../../util/axios';
import { IntlProvider, useIntl } from 'react-intl';
import { locale, translations } from '../../../util/i18n';
import convertToFormData from '../../../util/upload';
import Accordion from '../../../../../../components/Accordion/Accordion';
import Froala from '../../../util/Froala';
import toastr from 'toastr';
import UnitTypeSelect from '../../units/UnitTypeSelect';

const AccordionHeader = () => {
  const intl = useIntl();
  return intl.formatMessage({ id: 'courses.optional-details' });
};

const AccordionContent = ({
  register,
  getValues,
  setValue,
  errors,
  watch,
  defaultValues,
  courseAuthor,
  hideVisibility,
}) => {
  const intl = useIntl();
  watch('image');
  watch('delete_image');
  watch('limit_quiz_attempts');

  const changePhoto = () => {
    const imageInput = document.getElementsByName('image')[0];
    imageInput.click();
  };

  const deletePhoto = () => {
    const imageInput = document.getElementsByName('image')[0];
    imageInput.value = '';
    setValue('delete_image', true);
    const event = new Event('change');
    imageInput.dispatchEvent(event);
  };

  const showImage =
    (defaultValues.image_url && !getValues('delete_image')) || (getValues('image') && getValues('image')[0]);

  return (
    <>
      <div className="form-row">
        <div className="form-group">
          <label>{intl.formatMessage({ id: 'courses.certificate-text-field' })}</label>
          <textarea rows="5" className="form-control" {...register('certificate_text')}></textarea>
        </div>

        <div className="form-group">
          <label>{intl.formatMessage({ id: 'courses.unsuccess-message-field' })}</label>
          <textarea
            rows="5"
            className="form-control"
            {...register('unsuccess_message', {
              validate: {
                requiredOptional: (val) => {
                  return String(getValues('limit_quiz_attempts')) === 'true' ? !!val : true;
                },
              },
            })}
          ></textarea>
          {errors.unsuccess_message?.type === 'requiredOptional' && (
            <div className="field-error">{intl.formatMessage({ id: 'shared.forms.required' })}</div>
          )}
        </div>
      </div>

      <div className="form-row">
        <div className="form-group">
          <label>{intl.formatMessage({ id: 'courses.renewal-period-field' })}</label>
          <input type="number" className="form-control" {...register('renewal_period')}></input>
        </div>

        {!hideVisibility && (
          <div className="form-group">
            <label>{intl.formatMessage({ id: 'courses.visibility-field' })}</label>
            <select className="form-control" {...register('is_public')}>
              <option value="true">{intl.formatMessage({ id: 'shared.public' })}</option>
              <option value="false">{intl.formatMessage({ id: 'shared.private' })}</option>
            </select>
          </div>
        )}
      </div>

      <div className="form-row">
        <div className="form-group">
          <label>{intl.formatMessage({ id: 'courses.ordered-progression-field' })}</label>
          <select className="form-control" {...register('ordered_progression')}>
            <option value="false">{intl.formatMessage({ id: 'shared.no' })}</option>
            <option value="true">{intl.formatMessage({ id: 'shared.yes' })}</option>
          </select>
        </div>

        <div className="form-group">
          <label>{intl.formatMessage({ id: 'courses.limit-quiz-attempts-field' })}</label>
          <select className="form-control" {...register('limit_quiz_attempts')}>
            <option value="false">{intl.formatMessage({ id: 'shared.no' })}</option>
            <option value="true">{intl.formatMessage({ id: 'shared.yes' })}</option>
          </select>
        </div>
      </div>

      <div className="form-row">
        <div className="form-group">
          <label>{intl.formatMessage({ id: 'courses.number-of-attempts-field' })}</label>
          <input
            type="number"
            className="form-control"
            {...register('number_of_quiz_attempts', {
              validate: {
                requiredOptional: (val) => {
                  return String(getValues('limit_quiz_attempts')) === 'true' ? parseInt(val) > 0 : true;
                },
              },
            })}
          ></input>
          {errors.number_of_quiz_attempts?.type === 'requiredOptional' && (
            <div className="field-error">{intl.formatMessage({ id: 'shared.forms.required' })}</div>
          )}
        </div>

        {courseAuthor && (
          <div className="form-group">
            <label>{intl.formatMessage({ id: 'courses.course-author-field' })}</label>
            <input disabled className="form-control" value={courseAuthor}></input>
          </div>
        )}
      </div>

      <div className="form-group">
        <label>{intl.formatMessage({ id: 'courses.image-field' })}</label>
        <div className={`form-control image-wrapper feature-image ${showImage ? '' : 'add-image'}`}>
          {showImage && (
            <img
              src={
                getValues('image') && getValues('image')[0]
                  ? URL.createObjectURL(new Blob(getValues('image')))
                  : defaultValues.image_url
              }
            />
          )}
          <div className="image-actions">
            {showImage && (
              <>
                <a onClick={changePhoto}>
                  <i className="fas fa-pencil" />
                  &nbsp; {intl.formatMessage({ id: 'image-upload.change' })}
                </a>
                <a onClick={deletePhoto}>
                  <i className="fas fa-trash" />
                  &nbsp; {intl.formatMessage({ id: 'image-upload.delete' })}
                </a>
              </>
            )}
            {!showImage && (
              <a onClick={changePhoto}>
                <i className="fas fa-plus" />
                &nbsp; {intl.formatMessage({ id: 'image-upload.add' })}
              </a>
            )}
          </div>
        </div>
        <input
          type="file"
          accept="image/jpeg,image/png"
          multiple={false}
          style={{ display: 'none' }}
          className="form-control"
          {...register('image', {
            validate: { lessThan1MB: (files) => !files[0] || files[0]?.size < 1024 * 1024 * 2 },
          })}
        ></input>
        {errors.image?.type === 'lessThan1MB' && (
          <div className="field-error">{intl.formatMessage({ id: 'shared.forms.file_too_big' })}</div>
        )}
      </div>
    </>
  );
};

AccordionContent.propTypes = {
  register: PropTypes.func.isRequired,
  getValues: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  watch: PropTypes.func.isRequired,
  defaultValues: PropTypes.object.isRequired,
  courseAuthor: PropTypes.string.isRequired,
  hideVisibility: PropTypes.bool.isRequired,
};

const CourseForm = (props) => {
  const intl = useIntl();
  const [errorMessage, setErrorMessages] = useState();
  const [inStudentCatalogue, setInStudentCatalogue] = useState(props.inStudentCatalogue);
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    watch,
    formState: { errors },
  } = useForm({ defaultValues: { ...props.defaultValues } });
  const onSubmit = (data) => {
    const formData = convertToFormData(data, ['image']);
    ax({
      method: props.method,
      url: props.url,
      data: formData,
    })
      .then(function (response) {
        window.location = response.data.return_url;
      })
      .catch((err) => {
        setErrorMessages(err.response.data);
      });
  };

  const toggleStudentCatalogue = () => {
    ax({
      method: 'post',
      url: props.toggleInStudentCatalogueUrl,
    })
      .then(function () {
        console.log('toggle in student catalogue successfully');
        setInStudentCatalogue(!inStudentCatalogue);
      })
      .catch((err) => {
        console.log(err);
        toastr.error(intl.formatMessage({ id: 'shared.something-wrong' }));
      });
  };

  register('description', { required: true });

  return (
    <div>
      {props.toggleInStudentCatalogueUrl && (
        <form>
          <div className="switch-wrapper">
            <label className="switch">
              <input
                type="checkbox"
                onChange={toggleStudentCatalogue}
                name="inStudentCatalogue"
                checked={inStudentCatalogue}
              />
              <span className="slider round"></span>
            </label>
            <label>Included in student catalogue</label>
          </div>
        </form>
      )}

      <form onSubmit={handleSubmit(onSubmit)}>
        <fieldset disabled={!props.allowEdit}>
          <div className="form-row">
            <div className="form-group required">
              <label>{intl.formatMessage({ id: 'courses.title-field' })}</label>
              <input className="form-control" {...register('title', { required: true })}></input>
              {errors.title?.type === 'required' && (
                <div className="field-error">{intl.formatMessage({ id: 'shared.forms.required' })}</div>
              )}
            </div>
            <div className="form-group required">
              <label>{intl.formatMessage({ id: 'units.unit-type-field' })}</label>
              <UnitTypeSelect allowedUnits={props.allowedUnits} {...register('unit_type', { required: true })} />
              {errors.unit_type?.type === 'required' && (
                <div className="field-error">{intl.formatMessage({ id: 'shared.forms.required' })}</div>
              )}
            </div>
          </div>

          <div className="form-row">
            <div className="form-group required">
              <label>{intl.formatMessage({ id: 'courses.description-field' })}</label>
              <Froala
                model={getValues('description')}
                onModelChange={(val) => setValue('description', val)}
                s3Fields={props.s3Fields}
                s3UrlReplaceFrom={props.s3UrlReplaceFrom}
                s3UrlReplaceTo={props.s3UrlReplaceTo}
                isDarkTheme={props.isDarkTheme}
              />
              {errors.description?.type === 'required' && (
                <div className="field-error">{intl.formatMessage({ id: 'shared.forms.required' })}</div>
              )}
            </div>
          </div>

          <div className="stack">
            <Accordion
              header={<AccordionHeader />}
              content={
                <AccordionContent
                  intl={intl}
                  register={register}
                  getValues={getValues}
                  setValue={setValue}
                  hideVisibility={props.hideVisibility}
                  errors={errors}
                  courseAuthor={props.courseAuthor}
                  watch={watch}
                  defaultValues={props.defaultValues}
                />
              }
            ></Accordion>

            <div className="btn-group btn-end">
              <button type="button" className="btn btn-secondary small angled-top-left" onClick={() => history.back()}>
                {intl.formatMessage({ id: 'shared.cancel' })}
              </button>
              <button type="submit" className="btn btn-primary small angled-bottom-right">
                {intl.formatMessage({ id: 'shared.forms.save' })}
              </button>
            </div>
          </div>
        </fieldset>
      </form>

      <ServerFormErrors errorMessages={errorMessage}></ServerFormErrors>
    </div>
  );
};

CourseForm.propTypes = {
  url: PropTypes.string.isRequired,
  method: PropTypes.string.isRequired,
  defaultValues: PropTypes.object,
  courseAuthor: PropTypes.string.isRequired,
  allowEdit: PropTypes.bool.isRequired,
  inStudentCatalogue: PropTypes.bool,
  toggleInStudentCatalogueUrl: PropTypes.string,
  hideVisibility: PropTypes.bool,
  s3Fields: PropTypes.object.isRequired,
  s3UrlReplaceFrom: PropTypes.string.isRequired,
  s3UrlReplaceTo: PropTypes.string.isRequired,
  allowedUnits: PropTypes.arrayOf(PropTypes.string),
  isDarkTheme: PropTypes.bool
};

export default CourseForm;

export const WithIntlProvider = (props) => {
  return (
    <IntlProvider locale={locale} key={locale} messages={translations[locale]}>
      <CourseForm {...props} />
    </IntlProvider>
  );
};
