import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import 'moment/locale/en-au';
import { useIntl } from 'react-intl';
import CountrySelect from '../../util/CountrySelect';
import { SearchBarContext } from '../../../../../components/SearchBar';
import SearchBarRow from '../../../../../components/SearchBar/SearchBarRow';

const PrimarySearchItems = (props) => {
  const intl = useIntl();
  const { register } = useContext(SearchBarContext);

  return (
    <SearchBarRow>
      <div className="form-group" style={{ flex: 1 }}>
        <label>{intl.formatMessage({ id: 'admin-area.organisations.search.name-field' })}</label>
        <input className="form-control" {...register('name')}></input>
      </div>

      <div className="form-group" style={{ flex: 1 }}>
        <label>{intl.formatMessage({ id: 'admin-area.organisations.search.region-field' })}</label>
        <input className="form-control" {...register('region')}></input>
      </div>

      <div className="form-group" style={{ flex: 1 }}>
        <label>{intl.formatMessage({ id: 'admin-area.organisations.search.country-field' })}</label>
        <CountrySelect className="form-control" {...register('country')} countries={props.countries} />
      </div>

      <div className="form-group" style={{ flex: 1 }}>
        <label>{intl.formatMessage({ id: 'admin-area.organisations.search.status-field' })}</label>
        <select className="form-control" {...register('showArchived')}>
          <option value="">{intl.formatMessage({ id: 'shared.no' })}</option>
          <option value="yes">{intl.formatMessage({ id: 'shared.yes' })}</option>
        </select>
      </div>
    </SearchBarRow>
  );
};

PrimarySearchItems.propTypes = {
  countries: PropTypes.array.isRequired,
};

export { PrimarySearchItems };
