import React, { useEffect, useState, useCallback, useContext } from 'react';
import Table from '../Table';
import { loadMicroEvents, addMicroEvents } from './actions';
import StatusLabel from '../StatusLabel';
import FlexRight from '../utils/FlexRight';
import PropTypes from 'prop-types';
import debounce from 'lodash.debounce';
import { ModalContext } from '../../bundles/main/components/util/Modal';
import { useIntl } from 'react-intl';

import './AddMicroEvents.scss';

const AddMicroEvents = ({ loadEventUrl, addEventUrl }) => {
  const intl = useIntl();
  const [data, setData] = useState();
  const [search, setSearch] = useState();
  const [selectedRows, setSelectedRows] = useState([]);
  const modalContext = useContext(ModalContext);

  useEffect(() => {
    loadMicroEvents(loadEventUrl, setData);
  }, []);

  const nameRender = (name, item) => (
    <>
      {name} &nbsp;{' '}
      {item.added && <StatusLabel status="passed" text={intl.formatMessage({ id: 'events.already-added' })} />}
    </>
  );

  const columns = [
    {
      header: intl.formatMessage({ id: 'events.event-id' }),
      accessor: 'eventId',
      render: nameRender,
    },
    {
      header: intl.formatMessage({ id: 'events.name' }),
      accessor: 'name',
    },
    {
      header: intl.formatMessage({ id: 'events.category' }),
      accessor: 'category',
    },
    {
      header: intl.formatMessage({ id: 'events.description' }),
      accessor: 'description',
    },
  ];

  const onPageChange = (page) => {
    loadMicroEvents(loadEventUrl, setData, page, search);
  };

  const onSearch = (event) => {
    const search = event.target.value;
    setSearch(search);
    setSelectedRows([]);
    loadMicroEvents(loadEventUrl, setData, 1, search);
  };

  const debouncedOnSearch = useCallback(debounce(onSearch, 300), []);

  const onRowSelect = ({ selected, id }) => {
    if (selected) {
      setSelectedRows([...selectedRows, id]);
    } else {
      setSelectedRows(selectedRows.filter((item) => item != id));
    }
  };

  const onAddEvents = () => {
    addMicroEvents(addEventUrl, selectedRows);
  };

  if (!data) {
    return;
  } else {
    const { events, pagination } = data;
    const mappedEvents = events.map((item) => ({ ...item, selected: selectedRows.includes(item.id) }));

    return (
      <div className="add-micro-events">
        <FlexRight>
          <input data-testid="searchInput" className="form-control search-input" onChange={debouncedOnSearch} />
        </FlexRight>
        <Table
          columns={columns}
          items={mappedEvents}
          pagination={pagination}
          onPageChange={onPageChange}
          onRowSelect={onRowSelect}
          rowSelect={(item) => !item.added}
          rowClass={(item) => item.added && 'green-row'}
        ></Table>
        <FlexRight className="gap-1 margin-0.5">
          <button className="btn btn-secondary angled-top-left" onClick={modalContext.closeModal}>
            {intl.formatMessage({ id: 'shared.cancel' })}
          </button>

          <button
            className="btn btn-primary angled-bottom-right"
            disabled={!selectedRows.length}
            data-confirm=""
            data-disable-with="..."
            onClick={onAddEvents}
          >
            {intl.formatMessage({ id: 'events.add-event' })} {!!selectedRows.length && <>({selectedRows.length})</>}
          </button>
        </FlexRight>
      </div>
    );
  }
};

AddMicroEvents.propTypes = {
  addEventUrl: PropTypes.string.isRequired,
  loadEventUrl: PropTypes.string.isRequired,
};

export default AddMicroEvents;
