import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import PageHeader from '../../../../components/PageHeader';
import PlaylistUnitCreateForm from './PlaylistUnitCreateForm';
import ConfirmModal from '../util/ConfirmModal';
import wrap_with_intl_provider from '../util/wrap_with_intl_provider';

function FormStep2(props) {
  const intl = useIntl();
  const [openCancelModal, setOpenCancelModal] = useState(false);

  const toggleCancelModal = () => {
    setOpenCancelModal(!openCancelModal);
  }

  const onConfirm = () => {
    history.replaceState({}, '', props.submitUrl);
  }

  const onAddUnits = (units) => {
    let formData = {
      ...props.formData,
      units_attributes: props.formData.units_attributes.concat(units)
    };
    props.setFormData(formData);
  };

  const onConfirmUnassign = (unit_param) => {
    let copyFormData = {
      ...props.formData,
      units_attributes: props.formData.units_attributes.filter(function(unit) {
        return unit.id !== unit_param.id
      })
    };
    props.setFormData(copyFormData);
  };

  return (
    <>
      <PageHeader
        previousPages={[
          {
            link: props.submitUrl,
            title: intl.formatMessage({ id: 'playlists.title' }),
          },
        ]}
        title={props.formData.title}
        tabs={
          [
            {
              icon: 'fa-list-check',
              title: intl.formatMessage({ id: 'playlists.tabs.content' }),
              active: false,
              onClickFunc: props.goBackFunc,
            },
            {
              icon: 'fa-box-open',
              link: '#',
              title: intl.formatMessage({ id: 'playlists.tabs.unit' }),
              active: true,
            },
          ]
        }
        settingPages={[
          {
            title: intl.formatMessage({ id: 'shared.cancel' }),
            className: 'btn btn-secondary small angled-top-left',
            onClick: () => toggleCancelModal(),
            icon: '',
          },
          {
            title: intl.formatMessage({ id: 'shared.forms.save' }),
            className: 'btn btn-primary small angled-bottom-right',
            onClick: () => props.onSubmit(props.formData),
            icon: '',
          },
        ]}
      />
      {openCancelModal &&
        <ConfirmModal
          title={intl.formatMessage({ id: 'playlists.cancel.modal-title' })}
          text={intl.formatMessage({ id: 'playlists.cancel.modal-message' })}
          onConfirm={onConfirm}
          onCancel={toggleCancelModal}
        ></ConfirmModal>
      }
      <PlaylistUnitCreateForm
        formData={props.formData}
        setFormData={props.setFormData}
        unitsUrl={props.unitsUrl}
        isAdmin={props.isAdmin}
        onAddUnits={onAddUnits}
        onConfirmUnassign={onConfirmUnassign}
      />
    </>
  );
}

FormStep2.propTypes = {
  formData: PropTypes.object,
  setFormData: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  defaultValues: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  submitUrl: PropTypes.string.isRequired,
  unitsUrl: PropTypes.string.isRequired,
  goBackFunc: PropTypes.func.isRequired,
};

export default wrap_with_intl_provider(FormStep2);