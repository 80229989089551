import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import ServerFormErrors from '../../util/ServerFormErrors';
import ax from '../../util/axios';
import { IntlProvider, useIntl } from 'react-intl';
import { translations } from '../../util/i18n';
import convertToFormData from '../../util/upload';
import CountrySelect from '../../util/CountrySelect';
import toastr from 'toastr';

const OrgForm = (props) => {
  const intl = useIntl();
  const [errorMessage, setErrorMessages] = useState();
  const [courseEngineEnabled, setCourseEngineEnabled] = useState(props.courseEngineEnabled);
  const [blockPublicCustomScenario, setBlockPublicCustomScenario] = useState(props.blockPublicCustomScenario);
  const [lmsContentCourseAccess, setLmsContentCourseAccess] = useState(props.lmsContentCourseAccess);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues: { ...props.defaultValues } });
  const onSubmit = (data) => {
    const formData = convertToFormData(data, ['logo']);
    ax({
      method: props.method,
      url: props.url,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' },
    }).then(function (response) {
      window.location = response.data.return_url;
    }).catch((err) => {
      setErrorMessages(err.response.data);
    });
  };

  const toggleCourseEngineEnabled = () => {
    ax({
      method: 'post',
      url: props.toggleCourseEngineEnabledUrl,
    }).then(function () {
      const msgId = !courseEngineEnabled
              ? 'admin-area.organisations.online-course-engine-enabled'
              : 'admin-area.organisations.online-course-engine-disabled';
      toastr.success(intl.formatMessage({ id: msgId }));
      setCourseEngineEnabled(!courseEngineEnabled);
    }).catch(() => {
      toastr.error(intl.formatMessage({ id: 'shared.something-wrong' }));
    });
  };

  const toggleBlockPublicCustomScenario = () => {
    ax({
      method: 'post',
      url: props.toggleBlockPublicCustomScenarioUrl,
    }).then(function () {
      const msgId = !blockPublicCustomScenario
              ? 'admin-area.organisations.opt-out-of-global-scenarios-enabled'
              : 'admin-area.organisations.opt-out-of-global-scenarios-disabled';
      toastr.success(intl.formatMessage({ id: msgId }));
      setBlockPublicCustomScenario(!blockPublicCustomScenario);
    }).catch(() => {
      toastr.error(intl.formatMessage({ id: 'shared.something-wrong' }));
    });
  };

  const toggleLmsContentCourseAccess = () => {
    ax({
      method: 'post',
      url: props.toggleLmsContentCourseAccessUrl,
    }).then(function () {
      const msgId = !lmsContentCourseAccess
              ? 'admin-area.organisations.lms-content-course-access-enabled'
              : 'admin-area.organisations.lms-content-course-access-disabled';
      toastr.success(intl.formatMessage({ id: msgId }));
      setLmsContentCourseAccess(!lmsContentCourseAccess);
    }).catch(() => {
      toastr.error(intl.formatMessage({ id: 'shared.something-wrong' }));
    });
  };

  return (
          <div>
            <div className="display-flex">
              {props.toggleCourseEngineEnabledUrl && (
                      <form>
                        <div className="switch-wrapper">
                          <label className="switch">
                            <input
                                    type="checkbox"
                                    onChange={toggleCourseEngineEnabled}
                                    name="toggleCourseEngine"
                                    checked={courseEngineEnabled}
                            />
                            <span className="slider round"></span>
                          </label>
                          <label>{intl.formatMessage({ id: 'admin-area.organisations.enable-online-course-engine' })}</label>
                        </div>
                      </form>
              )}
              &emsp;
              {props.toggleLmsContentCourseAccessUrl && (
                      <form>
                        <div className="switch-wrapper">
                          <label className="switch">
                            <input
                                    type="checkbox"
                                    onChange={toggleLmsContentCourseAccess}
                                    name="toggleBlockPublicCustomScenario"
                                    checked={lmsContentCourseAccess}
                            />
                            <span className="slider round"></span>
                          </label>
                          <label>{intl.formatMessage({ id: 'admin-area.organisations.lms-content-course-access' })}</label>
                        </div>
                      </form>
              )}
              &emsp;
              {props.toggleBlockPublicCustomScenarioUrl && (
                      <form>
                        <div className="switch-wrapper">
                          <label className="switch">
                            <input
                                    type="checkbox"
                                    onChange={toggleBlockPublicCustomScenario}
                                    name="toggleBlockPublicCustomScenario"
                                    checked={blockPublicCustomScenario}
                            />
                            <span className="slider round"></span>
                          </label>
                          <label>{intl.formatMessage({ id: 'admin-area.organisations.opt-out-of-global-scenarios' })}</label>
                        </div>
                      </form>
              )}
            </div>

            <form onSubmit={handleSubmit(onSubmit)} className="narrow">
              <div className="form-group">
                <label>{intl.formatMessage({ id: 'admin-area.organisations.name-field' })}</label>
                <input className="form-control" disabled={props.disableNameField} {...register('name', { required: true })}></input>
                {errors.name?.type === 'required' && (
                        <div className="field-error">{intl.formatMessage({ id: 'shared.forms.required' })}</div>
                )}
              </div>

              <div className="form-group">
                <label>{intl.formatMessage({ id: 'admin-area.organisations.subdomain-field' })}</label>
                <input className="form-control" disabled={props.disableNameField} {...register('subdomain', { required: true })}></input>
                <div className="field-hint">
                  <i className="fa fa-question-circle"/>
                  <small>{intl.formatMessage({ id: 'admin-area.organisations.subdomain-field-hint' })}</small>
                </div>
                {errors.subdomain?.type === 'required' && (
                        <div className="field-error">{intl.formatMessage({ id: 'shared.forms.required' })}</div>
                )}
              </div>

              <div className="form-group">
                <label>{intl.formatMessage({ id: 'admin-area.organisations.email-field' })}</label>
                <input
                        className="form-control"
                        {...register('email', {
                          required: true,
                          pattern: EMAIL_REGEX,
                        })}
                ></input>
                {errors.email?.type === 'required' && (
                        <div className="field-error">{intl.formatMessage({ id: 'shared.forms.required' })}</div>
                )}
                {errors.email?.type === 'pattern' && (
                        <div className="field-error">{intl.formatMessage({ id: 'shared.forms.invalid-email' })}</div>
                )}
              </div>

              <div className="form-group">
                <label>{intl.formatMessage({ id: 'admin-area.organisations.primary-color-field' })}</label>
                <input type="color" className="form-control color-field" {...register('primary_color')}></input>
              </div>

              <div className="form-group">
                <label>{intl.formatMessage({ id: 'admin-area.organisations.logo-field' })}</label>
                {props.defaultValues.logo_url && (
                        <div className="image-wrapper">
                          <img src={props.defaultValues.logo_url}/>
                        </div>
                )}
                <input
                        type="file"
                        accept="image/jpeg,image/png"
                        multiple={false}
                        className="form-control"
                        {...register('logo', {
                          validate: { lessThan1MB: (files) => !files[0] || files[0]?.size < 1024 * 1024 },
                        })}
                ></input>
                <div className="field-hint">
                  <i className="fa fa-question-circle"/>
                  <small>{intl.formatMessage({ id: 'admin-area.organisations.logo-field-hint' })}</small>
                </div>
                {errors.logo?.type === 'lessThan1MB' && (
                        <div className="field-error">{intl.formatMessage({ id: 'shared.forms.file_too_big' })}</div>
                )}
              </div>

              {props.unitTypeFeatureFlagOn && (
                      <>
                        <h4>Access to Unit Type</h4>
                        <div className="display-flex">
                          <div className="form-group form-check">
                            <label htmlFor="extinguisher_unit_access">
                              <input type="checkbox"
                                     id="extinguisher_unit_access" {...register('extinguisher_unit_access')} />
                              Extinguisher
                            </label>
                          </div>
                          <div className="form-group form-check">
                            <label htmlFor="trainer_unit_access">
                              <input type="checkbox" id="trainer_unit_access" {...register('trainer_unit_access')} />
                              Trainer
                            </label>
                          </div>
                          <div className="form-group form-check">
                            <label htmlFor="sweeper_unit_access">
                              <input type="checkbox" id="sweeper_unit_access" {...register('sweeper_unit_access')} />
                              Sweeper
                            </label>
                          </div>
                        </div>
                      </>
              )}

              <h4>Location</h4>

              <div className="form-group">
                <label>{intl.formatMessage({ id: 'admin-area.organisations.line1-field' })}</label>
                <input className="form-control" {...register('line1', { required: true })}></input>
                {errors.line1?.type === 'required' && (
                        <div className="field-error">{intl.formatMessage({ id: 'shared.forms.required' })}</div>
                )}
              </div>

              <div className="form-group">
                <label>{intl.formatMessage({ id: 'admin-area.organisations.line2-field' })}</label>
                <input className="form-control" {...register('line2')}></input>
              </div>

              <div className="form-row">
                <div className="form-group">
                  <label>{intl.formatMessage({ id: 'admin-area.organisations.locality-field' })}</label>
                  <input className="form-control" {...register('locality', { required: true })}></input>
                  {errors.locality?.type === 'required' && (
                          <div className="field-error">{intl.formatMessage({ id: 'shared.forms.required' })}</div>
                  )}
                </div>

                <div className="form-group">
                  <label>{intl.formatMessage({ id: 'admin-area.organisations.region-field' })}</label>
                  <input className="form-control" {...register('region', { required: true })}></input>
                  {errors.region?.type === 'required' && (
                          <div className="field-error">{intl.formatMessage({ id: 'shared.forms.required' })}</div>
                  )}
                </div>
              </div>

              <div className="form-row">
                <div className="form-group">
                  <label>{intl.formatMessage({ id: 'admin-area.organisations.postcode-field' })}</label>
                  <input className="form-control" {...register('postcode', { required: true })}></input>
                  {errors.postcode?.type === 'required' && (
                          <div className="field-error">{intl.formatMessage({ id: 'shared.forms.required' })}</div>
                  )}
                </div>

                <div className="form-group">
                  <label>{intl.formatMessage({ id: 'admin-area.organisations.country-field' })}</label>
                  <CountrySelect
                          className="form-control"
                          {...register('country', { required: true })}
                          countries={props.countries}
                  />
                  {errors.country?.type === 'required' && (
                          <div className="field-error">{intl.formatMessage({ id: 'shared.forms.required' })}</div>
                  )}
                </div>
              </div>

              <button type="submit" className="btn btn-primary small angled-bottom-right">
                {intl.formatMessage({ id: 'shared.forms.save' })}
              </button>
            </form>

            <ServerFormErrors errorMessages={errorMessage}></ServerFormErrors>
          </div>
  );
};

OrgForm.propTypes = {
  url: PropTypes.string.isRequired,
  method: PropTypes.string.isRequired,
  defaultValues: PropTypes.object,
  countries: PropTypes.object,
  toggleCourseEngineEnabledUrl: PropTypes.string,
  courseEngineEnabled: PropTypes.bool,
  blockPublicCustomScenario: PropTypes.bool,
  toggleBlockPublicCustomScenarioUrl: PropTypes.string,
  unitTypeFeatureFlagOn: PropTypes.bool,
  lmsContentCourseAccess: PropTypes.bool,
  toggleLmsContentCourseAccessUrl: PropTypes.string,
  disableNameField: PropTypes.bool,
  disableSubdomainField: PropTypes.bool
};

export default OrgForm;

export const WithIntlProvider = (props) => {
  return (
          <IntlProvider
                  locale={window._FLAIM_LOCALE}
                  key={window._FLAIM_LOCALE}
                  messages={translations[window._FLAIM_LOCALE]}
          >
            <OrgForm {...props} />
          </IntlProvider>
  );
};
