import React from "react";
import PropTypes from "prop-types";

const DeleteLinkWithIcon = (props) => {
  return (
    <a href={props.url} className="with_icon" data-confirm="Are you sure?" data-method="delete">
      <div className="row-action-container">
        <div className="options-icon-container">
          <i className="far fa-trash-alt"></i>
        </div>
      </div>
    </a>
  );
};

DeleteLinkWithIcon.propTypes = {
  url: PropTypes.string.isRequired,
};

export default DeleteLinkWithIcon;
