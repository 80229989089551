import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import ServerFormErrors from '../util/ServerFormErrors';
import ax from '../util/axios';
import { useIntl } from 'react-intl';

const SimulationForm = (props) => {
  const intl = useIntl();
  const [errorMessage, setErrorMessages] = useState();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues: { ...props.defaultValues } });
  const onSubmit = (data) => {
    ax({
      method: props.method,
      url: props.url,
      data: { form: data },
    })
      .then(function () {
        window.location = window.location.href;
      })
      .catch((err) => {
        setErrorMessages(err.response.data);
      });
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-group">
          <label>{intl.formatMessage({ id: 'admin-area.simulations.trainer-first-name-field' })}</label>
          <input className="form-control" {...register('trainer_first_name', { required: true })}></input>
          {errors.trainer_first_name?.type === 'required' && (
            <div className="field-error">{intl.formatMessage({ id: 'shared.forms.required' })}</div>
          )}
        </div>

        <div className="form-group">
          <label>{intl.formatMessage({ id: 'admin-area.simulations.trainer-last-name-field' })}</label>
          <input className="form-control" {...register('trainer_last_name', { required: true })}></input>
          {errors.trainer_last_name?.type === 'required' && (
            <div className="field-error">{intl.formatMessage({ id: 'shared.forms.required' })}</div>
          )}
        </div>

        <div className="form-group">
          <label>{intl.formatMessage({ id: 'admin-area.simulations.trainer-email-field' })}</label>
          <input
            className="form-control"
            {...register('trainer_email', {
              required: true,
              pattern: EMAIL_REGEX,
            })}
          ></input>
          {errors.trainer_email?.type === 'required' && (
            <div className="field-error">{intl.formatMessage({ id: 'shared.forms.required' })}</div>
          )}
          {errors.trainer_email?.type === 'pattern' && (
            <div className="field-error">{intl.formatMessage({ id: 'shared.forms.invalid-email' })}</div>
          )}
        </div>

        <button type="submit" className="btn btn-primary small angled-bottom-right">
          {intl.formatMessage({ id: 'shared.forms.save' })}
        </button>
      </form>

      <ServerFormErrors errorMessages={errorMessage}></ServerFormErrors>
    </div>
  );
};

SimulationForm.propTypes = {
  url: PropTypes.string.isRequired,
  method: PropTypes.string.isRequired,
  defaultValues: PropTypes.object,
};

export default SimulationForm;
