import React from "react";
import RowMenu from "../../util/RowMenu";
import Modal from "../../util/Modal";
import Link from "../../util/Link";
import EditCustomReportModal from "./EditCustomReportModal";
import PropTypes from "prop-types";

import { IntlProvider } from "react-intl";
import { translations, locale } from "../../util/i18n";
import PostLink from "../../util/PostLink";

const AdminCustomReportManual = (props) => {
  const options = [
    {
      title: "custom-reports.dropdown.edit",
      component: Modal,
      params: {
        title: "custom-reports.dropdown.edit",
        content: EditCustomReportModal({ url: props.updateCustomReportUrl, details: { ...props.details } }),
      },
    },
    {
      title: "custom-reports.dropdown.view",
      component: Link,
      params: {
        url: props.viewCustomReportUrl,
      },
    },
    {
      title: "custom-reports.dropdown.delete",
      component: PostLink,
      params: {
        url: props.updateCustomReportUrl,
        method: "DELETE",
        refresh: true,
        confirmMessage: "custom-reports.dropdown.delete-confirm-message",
      },
    },
  ];

  return (
    <IntlProvider
      locale={window._FLAIM_LOCALE}
      key={window._FLAIM_LOCALE}
      messages={translations[window._FLAIM_LOCALE]}
    >
      <RowMenu options={options}></RowMenu>
    </IntlProvider>
  );
};

AdminCustomReportManual.propTypes = {
  updateCustomReportUrl: PropTypes.string,
  viewCustomReportUrl: PropTypes.string,
  details: PropTypes.object,
};

export default AdminCustomReportManual;
