import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import wrap from '../util/wrap_with_intl_provider';
import Table from '../../../../components/Table';
import ax from '../util/axios';
import StatusLabel from '../../../../components/StatusLabel';
import { tableParamsBuilder } from '../util/searchUtils';
import StudentsRowMenu from './StudentsRowMenu';
import RightArrowLink from '../../../../components/RightArrowLink';
import SearchBar from '../../../../components/SearchBar';
import { PrimarySearchItems, SecondarySearchItems } from './StudentSearchForm';
import {
  admin_area_student_archive_path,
  organisation_area_student_archive_path,
  admin_area_student_unarchive_path,
  organisation_area_student_unarchive_path,
  organisation_area_student_merge_path,
  organisation_area_student_check_student_for_merge_path,
  organisation_area_student_reset_password_path,
  admin_area_student_statistics_path,
  organisation_area_student_statistics_path,
} from '../../../../routes';

const StudentTable = (props) => {
  const intl = useIntl();
  const [pagination, setPagination] = useState({});
  const [students, setStudents] = useState([]);
  const [searchParams, setSearchParams] = useState({});

  const fetchStudents = async (page = 1) => {
    const response = await ax.get(props.studentsUrl + '.json' + tableParamsBuilder({ page, ...searchParams }));
    setPagination(response.data.pagination);
    setStudents(response.data.students);
  };

  const studentColumns = [
    {
      accessor: 'employeeId',
      header: intl.formatMessage({ id: 'students.fields.employee-id' }),
    },
    {
      accessor: 'trainingKey',
      header: intl.formatMessage({ id: 'students.fields.training-key' }),
    },
    {
      accessor: 'firstName',
      header: intl.formatMessage({ id: 'students.fields.first-name' }),
    },
    {
      accessor: 'lastName',
      header: intl.formatMessage({ id: 'students.fields.last-name' }),
    },
    {
      accessor: 'email',
      header: intl.formatMessage({ id: 'students.fields.email' }),
    },
    {
      accessor: 'organisation',
      header: intl.formatMessage({ id: 'students.fields.organisation' }),
    },
    {
      accessor: 'lastLogin',
      header: intl.formatMessage({ id: 'students.fields.last-login-date' }),
    },
    {
      accessor: 'status',
      className: 'status',
      header: intl.formatMessage({ id: 'shared.status' }),
    },
    {
      className: 'actions',
      accessor: 'rowMenu',
    },
    {
      className: 'actions',
      accessor: 'studentStatisticsLink',
    },
  ];

  const mappedStudents = students.map((student) => ({
    ...student,
    status: <StatusLabel text={student.status} status={student.status === 'active' ? 'passed' : 'failed'} />,
    rowMenu: (
      <StudentsRowMenu
        isActive={student.status === 'active'}
        fromUserEmail={student.fromUserEmail}
        fromUserName={student.fromUserName}
        archiveStudentUrl={
          props.isAdmin
            ? admin_area_student_archive_path(student.studentUserId)
            : organisation_area_student_archive_path(student.studentUserId)
        }
        unarchiveStudentUrl={
          props.isAdmin
            ? admin_area_student_unarchive_path(student.studentUserId)
            : organisation_area_student_unarchive_path(student.studentUserId)
        }
        mergeStudentUrl={props.isOrganisationAdmin && organisation_area_student_merge_path(student.studentUserId)}
        checkStudentForMergeUrl={
          props.isOrganisationAdmin && organisation_area_student_check_student_for_merge_path(student.studentUserId)
        }
        resetPasswordUrl={
          props.isOrganisationAdmin && organisation_area_student_reset_password_path(student.studentUserId)
        }
      />
    ),
    studentStatisticsLink: (
      <RightArrowLink
        url={
          props.isAdmin
            ? admin_area_student_statistics_path(student.studentUserId)
            : organisation_area_student_statistics_path(student.studentUserId)
        }
      />
    ),
  }));

  useEffect(() => {
    fetchStudents();
  }, [searchParams]);

  return (
    <div>
      <SearchBar
        primaryItems={<PrimarySearchItems />}
        secondaryItems={<SecondarySearchItems organisationId={props.organisationId} organisations={props.organisations}/>}
        onSearch={setSearchParams}
      />
      <div className="table-header-actions">
        <a href={props.studentsUrl + '.pdf'}>
          <i className="fas fa-file-pdf" />
          &nbsp;
          {intl.formatMessage({ id: 'shared.forms.export_as_pdf' })}
        </a>
      </div>
      {students.length > 0 && (
        <Table
          header={true}
          items={mappedStudents}
          columns={studentColumns}
          pagination={pagination}
          onPageChange={fetchStudents}
        />
      )}
    </div>
  );
};

StudentTable.propTypes = {
  studentsUrl: PropTypes.string.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  isOrganisationAdmin: PropTypes.bool.isRequired,
  organisationId: PropTypes.number.isRequired, 
  organisations: PropTypes.array.isRequired,
};

export default wrap(StudentTable);
