import React, { useContext, useEffect, useState } from 'react';
import { NewCustomScenarioPageContext } from './NewCustomScenarioPage';
import Accordion, { AccordionContext } from '../../Accordion/Accordion';
import classNames from 'classnames';
import { useIntl } from 'react-intl';
import StepActions from './StepActions';
import Table from '../../Table';
import {
  admin_area_custom_scenario_observation_event_path,
  admin_area_custom_scenario_observation_events_path,
  admin_area_custom_scenario_publish_path,
  admin_area_scenario_path,
  admin_area_scenarios_path,
  organisation_area_custom_scenario_observation_event_path,
  organisation_area_custom_scenario_observation_events_path,
  organisation_area_custom_scenario_publish_path,
  organisation_area_scenario_path,
  organisation_area_scenarios_path,
  reorder_admin_area_custom_scenario_observation_events_path,
  reorder_organisation_area_custom_scenario_observation_events_path,
} from '../../../routes';
import ax from '../../../bundles/main/components/util/axios';
import toastr from 'toastr';
import RowMenu from '../../../bundles/main/components/util/RowMenu';
import PostLink from '../../../bundles/main/components/util/PostLink';
import { useForm } from 'react-hook-form';

const Header = () => {
  const intl = useIntl();
  const [context] = useContext(NewCustomScenarioPageContext);
  const { activeStep } = context;
  const accordionContext = useContext(AccordionContext);

  useEffect(() => {
    if (activeStep == 'after-action-review') {
      accordionContext.setExpanded(true);
    } else {
      accordionContext.setExpanded(false);
    }
  }, [activeStep]);

  return (
    <div className={classNames('step-header')}>
      <div className="title">
        <div className="title-info">
          <div className="title-main">{intl.formatMessage({ id: 'scenarios.new-custom-scenario.step5-header.title' })}</div>
        </div>
      </div>
    </div>
  );
};

const Content = () => {
  const intl = useIntl();
  const [context] = useContext(NewCustomScenarioPageContext);
  const { scenario, isAdmin } = context;
  const [events, setEvents] = useState([]);
  const [editingRefId, setEditingRefId] = useState();
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();

  const [obEventPath, obEventsPath, publishPath, scenarioPath, scenariosPath, reorderPath] = isAdmin
    ? [
        admin_area_custom_scenario_observation_event_path,
        admin_area_custom_scenario_observation_events_path,
        admin_area_custom_scenario_publish_path,
        admin_area_scenario_path,
        admin_area_scenarios_path,
        reorder_admin_area_custom_scenario_observation_events_path,
      ]
    : [
        organisation_area_custom_scenario_observation_event_path,
        organisation_area_custom_scenario_observation_events_path,
        organisation_area_custom_scenario_publish_path,
        organisation_area_scenario_path,
        organisation_area_scenarios_path,
        reorder_organisation_area_custom_scenario_observation_events_path,
      ];

  useEffect(() => fetchEvents(), []);

  const fetchEvents = () => {
    let url = obEventsPath(scenario.id);

    ax({
      method: 'get',
      url: url,
    })
      .then((response) => {
        setEvents(response.data.events);
      })
      .catch((error) => {
        toastr['error'](error);
      });
  };

  const onNext = () => {
    ax({
      method: 'post',
      url: publishPath(scenario.id),
    })
      .then(() => {
        window.location = scenarioPath(scenario.id);
      })
      .catch((error) => {
        toastr['error'](error);
      });
  };
  const onSaveDraft = () => (window.location = scenariosPath());

  const cols = [
    {
      accessor: 'description',
      header: intl.formatMessage({ id: 'admin-area.settings.observation-event.fields.description' }),
    },
    {
      className: 'col-new-event-actions',
      accessor: 'newEventActions',
    },
    {
      accessor: 'actions',
      className: 'actions',
    },
  ];

  const options = (evt) => {
    const opts = [
      {
        title: 'shared.delete',
        component: PostLink,
        params: {
          method: 'delete',
          url: obEventPath(scenario.id, evt.refId),
          onDone: () => setEvents(events.filter((event) => event.refId != evt.refId)),
          confirmMessage: 'scenarios.new-custom-scenario.observation-events.confirm-delete',
        },
      },
    ];

    if (evt.scenarioId) {
      opts.unshift({
        title: 'shared.edit',
        onClick: () => {
          setEvents(events.filter((evt) => evt.refId));
          setEditingRefId(evt.refId);
          reset({ event_id: evt.eventId, description: evt.description, ref_id: evt.refId });
        },
      });
    }
    return opts;
  };

  const cancelEditEvent = () => {
    setEditingRefId(undefined);
    setEvents(events.filter((evt) => evt.refId));
  };

  const saveEditEvent = (data) => {
    ax({
      method: data.ref_id ? 'put' : 'post',
      url: data.ref_id ? obEventPath(scenario.id, data.ref_id) : obEventsPath(scenario.id),
      data: { form: data },
    })
      .then((response) => {
        const editingEvent = data.ref_id
          ? events.find((evt) => evt.refId == data.ref_id)
          : events.find((evt) => !evt.refId);
        editingEvent.eventId = data.event_id;
        editingEvent.description = data.description;
        editingEvent.scenarioId = scenario.id;
        editingEvent.refId = response.data.refId;

        reset();
        setEditingRefId(undefined);
      })
      .catch((error) => {
        toastr['error'](error);
      });
  };

  const mappedObservationEvents = events.map((evt) => {
    const editing = !evt.refId || editingRefId == evt.refId;
    return {
      scenarioId: evt.scenarioId,
      refId: evt.refId,
      description: editing ? (
        <div className="form-group">
          <input className="form-control" {...register('description', { required: true })}></input>
          {errors.description?.type === 'required' && (
            <div className="field-error">{intl.formatMessage({ id: 'shared.forms.required' })}</div>
          )}
        </div>
      ) : (
        evt.description
      ),
      newEventActions: editing ? (
        <span className="new-event-actions">
          <button type="button" className="btn btn-primary angled-top-left" onClick={handleSubmit(saveEditEvent)}>
            <i className="fas fa-check" />
          </button>&emsp;
          <button type="button" className="btn btn-secondary angled-bottom-right" onClick={cancelEditEvent}>
            <i className="fas fa-times" />
          </button>
        </span>
      ) : undefined,
      actions: evt.refId ? <RowMenu options={options(evt)}></RowMenu> : undefined,
    };
  });

  const rowClass = (evt) => {
    if (evt.refId) {
      return evt.scenarioId ? 'editable' : 'not-editable';
    } else {
      return 'row-editing';
    }
  };

  const addNewObservationEvent = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (events.find((evt) => !evt.refId)) {
      return;
    }

    reset({});
    setEditingRefId(undefined);
    setEvents([...events, {}]);
  };

  const onRowDragEnd = (e) => {
    ax({
      method: 'POST',
      url: reorderPath(scenario.id),
      data: { old_index: e.oldIndex, new_index: e.newIndex },
    });
  };

  return (
    <div className="stack">
      <form>
        <Table
          rawItems={events}
          columns={cols}
          items={mappedObservationEvents}
          rowClass={rowClass}
          rowDrag={true}
          onRowDragEnd={onRowDragEnd}
          onRowDrag={setEvents}
        />
      </form>
      <div className="add-event-actions">
        <a onClick={addNewObservationEvent}>
          <i className="fas fa-plus"></i>&nbsp;
          {intl.formatMessage({ id: 'scenarios.new-custom-scenario.add-new-observation-event' })}
        </a>
      </div>
      <StepActions onSaveDraft={onSaveDraft} onNext={onNext} />
    </div>
  );
};

const ConfigureAfterActionReviewAccordion = () => {
  const [context, setContext] = useContext(NewCustomScenarioPageContext);
  const { activeStep } = context;

  const onToggled = (expanded) => {
    if (expanded) {
      setContext({ ...context, activeStep: 'after-action-review' });
    } else {
      setContext({ ...context, activeStep: '' });
    }
  };

  return (
    <Accordion
      className={classNames({ 'step-ok': false })}
      header={<Header />}
      content={<Content />}
      onToggled={onToggled}
      disabled={activeStep != 'after-action-review'}
    />
  );
};

export default ConfigureAfterActionReviewAccordion;
