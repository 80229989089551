import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ServerFormErrors from '../util/ServerFormErrors';
import ax from '../util/axios';
import { IntlProvider, useIntl } from 'react-intl';
import { locale, translations } from '../util/i18n';
import FormStep1 from './FormStep1';
import FormStep2 from './FormStep2';
import { useForm } from 'react-hook-form';

const PlaylistForm = (props) => {
  const intl = useIntl();
  const [errorMessage, setErrorMessages] = useState();
  const [page, setPage] = useState(0);

  const [formData, setFormData] = useState({...props.defaultValues});
  const {
    register,
    setError,
    formState: { errors },
  } = useForm({ defaultValues: { ...props.defaultValues } });
  const conditionalComponent = () => {
    switch (page) {
      case 0:
        return <FormStep1
                formData={formData}
                setFormData={setFormData}
                isAdmin={props.isAdmin}
                defaultValues={props.defaultValues}
                handleNext={handleNext}
                submitUrl={props.submitUrl}
                scenariosUrl={props.scenariosUrl}
        />;
      case 1:
        return <FormStep2
                formData={formData}
                setFormData={setFormData}
                isAdmin={props.isAdmin}
                defaultValues={props.defaultValues}
                onSubmit={onSubmit}
                submitUrl={props.submitUrl}
                unitsUrl={props.unitsUrl}
                goBackFunc={goBack}
        />;
      default:
        return <FormStep1
                formData={formData}
                setFormData={setFormData}
                isAdmin={props.isAdmin}
                defaultValues={props.defaultValues}
                handleNext={handleNext}
                submitUrl={props.submitUrl}
                scenariosUrl={props.scenariosUrl}
        />;
    }
  };
  const onSubmit = (data) => {
    ax({
      method: props.method,
      url: props.submitUrl,
      data: {
        playlist: {
          title: data.title,
          visibility: data.visibility,
          scenario_ids: data.scenarios_attributes.map((c) => { return c.id }),
          unit_ids: data.units_attributes.map((c) => { return c.id }),
        }
      },
    })
      .then(function (response) {
        window.location = response.data.return_url;
      })
      .catch((err) => {
        setErrorMessages(err.response.data);
      });
  };

  const handleNext = () => {
    if (formData.scenarios_attributes.length > 0) {
      setPage(page + 1);
    } else {
      register('scenario_ids', { minLength: 1 });
      setError('scenario_ids', { type: 'custom', message: intl.formatMessage({id: 'playlists.errors.scenarios'}) });
    }
  }

  const goBack = () => {
    setPage(page - 1);
  }

  return (
    <div>
      <form>{conditionalComponent()}</form>
      {formData.scenarios_attributes.length === 0 && errors.scenario_ids && (
        <div className="form-errors">{errors.scenario_ids.message}</div>
      )}
      <ServerFormErrors errorMessages={errorMessage}></ServerFormErrors>
    </div>
  );
};

PlaylistForm.propTypes = {
  defaultValues: PropTypes.object,
  submitUrl: PropTypes.string.isRequired,
  scenariosUrl: PropTypes.string.isRequired,
  unitsUrl: PropTypes.string.isRequired,
  method: PropTypes.string.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  isDarkTheme: PropTypes.bool
};

export default PlaylistForm;

export const WithIntlProvider = (props) => {
  return (
    <IntlProvider locale={locale} key={locale} messages={translations[locale]}>
      <PlaylistForm {...props} />
    </IntlProvider>
  );
};
