import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './Message.scss';

/**
 * Show message in different style depending on type (error, warning, info etc) which can be closed
 */
const Message = ({ message, description, type, icon, noClose }) => {
  const [close, setClose] = useState(false);

  if (close) {
    return null;
  }

  const icon_for_type = (type) => {
    if (icon) return icon;

    switch (type) {
      case 'info':
        return 'fas fa-face-smile';
      case 'warning':
        return 'fas fa-exclamation-triangle';
      case 'success':
        return 'fas fa-circle-check';
    }
  };

  return (
    <div className={`message ${type}`}>
      <i className={`message-icon ${icon_for_type(type)}`}></i>
      <div className="message-text">
        <div className="message-header">{message}</div>
        {description && <div className="message-description">{description}</div>}
      </div>
      {!noClose && (
        <span role="button" onClick={() => setClose(true)}>
          <i className="message-close far fa-xmark" />
        </span>
      )}
    </div>
  );
};
Message.propTypes = {
  type: PropTypes.oneOf(['info', 'warning', 'success']),
  message: PropTypes.string.isRequired,
  description: PropTypes.string,
  icon: PropTypes.string,
  noClose: PropTypes.bool,
};

export default Message;
