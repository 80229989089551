import React, { useState, useEffect } from "react";
import ax from "../../../util/axios";
import PropTypes from "prop-types";

const AvailableQuizTile = (props) => {
  const quiz = props.content;
  const [details, setDetails] = useState(quiz.details);
  const [activeQuestions, setActiveQuestions] = useState([]);

  useEffect(() => {
    if (!details) {
      ax({
        method: "get",
        url: `/${props.orgArea ? "org" : "admin"}/online_courses/quiz/${quiz.id}/preview_data`,
      })
        .then(function (response) {
          quiz.details = response.data;
          setDetails(quiz.details);
          console.log("get quiz preview_data successfully");
        })
        .catch(() => {
          console.log("get quiz preview_data failed");
        });
    }
  });

  const toggleQuestion = (question_id) => {
    if (activeQuestions.includes(question_id)) {
      setActiveQuestions([...activeQuestions.filter((id) => id != question_id)]);
    } else {
      setActiveQuestions([...activeQuestions, question_id]);
    }
  };

  return (
    <div className="quiz-preview">
      <ul>
        {details &&
          details.questions.map((question, index) => {
            return (
              <li key={index} className={`question ${activeQuestions.includes(question.id) ? "active" : ""}`}>
                <div className="question-header" onClick={() => toggleQuestion(question.id)}>
                  <span>
                    Question {index + 1} of {details.questions.length}
                  </span>
                  <span>
                    <i className="fas fa-angle-down"></i>
                    <i className="fas fa-angle-up"></i>
                  </span>
                </div>
                <div className="question-body" dangerouslySetInnerHTML={{ __html: question.body }}></div>
              </li>
            );
          })}
        {!details && (
          <div className="spinner">
            <i className="fas fa-spinner fa-spin"></i>
          </div>
        )}
      </ul>
    </div>
  );
};

AvailableQuizTile.propTypes = {
  content: PropTypes.object.isRequired,
  orgArea: PropTypes.bool.isRequired,
};

export default AvailableQuizTile;
