import React from 'react';
import PropTypes from 'prop-types';
import wrap from '../../../util/wrap_with_intl_provider';
import {
  admin_area_settings_incident_organisation_path,
  admin_area_settings_incident_approved_organisations_path,
  admin_area_settings_incident_organisations_path,
  admin_area_settings_incident_not_approved_organisations_path,
} from '../../../../../../routes';
import ApprovedOrganisations from '../../../approved_organisaitons/ApprovedOrganisations';
import IncidentShowPageHeader from './IncidentShowPageHeader';

const IncidentOrganisations = (props) => {
  return (
    <ApprovedOrganisations
      id={props.id}
      loadApprovedPath={admin_area_settings_incident_approved_organisations_path}
      loadNotApprovedPath={admin_area_settings_incident_not_approved_organisations_path}
      addPath={admin_area_settings_incident_organisations_path}
      destroyPath={admin_area_settings_incident_organisation_path}
      header={
        <IncidentShowPageHeader
          id={props.id}
          title={props.title}
          isPublic={false}
          activeUrl={admin_area_settings_incident_approved_organisations_path(props.id)}
          {...props}
        />
      }
    />
  );
};

IncidentOrganisations.propTypes = {
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  visibility: PropTypes.string.isRequired,
  organisations: PropTypes.array.isRequired,
  hasObservationEvents: PropTypes.bool.isRequired,
  hasApprovedOrganisations: PropTypes.bool.isRequired,
  status: PropTypes.string.isRequired,
};

export default wrap(IncidentOrganisations);
