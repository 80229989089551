import React from "react";
import ModalLauncher from "../../util/ModalLauncher";
import MicroEventForm from "./MicroEventForm";
import PropTypes from "prop-types";

import { IntlProvider } from "react-intl";
import { translations, locale } from "../../util/i18n";

const CreateMicroEvent = (props) => {
  const modalContent = () => {
    return (
      <MicroEventForm
        url={props.createUrl}
        method={"POST"}
        defaultValues={{ scenario_id: props.scenarioId }}
      ></MicroEventForm>
    );
  };

  return (
    <IntlProvider
      locale={window._FLAIM_LOCALE}
      key={window._FLAIM_LOCALE}
      messages={translations[window._FLAIM_LOCALE]}
    >
      <ModalLauncher
        buttonId="create-micro-event"
        buttonText="admin-area.scenarios.create-micro-event"
        content={modalContent()}
        title="admin-area.scenarios.create-micro-event"
      />
    </IntlProvider>
  );
};

CreateMicroEvent.propTypes = {
  createUrl: PropTypes.string.isRequired,
};

export default CreateMicroEvent;
