import React, { useState } from "react";
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";
import ServerFormErrors from "../../util/ServerFormErrors";
import ax from "../../util/axios";
import { IntlProvider, useIntl } from "react-intl";
import { locale, translations } from "../../util/i18n";

const QuizForm = (props) => {
  const intl = useIntl();
  const [errorMessage, setErrorMessages] = useState();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues: { ...props.defaultValues } });
  const onSubmit = (data) => {
    ax({
      method: props.method,
      url: props.url,
      data: { form: data },
    })
      .then(function (response) {
        window.location = response.data.return_url;
      })
      .catch((err) => {
        setErrorMessages(err.response.data);
      });
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)} className="narrow">
        <div className="form-group">
          <label>{intl.formatMessage({ id: "contents.title-field" })}</label>
          <input className="form-control" {...register("title", { required: true })}></input>
          {errors.title?.type === "required" && (
            <div className="field-error">{intl.formatMessage({ id: "shared.forms.required" })}</div>
          )}
        </div>

        <div className="form-group">
          <label>{intl.formatMessage({ id: "contents.description-field" })}</label>
          <input className="form-control" {...register("description")}></input>
        </div>

        <div className="form-group">
          <label>{intl.formatMessage({ id: "contents.pass-percentage-field" })}</label>
          <input
            type="number"
            min="1"
            max="100"
            step="1"
            className="form-control"
            {...register("pass_percentage")}
          ></input>
        </div>

        <div className="form-group">
          <label>{intl.formatMessage({ id: "contents.visibility-field" })}</label>
          <select className="form-control" {...register("is_public")} disabled={props.org_area}>
            <option value="true">{intl.formatMessage({ id: "shared.public" })}</option>
            <option value="false">{intl.formatMessage({ id: "shared.private" })}</option>
          </select>
        </div>

        {props.defaultValues && props.defaultValues.status && (
          <div className="form-group">
            <label>{intl.formatMessage({ id: "contents.status-field" })}</label>
            <select className="form-control" {...register("status")}>
              <option value="draft">{intl.formatMessage({ id: "shared.status.draft" })}</option>
              <option value="published">{intl.formatMessage({ id: "shared.status.published" })}</option>
              <option value="archived">{intl.formatMessage({ id: "shared.status.archived" })}</option>
            </select>
          </div>
        )}

        <button type="submit" className="btn btn-primary small angled-bottom-right">
          {intl.formatMessage({ id: "shared.forms.save" })}
        </button>
      </form>

      <ServerFormErrors errorMessages={errorMessage}></ServerFormErrors>
    </div>
  );
};

QuizForm.propTypes = {
  url: PropTypes.string.isRequired,
  method: PropTypes.string.isRequired,
  defaultValues: PropTypes.object,
  orgArea: PropTypes.bool,
};

export default QuizForm;

export const WithIntlProvider = (props) => {
  return (
    <IntlProvider locale={locale} key={locale} messages={translations[locale]}>
      <QuizForm {...props} />
    </IntlProvider>
  );
};
