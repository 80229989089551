import React, { useState } from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import wrap_with_intl_provider from "../../util/wrap_with_intl_provider";

const EnrolmentLinkForm = (props) => {
  const intl = useIntl();

  const copyLink = () => {
    const eleLink = document.getElementById("enrolment-link");
    eleLink.select();
    eleLink.setSelectionRange(0, 99999);
    if (navigator.clipboard) {
      navigator.clipboard.writeText(eleLink.value);
    } else {
      console.log(`copy link: ${eleLink.value}`);
    }
  };
  return (
    <form className="enrolment-link-form">
      <div className="enrolment-link-row">
        <div className="form-group">
          <label>{intl.formatMessage({ id: "shared.link" })}</label>
          <input id="enrolment-link" className="form-control" readOnly value={props.url} />
        </div>
        <div className="form-group">
          <label>&nbsp;</label>
          <button type="button" className="form-control btn btn-primary small angled-top-left" onClick={copyLink}>
            <i className="fas fa-copy"></i>&nbsp;
            {intl.formatMessage({ id: "shared.copy" })}
          </button>
        </div>
      </div>

      <button type="button" className="btn btn-secondary small angled-bottom-right" onClick={props.handleClose}>
        <i className="fas fa-times"></i>&nbsp;
        {intl.formatMessage({ id: "shared.cancel" })}
      </button>
    </form>
  );
};

EnrolmentLinkForm.propTypes = {
  url: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default wrap_with_intl_provider(EnrolmentLinkForm);
