import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import 'moment/locale/en-au';
import { useIntl } from 'react-intl';
import { SearchBarContext } from '../../../../components/SearchBar';
import SearchBarRow from '../../../../components/SearchBar/SearchBarRow';

const PrimarySearchItems = () => {
  const intl = useIntl();
  const { register } = useContext(SearchBarContext);

  return (
    <SearchBarRow>
      <div className="form-group" style={{ flex: 1 }}>
        <label>{intl.formatMessage({ id: 'students.search.training-key-field' })}</label>
        <input className="form-control" {...register('training_key')}></input>
      </div>

      <div className="form-group" style={{ flex: 1 }}>
        <label>{intl.formatMessage({ id: 'students.search.first-name-field' })}</label>
        <input className="form-control" {...register('first_name')}></input>
      </div>

      <div className="form-group" style={{ flex: 1 }}>
        <label>{intl.formatMessage({ id: 'students.search.last-name-field' })}</label>
        <input className="form-control" {...register('last_name')}></input>
      </div>

      <div className="form-group" style={{ flex: 1 }}>
        <label>{intl.formatMessage({ id: 'students.search.email-field' })}</label>
        <input className="form-control" {...register('email')}></input>
      </div>
    </SearchBarRow>
  );
};

const SecondarySearchItems = (props) => {
  const intl = useIntl();
  const { register } = useContext(SearchBarContext);

  return (
    <SearchBarRow>
      <div className="form-group" style={{ width: '30%' }}>
        <label>{intl.formatMessage({ id: 'students.search.mobile-field' })}</label>
        <input className="form-control" {...register('mobile')}></input>
      </div>

      <div className="form-group" style={{ width: '30%' }}>
        <label>{intl.formatMessage({ id: 'students.search.organisation-field' })}</label>
        <select autoFocus className="form-control" {...register('organisation')} disabled={props.organisationId}>
          {!props.organisationId && (
            <option key="0" value="">
              {intl.formatMessage({ id: 'shared.dropdown.please-select' })}
            </option>
          )}
          {props.organisations.map((org) => (
            <option key={org.id} value={org.name} selected={props.organisationId == org.id}>
              {org.name}
            </option>
          ))}
        </select>
      </div>

      <div className="form-group" style={{ width: '30%' }}>
        <label>{intl.formatMessage({ id: 'students.search.status-field' })}</label>
        <select className="form-control" {...register('showArchived')}>
          <option value="">{intl.formatMessage({ id: 'shared.no' })}</option>
          <option value="yes">{intl.formatMessage({ id: 'shared.yes' })}</option>
        </select>
      </div>
    </SearchBarRow>
  );
};

SecondarySearchItems.propTypes = {
  organisationId: PropTypes.number.isRequired,
  organisations: PropTypes.array.isRequired,
};

export { PrimarySearchItems, SecondarySearchItems };
