import ActionWithConfirmation from '../../ActionWithConfirmation/ActionWithConfirmation';
import React, { useContext } from 'react';
import { NewCustomScenarioPageContext } from './NewCustomScenarioPage';
import ax from '../../../bundles/main/components/util/axios';
import {
  admin_area_custom_scenario_path,
  admin_area_custom_scenario_update_active_step_path,
  admin_area_scenarios_path,
  organisation_area_custom_scenario_path,
  organisation_area_scenarios_path,
  organisation_area_custom_scenario_update_active_step_path,
} from '../../../routes';
import toastr from 'toastr';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';

const STEPS = ['detail', 'environment', 'incident', 'injects', 'callout-message', 'after-action-review'];

const StepActions = ({ onSaveDraft, onNext, disabled }) => {
  const intl = useIntl();
  const [context, setContext] = useContext(NewCustomScenarioPageContext);
  const { scenario, isAdmin, activeStep } = context;
  const [customScenarioPath, scenariosPath, updateActiveStepPath] = isAdmin
    ? [admin_area_custom_scenario_path, admin_area_scenarios_path, admin_area_custom_scenario_update_active_step_path]
    : [
        organisation_area_custom_scenario_path,
        organisation_area_scenarios_path,
        organisation_area_custom_scenario_update_active_step_path,
      ];

  const onCancel = () => {
    if (scenario?.id) {
      ax({
        method: 'delete',
        url: customScenarioPath(scenario.id),
      })
        .then(() => {
          window.location = scenariosPath();
        })
        .catch((error) => {
          console.log(error);
          toastr.error(intl.formatMessage({ id: 'shared.something-wrong' }));
        });
    } else {
      window.location = scenariosPath();
    }
  };

  const onPrev = () => {
    const nextStep = STEPS[STEPS.indexOf(activeStep) - 1];
    ax({
      method: 'post',
      url: updateActiveStepPath(scenario.id),
      data: { custom_scenario_step: nextStep },
    });
    setContext({ scenario, isAdmin, activeStep: nextStep });
  };

  const isLastStep = () => activeStep == STEPS[STEPS.length - 1];

  return (
    <div className="step-actions">
      <ActionWithConfirmation
        onConfirm={onCancel}
        icon={'far fa-exclamation-triangle'}
        title={'Warning'}
        description={intl.formatMessage({ id: 'scenarios.new-custom-scenario.delete-confirm-description' })}
        skipConfirmation={!scenario?.id}
      >
        <a className='cancel-link'><i className="fas fa-times" />&nbsp;{intl.formatMessage({ id: 'shared.cancel' })}</a>
      </ActionWithConfirmation>
      <button type="button" className="btn btn-tertiary small" disabled={disabled} onClick={onSaveDraft}>
        <i className="fas fa-floppy-disk" />&nbsp;
        {intl.formatMessage({ id: 'scenarios.new-custom-scenario.save-draft' })}
      </button>
      {activeStep != 'detail' && (
        <ActionWithConfirmation
          onConfirm={onPrev}
          icon={'far fa-exclamation-triangle'}
          title={intl.formatMessage({ id: 'shared.warning' })}
          description={intl.formatMessage({ id: 'scenarios.new-custom-scenario.prev-confirm-description' })}
          subTitle={intl.formatMessage({ id: 'scenarios.new-custom-scenario.prev-confirm-subtitle' })}
        >
          <button className="btn btn-tertiary small">
            {intl.formatMessage({ id: 'scenarios.new-custom-scenario.prev' })}
          </button>
        </ActionWithConfirmation>
      )}

      {isLastStep() ? (
        <ActionWithConfirmation
          onConfirm={onNext}
          icon={'far fa-upload'}
          title={intl.formatMessage({ id: 'scenarios.new-custom-scenario.publish-confirm.title' })}
          description={intl.formatMessage({ id: 'scenarios.new-custom-scenario.publish-confirm.description' })}
        >
          <button type="button" className="btn btn-primary angled-bottom-right small" disabled={disabled}>
            <i className="fas fa-book-circle-arrow-right" />&nbsp;
            {intl.formatMessage({ id: 'shared.publish' })}
          </button>
        </ActionWithConfirmation>
      ) : (
        <button type="button" className="btn btn-primary angled-bottom-right small" disabled={disabled} onClick={onNext}>
          {intl.formatMessage({ id: 'scenarios.new-custom-scenario.next' })}
        </button>
      )}
    </div>
  );
};

StepActions.propTypes = {
  onSaveDraft: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};
export default StepActions;
