import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { IntlProvider, useIntl } from 'react-intl';
import { translations } from '../util/i18n';
import Table from '../../../../components/Table';
import StatusLabel from '../../../../components/StatusLabel';
import Card from '../../../../components/Card';
import Message from '../../../../components/Message';
import ax from '../util/axios';

const SimulationMicroEvents = ({ microEventsUrl, isCaptured, notCapturedText, notRecordedText, criticalText }) => {
  const intl = useIntl();
  const [pagination, setPagination] = useState({});
  const [microEvents, setMicroEvents] = useState([]);
  const fetchMicroEvents = async (page = 1) => {
    const response = await ax.get(`${microEventsUrl}?page=${page}`);
    setPagination(response.data.pagination);
    setMicroEvents(response.data.microEvents);
  };
  const microEventsColumns = [
    {
      accessor: 'title',
      className: 'title',
    },
    {
      accessor: 'message',
      className: 'outcome-message',
    },
    {
      accessor: 'recordTime',
      className: 'record-time',
    },
    {
      accessor: 'status',
      className: 'status',
    },
  ];
  const mappedMicroEvents = microEvents.map((event) => ({
    ...event,
    title: (
      <span>
        {event.title}
        {event.is_critical && <span className="critical">&nbsp;{criticalText}</span>}
      </span>
    ),
    message: event.message ? (
      event.message
    ) : (
      <span className="not-recorded">
        <i className="fa-regular fa-ban" />
        &nbsp;{notRecordedText}
      </span>
    ),
    status: <StatusLabel status={event.pass ? 'passed' : 'failed'} />,
    recordTime: event.record_time && (
      <span>
        <i className="fa-solid fa-clock-five" />
        &nbsp;{intl.formatMessage({ id: 'shared.achieved-at' })} {event.record_time}
      </span>
    ),
  }));

  useEffect(() => {
    fetchMicroEvents();
  }, []);

  return (
    <div className="simulation-events">
      <div className="events-filter">
        <h4>{intl.formatMessage({ id: 'admin-area.simulations.micro-events' })}</h4>
      </div>
      <div>
        {!isCaptured && (
          <div className="version-warning-container">
            <Message type="warning" message={notCapturedText} />
          </div>
        )}
        {microEvents.length > 0 && (
          <Card>
            <Table
              header={false}
              items={mappedMicroEvents}
              columns={microEventsColumns}
              pagination={pagination}
              onPageChange={fetchMicroEvents}
            />
          </Card>
        )}
      </div>
    </div>
  );
};

SimulationMicroEvents.propTypes = {
  microEventsUrl: PropTypes.string.isRequired,
  notCapturedText: PropTypes.string.isRequired,
  notRecordedText: PropTypes.string.isRequired,
  isCaptured: PropTypes.bool.isRequired,
  criticalText: PropTypes.string.isRequired,
};

export default SimulationMicroEvents;

export const WithIntlProvider = (props) => {
  return (
    <IntlProvider
      locale={window._FLAIM_LOCALE}
      key={window._FLAIM_LOCALE}
      messages={translations[window._FLAIM_LOCALE]}
    >
      <SimulationMicroEvents {...props} />
    </IntlProvider>
  );
};
