import React from 'react';
import RowMenu from '../util/RowMenu';
import PropTypes from 'prop-types';
import { IntlProvider } from 'react-intl';
import { translations } from '../util/i18n';
import PostLinkWithCustomConfirm from '../util/PostLinkWithCustomConfirm';
import PostLink from '../util/PostLink';

const PlaylistSettingsMenu = (props) => {
  const options = [];

  if (props.canModify) {
    options.push({
      title: 'shared.edit',
      onClick: props.onEdit,
    });

    options.push({
      title: 'shared.delete',
      component: PostLinkWithCustomConfirm,
      params: {
        method: 'DELETE',
        url: props.deleteUrl,
        confirmMessage: 'playlists.delete.modal-message',
        confirmTitle: 'playlists.delete.modal-title',
        refresh: false,
      },
    });
  }

  if (props.isAdmin) {
    options.push({
      title: 'contents.settings.duplicate',
      component: PostLinkWithCustomConfirm,
      params: {
        method: 'POST',
        url: props.duplicateUrl,
        confirmMessage: 'playlists.duplicate.modal-message',
        confirmTitle: 'playlists.duplicate.modal-title',
        refresh: false,
        hasFormFields: true,
        formFields: [
          {
            fieldName: 'visibility',
            fieldType: 'select',
            label: 'playlists.search.visibility-field',
            options: [
              {
                value: 'public',
                label: 'shared.public',
              },
              {
                value: 'private',
                label: 'shared.private'
              },
            ],
          }
        ],
      },
    });
  } else {
    options.push({
      title: "contents.settings.duplicate",
      component: PostLink,
      params: {
        url: props.duplicateUrl,
        method: 'POST',
        refresh: false
      },
    });
  }

  return (
    <IntlProvider
      locale={window._FLAIM_LOCALE}
      key={window._FLAIM_LOCALE}
      messages={translations[window._FLAIM_LOCALE]}
    >
      <RowMenu
        classNameForButton="btn-secondary angled-top-left playlist-settings-button"
        classNameForDropdown={'for-btn-secondary'}
        title={'shared.settings'}
        options={options}
        leftIcon="fa-cog"
        noArrayDownIcon={true}
      ></RowMenu>
    </IntlProvider>
  );
};

PlaylistSettingsMenu.propTypes = {
  deleteUrl: PropTypes.string.isRequired,
  duplicateUrl: PropTypes.string.isRequired,
  onEdit: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  canModify: PropTypes.bool.isRequired,
};

export default PlaylistSettingsMenu;
