import React, { useState } from "react";
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";
import ServerFormErrors from "../../util/ServerFormErrors";
import ax from "../../util/axios";
import { useIntl } from "react-intl";

const MicroEventForm = (props) => {
  const intl = useIntl();
  const [errorMessage, setErrorMessages] = useState();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues: { ...props.defaultValues } });
  const onSubmit = (data) => {
    ax({
      method: props.method,
      url: props.url,
      data: { form: data },
    })
      .then(function () {
        window.location = window.location.href;
      })
      .catch((err) => {
        setErrorMessages(err.response.data);
      });
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-group">
          <label>{intl.formatMessage({ id: "admin-area.scenarios.micro-event.event-id-field" })}</label>
          <input className="form-control" {...register("event_id", { required: true })}></input>
          {errors.event_id?.type === "required" && (
            <div className="field-error">{intl.formatMessage({ id: "shared.forms.required" })}</div>
          )}
        </div>

        <div className="form-group">
          <label>{intl.formatMessage({ id: "admin-area.scenarios.micro-event.title-field" })}</label>
          <input className="form-control" {...register("title", { required: true })}></input>
          {errors.title?.type === "required" && (
            <div className="field-error">{intl.formatMessage({ id: "shared.forms.required" })}</div>
          )}
        </div>

        <div className="form-group">
          <label>{intl.formatMessage({ id: "admin-area.scenarios.micro-event.default-success-message-field" })}</label>
          <textarea
            rows="5"
            className="form-control"
            {...register("default_success_message", { required: true })}
          ></textarea>
          {errors.default_success_message?.type === "required" && (
            <div className="field-error">{intl.formatMessage({ id: "shared.forms.required" })}</div>
          )}
        </div>

        <div className="form-group">
          <label>{intl.formatMessage({ id: "admin-area.scenarios.micro-event.default-failure-message-field" })}</label>
          <textarea
            rows="5"
            className="form-control"
            {...register("default_failure_message", { required: true })}
          ></textarea>
          {errors.default_failure_message?.type === "required" && (
            <div className="field-error">{intl.formatMessage({ id: "shared.forms.required" })}</div>
          )}
        </div>

        <div className="form-group">
          <label>{intl.formatMessage({ id: "units.status-field" })}</label>
          <select className="form-control" {...register("status")}>
            <option value="active">{intl.formatMessage({ id: "shared.status.active" })}</option>
            <option value="inactive">{intl.formatMessage({ id: "shared.status.inactive" })}</option>
          </select>
        </div>

        <button type="submit" className="btn btn-primary small angled-bottom-right">
          <i className="fas fa-check" />&nbsp;
          {intl.formatMessage({ id: "shared.forms.confirm" })}
        </button>
      </form>

      <ServerFormErrors errorMessages={errorMessage}></ServerFormErrors>
    </div>
  );
};

MicroEventForm.propTypes = {
  url: PropTypes.string.isRequired,
  method: PropTypes.string.isRequired,
  defaultValues: PropTypes.object,
};

export default MicroEventForm;
