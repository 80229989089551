import React, { useState } from 'react';
import ImageUpload from './ImageUpload';
import ImageGallery from './ImageGallery';
import PropTypes from 'prop-types';

const ImageUploadWithGallery = ({
  originImages,
  originPrimaryIndex,
  onImagesChange,
  onDeleteOriginImage,
  onPrimaryIndexChange,
}) => {
  const [deletedIds, setDeletedIds] = useState([]);
  const [files, setFiles] = useState([]);
  const [fileUrls, setFileUrls] = useState([]);
  const [imageUrls, setImageUrls] = useState(originImages.map((img) => img.url));
  const [primary, setPrimary] = useState(typeof originPrimaryIndex === 'number' ? originPrimaryIndex : -1);

  const fileListToImageUrls = (files) => {
    const srcs = [];
    for (let i = 0; i < files.length; i++) {
      srcs.push(URL.createObjectURL(files[i]));
    }
    return srcs;
  };

  const fileListToArray = (files) => {
    const fileArray = [];
    for (let i = 0; i < files.length; i++) {
      fileArray.push(files[i]);
    }

    return fileArray;
  };

  const handleImageChange = (selectedFiles) => {
    const newFileUrls = fileListToImageUrls(selectedFiles);
    setFileUrls([...fileUrls, ...newFileUrls]);

    const newFiles = [...files, ...fileListToArray(selectedFiles)];
    setFiles(newFiles);

    setImageUrls([...imageUrls, ...newFileUrls]);

    if (primary < 0) {
      handlePrimaryIndexChange(0);
    }

    onImagesChange(newFiles);
  };

  const handlePrimaryIndexChange = (index) => {
    setPrimary(index);
    onPrimaryIndexChange && onPrimaryIndexChange(index);
  };

  const handleDelete = (url) => {
    const originImage = originImages.find((img) => img.url == url);
    if (originImage) {
      const newDeletedIds = [...deletedIds, originImage.id];
      setDeletedIds(newDeletedIds);
      onDeleteOriginImage(newDeletedIds);
    } else {
      const index = fileUrls.indexOf(url);
      setFileUrls(fileUrls.filter((url, i) => i != index));

      const newFiles = files.filter((file, i) => i != index);
      setFiles(newFiles);
      onImagesChange(newFiles);
    }
    setImageUrls(
      imageUrls.filter((imgUrl, index) => {
        if (imgUrl === url) {
          if (index == primary) {
            handlePrimaryIndexChange(0);
          } else if (index < primary) {
            handlePrimaryIndexChange(primary - 1);
          }
        }

        return imgUrl != url;
      }),
    );
  };
  return (
    <div>
      <ImageUpload onImagesChange={handleImageChange}></ImageUpload>
      <ImageGallery
        imageUrls={imageUrls}
        onDelete={handleDelete}
        onSetPrimaryIndex={handlePrimaryIndexChange}
        primaryIndex={primary}
      />
    </div>
  );
};

ImageUploadWithGallery.propTypes = {
  originImages: PropTypes.arrayOf(PropTypes.object),
  originPrimaryIndex: PropTypes.number,
  onImagesChange: PropTypes.func.isRequired,
  onDeleteOriginImage: PropTypes.func.isRequired,
  onPrimaryIndexChange: PropTypes.func,
};

ImageUploadWithGallery.defaultProps = {
  originImages: [],
};

export default ImageUploadWithGallery;
