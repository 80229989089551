import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import wrap from '../../../util/wrap_with_intl_provider';
import PageHeader from '../../../../../../components/PageHeader';
import EnvironmentForm from './EnvironmentForm';

const EnvironmentEdit = (props) => {
  const intl = useIntl();

  return (
    <div>
      <PageHeader
        previousPages={[
          {
            link: props.environmentsUrl,
            title: intl.formatMessage({ id: 'admin-area.settings.environment.index.title' }),
          },
          {
            link: props.environmentUrl,
            title: props.defaultValues.title,
          },
        ]}
        title={intl.formatMessage({ id: 'admin-area.settings.environment.edit.title' })}
        pageDivider={true}
      />
      <EnvironmentForm url={props.environmentUrl} method={"PUT"} defaultValues={{ ...props.defaultValues }} isDarkTheme={props.isDarkTheme} />
    </div>
  );
};

EnvironmentEdit.propTypes = {
  environmentsUrl: PropTypes.string.isRequired,
  environmentUrl: PropTypes.string.isRequired,
  defaultValues : PropTypes.object.isRequired,
  isDarkTheme: PropTypes.bool
};

export default wrap(EnvironmentEdit);
