import React from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';

const SearchContentForCourseForm = (props) => {
  const intl = useIntl();
  const { register, handleSubmit } = useForm({ defaultValues: { ...props.defaultValues } });
  const onSubmit = (data) => {
    props.onSubmit(data);
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="filters">
          <div className="form-group">
            <label>{intl.formatMessage({ id: 'courses.content-title-field' })}</label>
            <input className="form-control" {...register('title')}></input>
          </div>

          {
            props.lmsContentCourseAccess && (
              <div className="form-group">
                <label>{intl.formatMessage({ id: 'courses.content-type-field' })}</label>
                <select className="form-control" {...register('type')}>
                  <option value="">{intl.formatMessage({ id: 'shared.dropdown.please-select' })}</option>
                  <option value="quiz">{intl.formatMessage({ id: 'courses.content-type.quiz' })}</option>
                  <option value="presentation">{intl.formatMessage({ id: 'courses.content-type.presentation' })}</option>
                  <option value="scenario">{intl.formatMessage({ id: 'courses.content-type.scenario' })}</option>
                </select>
              </div>
            )
          }
        </div>

        <button type="submit" className="btn btn-primary small angled-top-left">
          <i className="fas fa-filter"></i>&nbsp;
          {intl.formatMessage({ id: 'shared.forms.apply_filters' })}
        </button>
      </form>
    </div>
  );
};

SearchContentForCourseForm.propTypes = {
  defaultValues: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  lmsContentCourseAccess: PropTypes.bool.isRequired
};

export default SearchContentForCourseForm;
