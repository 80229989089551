import React from "react";

import { IntlProvider } from "react-intl";
import { translations } from "./i18n";

const wrap = (Component) => {
  const WrapperWithIntlProvider = (props) => {
    return (
      <IntlProvider
        locale={window._FLAIM_LOCALE}
        key={window._FLAIM_LOCALE}
        messages={translations[window._FLAIM_LOCALE]}
      >
        <Component {...props}></Component>
      </IntlProvider>
    );
  };

  return WrapperWithIntlProvider;
};

export default wrap;
