import React from "react";
import RowMenu from "../../../util/RowMenu";
import PostLink from "../../../util/PostLink";
import Modal from "../../../util/Modal";
import EditEventCategoryModal from "./EditEventCategoryModal";
import PropTypes from "prop-types";

import { IntlProvider } from "react-intl";
import { translations, locale } from "../../../util/i18n";

const AdminEventCategoryRowMenu = (props) => {
  const options = [
    {
      title: "admin-area.settings.event-category.dropdown.edit",
      component: Modal,
      params: {
        title: "admin-area.settings.event-category.dropdown.edit",
        content: (
          <EditEventCategoryModal
            url={props.updateEventCategoryUrl}
            eventCategoryDetails={props.eventCategoryDetails}
          />
        ),
      },
    },
    {
      title: "admin-area.settings.event-category.dropdown.delete",
      component: PostLink,
      params: {
        method: "delete",
        url: props.deleteEventCategoryUrl,
        refresh: true,
        confirmMessage: "admin-area.settings.event-category.confirmation-message",
        notice: "admin-area.settings.event-category.delete-complete",
      },
    },
  ];

  return (
    <IntlProvider
      locale={window._FLAIM_LOCALE}
      key={window._FLAIM_LOCALE}
      messages={translations[window._FLAIM_LOCALE]}
    >
      <RowMenu options={options}></RowMenu>
    </IntlProvider>
  );
};

AdminEventCategoryRowMenu.propTypes = {
  deleteEventCategoryUrl: PropTypes.string,
  updateEventCategoryUrl: PropTypes.string,
  eventCategoryDetails: PropTypes.object,
};

export default AdminEventCategoryRowMenu;
