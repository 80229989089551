import React from 'react';
import PropTypes from 'prop-types';
import {
  admin_area_online_courses_course_approved_organisations_path,
  admin_area_online_courses_course_not_approved_organisations_path,
  admin_area_online_courses_course_organisation_path,
  admin_area_online_courses_course_organisations_path,
} from '../../../../../../routes';
import ApprovedOrganisations from '../../../approved_organisaitons/ApprovedOrganisations';

const CourseOrganisations = (props) => {
  return (
    <ApprovedOrganisations
      id={props.id}
      loadApprovedPath={admin_area_online_courses_course_approved_organisations_path}
      loadNotApprovedPath={admin_area_online_courses_course_not_approved_organisations_path}
      addPath={admin_area_online_courses_course_organisations_path}
      destroyPath={admin_area_online_courses_course_organisation_path}
      showAuthor
    />
  );
};

CourseOrganisations.propTypes = {
  id: PropTypes.number.isRequired,
};

export default CourseOrganisations;
