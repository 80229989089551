const add_submit_overlay = () => {
  var overlay = document.createElement("div");
  overlay.innerHTML = "<i class='fas fa-spinner fa-spin'></i>";
  overlay.className = "submit-overlay";
  document.body.appendChild(overlay);
};

const remove_submit_overlay = () => {
  const overlay = document.querySelector(".submit-overlay");
  document.body.removeChild(overlay);
};

export { add_submit_overlay, remove_submit_overlay };
