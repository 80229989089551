import React from 'react';
import PropTypes from 'prop-types';
import Image from './Image';
import './ImageAlbum.scss';

import wrap from '../../bundles/main/components/util/wrap_with_intl_provider';

const ImageAlbum = (props) => {
  const images = new Array(...props.images);
  const primaryImage = images.splice(props.primaryImageIndex, 1)[0];

  return (
    <div className="image-album">
      <Image imageUrl={primaryImage.url} primary={true} />
      <div className="image-container">
        {images.map((image) => (
          <Image key={image.id} imageUrl={image.url} />
        ))}
      </div>
    </div>
  );
};

ImageAlbum.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
    }),
  ).isRequired,
  primaryImageIndex: PropTypes.number.isRequired,
};

export default ImageAlbum;

export const WrappedImageAlbum = wrap(ImageAlbum);
