import React  from 'react';
import './Checkbox.scss';

const Checkbox = (props) => (
  <label className="checkbox">
    <input type="checkbox" {...props} />
    <span className="checkmark"/>
  </label>
);

export default Checkbox;
