const convertToFormData = (data, file_field_keys) => {
  const formData = new FormData();
  for (const [key, value] of Object.entries(data)) {
    if (!file_field_keys.includes(key)) {
      const nonNullValue = value === null ? '' : value;
      formData.append(`form[${key}]`, nonNullValue);
    }
  }
  file_field_keys.forEach((key) => {
    const files = data[key];
    if (files) {
      if (files.length == 1) {
        formData.append(`form[${key}]`, files[0]);
      } else {
        for (let i = 0; i < files.length; i++) {
          const nonNullValue = files[i] === null ? '' : files[i];
          formData.append(`form[${key}][]`, nonNullValue);
        }
      }
    }
  });

  return formData;
};

export default convertToFormData;
