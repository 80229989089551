import React from 'react';
import PropTypes from 'prop-types';

import './EmptyStateHolder.scss';
import icon from './variable_icon.png';

const EmptyStateHolder = ({ heading, description, buttonTitle, onButtonClick, button }) => {
  return (
    <div className="empty-state-holder">
      <img className="variable-icon" src={icon} />
      <div className="heading">{heading}</div>
      <div className="description">{description}</div>
      <div>
        {button ? button : (<button className="btn btn-primary angled-top-left" onClick={onButtonClick}>
          <i className="far fa-add" />
          &nbsp;&nbsp;
          {buttonTitle}
        </button>)}
      </div>
    </div>
  );
};

EmptyStateHolder.propTypes = {
  heading: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  buttonTitle: PropTypes.string,
  onButtonClick: PropTypes.func,
  button: PropTypes.node,
};

EmptyStateHolder.defaultProps = {
  heading: 'No variables yet',
  description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elementum at metus id ullamcorper.',
  buttonTitle: 'Create New Variable',
  onButtonClick: () => {},
};
export default EmptyStateHolder;
