import { useIntl } from 'react-intl';
import React, { useContext, useEffect, useState } from 'react';
import { ModalContext } from '../util/Modal';
import ax from '../util/axios';
import toastr from 'toastr';
import SearchBar, { SearchBarContext } from '../../../../components/SearchBar';
import Table from '../../../../components/Table';
import FlexRight from '../../../../components/utils/FlexRight';
import PropTypes from 'prop-types';
import SearchBarRow from '../../../../components/SearchBar/SearchBarRow';

import _ from 'lodash';

const PrimarySearchItems = () => {
  const intl = useIntl();
  const { register } = useContext(SearchBarContext);

  return (
    <SearchBarRow>
      <div className="form-group" style={{ flex: 1 }}>
        <label>{intl.formatMessage({ id: 'approved-organisations.fields.name' })}</label>
        <input className="form-control" {...register('name')}></input>
      </div>
    </SearchBarRow>
  );
};

const AddMultiApprovedOrganisationModal = ({ id, loadNotApprovedPath, addPath, confirmAddText }) => {
  const intl = useIntl();
  const [data, setData] = useState();
  const [selectedRows, setSelectedRows] = useState([]);
  const [searchParams, setSearchParams] = useState({});
  const modalContext = useContext(ModalContext);

  const loadOrganisations = (page = 1) => {
    let url = loadNotApprovedPath(id, { page: page, ...searchParams });
    ax({
      method: 'get',
      url: url,
    })
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        toastr['error'](error);
      });
  };

  useEffect(() => {
    loadOrganisations();
  }, [searchParams]);

  const onPageChange = (page) => {
    loadOrganisations(page);
  };

  const onRowSelect = ({ selected, id }) => {
    if (selected) {
      setSelectedRows([...selectedRows, id]);
    } else {
      setSelectedRows(selectedRows.filter((item) => item != id));
    }
  };

  const onSelectAll = (selected) => {
    const ids = data.organisations.map((org) => org.id);
    if (selected) {
      setSelectedRows(_.uniq([...selectedRows, ...ids]));
    } else {
      setSelectedRows(selectedRows.filter((id) => !ids.includes(id)));
    }
  };

  const onAddOrganisations = () => {
    if (confirm(confirmAddText)) {
      ax({
        method: 'post',
        url: addPath(id),
        data: { ids: selectedRows },
      })
        .then(() => {
          window.location.reload();
        })
        .catch((error) => {
          toastr['error'](error);
        });
    }
  };

  const columns = [
    {
      header: intl.formatMessage({ id: 'approved-organisations.fields.name' }),
      accessor: 'name',
    },
  ];

  const onSearch = (data) => {
    setSearchParams(data);
    setSelectedRows([]);
  };

  if (!data) {
    return;
  } else {
    const { organisations, pagination } = data;
    const mappedOrganisations = organisations.map((org) => ({ ...org, selected: selectedRows.includes(org.id) }));
    return (
      <div className="add-organisations">
        <SearchBar primaryItems={<PrimarySearchItems />} onSearch={onSearch} />
        <Table
          columns={columns}
          items={mappedOrganisations}
          pagination={pagination}
          onPageChange={onPageChange}
          onRowSelect={onRowSelect}
          rowSelect={true}
          onSelectAll={onSelectAll}
        ></Table>
        <FlexRight className="gap-1 margin-0.5">
          <button className="btn btn-secondary angled-top-left" onClick={modalContext.closeModal}>
            {intl.formatMessage({ id: 'shared.cancel' })}
          </button>

          <button
            className="btn btn-primary angled-bottom-right"
            disabled={!selectedRows.length}
            data-disable-with="..."
            onClick={onAddOrganisations}
          >
            {intl.formatMessage({ id: 'approved-organisations.add-approved-organisations' })}{' '}
            {!!selectedRows.length && <>({selectedRows.length})</>}
          </button>
        </FlexRight>
      </div>
    );
  }
};

AddMultiApprovedOrganisationModal.propTypes = {
  id: PropTypes.number.isRequired,
  loadNotApprovedPath: PropTypes.func.isRequired,
  addPath: PropTypes.func.isRequired,
  confirmAddText: PropTypes.string.isRequired,
};

export default AddMultiApprovedOrganisationModal;
