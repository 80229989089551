import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { SearchBarContext } from '../../../../components/SearchBar';
import SearchBarRow from '../../../../components/SearchBar/SearchBarRow';

const PrimarySearchItems = () => {
  const intl = useIntl();
  const { register } = useContext(SearchBarContext);

  return (
    <SearchBarRow>
      <div className="form-group" style={{ flex: 1 }}>
        <label>{intl.formatMessage({ id: 'contents.title-field' })}</label>
        <input className="form-control" {...register('title')}></input>
      </div>

      <div className="form-group" style={{ flex: 1 }}>
        <label>{intl.formatMessage({ id: 'contents.kind-field' })}</label>
        <select className="form-control" {...register('kind')}>
          <option value="">{intl.formatMessage({ id: 'shared.dropdown.please-select' })}</option>
          <option value="quiz">{intl.formatMessage({ id: 'contents.kind.quiz' })}</option>
          <option value="presentation">{intl.formatMessage({ id: 'contents.kind.presentation' })}</option>
          <option value="articulate">{intl.formatMessage({ id: 'contents.kind.articulate' })}</option>
        </select>
      </div>

      <div className="form-group" style={{ flex: 1 }}>
        <label>{intl.formatMessage({ id: 'contents.status-field' })}</label>
        <select className="form-control" {...register('status')}>
          <option value="">{intl.formatMessage({ id: 'shared.dropdown.please-select' })}</option>
          <option value="draft">{intl.formatMessage({ id: 'shared.status.draft' })}</option>
          <option value="published">{intl.formatMessage({ id: 'shared.status.published' })}</option>
          <option value="archived">{intl.formatMessage({ id: 'shared.status.archived' })}</option>
        </select>
      </div>

      <div className="form-group" style={{ flex: 1 }}>
        <label>{intl.formatMessage({ id: 'contents.visibility-field' })}</label>
        <select className="form-control" {...register('is_public')}>
          <option value="">{intl.formatMessage({ id: 'shared.dropdown.please-select' })}</option>
          <option value="true">{intl.formatMessage({ id: 'shared.public' })}</option>
          <option value="false">{intl.formatMessage({ id: 'shared.private' })}</option>
        </select>
      </div>
    </SearchBarRow>
  );
};

const SecondarySearchItems = (props) => {
  const intl = useIntl();
  const { register, getValues, setValue } = useContext(SearchBarContext);

  return (
    <>
      <SearchBarRow>
        <div className="form-group form-check" style={{ width: '30%' }}>
          <label htmlFor="show_archived">
            <input
              type="checkbox"
              name="show_archived"
              id="show_archived"
              defaultChecked={getValues('show_archived') == 'true'}
              onChange={(e) =>
                e.currentTarget.checked ? setValue('show_archived', 'true') : setValue('show_archived', null)
              }
            />
            {intl.formatMessage({ id: 'courses.show-archived-field' })}
          </label>
        </div>
      </SearchBarRow>
      {props.organisations && (
        <div className="searchbar-secondary-items extended">
          <SearchBarRow>
            <div className="form-group" style={{ width: '30%' }}>
              <label>{intl.formatMessage({ id: 'contents.organisation-field' })}</label>
              <select className="form-control" {...register('organisation_id')}>
                <option key="0" value="">
                  {intl.formatMessage({ id: 'shared.dropdown.please-select' })}
                </option>
                {props.organisations.map((org) => {
                  return (
                    <option key={org.id} value={org.id}>
                      {org.name}
                    </option>
                  );
                })}
              </select>
            </div>
          </SearchBarRow>
        </div>
      )}
    </>
  );
};

SecondarySearchItems.propTypes = {
  organisations: PropTypes.array.isRequired,
};

export { PrimarySearchItems, SecondarySearchItems };
