import React from "react";
import CustomReportFrom from "./CustomReportForm";
import PropTypes from "prop-types";

import { IntlProvider } from "react-intl";
import { translations, locale } from "../../util/i18n";

const EditUserModal = (props) => {
  return (
    <IntlProvider
      locale={window._FLAIM_LOCALE}
      key={window._FLAIM_LOCALE}
      messages={translations[window._FLAIM_LOCALE]}
    >
      <CustomReportFrom url={props.url} method={"PUT"} defaultValues={{ ...props.details }}></CustomReportFrom>
    </IntlProvider>
  );
};

EditUserModal.propTypes = {
  url: PropTypes.string.isRequired,
  details: PropTypes.object,
};

export default EditUserModal;
