import React from "react";
import ModalLauncher from "../../../util/ModalLauncher";
import ObservationEventForm from "./ObservationEventForm";
import PropTypes from "prop-types";

import { IntlProvider } from "react-intl";
import { translations, locale } from "../../../util/i18n";

const CreateObservationEventModal = (props) => (
  <IntlProvider locale={window._FLAIM_LOCALE} key={window._FLAIM_LOCALE} messages={translations[window._FLAIM_LOCALE]}>
    <ModalLauncher
      buttonId="create-observation-event"
      buttonText="admin-area.settings.observation-event.create"
      content={<ObservationEventForm url={props.createUrl} method={"POST"}></ObservationEventForm>}
      title="admin-area.settings.observation-event.create"
    />
  </IntlProvider>
);

CreateObservationEventModal.propTypes = {
  createUrl: PropTypes.string.isRequired,
};

export default CreateObservationEventModal;
