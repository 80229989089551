import React from 'react';
import ModalLauncher from '../../util/ModalLauncher';
import AddEventForm from './AddEventForm';
import PropTypes from 'prop-types';

import { IntlProvider } from 'react-intl';
import { translations } from '../../util/i18n';

const AddEventModal = (props) => {
  const modalContent = () => {
    return (
      <AddEventForm
        url={props.addEventUrl}
        eventType={props.microEvent ? 'micro-event' : 'performance-event'}
        events={props.events}
        method="POST"
      ></AddEventForm>
    );
  };

  return (
    <IntlProvider
      locale={window._FLAIM_LOCALE}
      key={window._FLAIM_LOCALE}
      messages={translations[window._FLAIM_LOCALE]}
    >
      <ModalLauncher
        buttonId={props.microEvent ? 'add-micro-event' : 'add-performance-event'}
        buttonText={
          props.microEvent ? 'admin-area.scenarios.add-micro-event' : 'admin-area.scenarios.add-performance-event'
        }
        content={modalContent()}
        title={props.microEvent ? 'admin-area.scenarios.add-micro-event' : 'admin-area.scenarios.add-performance-event'}
      />
    </IntlProvider>
  );
};

AddEventModal.propTypes = {
  addEventUrl: PropTypes.string.isRequired,
  events: PropTypes.array.isRequired,
  microEvent: PropTypes.bool.isRequired,
};

AddEventModal.defaultProps = {
  microEvent: true,
};

export default AddEventModal;
