import React, { useState } from 'react';
import Modal from './Modal';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

const ModalLauncher = (props) => {
  const intl = useIntl();
  const [openModal, setOpenModal] = useState(false);
  return (
    <div>
      <a
        id={props.buttonId}
        className={props.showAsLink ? '' : 'btn btn-primary angled-top-left'}
        onClick={() => {
          setOpenModal(true);
        }}
      >
        {props.buttonIcon && (
          <>
            <i className={props.buttonIcon} />
            &nbsp;&nbsp;
          </>
        )}
        {intl.formatMessage({ id: props.buttonText })}
      </a>
      {openModal === true && (
        <Modal
          open={openModal}
          onClose={() => {
            setOpenModal(false);
          }}
          title={props.title}
          content={props.content}
          classNameForContent={props.classNameForContent}
          modalVariation={props.modalVariation}
        ></Modal>
      )}
    </div>
  );
};

ModalLauncher.propTypes = {
  buttonId: PropTypes.string,
  buttonText: PropTypes.string.isRequired,
  buttonIcon: PropTypes.string,
  title: PropTypes.string.isRequired,
  content: PropTypes.object.isRequired,
  classNameForContent: PropTypes.string,
  modalVariation: PropTypes.oneOf(['wide']),
  showAsLink: PropTypes.bool,
};

export default ModalLauncher;
