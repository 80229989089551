import React from "react";
import ModalLauncher from "../../../util/ModalLauncher";
import PerformanceEventForm from "./PerformanceEventForm";
import PropTypes from "prop-types";

import { IntlProvider } from "react-intl";
import { translations, locale } from "../../../util/i18n";

const CreatePerformanceEvent = (props) => {
  const modalContent = () => {
    return <PerformanceEventForm url={props.createUrl} method={"POST"}></PerformanceEventForm>;
  };

  return (
    <IntlProvider
      locale={window._FLAIM_LOCALE}
      key={window._FLAIM_LOCALE}
      messages={translations[window._FLAIM_LOCALE]}
    >
      <ModalLauncher
        buttonId="create-performance-event"
        buttonText="admin-area.event-library.create-performance-event"
        content={modalContent()}
        title="admin-area.event-library.create-performance-event"
      />
    </IntlProvider>
  );
};

CreatePerformanceEvent.propTypes = {
  createUrl: PropTypes.string.isRequired,
};

export default CreatePerformanceEvent;
