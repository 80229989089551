import React from 'react';
import PropTypes from 'prop-types';

/**
 * We need to display simulation outcome in a card.
 */
const OutcomeCard = ({ type, outcomeText, secondaryText, icon }) => {
  const icon_for_type = (type) => {
    if (icon) {
      return icon;
    }
    switch (type) {
      case 'success':
        return 'fa-circle-check';
      case 'unsuccess':
        return 'fa-circle-xmark';
      default:
        return 'fa-gauge-high';
    }
  };

  return (
    <div className="indicator-panel">
      <div className="counter-icon"><i className={`fat ${icon_for_type(type)} fa-3x`}></i></div>
      <div className="counter-info-wrapper">
        <div className="counter-info">
          <div className="counter">
            {outcomeText}
          </div>
          <div className="counter-text">
            {secondaryText}
          </div>
        </div>
      </div>
    </div>
  );
};

OutcomeCard.propTypes = {
  type: PropTypes.oneOf(['success', 'unsuccess', 'default']),
  outcomeText: PropTypes.string.isRequired,
  secondaryText: PropTypes.string.isRequired,
  icon: PropTypes.string,
};

export default OutcomeCard;
