import React from 'react';
import RowMenu from '../util/RowMenu';
import PropTypes from 'prop-types';

import { IntlProvider } from 'react-intl';
import { translations } from '../util/i18n';
import PostLinkWithCustomConfirm from '../util/PostLinkWithCustomConfirm';

const PlaylistRowMenu = (props) => {
  const options = [];

  options.push({
    title: 'playlists.delete-title',
    component: PostLinkWithCustomConfirm,
    params: {
      method: 'DELETE',
      url: props.removeUrl,
      confirmMessage: 'playlists.delete.modal-message',
      confirmTitle: 'playlists.delete.modal-title',
    },
  });

  return (
    <IntlProvider
      locale={window._FLAIM_LOCALE}
      key={window._FLAIM_LOCALE}
      messages={translations[window._FLAIM_LOCALE]}
    >
      <RowMenu options={options}></RowMenu>
    </IntlProvider>
  );
};

PlaylistRowMenu.propTypes = {
  removeUrl: PropTypes.string,
};

export default PlaylistRowMenu;
