import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import ServerFormErrors from '../../../util/ServerFormErrors';
import ax from '../../../util/axios';
import { useIntl } from 'react-intl';
import toastr from 'toastr';
import UnitTypeSelect from '../../units/UnitTypeSelect';

const ObservationEventForm = (props) => {
  const intl = useIntl();
  const [errorMessage] = useState();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues: { ...props.defaultValues } });
  const onSubmit = (data) => {
    ax({
      method: props.method,
      url: props.url,
      data: { form: data },
    })
      .then(function () {
        window.location = window.location.href;
      })
      .catch((error) => {
        toastr['error'](error);
      });
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-group">
          <label>{intl.formatMessage({ id: 'admin-area.settings.observation-event.fields.description' })}</label>
          <textarea rows="5" className="form-control" {...register('description', { required: true })}></textarea>
          {errors.description?.type === 'required' && (
            <div className="field-error">{intl.formatMessage({ id: 'shared.forms.required' })}</div>
          )}
        </div>

        <div className="form-group">
          <label>{intl.formatMessage({ id: 'units.status-field' })}</label>
          <select className="form-control" {...register('status')}>
            <option value="active">{intl.formatMessage({ id: 'shared.status.active' })}</option>
            <option value="inactive">{intl.formatMessage({ id: 'shared.status.inactive' })}</option>
          </select>
        </div>

        <div className="form-group">
          <label>{intl.formatMessage({ id: 'admin-area.settings.micro-event.fields.device-type' })}</label>
          <UnitTypeSelect allowedUnits= {["extinguisher", "trainer", "sweeper"]} {...register('device_type', { required: true })} />
          {errors.device_type?.type === 'required' && (
            <div className="field-error">{intl.formatMessage({ id: 'shared.forms.required' })}</div>
          )}
        </div>

        <button type="submit" className="btn btn-primary small angled-bottom-right">
          {intl.formatMessage({ id: 'shared.forms.save' })}
        </button>
      </form>

      <ServerFormErrors errorMessages={errorMessage}></ServerFormErrors>
    </div>
  );
};

ObservationEventForm.propTypes = {
  url: PropTypes.string.isRequired,
  method: PropTypes.string.isRequired,
  defaultValues: PropTypes.object,
};

export default ObservationEventForm;
