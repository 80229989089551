import React from 'react';
import ModalLauncher from '../../../util/ModalLauncher';
import MicroEventForm from './MicroEventForm';
import PropTypes from 'prop-types';

import { IntlProvider } from 'react-intl';
import { translations } from '../../../util/i18n';

const CreateMicroEventModal = (props) => (
  <IntlProvider locale={window._FLAIM_LOCALE} key={window._FLAIM_LOCALE} messages={translations[window._FLAIM_LOCALE]}>
    <ModalLauncher
      buttonId="create-micro-event"
      buttonText="admin-area.settings.micro-event.create"
      content={<MicroEventForm url={props.createUrl} method={'POST'} eventCategories={props.eventCategories} />}
      title="admin-area.settings.micro-event.create"
      modalVariation="wide"
    />
  </IntlProvider>
);

CreateMicroEventModal.propTypes = {
  createUrl: PropTypes.string.isRequired,
  eventCategories: PropTypes.array.isRequired,
};

export default CreateMicroEventModal;
