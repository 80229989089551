import React, { useState } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import PropTypes from 'prop-types';
import ServerFormErrors from '../util/ServerFormErrors';
import ax from '../util/axios';
import { IntlProvider, useIntl } from 'react-intl';
import moment from 'moment';
import CountrySelect from '../util/CountrySelect';
import MobileCountrySelect from '../util/MobileCountrySelect';
import { translations } from '../util/i18n';
import convertToFormData from '../util/upload';
import getFlagEmoji from '../util/getFlagEmoji';

import DatePicker, { registerLocale } from 'react-datepicker';
import de from 'date-fns/locale/de';
registerLocale('de', de);
import es from 'date-fns/locale/es';
registerLocale('es', es);
import fr from 'date-fns/locale/fr';
registerLocale('fr', fr);

const SignUpForm = (props) => {
  const intl = useIntl();
  const form = useForm({ defaultValues: { ...props.defaultValues } });
  useWatch({
    name: 'locale',
    control: form.control,
  });
  const {
    register,
    handleSubmit,
    control,
    getValues,
    setError,
    trigger,
    setValue,
    formState: { errors },
  } = form;
  const [errorMessage, setErrorMessages] = useState();

  const onSubmit = (data) => {
    const formData = convertToFormData(data, ['profile_photo']);
    ax({
      method: props.method || 'post',
      url: props.url,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' },
    })
      .then(function (response) {
        window.location = response.data.return_url;
      })
      .catch((err) => {
        setErrorMessages(err.response.data);
      });
  };

  const studentAsOrgAdmin = (user_id) => {
    ax({
      method: 'post',
      url: props.studentAsOrgAdminUrl,
      data: { user_id: user_id },
    })
      .then(function (response) {
        window.location = response.data.return_url;
      })
      .catch((err) => {
        setErrorMessages(err.response.data);
      });
  };

  const onEmailBlur = (email) => {
    trigger('email').then(() => {
      if (!email || errors.email?.type === 'required' || errors.email?.type === 'pattern') {
        return;
      }

      ax({
        method: 'get',
        url: props.checkEmailUrl,
        params: { email: email, organisation_id: getValues('organisation_id'), id: getValues('id') },
      })
        .then(function (response) {
          // clearErrors('email')
          if (response.data.emailTaken) {
            setError('email', { type: 'taken' });
          }
          if (response.data.login) {
            setError('email', { type: 'login' });
          }
          if (response.data.setPassword) {
            setError('email', { type: 'setPassword' });
          }
          if (response.data.existingUser) {
            const { user_id, user_name, org_admin } = response.data.existingUser;
            if (props.forOrgAdminUser && !org_admin) {
              if (window.confirm(`Please confirm you would like to add ${user_name} as an organisation admin`)) {
                studentAsOrgAdmin(user_id);
              } else {
                setError('email', { type: 'existingUser' });
              }
            } else {
              setError('email', { type: 'existingUser' });
            }
          }
        })
        .catch((err) => {
          setErrorMessages(err.response.data);
        });
    });
  };

  const setMobileCountry = (countryCode) => {
    setValue('mobile_country', countryCode);
  };

  const { onChange: onCountryChange, onBlur: onCountryBlurOrigin, ref: countryRef } = register('country');

  const { onChange: onMobileCountryChange, ref: mobileCountryRef } = register('mobile_country');

  const onCountryBlur = (e) => {
    onCountryBlurOrigin(e);
    setMobileCountry(e.target.value);
  };

  return (
    <div>
      <form className="narrow" onSubmit={handleSubmit(onSubmit)}>
        {props.adminArea && (
          <>
            <div className="form-group">
              <label>{intl.formatMessage({ id: 'students.fields.organisation' })}</label>
              <select autoFocus className="form-control" {...register('organisation_id', { required: true })}>
                <option key="0" value="" disabled={props.organisationId}>
                  {intl.formatMessage({ id: 'shared.dropdown.please-select' })}
                </option>
                {props.organisations.map((org) => {
                  return (
                    <option key={org.id} value={org.id} selected={props.organisationId == org.id}>
                      {org.name}
                    </option>
                  );
                })}
              </select>
              {errors.organisation_id?.type === 'required' && (
                <div className="field-error">{intl.formatMessage({ id: 'shared.forms.required' })}</div>
              )}
            </div>
          </>
        )}

        {!props.studentForm && (
          <div className="form-group required">
            <label>{intl.formatMessage({ id: 'students.fields.locale' })}</label>
            <Controller
              control={control}
              name="locale"
              rules={{ required: true }}
              render={({ field }) => (
                <select
                  className="form-control"
                  onChange={(e) => {
                    window.location = '/sign_up?locale=' + e.target.value;
                  }}
                  name="locale"
                  value={field.value}
                >
                  {props.locales.map((locale) => {
                    return (
                      <option key={locale.locale} value={locale.locale}>
                        {`${getFlagEmoji(locale.country_code.toUpperCase())} ${locale.language}`}
                      </option>
                    );
                  })}
                </select>
              )}
            />

            {errors.locale?.type === 'required' && (
              <div className="field-error">{intl.formatMessage({ id: 'shared.forms.required' })}</div>
            )}
          </div>
        )}

        <div className="form-group required">
          <label>{intl.formatMessage({ id: 'students.fields.email' })}</label>
          <Controller
            control={control}
            name="email"
            rules={{ required: true, pattern: EMAIL_REGEX }}
            render={({ field }) => (
              <input
                autoFocus={!props.adminArea}
                className="form-control"
                onChange={(e) => field.onChange(e.target.value)}
                onBlur={(e) => {
                  field.onBlur();
                  onEmailBlur(e.target.value);
                }}
                name="email"
                value={field.value}
                autoComplete="off"
              />
            )}
          />
          {errors.email?.type === 'required' && (
            <div className="field-error">{intl.formatMessage({ id: 'shared.forms.required' })}</div>
          )}
          {errors.email?.type === 'pattern' && (
            <div className="field-error">{intl.formatMessage({ id: 'shared.forms.invalid-email' })}</div>
          )}
          {errors.email?.type === 'taken' && (
            <div className="field-error">{intl.formatMessage({ id: 'shared.forms.email-taken' })}</div>
          )}
          {errors.email?.type === 'existingUser' && (
            <div className="field-error">{intl.formatMessage({ id: 'students.user-exists' })}</div>
          )}
        </div>

        {errors.email?.type === 'login' && (
          <>
            {intl.formatMessage({ id: 'students.please-login' })}&nbsp;
            <a href="/login">{intl.formatMessage({ id: 'students.login' })}</a>
          </>
        )}

        {errors.email?.type === 'setPassword' && (
          <>
            {intl.formatMessage({ id: 'students.please-set-password' })}&nbsp;
            <a href="/password_resets/new">{intl.formatMessage({ id: 'students.set-password' })}</a>
          </>
        )}

        {errors.email?.type !== 'setPassword' && errors.email?.type !== 'login' && (
          <>
            <div className="form-group required">
              <label>{intl.formatMessage({ id: 'students.fields.first-name' })}</label>
              <input className="form-control" {...register('first_name', { required: true })}></input>
              {errors.first_name?.type === 'required' && (
                <div className="field-error">{intl.formatMessage({ id: 'shared.forms.required' })}</div>
              )}
            </div>

            <div className="form-group required">
              <label>{intl.formatMessage({ id: 'students.fields.last-name' })}</label>
              <input className="form-control" {...register('last_name', { required: true })}></input>
              {errors.last_name?.type === 'required' && (
                <div className="field-error">{intl.formatMessage({ id: 'shared.forms.required' })}</div>
              )}
            </div>
            {!props.hideSensitive && (
              <>
                <div className="form-group">
                  <label>{intl.formatMessage({ id: 'students.fields.date-of-birth' })}</label>
                  <span className="optional">{intl.formatMessage({ id: 'shared.forms.optional' })}</span>
                  <Controller
                    control={control}
                    name="date_of_birth"
                    render={({ field }) => (
                      <DatePicker
                        className="form-control"
                        selected={field.value ? moment(field.value).toDate() : undefined}
                        onChange={(date) => field.onChange(date)}
                        dateFormat={window._FLAIM_DATE_FORMAT}
                        name="date_of_birth"
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        locale={window._FLAIM_LOCALE}
                      />
                    )}
                  />
                </div>

                <div className="form-group">
                  <label>{intl.formatMessage({ id: 'students.fields.gender' })}</label>
                  <span className="optional">{intl.formatMessage({ id: 'shared.forms.optional' })}</span>
                  <select className="form-control" {...register('gender')}>
                    <option value="">{intl.formatMessage({ id: 'shared.dropdown.please-select' })}</option>
                    <option value="male">{intl.formatMessage({ id: 'gender.options.male' })}</option>
                    <option value="female">{intl.formatMessage({ id: 'gender.options.female' })}</option>
                    <option value="other">{intl.formatMessage({ id: 'gender.options.other' })}</option>
                    <option value="prefer_not_to_say">
                      {intl.formatMessage({ id: 'gender.options.prefer_not_to_say' })}
                    </option>
                  </select>
                </div>
              </>
            )}

            <div className="form-group">
              <label>{intl.formatMessage({ id: 'students.fields.country' })}</label>
              <span className="optional">{intl.formatMessage({ id: 'shared.forms.optional' })}</span>
              <CountrySelect
                className="form-control"
                ref={countryRef}
                onChange={onCountryChange}
                onBlur={onCountryBlur}
                name="country"
                countries={props.countries}
              />
            </div>

            <div>
              <label>{intl.formatMessage({ id: 'students.fields.mobile' })}</label>
              <span className="optional">{intl.formatMessage({ id: 'shared.forms.optional' })}</span>
              <div className="mobile">
                <MobileCountrySelect
                  className="form-control"
                  ref={mobileCountryRef}
                  onChange={onMobileCountryChange}
                  name="mobile_country"
                  countries={props.countries}
                />
                <input className="form-control" {...register('mobile')}></input>
              </div>
            </div>

            <div className="form-group">
              <label>{intl.formatMessage({ id: 'students.fields.external-employee-id' })}</label>
              <span className="optional">{intl.formatMessage({ id: 'shared.forms.optional' })}</span>
              <input className="form-control" {...register('external_employee_id')}></input>
            </div>
            <div className="form-group">
              <label>{intl.formatMessage({ id: 'students.fields.location' })}</label>
              <span className="optional">{intl.formatMessage({ id: 'shared.forms.optional' })}</span>
              <input className="form-control" {...register('location')}></input>
            </div>

            {props.defaultValues && props.defaultValues.mobile && (
              <div className="form-group">
                <input type="checkbox" id="notifySimulationReport" {...register('notify_simulation_report')}></input>
                <label htmlFor="notifySimulationReport">
                  {intl.formatMessage({ id: 'students.fields.notify-simulation-report' })}
                </label>
              </div>
            )}

            {!props.studentForm && (
              <>
                <div className="form-group required">
                  <label>{intl.formatMessage({ id: 'students.fields.password' })}</label>
                  <input
                    autoComplete="new-password"
                    readOnly
                    onFocus={(e) => e.target.removeAttribute('readonly')}
                    type="password"
                    className="form-control"
                    {...register('password', { required: true })}
                  ></input>
                  <div className="field-hint">
                    <i className="fa fa-question-circle"></i>&nbsp;
                    <small>{intl.formatMessage({ id: 'students.password-hint' })}</small>
                  </div>
                  {errors.password?.type === 'required' && (
                    <div className="field-error">{intl.formatMessage({ id: 'shared.forms.required' })}</div>
                  )}
                </div>

                <div className="form-group required">
                  <label>{intl.formatMessage({ id: 'students.fields.password-confirmation' })}</label>
                  <input
                    autoComplete="new-password"
                    readOnly
                    onFocus={(e) => e.target.removeAttribute('readonly')}
                    type="password"
                    className="form-control"
                    {...register('password_confirmation', {
                      required: true,
                      validate: (value) => value === getValues('password'),
                    })}
                  ></input>
                  {errors.password_confirmation?.type === 'required' && (
                    <div className="field-error">{intl.formatMessage({ id: 'shared.forms.required' })}</div>
                  )}
                  {errors.password_confirmation?.type === 'validate' && (
                    <div className="field-error">
                      {intl.formatMessage({ id: 'students.forms.errors.password-confirmation' })}
                    </div>
                  )}
                </div>

                <div className="form-group">
                  <a href="https://flaimsystems.com/privacy-policy" target="_blank" rel="noreferrer">
                    <small>Check</small>
                  </a>{' '}
                  {intl.formatMessage({ id: 'shared.privacy-link' })}
                </div>

                <div
                  className="actions"
                  style={{
                    display: 'flex',
                    'align-items': 'center',
                    'justify-content': 'space-between',
                  }}
                >
                  <button type="submit" className="btn btn-primary small angled-top-left">
                    {intl.formatMessage({ id: 'students.sign-up' })}
                  </button>
                  <a className="btn btn-secondary small angled-bottom-right" href="/">
                    {intl.formatMessage({ id: 'shared.cancel' })}
                  </a>
                </div>
              </>
            )}

            {props.studentForm && (
              <>
                <div>
                  <button type="submit" className="btn btn-primary small angled-bottom-right">
                    {intl.formatMessage({ id: 'shared.forms.save' })}
                  </button>
                  {!props.defaultValues && (
                    <>
                      &nbsp;
                      <span className="body-small">{intl.formatMessage({ id: 'students.sms-email-notice' })}</span>
                    </>
                  )}
                </div>
              </>
            )}
          </>
        )}
      </form>

      <ServerFormErrors errorMessages={errorMessage}></ServerFormErrors>
    </div>
  );
};

SignUpForm.propTypes = {
  url: PropTypes.string.isRequired,
  checkEmailUrl: PropTypes.string,
  studentForm: PropTypes.bool,
  adminArea: PropTypes.bool,
  organisations: PropTypes.array,
  organisationId: PropTypes.array,
  defaultValues: PropTypes.object,
  method: PropTypes.string,
  forOrgAdminUser: PropTypes.bool,
  studentAsOrgAdminUrl: PropTypes.string,
  locales: PropTypes.array,
  countries: PropTypes.object.isRequired,
  hideSensitive: PropTypes.bool,
};

export default SignUpForm;

export const WithIntlProvider = (props) => (
  <IntlProvider locale={window._FLAIM_LOCALE} key={window._FLAIM_LOCALE} messages={translations[window._FLAIM_LOCALE]}>
    <SignUpForm {...props} />
  </IntlProvider>
);
