import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";

const CountrySelect = React.forwardRef(({ className, onChange, onBlur, name, countries }, ref) => {
  const intl = useIntl();

  return (
    <select className={className} name={name} ref={ref} onChange={onChange} onBlur={onBlur}>
      <option value="">{intl.formatMessage({ id: "shared.dropdown.please-select" })}</option>
      {Object.keys(countries).map((key) => (
        <option key={key} value={key}>
          {countries[key]}
        </option>
      ))}
    </select>
  );
});

CountrySelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  countries: PropTypes.object.isRequired,
};

export default CountrySelect;
