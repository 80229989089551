import React from "react";
import RowMenu from "../../util/RowMenu";
import Modal from "../../util/Modal";
import PostLink from "../../util/PostLink";
import EditProfileModal from "./EditProfileModal";
import PropTypes from "prop-types";

import { IntlProvider } from "react-intl";
import { translations, locale } from "../../util/i18n";

const MyAccountMenu = (props) => {
  const options = [
    {
      title: "shared.settings",
      component: Modal,
      params: {
        title: "shared.settings",
        content: EditProfileModal({
          url: props.updateUserUrl,
          userDetails: { ...props.userDetails },
          timezones: props.timezones,
        }),
      },
    },
    {
      title: "profile.dropdown.change-password",
      component: PostLink,
      params: {
        url: props.sendPasswordUrl,
        notice: "profile.dropdown.change-password-complete",
        refresh: false,
      },
    },
    {
      title: "profile.dropdown.logout",
      component: PostLink,
      params: {
        url: props.logoutUrl,
      },
    },
  ];
  return (
    <IntlProvider
      locale={window._FLAIM_LOCALE}
      key={window._FLAIM_LOCALE}
      messages={translations[window._FLAIM_LOCALE]}
    >
      <div className="my-account">
        <RowMenu classNameForButton="btn-secondary small angled-top-left my-account-btn" title={"profile.my-account"} options={options}></RowMenu>
      </div>
    </IntlProvider>
  );
};

MyAccountMenu.propTypes = {
  sendPasswordUrl: PropTypes.string.isRequired,
  logoutUrl: PropTypes.string.isRequired,
  updateUserUrl: PropTypes.string.isRequired,
  userDetails: PropTypes.object.isRequired,
  timezones: PropTypes.array.isRequired,
};

export default MyAccountMenu;
