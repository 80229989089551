import React, { useState } from "react";
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";
import ServerFormErrors from "../../util/ServerFormErrors";
import ax from "../../util/axios";
import { useIntl } from "react-intl";

const AddApprovedOrganisationForm = (props) => {
  const intl = useIntl();
  const [errorMessage, setErrorMessages] = useState();
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({ defaultValues: { ...props.defaultValues } });
  const onSubmit = (data) => {
    ax({
      method: "POST",
      url: props.url,
      data: { form: data },
    })
      .then(function () {
        window.location = window.location.href;
      })
      .catch((err) => {
        setErrorMessages(err.response.data);
      });
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-group">
          <label>{intl.formatMessage({ id: "admin-area.scenarios.add-approved-organisation" })}</label>
          <select autoFocus className="form-control" {...register("organisation_id", { required: true })}>
            <option key="0" value="">
              {intl.formatMessage({ id: "shared.dropdown.please-select" })}
            </option>
            {props.organisations.map((org) => {
              return (
                <option key={org.id} value={org.id}>
                  {org.name}
                </option>
              );
            })}
          </select>
          {errors.organisation_id?.type === "required" && (
            <div className="field-error">{intl.formatMessage({ id: "shared.forms.required" })}</div>
          )}
        </div>

        <button type="submit" className="btn btn-primary small angled-bottom-right">
          {intl.formatMessage({ id: "shared.forms.save" })}
        </button>
      </form>

      <ServerFormErrors errorMessages={errorMessage}></ServerFormErrors>
    </div>
  );
};

AddApprovedOrganisationForm.propTypes = {
  url: PropTypes.string.isRequired,
  organisations: PropTypes.array.isRequired,
};

export default AddApprovedOrganisationForm;
