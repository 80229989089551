import React from "react";
import EditProfileForm from "./EditProfileForm";
import PropTypes from "prop-types";

import { IntlProvider } from "react-intl";
import { translations, locale } from "../../util/i18n";

const EditProfileModal = (props) => {
  return (
    <IntlProvider
      locale={window._FLAIM_LOCALE}
      key={window._FLAIM_LOCALE}
      messages={translations[window._FLAIM_LOCALE]}
    >
      <EditProfileForm
        url={props.url}
        method={"PUT"}
        timezones={props.timezones}
        defaultValues={{ ...props.userDetails }}
      ></EditProfileForm>
    </IntlProvider>
  );
};

EditProfileModal.propTypes = {
  url: PropTypes.string.isRequired,
  timezones: PropTypes.array,
  userDetails: PropTypes.object,
};

export default EditProfileModal;
