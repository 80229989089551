import React from "react";
import AddLearningOutcomeForm from "./AddLearningOutcomeForm";
import PropTypes from "prop-types";

import { IntlProvider } from "react-intl";
import { translations, locale } from "../../util/i18n";

const EditLearningOutcomeModal = (props) => {
  return (
    <IntlProvider locale={locale} key={locale} messages={translations[locale]}>
      <AddLearningOutcomeForm
        url={props.url}
        method="PUT"
        defaultValues={{ ...props.details }}
      ></AddLearningOutcomeForm>
    </IntlProvider>
  );
};

EditLearningOutcomeModal.propTypes = {
  url: PropTypes.string.isRequired,
  details: PropTypes.object.isRequired,
};

export default EditLearningOutcomeModal;
