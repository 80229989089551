import React, { useContext, useEffect, useState } from 'react';
import ModalLauncher from '../util/ModalLauncher';
import { useIntl } from 'react-intl';
import { ModalContext } from '../util/Modal';
import FlexRight from '../../../../components/utils/FlexRight';
import Table from '../../../../components/Table';
import wrap_with_intl_provider from '../util/wrap_with_intl_provider';
import ax from '../util/axios';
import toastr from 'toastr';
import {
  organisation_area_online_courses_course_assign_units_path,
  organisation_area_online_courses_course_load_units_for_assign_path,
  organisation_area_online_courses_course_unassign_unit_path,
  organisation_area_online_courses_course_units_path,
  organisation_area_unit_courses_path,
  admin_area_online_courses_course_assign_units_path,
  admin_area_online_courses_course_load_units_for_assign_path,
  admin_area_online_courses_course_unassign_unit_path,
  admin_area_online_courses_course_units_path,
  admin_area_unit_courses_path,
} from '../../../../routes';
import PropTypes from 'prop-types';
import RowMenu from '../util/RowMenu';
import PostLink from '../util/PostLink';
import SearchBar, { SearchBarContext } from '../../../../components/SearchBar';
import SearchBarRow from '../../../../components/SearchBar/SearchBarRow';
import _ from 'lodash';

const PrimarySearchItems = ({ isAdmin }) => {
  const intl = useIntl();
  const { register } = useContext(SearchBarContext);

  return (
    <SearchBarRow>
      <div className="form-group" style={{ flex: 1 }}>
        <label>{intl.formatMessage({ id: 'units.search.name-field' })}</label>
        <input className="form-control" {...register('name')}></input>
      </div>

      {isAdmin && (
        <div className="form-group" style={{ flex: 1 }}>
          <label>{intl.formatMessage({ id: 'units.search.organisation-field' })}</label>
          <input className="form-control" {...register('organisation')}></input>
        </div>
      )}

      <div className="form-group" style={{ flex: 1 }}>
        <label>{intl.formatMessage({ id: 'units.search.serial-no-field' })}</label>
        <input className="form-control" {...register('serial_no')}></input>
      </div>
    </SearchBarRow>
  );
};

PrimarySearchItems.propTypes = {
  isAdmin: PropTypes.bool.isRequired,
};

const AddUnitModal = ({ course_id, isAdmin }) => {
  const intl = useIntl();
  const [data, setData] = useState();
  const [selectedRows, setSelectedRows] = useState([]);
  const [searchParams, setSearchParams] = useState({});
  const modalContext = useContext(ModalContext);

  const [loadUnitPath, assignUnitsPath] = isAdmin
    ? [admin_area_online_courses_course_load_units_for_assign_path, admin_area_online_courses_course_assign_units_path]
    : [
        organisation_area_online_courses_course_load_units_for_assign_path,
        organisation_area_online_courses_course_assign_units_path,
      ];

  const loadUnits = (page = 1) => {
    let url = loadUnitPath(course_id, { page: page, ...searchParams });
    ax({
      method: 'get',
      url: url,
    })
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        toastr['error'](error);
      });
  };

  useEffect(() => {
    loadUnits();
  }, [searchParams]);

  const onPageChange = (page) => {
    loadUnits(page);
  };

  const onRowSelect = ({ selected, id }) => {
    if (selected) {
      setSelectedRows([...selectedRows, id]);
    } else {
      setSelectedRows(selectedRows.filter((item) => item != id));
    }
  };

  const onSelectAll = (selected) => {
    const ids = data.units.map((unit) => unit.id);
    if (selected) {
      setSelectedRows(_.uniq([...selectedRows, ...ids]));
    } else {
      setSelectedRows(selectedRows.filter((id) => !ids.includes(id)));
    }
  };

  const onAddUnits = () => {
    if (confirm(intl.formatMessage({ id: 'courses.units.assign-units.confirm' }))) {
      ax({
        method: 'post',
        url: assignUnitsPath(course_id),
        data: { ids: selectedRows },
      })
        .then(() => {
          window.location.reload();
        })
        .catch((error) => {
          toastr['error'](error);
        });
    }
  };

  const columns = [
    {
      header: intl.formatMessage({ id: 'units.name-field' }),
      accessor: 'name',
    },
    {
      header: intl.formatMessage({ id: 'units.serial-no-field' }),
      accessor: 'serialNo',
    },
    {
      header: intl.formatMessage({ id: 'units.software-version-field' }),
      accessor: 'softwareVersion',
    },
    {
      header: intl.formatMessage({ id: 'units.search.last-used-field' }),
      accessor: 'lastUsedAt',
    },
  ];

  if (isAdmin) {
    columns.splice(1, 0, {
      header: intl.formatMessage({ id: 'units.organisation-field' }),
      accessor: 'organisation',
    });
  }

  const onSearch = (data) => {
    setSearchParams(data);
    setSelectedRows([]);
  };

  if (!data) {
    return;
  } else {
    const { units, pagination } = data;
    const mappedUnits = units.map((unit) => ({ ...unit, selected: selectedRows.includes(unit.id) }));
    return (
      <div className="add-units">
        <SearchBar primaryItems={<PrimarySearchItems isAdmin={isAdmin} />} onSearch={onSearch} />
        <Table
          columns={columns}
          items={mappedUnits}
          pagination={pagination}
          onPageChange={onPageChange}
          onRowSelect={onRowSelect}
          rowSelect={true}
          onSelectAll={onSelectAll}
        ></Table>
        <FlexRight className="gap-1 margin-0.5">
          <button className="btn btn-tertiary" onClick={modalContext.closeModal}>
            {intl.formatMessage({ id: 'shared.cancel' })}
          </button>

          <button
            className="btn btn-primary angled-top-left"
            disabled={!selectedRows.length}
            data-disable-with="..."
            onClick={onAddUnits}
          >
            {intl.formatMessage({ id: 'courses.units.assign-units' })}{' '}
            {!!selectedRows.length && <>({selectedRows.length})</>}
          </button>
        </FlexRight>
      </div>
    );
  }
};

AddUnitModal.propTypes = {
  course_id: PropTypes.number.isRequired,
  isAdmin: PropTypes.bool.isRequired,
};

const CourseUnits = ({ course_id, isAdmin }) => {
  const intl = useIntl();
  const [data, setData] = useState({});

  const [courseUnitsPath, unassignUnitPath, unitCoursesPath] = isAdmin
    ? [
        admin_area_online_courses_course_units_path,
        admin_area_online_courses_course_unassign_unit_path,
        admin_area_unit_courses_path,
      ]
    : [
        organisation_area_online_courses_course_units_path,
        organisation_area_online_courses_course_unassign_unit_path,
        organisation_area_unit_courses_path,
      ];

  const loadUnits = (page = 1) => {
    let url = courseUnitsPath(course_id, { page: page, format: 'json' });
    ax({
      method: 'get',
      url: url,
    })
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        toastr['error'](error);
      });
  };

  useEffect(() => {
    loadUnits();
  }, []);

  const onPageChange = (page) => {
    loadUnits(page);
  };

  const { units, pagination } = data;

  const modalContent = () => {
    return <AddUnitModal course_id={course_id} isAdmin={isAdmin}></AddUnitModal>;
  };

  const options = (unit) => {
    const opts = [
      {
        title: 'shared.unassign',
        component: PostLink,
        params: {
          url: unassignUnitPath(course_id, { unit_id: unit.id }),
          refresh: true,
        },
      },
    ];

    return opts;
  };

  const columns = [
    {
      header: intl.formatMessage({ id: 'units.name-field' }),
      accessor: 'name',
      className: 'link',
    },
    {
      header: intl.formatMessage({ id: 'units.serial-no-field' }),
      accessor: 'serialNo',
    },
    {
      header: intl.formatMessage({ id: 'units.software-version-field' }),
      accessor: 'softwareVersion',
    },
    {
      header: intl.formatMessage({ id: 'units.search.last-used-field' }),
      accessor: 'lastUsedAt',
    },
    {
      header: '',
      accessor: 'actions',
      className: 'actions',
    },
  ];

  if (isAdmin) {
    columns.splice(1, 0, {
      header: intl.formatMessage({ id: 'units.organisation-field' }),
      accessor: 'organisation',
    });
  }

  const mappedItems =
    units &&
    units.map((unit) => ({
      ...unit,
      name: <a href={unitCoursesPath(unit.id)}>{unit.name}</a>,
      actions: <RowMenu options={options(unit)}></RowMenu>,
    }));
  return (
    <>
      <div className="page-header">
        <div></div>
        <ModalLauncher
          buttonId="btn-add-units"
          buttonText="courses.units.assign-units"
          content={modalContent(course_id)}
          title="courses.units.assign-units"
          modalVariation="wide"
        />
      </div>
      {units && (
        <Table columns={columns} items={mappedItems} pagination={pagination} onPageChange={onPageChange}></Table>
      )}
    </>
  );
};

CourseUnits.propTypes = {
  course_id: PropTypes.number.isRequired,
  isAdmin: PropTypes.bool.isRequired,
};

export default wrap_with_intl_provider(CourseUnits);
