import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import ServerFormErrors from '../../../util/ServerFormErrors';
import ax from '../../../util/axios';
import { useIntl } from 'react-intl';
import UnitTypeSelect from '../../units/UnitTypeSelect';

const PerformanceEventForm = (props) => {
  const intl = useIntl();
  const [errorMessage, setErrorMessages] = useState();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues: { ...props.defaultValues } });
  const onSubmit = (data) => {
    ax({
      method: props.method,
      url: props.url,
      data: { form: data },
    })
      .then(function () {
        window.location = window.location.href;
      })
      .catch((err) => {
        setErrorMessages(err.response.data);
      });
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-group">
          <label>{intl.formatMessage({ id: 'admin-area.settings.performance-event.event-id-field' })}</label>
          <input className="form-control" {...register('event_id', { required: true })}></input>
          {errors.event_id?.type === 'required' && (
            <div className="field-error">{intl.formatMessage({ id: 'shared.forms.required' })}</div>
          )}
        </div>

        <div className="form-group">
          <label>{intl.formatMessage({ id: 'admin-area.settings.performance-event.title-field' })}</label>
          <input className="form-control" {...register('title', { required: true })}></input>
          {errors.title?.type === 'required' && (
            <div className="field-error">{intl.formatMessage({ id: 'shared.forms.required' })}</div>
          )}
        </div>

        <div className="form-group">
          <label>{intl.formatMessage({ id: 'admin-area.settings.performance-event.type-field' })}</label>
          <select className="form-control" {...register('type', { required: true })}>
            <option value="number">
              {intl.formatMessage({ id: 'admin-area.settings.performance-event.type.number' })}
            </option>
            <option value="text">
              {intl.formatMessage({ id: 'admin-area.settings.performance-event.type.text' })}
            </option>
            <option value="link">
              {intl.formatMessage({ id: 'admin-area.settings.performance-event.type.link' })}
            </option>
          </select>
          {errors.type?.type === 'required' && (
            <div className="field-error">{intl.formatMessage({ id: 'shared.forms.required' })}</div>
          )}
        </div>

        <div className="form-group">
          <label>{intl.formatMessage({ id: 'admin-area.settings.performance-event.suffix-field' })}</label>
          <input className="form-control" {...register('suffix')}></input>
        </div>

        <div className="form-group">
          <label>{intl.formatMessage({ id: 'admin-area.settings.performance-event.description-field' })}</label>
          <textarea rows="4" className="form-control" {...register('description')}></textarea>
        </div>

        <div className="form-group">
          <label>{intl.formatMessage({ id: 'units.status-field' })}</label>
          <select className="form-control" {...register('status')}>
            <option value="active">{intl.formatMessage({ id: 'shared.status.active' })}</option>
            <option value="inactive">{intl.formatMessage({ id: 'shared.status.inactive' })}</option>
          </select>
        </div>

        <div className="form-group">
          <label>{intl.formatMessage({ id: 'admin-area.settings.micro-event.fields.device-type' })}</label>
          <UnitTypeSelect allowedUnits= {["extinguisher", "trainer", "sweeper"]} {...register('device_type', { required: true })} />
          {errors.device_type?.type === 'required' && (
            <div className="field-error">{intl.formatMessage({ id: 'shared.forms.required' })}</div>
          )}
        </div>

        <button type="submit" className="btn btn-primary small angled-bottom-right">
          {intl.formatMessage({ id: 'shared.forms.save' })}
        </button>
      </form>

      <ServerFormErrors errorMessages={errorMessage}></ServerFormErrors>
    </div>
  );
};

PerformanceEventForm.propTypes = {
  url: PropTypes.string.isRequired,
  method: PropTypes.string.isRequired,
  defaultValues: PropTypes.object,
};

export default PerformanceEventForm;
