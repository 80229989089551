import { useEffect } from 'react';
import ax from './axios';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import toastr from 'toastr';

const PostLink = (props) => {
  const intl = useIntl();

  useEffect(() => {
    if (!props.confirmMessage || window.confirm(intl.formatMessage({ id: props.confirmMessage }))) {
      ax({
        method: props.method,
        url: props.url,
        data: {},
      }).then(function (response) {
        if (props.notice) {
          toastr.success(intl.formatMessage({ id: props.notice }));
        }
        if (props.refresh) {
          window.location.reload();
        } else if (response.data.customResponseURL !== undefined) {
          window.location = response.data.customResponseURL;
        } else if (response.status == 200) {
          window.location = response.request.responseURL;
        }
        if (props.onDone) {
          props.onDone();
        }
      });
    }
  });
  return null;
};

PostLink.propTypes = {
  notice: PropTypes.string,
  url: PropTypes.string.isRequired,
  method: PropTypes.string,
  confirmMessage: PropTypes.string,
  onDone: PropTypes.func,
};

PostLink.defaultProps = {
  method: 'POST',
};
export default PostLink;
