import toastr from 'toastr';
import ax from '../../bundles/main/components/util/axios';

const loadMicroEvents = async (loadEventUrl, setData, page = 1, search) => {
  let url = `${loadEventUrl}?page=${page}`;
  if (search) {
    url = `${url}&search=${encodeURIComponent(search)}`;
  }

  ax({
    method: 'get',
    url: url,
  })
    .then((response) => {
      setData(response.data);
    })
    .catch((error) => {
      toastr['error'](error);
    });
};

const addMicroEvents = (addEventUrl, event_ids) => {
  ax({
    method: 'post',
    url: addEventUrl,
    data: { ids: event_ids },
  })
    .then(() => {
      window.location.reload();
    })
    .catch((error) => {
      toastr['error'](error);
    });
};

export { loadMicroEvents, addMicroEvents };
