import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import wrap from '../../util/wrap_with_intl_provider';
import Table from '../../../../../components/Table';
import ax from '../../util/axios';
import {
  admin_area_scenario_performance_events_path,
  organisation_area_scenario_performance_events_path,
} from '../../../../../routes';
import StatusLabel from '../../../../../components/StatusLabel';

const ScenarioPerformanceEvents = (props) => {
  const intl = useIntl();

  const [pagination, setPagination] = useState({});
  const [items, setItems] = useState([]);

  const fetchItems = async (page = 1) => {
    const loadUrl = props.isAdmin
      ? admin_area_scenario_performance_events_path
      : organisation_area_scenario_performance_events_path;
    const response = await ax.get(loadUrl(props.id, { format: 'json', page }));
    setPagination(response.data.pagination);
    setItems(response.data.items);
  };

  const columns = [
    {
      accessor: 'eventId',
      header: intl.formatMessage({ id: 'admin-area.settings.performance-event.event-id-field' }),
    },
    {
      accessor: 'title',
      header: intl.formatMessage({ id: 'admin-area.settings.performance-event.title-field' }),
    },
    {
      accessor: 'type',
      header: intl.formatMessage({ id: 'admin-area.settings.performance-event.type-field' }),
    },
    {
      accessor: 'suffix',
      header: intl.formatMessage({ id: 'admin-area.settings.performance-event.suffix-field' }),
    },
    {
      accessor: 'description',
      header: intl.formatMessage({ id: 'admin-area.settings.performance-event.description-field' }),
    },
    {
      accessor: 'status',
      header: intl.formatMessage({ id: 'admin-area.settings.incident.fields.status' }),
      className: 'status',
    },
    {
      accessor: 'deviceType',
      header: intl.formatMessage({ id: 'admin-area.settings.micro-event.fields.device-type' }),
    },
  ];

  useEffect(() => {
    fetchItems();
  }, []);

  const mappedItems = items.map((event) => ({
    ...event,
    deviceType: event.deviceType ? intl.formatMessage({ id: `units.unit-type.${event.deviceType}` }) : '',
    status: <StatusLabel text={event.status} status={event.status === 'active' ? 'passed' : 'failed'} />,
  }));

  if (pagination.total != undefined) {
    return <Table items={mappedItems} columns={columns} pagination={pagination} onPageChange={fetchItems} />;
  }
};

ScenarioPerformanceEvents.propTypes = {
  id: PropTypes.number.isRequired,
  isAdmin: PropTypes.bool,
};

export default wrap(ScenarioPerformanceEvents);
