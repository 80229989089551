import React from "react";
import ModalLauncher from "../util/ModalLauncher";
import AssignSimulationUserForm from "./AssignSimulationUserForm";
import PropTypes from "prop-types";

import { IntlProvider } from "react-intl";
import { translations } from "../util/i18n";

const AssignSimulationModalLauncher = (props) => {
  const modalContent = () => {
    return <AssignSimulationUserForm {...props} />;
  };

  return (
    <IntlProvider
      locale={window._FLAIM_LOCALE}
      key={window._FLAIM_LOCALE}
      messages={translations[window._FLAIM_LOCALE]}
    >
      <ModalLauncher
        buttonId="assign-simulation"
        buttonText="simulations.dropdown.assign-student"
        content={modalContent()}
        title="simulations.dropdown.assign-student"
        classNameForContent="cancel-overflow-auto"
      />
    </IntlProvider>
  );
};

AssignSimulationModalLauncher.propTypes = {
  url: PropTypes.string.isRequired,
  assignableStudentsUrl: PropTypes.string.isRequired,
};

export default AssignSimulationModalLauncher;
