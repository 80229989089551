import React, { useState, forwardRef, useImperativeHandle, createContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './Accordion.scss';

export const AccordionContext = createContext({});

const Accordion = forwardRef(({ header, content, className, initExpanded, onToggled, disabled }, ref) => {
  const [expanded, setExpanded] = useState(initExpanded);

  const toggleAccordion = () => {
    const newExpanded = !expanded;
    setExpanded(newExpanded);
    onToggled && onToggled(newExpanded);
  };

  useImperativeHandle(ref, () => ({
    toggleAccordion: toggleAccordion,
  }));

  return (
    <AccordionContext.Provider value={{ setExpanded, toggleAccordion }}>
      <div className={classNames('accordion', className, { expanded, disabled })}>
        <div className="accordion-header" onClick={toggleAccordion}>
          <div className="accordion-toggle-icon" onClick={toggleAccordion}>
            <i className="fas fa-chevron-down"></i>
            <i className="fas fa-chevron-up"></i>
          </div>
          <div>{header}</div>
        </div>
        <div className="accordion-content">{!disabled && expanded && content}</div>
      </div>
    </AccordionContext.Provider>
  );
});

Accordion.displayName = 'Accordion';
Accordion.propTypes = {
  header: PropTypes.node.isRequired,
  content: PropTypes.node.isRequired,
  className: PropTypes.string,
  initExpanded: PropTypes.bool,
  onToggled: PropTypes.func,
  disabled: PropTypes.bool,
};

export default Accordion;
