import React, { useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import PropTypes from "prop-types";
import ServerFormErrors from "../util/ServerFormErrors";
import ax from "../util/axios";
import { IntlProvider, useIntl } from "react-intl";
import { translations } from "../util/i18n";
import { add_submit_overlay, remove_submit_overlay } from "../util/submit_overlay";

const AnswerQuestionForm = (props) => {
  const intl = useIntl();
  const [errorMessage, setErrorMessages] = useState();
  const {
    register,
    setValue,
    getValues,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({ defaultValues: { ...props.defaultValues } });

  const onSubmit = (data) => {
    if (data.answer.length == 0) {
      setError("answer", { type: "required" });
      return;
    }
    add_submit_overlay();
    ax({
      method: "put",
      url: props.url,
      data: data,
    })
      .then(function () {
        window.location.reload();
      })
      .catch((err) => {
        remove_submit_overlay();
        setErrorMessages(err.response.data);
      });
  };

  const toggleOption = (option_id) => {
    return () => {
      clearErrors("answer");
      const answer = getValues("answer");
      if (answer.includes(option_id)) {
        setValue("answer", [...answer.filter((id) => id !== option_id)]);
      } else {
        setValue("answer", [...answer, option_id]);
      }
    };
  };

  const optionSelected = (option_id) => {
    const answer = getValues("answer");
    return answer.includes(option_id);
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        {!props.singleResponse && (
          <>
            <div className="form-group">
              {intl.formatMessage({ id: "enrolments.questions.please-select-all-correct" })}
            </div>

            <div className="form-group">
              {props.options.map((option) => {
                return (
                  <div key={option.id}>
                    <label htmlFor={option.id}>
                      <input
                        disabled={props.shouldProceed}
                        type="checkbox"
                        defaultChecked={optionSelected(String(option.id))}
                        id={option.id}
                        onChange={toggleOption(String(option.id))}
                      />
                      {option.body}
                    </label>
                  </div>
                );
              })}
            </div>
          </>
        )}
        {props.singleResponse && (
          <>
            <div className="form-group">
              {intl.formatMessage({ id: "enrolments.questions.please-select-most-appropriate" })}
            </div>

            <div className="form-group">
              {props.options.map((option) => {
                return (
                  <div key={option.id}>
                    <label htmlFor={option.id}>
                      <input
                        disabled={props.shouldProceed}
                        type="radio"
                        name="answer"
                        id={option.id}
                        value={option.id}
                        {...register("answer")}
                      />
                      {option.body}
                    </label>
                  </div>
                );
              })}
            </div>
          </>
        )}

        {errors.answer?.type === "required" && (
          <div className="field-error align-left">{intl.formatMessage({ id: "shared.forms.required" })}</div>
        )}

        {!props.hideActions && (
          <>
            <button type="submit" disabled={props.shouldProceed} className="btn btn-primary small angled-top-left">
              <i className="fas fa-check"></i>&nbsp;&nbsp;
              {intl.formatMessage({ id: "enrolments.questions.submit-answer" })}
            </button>

            <a
              href={props.proceedUrl}
              data-method="post"
              disabled={!props.shouldProceed}
              className="btn btn-secondary small angled-bottom-right"
              data-disable-with="..."
            >
              <i className="fas fa-chevron-right"></i>&nbsp;&nbsp;
              {intl.formatMessage({ id: "enrolments.questions.proceed" })}
            </a>
          </>
        )}
      </form>

      <ServerFormErrors errorMessages={errorMessage}></ServerFormErrors>
    </div>
  );
};

AnswerQuestionForm.propTypes = {
  options: PropTypes.array.isRequired,
  defaultValues: PropTypes.object.isRequired,
  singleResponse: PropTypes.bool.isRequired,
  shouldProceed: PropTypes.bool,
  url: PropTypes.string.isRequired,
  proceedUrl: PropTypes.string.isRequired,
  hideActions: PropTypes.string.isRequired,
};

export const WithIntlProvider = (props) => {
  return (
    <IntlProvider
      locale={window._FLAIM_LOCALE}
      key={window._FLAIM_LOCALE}
      messages={translations[window._FLAIM_LOCALE]}
    >
      <AnswerQuestionForm {...props} />
    </IntlProvider>
  );
};

export default WithIntlProvider;
