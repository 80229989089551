import React from 'react';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import 'moment/locale/en-au';
import { useIntl } from 'react-intl';
import UnitTypeSelect from '../units/UnitTypeSelect';

const ScenarioSearchForm = (props) => {
  const intl = useIntl();
  const { register, handleSubmit } = useForm({ defaultValues: { ...props.defaultValues } });
  const onSubmit = (data) => {
    props.onSubmit(data);
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="filters">
          <div className="form-group">
            <label>{intl.formatMessage({ id: 'admin-area.scenarios.id-field' })}</label>
            <input className="form-control" {...register('scenarioIdentity')}></input>
          </div>

          <div className="form-group">
            <label>{intl.formatMessage({ id: 'admin-area.scenarios.name-field' })}</label>
            <input className="form-control" {...register('name')}></input>
          </div>

          <div className="form-group">
            <label>{intl.formatMessage({ id: 'admin-area.scenarios.device-type-field' })}</label>
            <UnitTypeSelect allowedUnits= {["extinguisher", "trainer", "sweeper"]} {...register('deviceType')} />
          </div>
          {!props.for_org_user && (
            <div className="form-group">
              <label>{intl.formatMessage({ id: 'admin-area.scenarios.status-field' })}</label>
              <select className="form-control" {...register('status')}>
                <option value="">{intl.formatMessage({ id: 'shared.all' })}</option>
                <option value="0">{intl.formatMessage({ id: 'shared.status.draft' })}</option>
                <option value="1">{intl.formatMessage({ id: 'shared.status.published' })}</option>
              </select>
            </div>
          )}

          <div className="form-group">
            <label>{intl.formatMessage({ id: 'admin-area.scenarios.environment-field' })}</label>
            <input className="form-control" {...register('setting')}></input>
          </div>

          <div className="form-group">
            <label>{intl.formatMessage({ id: 'admin-area.scenarios.incident-field' })}</label>
            <input className="form-control" {...register('fireEvent')}></input>
          </div>
        </div>

        <button type="submit" className="btn btn-primary small angled-top-left">
          <i className="fas fa-filter"></i>&nbsp;
          {intl.formatMessage({ id: 'shared.forms.apply_filters' })}
        </button>
      </form>
    </div>
  );
};

ScenarioSearchForm.propTypes = {
  for_org_user: PropTypes.bool,
  defaultValues: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default ScenarioSearchForm;
