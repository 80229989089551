import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { SearchBarContext } from '../../../../../../components/SearchBar';
import SearchBarRow from '../../../../../../components/SearchBar/SearchBarRow';
import UnitTypeSelect from '../../units/UnitTypeSelect';

const PrimarySearchItems = (props) => {
  const intl = useIntl();
  const { register } = useContext(SearchBarContext);

  return (
    <SearchBarRow>
      <div className="form-group" style={{ flex: 1 }}>
        <label>{intl.formatMessage({ id: 'admin-area.settings.micro-event.fields.event-id' })}</label>
        <input className="form-control" {...register('event_id')}></input>
      </div>

      <div className="form-group" style={{ flex: 1 }}>
        <label>{intl.formatMessage({ id: 'units.status-field' })}</label>
        <select className="form-control" {...register('status')}>
          <option key="0" value="">
            {intl.formatMessage({ id: 'shared.dropdown.please-select' })}
          </option>
          <option value="active">{intl.formatMessage({ id: 'shared.status.active' })}</option>
          <option value="inactive">{intl.formatMessage({ id: 'shared.status.inactive' })}</option>
        </select>
      </div>

      <div className="form-group" style={{ flex: 1 }}>
        <label>{intl.formatMessage({ id: 'admin-area.settings.micro-event.fields.event-category' })}</label>
        <select className="form-control" {...register('event_category_id')}>
          <option key="0" value="">
            {intl.formatMessage({ id: 'shared.dropdown.please-select' })}
          </option>
          {props.eventCategories.map((eventCategory) => (
            <option key={eventCategory.id} value={eventCategory.id}>{eventCategory.name}</option>
          ))}
        </select>
      </div>

      <div className="form-group" style={{ flex: 1 }}>
        <label>{intl.formatMessage({ id: 'admin-area.settings.micro-event.fields.device-type' })}</label>
        <UnitTypeSelect
          allowedUnits= {["extinguisher", "trainer", "sweeper"]}
          emptyOption={true}
          {...register('device_type')}
        />
      </div>
    </SearchBarRow>
  );
};

PrimarySearchItems.propTypes = {
  eventCategories: PropTypes.array.isRequired,
};

export default PrimarySearchItems;
