import React from "react";
import PerformanceEventForm from "./PerformanceEventForm";
import PropTypes from "prop-types";

import { IntlProvider } from "react-intl";
import { translations, locale } from "../../../util/i18n";

const EditPerformanceEventModal = (props) => {
  return (
    <IntlProvider
      locale={window._FLAIM_LOCALE}
      key={window._FLAIM_LOCALE}
      messages={translations[window._FLAIM_LOCALE]}
    >
      <PerformanceEventForm
        url={props.url}
        method={"PUT"}
        defaultValues={{ ...props.eventDetails }}
      ></PerformanceEventForm>
    </IntlProvider>
  );
};

EditPerformanceEventModal.propTypes = {
  url: PropTypes.string.isRequired,
  eventDetails: PropTypes.object.isRequired,
};

export default EditPerformanceEventModal;
