import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import wrap from '../../../util/wrap_with_intl_provider';
import Table from '../../../../../../components/Table';
import PageHeader from '../../../../../../components/PageHeader';
import ax from '../../../util/axios';
import SearchBar, { SearchBarContext } from '../../../../../../components/SearchBar';
import SearchBarRow from '../../../../../../components/SearchBar/SearchBarRow';
import SingleSelect from '../../../../../../components/SingleSelect';
import StatusLabel from '../../../../../../components/StatusLabel';
import { tableParamsBuilder } from '../../../util/searchUtils';
import OutcomeCard from '../../../../../../components/OutcomeCard';
import { admin_area_settings_environments_path } from '../../../../../../routes';
import { Controller } from 'react-hook-form';

const PrimarySearchItems = () => {
  const intl = useIntl();
  const { register, control } = useContext(SearchBarContext);

  const environmentsUrl = admin_area_settings_environments_path({
    no_pagination: true,
    format: 'json',
  });

  return (
    <SearchBarRow>
      <div className="form-group" style={{ flex: 2 }}>
        <label htmlFor="title">{intl.formatMessage({ id: 'shared.search-label' })}</label>
        <input
          className="form-control search"
          placeholder={intl.formatMessage({ id: 'shared.search' })}
          {...register('title')}
        />
      </div>
      <div className="form-group" style={{ flex: 1 }}>
        <label htmlFor="incident_id">
          {intl.formatMessage({ id: 'admin-area.settings.incident.fields.incident-id' })}
        </label>
        <input className="form-control" id="incident_id" {...register('incident_id')} />
      </div>
      <div className="form-group" style={{ flex: 1 }}>
        <label htmlFor="environment_id">
          {intl.formatMessage({ id: 'admin-area.settings.incident.fields.environment-id' })}
        </label>
        <Controller
          control={control}
          name="environment_id"
          render={({ field }) => (
            <SingleSelect
              optionsUrl={environmentsUrl}
              optionsAccessor="environments"
              optionValueAccessor="environmentId"
              optionLabelAccessor="title"
              defaultValue={field.value}
              onChange={(option) => field.onChange(option?.value)}
            />
          )}
        />
      </div>
    </SearchBarRow>
  );
};

const SecondarySearchItems = () => {
  const intl = useIntl();
  const { register } = useContext(SearchBarContext);
  return (
    <SearchBarRow>
      <div className="form-group" style={{ width: '25%' }}>
        <label htmlFor="visibility">
          {intl.formatMessage({ id: 'admin-area.settings.environment.fields.visibility' })}
        </label>
        <select
          className="form-control"
          placeholder={intl.formatMessage({ id: 'shared.all' })}
          {...register('visibility')}
          defaultValue=""
        >
          <option value="">{intl.formatMessage({ id: 'shared.all' })}</option>
          <option value="private_incident">{intl.formatMessage({ id: 'shared.private' })}</option>
          <option value="public_incident">{intl.formatMessage({ id: 'shared.public' })}</option>
        </select>
      </div>

      <div className="form-group" style={{ width: '25%' }}>
        <label htmlFor="status">{intl.formatMessage({ id: 'admin-area.settings.environment.fields.status' })}</label>
        <select
          className="form-control"
          placeholder={intl.formatMessage({ id: 'shared.all' })}
          {...register('status')}
          defaultValue=""
        >
          <option value="">{intl.formatMessage({ id: 'shared.all' })}</option>
          <option value="active">{intl.formatMessage({ id: 'shared.status.active' })}</option>
          <option value="draft">{intl.formatMessage({ id: 'shared.status.draft' })}</option>
          <option value="archived">{intl.formatMessage({ id: 'shared.status.archived' })}</option>
        </select>
      </div>
    </SearchBarRow>
  );
};
const IncidentIndex = (props) => {
  const intl = useIntl();
  const [pagination, setPagination] = useState({});
  const [incidents, setIncidents] = useState([]);
  const [searchParams, setSearchParams] = useState({});

  const fetchIncidents = async (page = 1) => {
    const response = await ax.get(props.incidentsUrl + '.json' + tableParamsBuilder({ page, ...searchParams }));
    setPagination(response.data.pagination);
    setIncidents(response.data.incidents);
  };

  const incidentsColumns = [
    {
      accessor: 'incidentId',
      className: 'link',
      header: intl.formatMessage({ id: 'admin-area.settings.incident.fields.incident-id' }),
    },
    {
      accessor: 'title',
      header: intl.formatMessage({ id: 'admin-area.settings.incident.fields.title' }),
    },
    {
      accessor: 'environmentTitle',
      header: intl.formatMessage({ id: 'admin-area.settings.incident.fields.environment-id' }),
    },
    {
      accessor: 'visibility',
      header: intl.formatMessage({ id: 'admin-area.settings.incident.fields.visibility' }),
    },
    {
      accessor: 'status',
      className: 'status',
      header: intl.formatMessage({ id: 'admin-area.settings.incident.fields.status' }),
    },
  ];

  const mappedIncidents = incidents.map((incident) => ({
    ...incident,
    incidentId: <a href={`${props.incidentsUrl}/${incident.id}`}>{incident.incidentId}</a>,
    visibility:
      incident.visibility === 'public_incident'
        ? intl.formatMessage({ id: 'shared.public' })
        : intl.formatMessage({ id: 'shared.private' }),
    status: <StatusLabel text={incident.status} status={incident.status === 'active' ? 'passed' : 'failed'} />,
  }));

  useEffect(() => {
    fetchIncidents();
  }, [searchParams]);
  return (
    <div>
      <PageHeader tabs={props.tabs} title={intl.formatMessage({ id: 'admin-area.settings.scenario-assets.title' })} />
      <div className="stack">
        <div className="row larger-gap">
          <OutcomeCard
            type="default"
            icon="fa-fire"
            outcomeText={props.publicIncidentsActiveCount.toString()}
            secondaryText={intl.formatMessage({ id: 'admin-area.settings.incident.index.public-incidents' })}
          />
          <OutcomeCard
            type="default"
            icon="fa-fire"
            outcomeText={props.privateIncidentsActiveCount.toString()}
            secondaryText={intl.formatMessage({ id: 'admin-area.settings.incident.index.private-incidents' })}
          />
        </div>
      </div>
      <div className="page-header with-margin-top">
        <h2>{intl.formatMessage({ id: 'admin-area.settings.incident.index.title' })}</h2>
        <a className="btn btn-primary angled-top-left" href={props.incidentNewUrl}>
          <i className="fa fa-plus" />
          &nbsp;
          {intl.formatMessage({ id: 'admin-area.settings.incident.new.link' })}
        </a>
      </div>
      <SearchBar
        primaryItems={<PrimarySearchItems />}
        secondaryItems={<SecondarySearchItems />}
        onSearch={(searchParams) => setSearchParams({ ...searchParams })}
      />
      {incidents.length > 0 && (
        <Table
          header={true}
          items={mappedIncidents}
          columns={incidentsColumns}
          pagination={pagination}
          onPageChange={fetchIncidents}
        />
      )}
    </div>
  );
};

IncidentIndex.propTypes = {
  incidentsUrl: PropTypes.string.isRequired,
  incidentNewUrl: PropTypes.string.isRequired,
  tabs: PropTypes.array.isRequired,
  publicIncidentsActiveCount: PropTypes.number.isRequired,
  privateIncidentsActiveCount: PropTypes.number.isRequired,
};

export default wrap(IncidentIndex);
