import React, { useEffect, useState } from 'react';
import { IntlProvider, useIntl } from 'react-intl';
import { locale, translations } from '../util/i18n';
import PropTypes from 'prop-types';
import _ from 'lodash';
import ax from '../util/axios';
import AvailableScenarioTile from '../admin_area/online_courses/courses/AvailableScenarioTile';
import PlaylistScenariosList from './PlaylistScenariosList';
import TableSearch from '../util/TableSearch';
import SearchScenariosForPlaylistForm from './SearchScenariosForPlaylistForm';

const PlaylistScenariosCreateForm = (props) => {
  const intl = useIntl();
  const [scenarios, setScenarios] = useState([]);
  const groupedAvailableScenarios = _.chunk(scenarios, 3);
  const [activeScenarioId, setActiveScenarioId] = useState();
  const toggleActiveContentId = (id) => {
    if (activeScenarioId == id) {
      setActiveScenarioId(null);
    } else {
      setActiveScenarioId(id);
    }
  };

  const fetchScenarios = async () => {
    const response = await ax.get(props.scenariosUrl + '.json');
    setScenarios(response.data.scenarios);
  };

  const getKeyForContent = (c) => `${c.id}`;

  const playlistContentKeys = props.formData.scenarios_attributes.map((cc) => getKeyForContent(cc));

  useEffect(() => { {scenarios.length === 0 && fetchScenarios();} });

  return (
    <div className="course-contents">
      <div className="available-contents-section">
        <div className="section-header">{intl.formatMessage({ id: 'playlists.content.select-title' })}</div>
        <TableSearch form={SearchScenariosForPlaylistForm}
                     defaultValues={{}}
                     scenariosUrl={props.scenariosUrl}
                     setScenarios={setScenarios}
        >
        </TableSearch>
        {groupedAvailableScenarios.map((group, i) => {
          let activeContent = null;
          return (
            <div key={i}>
              <div className="available-contents">
                {group.map((content) => {
                  const keyForContent = getKeyForContent(content);
                  if (keyForContent == activeScenarioId) {
                    activeContent = content;
                  }
                  return (
                    <div
                      key={keyForContent}
                      className={`available-content ${activeScenarioId == keyForContent ? 'active' : ''}`}
                      onClick={() => toggleActiveContentId(keyForContent)}
                    >
                      <div className="content-title">{content.title}</div>
                      <div className="content-type">
                        <i className={`fal fa-mask`}></i>&nbsp;
                        {intl.formatMessage({ id: 'courses.content-type.scenario' })}
                        {playlistContentKeys.includes(keyForContent) && (
                          <span className="info">
                            <i className="fas fa-check"></i> {intl.formatMessage({ id: 'courses.module-added-short' })}
                          </span>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
              {activeContent && (
                <div className={`content-slideout ${activeContent ? 'active' : ''}`}>
                  <div className="body">
                    <AvailableScenarioTile
                      key={getKeyForContent(activeContent)}
                      content={activeContent}
                      orgArea={!props.isAdmin}
                    ></AvailableScenarioTile>

                    {props.canModify && (
                      <button
                              className="btn btn-primary small angled-top-left"
                              onClick={() => props.addContent(activeContent)}
                              disabled={props.loading || playlistContentKeys.includes(getKeyForContent(activeContent))}
                      >
                        {intl.formatMessage({ id: 'courses.add-to-course' })} <i className="fa fa-plus"></i>
                      </button>
                    )}

                    {playlistContentKeys.includes(getKeyForContent(activeContent)) && (
                      <span className="info">
                        <span>
                          <i className="fas fa-check"></i>
                        </span>
                        &nbsp;
                        <span>{intl.formatMessage({ id: 'courses.module-added' })}</span>
                      </span>
                    )}
                  </div>
                </div>
              )}
            </div>
          );
        })}
      </div>
      <PlaylistScenariosList
        formData={props.formData}
        setFormData={props.setFormData}
        removeContent={props.removeContent}
        reorderPlaylistContent={props.reorderPlaylistContent}
        canModify={props.canModify}
      />
    </div>
  );
};

export default PlaylistScenariosCreateForm;

PlaylistScenariosCreateForm.propTypes = {
  formData: PropTypes.object,
  setFormData: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  canModify: PropTypes.bool.isRequired,
  scenariosUrl: PropTypes.string.isRequired,
  addContent: PropTypes.func.isRequired,
  removeContent: PropTypes.func.isRequired,
  reorderPlaylistContent: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export const WithIntlProvider = (props) => {
  return (
    <IntlProvider locale={locale} key={locale} messages={translations[locale]}>
      <PlaylistScenariosCreateForm {...props} />
    </IntlProvider>
  );
};
