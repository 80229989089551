import React from 'react';
import ModalLauncher from '../../bundles/main/components/util/ModalLauncher';
import AddMicroEvents from './AddMicroEvents';
import PropTypes from 'prop-types';

import { IntlProvider } from 'react-intl';
import { translations } from '../../bundles/main/components/util/i18n';

const AddMicroEventsModal = (props) => {
  const modalContent = () => {
    return <AddMicroEvents addEventUrl={props.addEventUrl} loadEventUrl={props.loadEventUrl}></AddMicroEvents>;
  };

  return (
    <IntlProvider
      locale={window._FLAIM_LOCALE}
      key={window._FLAIM_LOCALE}
      messages={translations[window._FLAIM_LOCALE]}
    >
      <ModalLauncher
        buttonId="add-micro-event"
        buttonText="admin-area.scenarios.add-micro-event"
        content={modalContent()}
        title="admin-area.scenarios.add-micro-event"
        modalVariation="wide"
      />
    </IntlProvider>
  );
};

AddMicroEventsModal.propTypes = {
  addEventUrl: PropTypes.string.isRequired,
  loadEventUrl: PropTypes.string.isRequired,
};

export default AddMicroEventsModal;
