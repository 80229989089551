import React from "react";
import ModalLauncher from "../../util/ModalLauncher";
import AddLearningOutcomeForm from "./AddLearningOutcomeForm";
import PropTypes from "prop-types";

import { IntlProvider } from "react-intl";
import { translations, locale } from "../../util/i18n";

const AddLearningOutcomeModalLauncher = (props) => {
  const modalContent = () => {
    return <AddLearningOutcomeForm {...props} />;
  };

  return (
    <IntlProvider locale={locale} key={locale} messages={translations[locale]}>
      <ModalLauncher
        buttonId="add-learning-outcome"
        buttonText="admin-area.scenarios.add-learning-outcome"
        content={modalContent()}
        title="admin-area.scenarios.add-learning-outcome"
      />
    </IntlProvider>
  );
};

AddLearningOutcomeModalLauncher.propTypes = {
  url: PropTypes.object.isRequired,
};

export default AddLearningOutcomeModalLauncher;
