import React from 'react';
import RowMenu from '../util/RowMenu';
import PropTypes from 'prop-types';

import { IntlProvider } from 'react-intl';
import { translations } from '../util/i18n';
import PostLinkWithCustomConfirm from '../util/PostLinkWithCustomConfirm';

const UnassignRowMenu = (props) => {
  const options = [];

  options.push({
    title: 'shared.unassign',
    component: PostLinkWithCustomConfirm,
    params: {
      method: 'POST',
      url: props.unassignUrl,
      confirmMessage: props.confirmMessage,
      confirmTitle: props.confirmTitle,
    },
  });

  return (
    <IntlProvider
      locale={window._FLAIM_LOCALE}
      key={window._FLAIM_LOCALE}
      messages={translations[window._FLAIM_LOCALE]}
    >
      <RowMenu options={options}></RowMenu>
    </IntlProvider>
  );
};

UnassignRowMenu.propTypes = {
  unassignUrl: PropTypes.string.isRequired,
  confirmMessage: PropTypes.string.isRequired,
  confirmTitle: PropTypes.string.isRequired,
};

export default UnassignRowMenu;