import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import wrap from '../util/wrap_with_intl_provider';
import Table from '../../../../components/Table';
import ax from '../util/axios';
import {
  organisation_area_unit_playlists_path,
  admin_area_unit_playlists_path,
  admin_area_playlist_unassign_unit_path,
  organisation_area_playlist_unassign_unit_path,
  organisation_area_playlist_units_tab_path,
  admin_area_playlist_units_tab_path,
} from '../../../../routes';
import toastr from 'toastr';
import UnassignRowMenu from './UnassignRowMenu';

const PlaylistTable = ({ unit_id, isAdmin }) => {
  const intl = useIntl();
  const [pagination, setPagination] = useState({});
  const [playlists, setPlaylists] = useState([]);

  const unitPlaylistsPath = isAdmin
    ? admin_area_unit_playlists_path
    : organisation_area_unit_playlists_path;

  const fetchPlaylists = async (page = 1) => {
    let url = unitPlaylistsPath(unit_id, { page: page, format: 'json' });
    ax({
      method: 'get',
      url: url,
    })
        .then((response) => {
          setPagination(response.data.pagination);
          setPlaylists(response.data.playlists);
        })
        .catch((error) => {
          toastr['error'](error);
        });
  };

  const playlistColumns = () => {
    const columns = [
      {
        accessor: 'id',
        header: intl.formatMessage({ id: 'playlists.search.id-field' }),
      },
      {
        accessor: 'title',
        header: intl.formatMessage({ id: 'playlists.search.title-field' }),
      },
      {
        accessor: 'author',
        header: intl.formatMessage({ id: 'playlists.author-field' }),
      },
      {
        accessor: 'visibility',
        header: intl.formatMessage({ id: 'playlists.search.visibility-field' }),
      },
      {
        accessor: 'scenarios',
        header: intl.formatMessage({ id: 'playlists.scenarios-field' }),
      },
      {
        accessor: 'rowMenu',
        header: intl.formatMessage({ id: 'shared.unassign' }),
      },
    ];

    return columns;
  };

  const mappedPlaylists = playlists.map((playlist) => ({
    ...playlist,
    rowMenu: (
      <UnassignRowMenu
        unassignUrl={
          isAdmin ?
            admin_area_playlist_unassign_unit_path(playlist.id, {unit_id: unit_id}) :
            organisation_area_playlist_unassign_unit_path(playlist.id, {unit_id: unit_id})
        }
        confirmMessage={intl.formatMessage({ id: 'units.playlists.unassign-modal-message' })}
        confirmTitle={intl.formatMessage({ id: 'units.playlists.unassign-modal-title' })}
      />
    ),
    id: <a href={
          isAdmin ? admin_area_playlist_units_tab_path(playlist.id) :
            organisation_area_playlist_units_tab_path(playlist.id)}>{playlist.id}</a>,
  }));

  useEffect(() => {
    fetchPlaylists();
  }, []);

  return (
    <div className="stack">
      {playlists.length > 0 && (
        <>
          <div>{intl.formatMessage({ id: 'units.playlists.to-edit-course' })}</div>
          <Table
            header={true}
            items={mappedPlaylists}
            columns={playlistColumns()}
            pagination={pagination}
            onPageChange={fetchPlaylists}
          />
        </>
      )}
      {playlists.length == 0 && intl.formatMessage({ id: 'units.playlists.no-playlists-yet' })}
    </div>
  );
};

PlaylistTable.propTypes = {
  unit_id: PropTypes.number.isRequired,
  isAdmin: PropTypes.bool.isRequired,
};

export default wrap(PlaylistTable);
