import React from "react";
import RowMenu from "../util/RowMenu";
import Modal from "../util/Modal";
import PostLink from "../util/PostLink";
import MergeStudentModal from "./MergeStudentModal";
import PropTypes from "prop-types";

import { IntlProvider } from "react-intl";
import { translations } from "../util/i18n";

const StudentsRowMenu = (props) => {
  const options = [];

  if (props.isActive) {
    options.push({
      title: "students.dropdown.archive-student",
      component: PostLink,
      params: {
        url: props.archiveStudentUrl,
        notice: "students.dropdown.archive-student-complete",
        refresh: true,
        confirmMessage: "students.dropdown.archive-student-confirm-message",
      },
    });
  } else {
    options.push({
      title: "students.dropdown.unarchive-student",
      component: PostLink,
      params: {
        url: props.unarchiveStudentUrl,
        notice: "students.dropdown.unarchive-student-complete",
        refresh: true,
        confirmMessage: "students.dropdown.unarchive-student-confirm-message",
      },
    });
  }

  if (props.mergeStudentUrl) {
    options.push({
      title: "students.dropdown.merge-student",
      component: Modal,
      params: {
        title: "students.dropdown.merge-student",
        content: MergeStudentModal({
          checkStudentForMergeUrl: props.checkStudentForMergeUrl,
          mergeStudentUrl: props.mergeStudentUrl,
          fromUserName: props.fromUserName,
          fromUserEmail: props.fromUserEmail,
        }),
      },
    });
  }

  if (props.resetPasswordUrl) {
    options.push({
      title: "students.dropdown.reset-password",
      component: PostLink,
      params: {
        url: props.resetPasswordUrl,
        refresh: true,
      },
    });
  }

  return (
    <IntlProvider
      locale={window._FLAIM_LOCALE}
      key={window._FLAIM_LOCALE}
      messages={translations[window._FLAIM_LOCALE]}
    >
      <RowMenu options={options}></RowMenu>
    </IntlProvider>
  );
};

StudentsRowMenu.propTypes = {
  checkStudentForMergeUrl: PropTypes.string,
  mergeStudentUrl: PropTypes.string,
  archiveStudentUrl: PropTypes.string,
  unarchiveStudentUrl: PropTypes.string,
  isActive: PropTypes.bool,
  fromUserEmail: PropTypes.string,
  fromUserName: PropTypes.string,
  resetPasswordUrl: PropTypes.string,
};

export default StudentsRowMenu;
