import React from "react";
import ModalLauncher from "../../util/ModalLauncher";
import UserForm from "./UserForm";
import PropTypes from "prop-types";

import { IntlProvider } from "react-intl";
import { translations, locale } from "../../util/i18n";

const CreateUserModal = (props) => {
  const modalContent = () => {
    return <UserForm url={props.createUrl} method={"POST"}></UserForm>;
  };

  return (
    <IntlProvider
      locale={window._FLAIM_LOCALE}
      key={window._FLAIM_LOCALE}
      messages={translations[window._FLAIM_LOCALE]}
    >
      <ModalLauncher
        buttonId="create-user"
        buttonText="users.create-user"
        content={modalContent()}
        title="users.create-user"
      />
    </IntlProvider>
  );
};

CreateUserModal.propTypes = {
  createUrl: PropTypes.string,
};

export default CreateUserModal;
