import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import PageHeader from '../../../../../../components/PageHeader';
import ax from '../../../util/axios';
import ArrowLabel from '../../../../../../components/ArrowLabel';
import {
  admin_area_settings_incident_path,
  admin_area_settings_incident_approved_organisations_path,
  admin_area_settings_incident_observation_events_path,
  admin_area_settings_incidents_path,
  edit_admin_area_settings_incident_path,
  admin_area_settings_incident_performance_events_path,
  admin_area_settings_incident_toggle_status_path,
  admin_area_settings_incident_injects_path,
} from '../../../../../../routes';

const IncidentShowPageHeader = (props) => {
  const intl = useIntl();

  const tabs = () => {
    const tabs = [
      {
        icon: 'fa-solid fa-fire',
        link: admin_area_settings_incident_path(props.id),
        title: intl.formatMessage({ id: 'admin-area.settings.incident.show.tab.detail' }),
        active: props.activeUrl === admin_area_settings_incident_path(props.id),
      },
      {
        icon: 'fa-regular fa-calendar-week',
        link: admin_area_settings_incident_observation_events_path(props.id),
        title: intl.formatMessage({ id: 'admin-area.settings.incident.show.tab.observation-events' }),
        active: props.activeUrl === admin_area_settings_incident_observation_events_path(props.id),
      },
      {
        icon: 'fa-tachometer-alt',
        link: admin_area_settings_incident_performance_events_path(props.id),
        title: intl.formatMessage({ id: 'admin-area.settings.incident.show.tab.performance-events' }),
        active: props.activeUrl === admin_area_settings_incident_performance_events_path(props.id),
      },
      {
        icon: 'fa-square-root-variable',
        link: admin_area_settings_incident_injects_path(props.id),
        title: intl.formatMessage({ id: 'admin-area.settings.incident.show.tab.injects' }),
        active: props.activeUrl === admin_area_settings_incident_injects_path(props.id),
      },
    ];

    if (props.visibility == 'private_incident') {
      tabs.push({
        icon: 'fa-regular fa-users',
        link: admin_area_settings_incident_approved_organisations_path(props.id),
        title: intl.formatMessage({ id: 'admin-area.settings.incident.show.tab.approved-organisations' }),
        active: props.activeUrl === admin_area_settings_incident_approved_organisations_path(props.id),
      });
    }

    return tabs;
  };

  const onStatusClick = (status) => {
    ax({
      method: 'put',
      url: admin_area_settings_incident_toggle_status_path(props.id),
      data: { status },
    }).then(() => (window.location = window.location.href));
  };

  const canActive =
    props.hasObservationEvents && (props.visibility === 'public_incident' || props.hasApprovedOrganisations);

  const titleLabelTooltip = () => {
    const incidentActiveConditions = [];
    let tooltip;
    if (!props.hasObservationEvents) {
      incidentActiveConditions.push(
        intl.formatMessage({ id: 'admin-area.settings.incident.status-tooltip.observation-event' }),
      );
    }
    if (props.visibility === 'private_incident' && !props.hasApprovedOrganisations) {
      incidentActiveConditions.push(
        intl.formatMessage({ id: 'admin-area.settings.incident.status-tooltip.approved-organisation' }),
      );
    }

    if (incidentActiveConditions.length > 0) {
      tooltip = (
        <div>
          <p>{intl.formatMessage({ id: 'admin-area.settings.incident.status-tooltip.instruction' })}</p>
          <ul>
            {incidentActiveConditions.map((condition, ind) => {
              return <li key={ind}>{condition}</li>;
            })}
          </ul>
        </div>
      );
    } else {
      tooltip = intl.formatMessage({ id: 'admin-area.settings.incident.status-tooltip.publish' });
    }

    return tooltip;
  };

  return (
    <PageHeader
      previousPages={[
        {
          link: admin_area_settings_incidents_path(),
          title: intl.formatMessage({ id: 'admin-area.settings.incident.index.title' }),
        },
      ]}
      settingPages={[
        {
          title: intl.formatMessage({ id: 'admin-area.settings.incident.show.archive' }),
          className: 'btn btn-secondary center angled-top-left',
          icon: 'fa-solid fa-box-archive',
          onClick: () => onStatusClick('archived'),
          disable: props.status !== 'active',
        },
        {
          link: edit_admin_area_settings_incident_path(props.id),
          title: intl.formatMessage({ id: 'admin-area.settings.incident.edit.title' }),
          className: 'btn btn-tertiary center without-margin-left',
          icon: 'fa-solid fa-pen',
        },
        {
          title: intl.formatMessage({ id: 'admin-area.settings.incident.show.publish' }),
          className: 'btn btn-primary center angled-top-left',
          icon: 'fa-solid fa-upload',
          onClick: () => onStatusClick('active'),
          disable: props.status === 'active' || !canActive,
        },
      ]}
      tabs={tabs()}
      title={props.title}
      titleLabel={
        <ArrowLabel
          type={props.status}
          label={intl.formatMessage({ id: `shared.status.${props.status}` })}
          tooltip={props.status != 'active' && titleLabelTooltip()}
        />
      }
    />
  );
};

IncidentShowPageHeader.propTypes = {
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  visibility: PropTypes.string.isRequired,
  activeUrl: PropTypes.string.isRequired,
  hasObservationEvents: PropTypes.bool.isRequired,
  hasApprovedOrganisations: PropTypes.bool.isRequired,
  status: PropTypes.string.isRequired,
};

export default IncidentShowPageHeader;
