import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import ax from '../../util/axios';
import { useIntl } from 'react-intl';
import wrap_with_intl_provider from '../../util/wrap_with_intl_provider';

const InviteStudentForm = (props) => {
  const intl = useIntl();
  const [messageType, setMessageType] = useState();
  const [message, setMessage] = useState();
  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({ defaultValues: {} });

  const reset = () => {
    setValue('email', '');
    setValue('first_name', '');
    setValue('last_name', '');
    setValue('ignore_warning', false);
  };

  const onSubmit = (formData) => {
    ax({
      method: 'post',
      url: props.url,
      data: formData,
    })
      .then(function (response) {
        const result = response.data;
        if (result.type == 'success') {
          reset();
        } else if (result.type == 'warning') {
          setValue('ignore_warning', true);
        }
        setMessage(result.message);
        setMessageType(result.type);
      })
      .catch((err) => {
        console.log(err.data);
      });
  };

  return (
    <div className="invitation-form">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-group">
          <label>{intl.formatMessage({ id: 'students.fields.email' })}</label>
          <Controller
            control={control}
            name="email"
            rules={{ required: true, pattern: EMAIL_REGEX }}
            render={({ field }) => (
              <input
                type="email"
                autoFocus
                className="form-control"
                onChange={(e) => {
                  setValue('ignore_warning', false);
                  field.onChange(e.target.value);
                }}
                onBlur={() => field.onBlur()}
                name="email"
                value={field.value}
                autoComplete="off"
              />
            )}
          />
          {errors.email?.type === 'required' && (
            <div className="field-error">{intl.formatMessage({ id: 'shared.forms.required' })}</div>
          )}
          {errors.email?.type === 'pattern' && (
            <div className="field-error">{intl.formatMessage({ id: 'shared.forms.invalid-email' })}</div>
          )}
          {message && (
            <div className={`message ${messageType}`}>
              <i className="fas fa-diamond-exclamation"></i>
              <i className="fas fa-circle-exclamation"></i>
              {message}
            </div>
          )}
        </div>

        <div className="form-group">
          <label>{intl.formatMessage({ id: 'users.first-name-field' })}</label>
          <input className="form-control" {...register('first_name', { required: true })}></input>
          {errors.first_name?.type === 'required' && (
            <div className="field-error">{intl.formatMessage({ id: 'shared.forms.required' })}</div>
          )}
        </div>
        <div className="form-group">
          <label>{intl.formatMessage({ id: 'users.last-name-field' })}</label>
          <input className="form-control" {...register('last_name', { required: true })}></input>
          {errors.last_name?.type === 'required' && (
            <div className="field-error">{intl.formatMessage({ id: 'shared.forms.required' })}</div>
          )}
        </div>

        <button type="submit" className="btn btn-primary small angled-top-left">
          <i className="fas fa-plus"></i>&nbsp;
          {intl.formatMessage({ id: 'enrolments.enrol-students.dropdown.invite-student' })}
        </button>&emsp;
        <button type="button" className="btn btn-secondary small angled-bottom-right" onClick={props.handleClose}>
          <i className="fas fa-times"></i>&nbsp;
          {intl.formatMessage({ id: 'shared.cancel' })}
        </button>
      </form>
    </div>
  );
};

InviteStudentForm.propTypes = {
  url: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired
};

export default wrap_with_intl_provider(InviteStudentForm);
