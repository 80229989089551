import React from "react";
import PropTypes from "prop-types";
import { IntlProvider } from "react-intl";
import { translations } from "../../util/i18n";
import RowMenu from "../../util/RowMenu";
import Modal from "../../util/Modal";
import InviteStudentForm from "./InviteStudentForm";
import EnrolmentLinkForm from "./EnrolmentLinkForm";
import Link from "../../util/Link";

const EnrolStudentMenu = (props) => {
  const options = [];

  options.push({
    title: "enrolments.enrol-students.dropdown.invite-student",
    component: Modal,
    params: {
      title: "enrolments.enrol-students.dropdown.invite-student",
      component: InviteStudentForm,
      params: {
        url: props.inviteStudentUrl,
      },
    },
  });

  options.push({
    title: "enrolments.enrol-students.dropdown.search-student",
    component: Link,
    params: {
      url: props.searchStudentUrl,
    },
  });

  options.push({
    title: "enrolments.enrol-students.dropdown.enrolment-link",
    component: Modal,
    params: {
      title: "enrolments.enrol-students.dropdown.enrolment-link",
      component: EnrolmentLinkForm,
      params: {
        url: props.enrolStudentUrl,
      },
    },
  });

  return (
    <IntlProvider
      locale={window._FLAIM_LOCALE}
      key={window._FLAIM_LOCALE}
      messages={translations[window._FLAIM_LOCALE]}
    >
      <RowMenu
        classNameForButton="btn-primary small angled-top-left"
        classNameForDropdown={"for-btn-primary"}
        title={"enrolments.enrol-students"}
        options={options}
        leftIcon="fa-plus"
      ></RowMenu>
    </IntlProvider>
  );
};

EnrolStudentMenu.propTypes = {
  inviteStudentUrl: PropTypes.string.isRequired,
  searchStudentUrl: PropTypes.string.isRequired,
  enrolStudentUrl: PropTypes.string.isRequired,
};

export default EnrolStudentMenu;
