import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import ServerFormErrors from '../../../util/ServerFormErrors';
import ax from '../../../util/axios';
import Froala from '../../../util/Froala';
import convertToFormData from '../../../util/upload';
import ImageUploadWithGallery from '../../../../../../components/ImageUploadWithGallery';
import SingleSelect from '../../../../../../components/SingleSelect';
import { admin_area_settings_environments_path } from '../../../../../../routes';
import UnitTypeSelect from '../../units/UnitTypeSelect';

const IncidentForm = (props) => {
  const intl = useIntl();
  const [errorMessage, setErrorMessages] = useState();
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    control,
    formState: { errors },
  } = useForm({ defaultValues: { ...props.defaultValues } });
  const onSubmit = (data) => {
    if (data.images && data.images.length > 0) {
      data.images = data.images.filter((image) => !image.id);
    }
    data.environment_id = data.environment_id.value;
    ax({
      method: props.method,
      url: props.url,
      data: convertToFormData(data, ['images']),
      headers: { 'Content-Type': 'multipart/form-data' },
    })
      .then((response) => (window.location = response.data.redirectUrl))
      .catch((err) => {
        setErrorMessages(err.response.data);
      });
  };

  const environmentsUrl = admin_area_settings_environments_path({
    status: 'active',
    no_pagination: true,
    format: 'json',
  });

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="form-group">
            <label>{intl.formatMessage({ id: 'admin-area.settings.incident.fields.title' })}</label>
            <input
              className="form-control"
              placeholder={intl.formatMessage({ id: 'admin-area.settings.incident.placeholder.title' })}
              disabled={props.locked}
              {...register('title', { required: true })}
            />
            {errors.title?.type === 'required' && (
              <div className="field-error">{intl.formatMessage({ id: 'shared.forms.required' })}</div>
            )}
          </div>
          <div className="form-group">
            <label>{intl.formatMessage({ id: 'admin-area.settings.incident.fields.incident-id' })}</label>
            <input
              className="form-control"
              placeholder={intl.formatMessage({ id: 'admin-area.settings.incident.placeholder.incident-id' })}
              disabled={props.locked}
              {...register('incident_id', { required: true })}
            />
            {errors.incident_id?.type === 'required' && (
              <div className="field-error">{intl.formatMessage({ id: 'shared.forms.required' })}</div>
            )}
          </div>
        </div>
        <div className="row">
          <div className="form-group">
            <label htmlFor="status">
              {intl.formatMessage({ id: 'admin-area.settings.incident.fields.environment-id' })}
            </label>
            <Controller
              control={control}
              name="environment_id"
              rules={{ required: true }}
              render={({ field }) => (
                <SingleSelect
                  optionsUrl={environmentsUrl}
                  optionsAccessor="environments"
                  optionValueAccessor="id"
                  optionLabelAccessor="title"
                  defaultValue={field.value}
                  onChange={field.onChange}
                  disabled={props.locked}
                />
              )}
            />
            {errors.environment_id?.type === 'required' && (
              <div className="field-error">{intl.formatMessage({ id: 'shared.forms.required' })}</div>
            )}
          </div>
          <div className="form-group">
            <label htmlFor="visibility">
              {intl.formatMessage({ id: 'admin-area.settings.incident.fields.visibility' })}
            </label>
            <select className="form-control" {...register('visibility')} disabled={props.locked}>
              <option value="public_incident">{intl.formatMessage({ id: 'shared.public' })}</option>
              <option value="private_incident">{intl.formatMessage({ id: 'shared.private' })}</option>
            </select>
          </div>
        </div>
        <div className="form-group">
          <label>{intl.formatMessage({ id: 'shared.device-type' })}</label>
          <UnitTypeSelect allowedUnits= {["extinguisher", "trainer", "sweeper"]} {...register('device_type', { required: true })} disabled={props.locked}/>
        </div>
        <div className="form-group">
          <label>{intl.formatMessage({ id: 'admin-area.settings.observation-event.fields.description' })}</label>
          <Froala
            model={getValues('description')}
            onModelChange={(value) => setValue('description', value)}
            heightMin={150}
            {...register('description', { required: true })}
            isDarkTheme={props.isDarkTheme}
          />
          {errors.description?.type === 'required' && (
            <div className="field-error">{intl.formatMessage({ id: 'shared.forms.required' })}</div>
          )}
        </div>
        <div className="form-group">
          <label>{intl.formatMessage({ id: 'admin-area.settings.incident.fields.default-call-out-message' })}</label>
          <textarea
            rows="5"
            className="form-control"
            {...register('default_call_out_message', { required: true })}
          ></textarea>
          {errors.default_call_out_message?.type === 'required' && (
            <div className="field-error">{intl.formatMessage({ id: 'shared.forms.required' })}</div>
          )}
        </div>
        <div className="form-group">
          <label>{intl.formatMessage({ id: 'admin-area.settings.incident.fields.images' })}</label>
          <ImageUploadWithGallery
            originImages={getValues('images')}
            onImagesChange={(images) => setValue('images', images)}
            onDeleteOriginImage={(deletedIds) => {
              setValue('deleted_image_ids', deletedIds);
            }}
            originPrimaryIndex={getValues('primary_image_index')}
            onPrimaryIndexChange={(primaryImageIndex) => setValue('primary_image_index', primaryImageIndex)}
            {...register('images', { required: true })}
          />
          {errors.images?.type === 'required' && (
            <div className="field-error">{intl.formatMessage({ id: 'shared.forms.required' })}</div>
          )}
        </div>
        <div className="btn-group btn-end">
          <a className="btn btn-secondary small angled-top-left" href={props.url}>
            {intl.formatMessage({ id: 'shared.cancel' })}
          </a>
          <button type="submit" className="btn btn-primary small angled-bottom-right">
            {intl.formatMessage({ id: 'shared.forms.save' })}
          </button>
        </div>
      </form>

      <ServerFormErrors errorMessages={errorMessage}></ServerFormErrors>
    </div>
  );
};

IncidentForm.propTypes = {
  url: PropTypes.string.isRequired,
  method: PropTypes.string.isRequired,
  defaultValues: PropTypes.object,
  locked: PropTypes.bool, default: false,
  isDarkTheme: PropTypes.bool
};

export default IncidentForm;
