import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import wrap from '../util/wrap_with_intl_provider';
import Table from '../../../../components/Table';
import EmptyStateHolder from '../../../../components/EmptyStateHolder';
import ax from '../util/axios';
import ApprovedOrganisationRowMenu from './ApprovedOrgRowMenu';
import AddMultiApprovedOrganisationsModalLauncher from './AddMultiApprovedOrganisationsModalLauncher';

const ApprovedOrganisations = (props) => {
  const intl = useIntl();
  const [pagination, setPagination] = useState({});
  const [approvedOrganisations, setApprovedOrganisations] = useState([]);
  const { loadApprovedPath, loadNotApprovedPath, destroyPath, addPath, header } = props;

  const fetchApprovedOrganisations = async (page = 1) => {
    const response = await ax.get(loadApprovedPath(props.id, { format: 'json', page }));
    setPagination(response.data.pagination);
    setApprovedOrganisations(response.data.approvedOrganisations);
  };

  const approvedOrganisationsColumns = [
    {
      accessor: 'name',
      header: intl.formatMessage({ id: 'approved-organisations.fields.name' }),
    },
    {
      accessor: 'addedAt',
      header: intl.formatMessage({ id: 'approved-organisations.fields.added-at' }),
    },
  ];

  if (props.showAuthor) {
    approvedOrganisationsColumns.push({
      accessor: 'addedBy',
      header: intl.formatMessage({ id: 'approved-organisations.fields.added-by' }),
    });
    approvedOrganisationsColumns.push({
      accessor: 'author',
      header: intl.formatMessage({ id: 'approved-organisations.fields.author' }),
    });
  }

  approvedOrganisationsColumns.push({
    accessor: 'action',
    className: 'actions',
  });

  const mappedApprovedOrganisations = approvedOrganisations.map((org) => ({
    ...org,
    action: <ApprovedOrganisationRowMenu removeApprovedOrganisationUrl={destroyPath(props.id, org.id)} />,
  }));

  useEffect(() => {
    fetchApprovedOrganisations();
  }, []);

  if (pagination.total != undefined) {
    return (
      <div>
        {header}
        <div className="page-header with-margin-top">
          <div></div>
          <div>
            {approvedOrganisations.length > 0 && (
              <AddMultiApprovedOrganisationsModalLauncher
                id={props.id}
                loadNotApprovedPath={loadNotApprovedPath}
                addPath={addPath}
                confirmAddText={intl.formatMessage({ id: 'approved-organisations.add-confirm' })}
              />
            )}
          </div>
        </div>
        {approvedOrganisations.length > 0 ? (
          <Table
            header={true}
            items={mappedApprovedOrganisations}
            columns={approvedOrganisationsColumns}
            pagination={pagination}
            onPageChange={fetchApprovedOrganisations}
          />
        ) : (
          <EmptyStateHolder
            button={
              <AddMultiApprovedOrganisationsModalLauncher
                id={props.id}
                loadNotApprovedPath={loadNotApprovedPath}
                addPath={addPath}
                confirmAddText={intl.formatMessage({ id: 'approved-organisations.add-confirm' })}
              />
            }
            heading={intl.formatMessage({ id: 'approved-organisations.empty-state.heading' })}
            description=""
          />
        )}
      </div>
    );
  }
};

ApprovedOrganisations.propTypes = {
  id: PropTypes.number.isRequired,
  header: PropTypes.node,
  loadApprovedPath: PropTypes.func.isRequired,
  loadNotApprovedPath: PropTypes.func.isRequired,
  addPath: PropTypes.func.isRequired,
  destroyPath: PropTypes.func.isRequired,
  showAuthor: PropTypes.bool,
};

export default wrap(ApprovedOrganisations);
