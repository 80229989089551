import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import ax from '../util/axios';
import { tableParamsBuilder } from '../util/searchUtils';

const SearchScenariosForPlaylistForm = (props) => {
  const intl = useIntl();
  const { register } = useForm({ defaultValues: { ...props.defaultValues } });
  const [title, setTitle] = useState(null);
  const handleSearch = () => {
    ax.get(
    props.scenariosUrl + '.json' + tableParamsBuilder({ title: title })
    ).then(function (response) {
      props.setScenarios(response.data.scenarios);
      window.history.pushState({}, '', '?title=' + title)
    }).catch(() => {
      console.log("something went wrong");
    });
  };

  return (
    <div>
      <div>
        <div className="filters">
          <div className="form-group">
            <label>{intl.formatMessage({ id: 'courses.content-title-field' })}</label>
            <input
              className="form-control" {...register('title')}
              onChange={(e) => setTitle(e.target.value)}
            >
            </input>
          </div>
        </div>

        <button type="button" className="btn btn-primary small angled-top-left" onClick={handleSearch}>
          <i className="fas fa-filter"></i>&nbsp;
          {intl.formatMessage({ id: 'shared.forms.apply_filters' })}
        </button>
      </div>
    </div>
  );
};

SearchScenariosForPlaylistForm.propTypes = {
  defaultValues: PropTypes.object.isRequired,
  scenariosUrl: PropTypes.string.isRequired,
  setScenarios: PropTypes.func.isRequired
};

export default SearchScenariosForPlaylistForm;
