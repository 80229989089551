import React, { useState, createContext } from 'react';
import PageHeader from '../../PageHeader';
import { admin_area_scenarios_path, organisation_area_scenarios_path } from '../../../routes';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import DetailAccordion from './DetailAccordion';
import EnvironmentAccordion from './EnvironmentAccordion';
import IncidentAccordion from './IncidentAccordion';
import wrap_with_intl_provider from '../../../bundles/main/components/util/wrap_with_intl_provider';

import './NewCusomScenarioPage.scss';
import CalloutMessageAccordion from './CalloutMessageAccordion';
import ConfigureAfterActionReviewAccordion from './ConfigureAfterActionReviewAccordion';
import InjectsAccordion from './InjectsAccordion';

export const NewCustomScenarioPageContext = createContext({});

const NewCustomScenarioPage = ({ isAdmin, initScenario }) => {
  const intl = useIntl();
  const [context, setContext] = useState({
    scenario: initScenario,
    isAdmin: isAdmin,
    activeStep: initScenario ? initScenario.custom_scenario_step : 'detail',
  });
  return (
    <NewCustomScenarioPageContext.Provider value={[context, setContext]}>
      <div className="new-custom-scenario">
        <PageHeader
          previousPages={[
            {
              link: isAdmin ? admin_area_scenarios_path() : organisation_area_scenarios_path(),
              title: intl.formatMessage({ id: 'scenarios.all-scenarios' }),
            },
          ]}
          title={intl.formatMessage({ id: 'scenarios.new-custom-scenario.title' })}
          pageDivider={true}
        />
        <div className="steps">
          <DetailAccordion />

          <EnvironmentAccordion />

          <IncidentAccordion />

          <InjectsAccordion />

          <CalloutMessageAccordion />

          <ConfigureAfterActionReviewAccordion />
        </div>
      </div>
    </NewCustomScenarioPageContext.Provider>
  );
};

NewCustomScenarioPage.propTypes = {
  isAdmin: PropTypes.bool.isRequired,
  initScenario: PropTypes.object,
};

export default wrap_with_intl_provider(NewCustomScenarioPage);
