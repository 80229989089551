import React, { useState } from "react";
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";
import ServerFormErrors from "../util/ServerFormErrors";
import ax from "../util/axios";
import { IntlProvider, useIntl } from "react-intl";
import { translations } from "../util/i18n";
import convertToFormData from "../util/upload";
import { add_submit_overlay, remove_submit_overlay } from "../util/submit_overlay";

const ImportStudentsForm = (props) => {
  const intl = useIntl();
  const [errorMessage, setErrorMessages] = useState();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    add_submit_overlay();
    const formData = convertToFormData(data, ["file"]);
    ax({
      method: "POST",
      url: props.url,
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(function (response) {
        window.location = response.data.return_url;
      })
      .catch((err) => {
        if (err.toString() == "Error: Network Error") {
          setErrorMessages([intl.formatMessage({ id: "students.import-students.refresh-page" })]);
        } else {
          setErrorMessages(err.response.data);
        }
        remove_submit_overlay();
      });
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)} className="narrow">
        {props.adminArea && (
          <>
            <div className="form-group">
              <label>{intl.formatMessage({ id: "students.fields.organisation" })}</label>
              <select autoFocus className="form-control" {...register("organisation_id", { required: true })}>
                <option key="0" value="" disabled={props.organisationId}>
                  {intl.formatMessage({ id: "shared.dropdown.please-select" })}
                </option>
                {props.organisations.map((org) => {
                  return (
                    <option key={org.id} value={org.id} selected={props.organisationId == org.id}>
                      {org.name}
                    </option>
                  );
                })}
              </select>
              {errors.organisation_id?.type === "required" && (
                <div className="field-error">{intl.formatMessage({ id: "shared.forms.required" })}</div>
              )}
            </div>
          </>
        )}
        <div className="form-group">
          <label>{intl.formatMessage({ id: "students.import-students.select-file" })}</label>
          <input
            type="file"
            accept="text/csv"
            multiple={false}
            className="form-control"
            {...register("file", { required: true })}
          ></input>
          {errors.file?.type === "required" && (
            <div className="field-error">{intl.formatMessage({ id: "shared.forms.required" })}</div>
          )}
        </div>

        <button type="submit" className="btn btn-primary small angled-top-left">
          {intl.formatMessage({ id: "students.import-students.import" })}
        </button>
      </form>

      <ServerFormErrors errorMessages={errorMessage}></ServerFormErrors>
    </div>
  );
};

ImportStudentsForm.propTypes = {
  url: PropTypes.string.isRequired,
  adminArea: PropTypes.bool,
  organisations: PropTypes.array,
  organisationId: PropTypes.string,
};

export default ImportStudentsForm;

export const WithIntlProvider = (props) => {
  return (
    <IntlProvider
      locale={window._FLAIM_LOCALE}
      key={window._FLAIM_LOCALE}
      messages={translations[window._FLAIM_LOCALE]}
    >
      <ImportStudentsForm {...props} />
    </IntlProvider>
  );
};
