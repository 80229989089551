import React from 'react';
import PropTypes from 'prop-types';
import './SearchBarRow.scss';

const SearchBarRow = ({ children, style }) => {
  return (
    <div className="searchbar-row" style={style}>
      {children}
    </div>
  );
};

SearchBarRow.propTypes = {
  children: PropTypes.node,
  style: PropTypes.object,
};
export default SearchBarRow;
