import React from 'react';
import RowMenu from '../../../util/RowMenu';
import Modal from '../../../util/Modal';
import PostLink from '../../../util/PostLink';
import EditMicroEventModal from './EditMicroEventModal';
import PropTypes from 'prop-types';

import { IntlProvider } from 'react-intl';
import { translations } from '../../../util/i18n';

const MicroEventRowMenu = (props) => {
  const options = [
    {
      title: 'admin-area.settings.micro-event.dropdown.edit',
      component: Modal,
      params: {
        title: 'admin-area.settings.micro-event.dropdown.edit',
        content: (
          <EditMicroEventModal
            url={props.updateEventUrl}
            eventDetails={props.eventDetails}
            eventCategories={props.eventCategories}
          />
        ),
        modalVariation: 'wide',
      },
    },
    {
      title:
        props.eventDetails.status == 'active'
          ? 'admin-area.settings.micro-event.dropdown.disable'
          : 'admin-area.settings.micro-event.dropdown.enable',
      component: PostLink,
      params: {
        url: props.toggleEventStatusUrl,
        refresh: true,
      },
    },
  ];

  if (props.deleteEventUrl) {
    options.push({
      title: 'admin-area.settings.micro-event.dropdown.delete',
      component: PostLink,
      params: {
        method: 'delete',
        url: props.deleteEventUrl,
        refresh: true,
        confirmMessage: 'admin-area.settings.micro-event.confirmation-message',
      },
    });
  }

  return (
    <IntlProvider
      locale={window._FLAIM_LOCALE}
      key={window._FLAIM_LOCALE}
      messages={translations[window._FLAIM_LOCALE]}
    >
      <RowMenu options={options}></RowMenu>
    </IntlProvider>
  );
};

MicroEventRowMenu.propTypes = {
  updateEventUrl: PropTypes.string.isRequired,
  toggleEventStatusUrl: PropTypes.string.isRequired,
  deleteEventUrl: PropTypes.string,
  eventDetails: PropTypes.object,
  eventCategories: PropTypes.array.isRequired,
};

export default MicroEventRowMenu;
