import React from 'react';
import PropTypes from 'prop-types';
import Card from '../Card';
import { useIntl } from 'react-intl';
import classNames from 'classnames';

import './PerformanceDataCard.scss';

/**
 * We need to display performance data (title, value) in a card, if value is blank, then it will display value as N/A
 * and show 'not recorded' in warning color
 */
const PerformanceDataCard = ({ title, value, isLink }) => {
  const intl = useIntl();
  const displayValue = value || intl.formatMessage({ id: 'shared.not-applicable' });

  return (
    <Card className={classNames('perf-data-card', { 'not-recorded': !value })}>
      <div className="perf-data-card-header">
        <div>
          <i className="fa-solid fa-chart-mixed"/>
          <span className="title">{title}</span>
        </div>
        {!value && (
          <div className="not-recorded">
            <i className="far fa-ban"></i>
            {intl.formatMessage({ id: 'shared.not-recorded' })}
          </div>
        )}
      </div>
      <div className="perf-data-value">
        {isLink && value ? <a className="perf-data-link" href={value} target={'_blank'} rel="noreferrer">View Here</a> : displayValue}
      </div>
    </Card>
  );
};

PerformanceDataCard.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string,
  isLink: PropTypes.bool.isRequired
};

export default PerformanceDataCard;
