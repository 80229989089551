import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import { useForm } from 'react-hook-form';
import { createContext } from 'react';
import Card from '../Card';

import './SearchBar.scss';

export const SearchBarContext = createContext({});

const SearchBar = ({ primaryItems, secondaryItems, onSearch, defaultValues, defaultExtended }) => {
  const intl = useIntl();
  const { register, handleSubmit, reset, setValue, control, getValues, watch } = useForm({
    defaultValues: { ...defaultValues },
  });
  const [extended, setExtended] = useState(defaultExtended);

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues]);

  useEffect(() => {
    setExtended(defaultExtended);
  }, [defaultExtended]);

  return (
    <div className={classNames('searchbar', { extended })}>
      <Card>
        <form onSubmit={handleSubmit(onSearch)}>
          <SearchBarContext.Provider value={{ register, setValue, getValues, control, watch }}>
            <div className="searchbar-primary-items-bar">
              <div className="searchbar-primary-items">{primaryItems}</div>
              <button
                type="button"
                className="btn btn-extend btn-secondary small angled-top-left"
                aria-label="extend button"
                disabled={!secondaryItems}
                onClick={() => {
                  setExtended(!extended);
                }}
              >
                <i className={classNames('fas', 'fa-chevrons-down')}></i>
                <i className={classNames('fas', 'fa-chevrons-up')}></i>
              </button>
              <button type="button" className="btn btn-primary small angled-top-left" onClick={handleSubmit(onSearch)}>
                {intl.formatMessage({ id: 'shared.search' })}
              </button>
            </div>

            <div className={classNames('searchbar-secondary-items', { extended: extended })}>{secondaryItems}</div>
          </SearchBarContext.Provider>
        </form>
      </Card>
    </div>
  );
};

SearchBar.propTypes = {
  primaryItems: PropTypes.node.isRequired,
  secondaryItems: PropTypes.node,
  defaultValues: PropTypes.object,
  onSearch: PropTypes.func.isRequired,
  defaultExtended: PropTypes.bool,
};

SearchBar.defaultProps = {
  onSearch: () => {},
};

export default SearchBar;
