import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Modal from '../../bundles/main/components/util/Modal';

const Image = ({ imageUrl, primary }) => {
  const [magnifierOpen, setMagnifierOpen] = useState(false);
  return (
    <div className={classNames('image', { primary: primary, secondary: !primary })}>
      <img src={imageUrl} />
      <span className="magnifier" onClick={() => setMagnifierOpen(true)}>
        <i className="far fa-magnifying-glass-plus" />
      </span>
      {magnifierOpen && (
        <Modal
          open={magnifierOpen}
          onClose={() => setMagnifierOpen(false)}
          noTitleTranslation
          modalVariation="image-magnifier"
          content={
            <div>
              <img src={imageUrl} />
            </div>
          }
        />
      )}
    </div>
  );
};

Image.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  primary: PropTypes.bool,
};

export default Image;
