import React, { useContext, useEffect, useState } from 'react';
import { NewCustomScenarioPageContext } from './NewCustomScenarioPage';
import Accordion, { AccordionContext } from '../../Accordion/Accordion';
import classNames from 'classnames';
import { useIntl } from 'react-intl';
import Message from '../../Message';
import StepActions from './StepActions';
import ax from '../../../bundles/main/components/util/axios';
import toastr from 'toastr';
import {
  admin_area_custom_scenario_step_injects_path,
  organisation_area_custom_scenario_step_injects_path,
  admin_area_scenarios_path,
  organisation_area_scenarios_path,
  admin_area_custom_scenario_available_injects_path,
  organisation_area_custom_scenario_available_injects_path,
} from '../../../routes';
import { useForm } from 'react-hook-form';

const Header = () => {
  const intl = useIntl();
  const [context] = useContext(NewCustomScenarioPageContext);
  const { scenario, activeStep } = context;
  const editable_inject_ids = scenario ? scenario.editable_inject_ids : undefined;
  const accordionContext = useContext(AccordionContext);

  useEffect(() => {
    if (activeStep == 'injects') {
      accordionContext.setExpanded(true);
    } else {
      accordionContext.setExpanded(false);
    }
  }, [activeStep]);

  return (
    <div className={classNames('step-header')}>
      {!editable_inject_ids && (
        <>
          <div className="title">
            <div className="title-info">
              <div className="title-main">{intl.formatMessage({ id: 'scenarios.new-custom-scenario.injects-header.title' })}</div>
              <div className="title-description">{intl.formatMessage({ id: 'scenarios.new-custom-scenario.injects-header.description' })}</div>
            </div>
          </div>
        </>
      )}
      {editable_inject_ids && (
        <div className="title">
          <div className="title-info">
            <div className="title-main">{intl.formatMessage({ id: 'admin-area.scenarios.injects-field' })}</div>
            {scenario.injects.length > 0 && (
              <div className="title-description long-text">{scenario.injects.map((inj) => inj.title).join(', ')}</div>
            )}
            {scenario.injects.length == 0 && <div className="title-description">{intl.formatMessage({ id: 'shared.not-available' })}</div>}
          </div>

          <div>
            <i className="fal fa-circle-check"></i>
          </div>
        </div>
      )}
    </div>
  );
};

const Content = () => {
  const intl = useIntl();
  const [injects, setInjects] = useState();
  const [context, setContext] = useContext(NewCustomScenarioPageContext);
  const { scenario, isAdmin } = context;
  const [stepInjectsPath, scenariosPath, availableInjectsPath] = isAdmin
    ? [
        admin_area_custom_scenario_step_injects_path,
        admin_area_scenarios_path,
        admin_area_custom_scenario_available_injects_path,
      ]
    : [
        organisation_area_custom_scenario_step_injects_path,
        organisation_area_scenarios_path,
        organisation_area_custom_scenario_available_injects_path,
      ];

  const {
    register,
    handleSubmit,
    reset,
    formState: { isDirty },
  } = useForm({
    defaultValues: { injects: [...(scenario.editable_inject_ids || [])] },
  });

  useEffect(() => fetchInjects(), []);

  const registerSelectedInjects = register('injects');

  const fetchInjects = () => {
    let url = availableInjectsPath(scenario.id);
    ax({
      method: 'get',
      url: url,
    })
      .then((response) => {
        const injs = response.data.injects;
        if (!scenario.injects && injs) {
          reset({
            injects: injs.map((inj) => inj.id.toString()),
          });
        }
        setInjects(injs);
      })
      .catch((error) => {
        console.log(error);
        toastr['error'](toastr.error(intl.formatMessage({ id: 'shared.something-wrong' })));
      });
  };

  const save = (data, backToIndex) => {
    if (isDirty && !confirm(intl.formatMessage({ id: 'scenarios.new-custom-scenario.injects.confirm-next' }))) {
      return;
    }

    if (!backToIndex) {
      data.custom_scenario_step = 'callout-message';
    }

    ax({
      method: 'post',
      url: stepInjectsPath(scenario.id),
      data: data,
    })
      .then((res) => {
        if (backToIndex) {
          window.location = scenariosPath();
        } else {
          setContext({
            scenario: { ...scenario, ...res.data },
            activeStep: 'callout-message',
            isAdmin,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        toastr.error(intl.formatMessage({ id: 'shared.something-wrong' }));
      });
  };

  const saveDraft = (data) => {
    save(data, true);
  };

  const next = (data) => {
    save(data, false);
  };

  const lockedInjects = injects && injects.filter((inj) => inj.locked);

  const editableInjects = injects && injects.filter((inj) => !inj.locked);

  if (injects && injects.length == 0) {
    return (
      <div>
        <form>
          <Message
            type="info"
            icon="fas fa-ellipsis-vertical"
            message={intl.formatMessage({ id: 'scenarios.new-custom-scenario.injects.message.no-injects.title' })}
            description={intl.formatMessage({
              id: 'scenarios.new-custom-scenario.injects.message.no-injects.description',
            })}
            noClose
          />
          <StepActions onSaveDraft={handleSubmit(saveDraft)} onNext={handleSubmit(next)} />
        </form>
      </div>
    );
  } else {
    return (
      <div className="stack select-injects">
        <div className="incident-title">{scenario.incident_title}</div>
        <form>
          {lockedInjects && lockedInjects.length > 0 && (
            <>
              <Message
                type="warning"
                icon="fal fa-triangle-exclamation"
                message={intl.formatMessage({
                  id: 'scenarios.new-custom-scenario.injects.message.locked-injects.title',
                })}
                description={intl.formatMessage({
                  id: 'scenarios.new-custom-scenario.injects.message.locked-injects.description',
                })}
                noClose
              />
              <div className="injects">
                {lockedInjects.map((inj) => (
                  <span key={inj.id} className='inject-option'>
                    <label className="checkbox">
                      <input type="checkbox" disabled checked></input>
                      <span className="checkmark"/>
                    </label>
                    <label>{inj.title}</label>
                  </span>
                ))}
              </div>
            </>
          )}
          {editableInjects && editableInjects.length > 0 && (
            <>
              <Message
                type="info"
                icon="fal fa-circle-info"
                message={intl.formatMessage({
                  id: 'scenarios.new-custom-scenario.injects.message.editable-injects.title',
                })}
                description={intl.formatMessage({
                  id: 'scenarios.new-custom-scenario.injects.message.editable-injects.description',
                })}
                noClose
              />
              <div className="injects">
                {/* react-hook-form behave differently between single checkbox vs multi checkbox, i.e here we use one hidden checkbox to enforce it always behave as multi checkbox */}
                <input type="checkbox" hidden value="-1" {...registerSelectedInjects} />
                {editableInjects.map((inj) => (
                  <span key={inj.id} className='inject-option'>
                    <label className="checkbox">
                      <input type="checkbox" value={inj.id} {...registerSelectedInjects} id={`inject-${inj.id}`}></input>
                      <span className="checkmark"/>
                    </label>
                    <label htmlFor={`inject-${inj.id}`}>{inj.title}</label>
                  </span>
                ))}
              </div>
            </>
          )}
          <StepActions onSaveDraft={handleSubmit(saveDraft)} onNext={handleSubmit(next)} />
        </form>
      </div>
    );
  }
};

const InjectsAccordion = () => {
  const [context, setContext] = useContext(NewCustomScenarioPageContext);
  const { scenario } = context;

  const onToggled = (expanded) => {
    if (expanded) {
      setContext({ ...context, activeStep: 'injects' });
    } else {
      setContext({ ...context, activeStep: '' });
    }
  };

  const isStepOk = () => scenario?.editable_inject_ids;

  return (
    <Accordion
      className={classNames({ 'step-ok': isStepOk() })}
      header={<Header />}
      content={<Content />}
      onToggled={onToggled}
      disabled={!context.scenario?.incident_id}
    />
  );
};

export default InjectsAccordion;
