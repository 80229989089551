import React from "react";
import ModalLauncher from "../../../util/ModalLauncher";
import EventCategoryForm from "./EventCategoryForm";
import PropTypes from "prop-types";

import { IntlProvider } from "react-intl";
import { translations, locale } from "../../../util/i18n";

const CreateEventCategoryModal = (props) => (
  <IntlProvider locale={window._FLAIM_LOCALE} key={window._FLAIM_LOCALE} messages={translations[window._FLAIM_LOCALE]}>
    <ModalLauncher
      buttonId="create-event-category"
      buttonText="admin-area.settings.event-category.create"
      content={<EventCategoryForm url={props.createEventCategoryUrl} method={"POST"} />}
      title="admin-area.settings.event-category.create"
    />
  </IntlProvider>
);

CreateEventCategoryModal.propTypes = {
  createEventCategoryUrl: PropTypes.string.isRequired,
};

export default CreateEventCategoryModal;
