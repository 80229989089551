import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import phone_prefix_mapping from "../util/phone_prefix";
import getFlagEmoji from "./getFlagEmoji";

const MobileCountrySelect = React.forwardRef(({ className, onChange, name, countries }, ref) => {
  const intl = useIntl();

  const getPhonePrefix = (countryCode) => {
    const country = phone_prefix_mapping.find((e) => e.isoCode == countryCode);
    return country && country.dialCode;
  };

  return (
    <select className={className} name={name} ref={ref} onChange={onChange}>
      <option value="">{intl.formatMessage({ id: "shared.dropdown.please-select" })}</option>
      {Object.keys(countries).map((key) => (
        <option key={key} value={key}>
          {getFlagEmoji(key)} ({getPhonePrefix(key)})
        </option>
      ))}
    </select>
  );
});

MobileCountrySelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  countries: PropTypes.object.isRequired,
};

export default MobileCountrySelect;
