import React from "react";
import ModalLauncher from "../../util/ModalLauncher";
import CustomReportForm from "./CustomReportForm";
import PropTypes from "prop-types";

import { IntlProvider } from "react-intl";
import { translations, locale } from "../../util/i18n";

const CreateCustomReportModal = (props) => {
  const modalContent = () => {
    return (
      <CustomReportForm
        url={props.createUrl}
        method={"POST"}
        defaultValues={{ org_id: props.orgId }}
      ></CustomReportForm>
    );
  };

  return (
    <IntlProvider
      locale={window._FLAIM_LOCALE}
      key={window._FLAIM_LOCALE}
      messages={translations[window._FLAIM_LOCALE]}
    >
      <ModalLauncher
        buttonId="create-custom-report"
        buttonText="custom-reports.create-custom-report"
        content={modalContent()}
        title="custom-reports.create-custom-report"
      />
    </IntlProvider>
  );
};

CreateCustomReportModal.propTypes = {
  createUrl: PropTypes.string,
  orgId: PropTypes.string,
};

export default CreateCustomReportModal;
