import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import ax from "../../../util/axios";

const AvailablePresentationTile = (props) => {
  const presentation = props.content;
  const [details, setDetails] = useState(presentation.details);

  useEffect(() => {
    if (!details) {
      ax({
        method: "get",
        url: `/${props.orgArea ? "org" : "admin"}/online_courses/presentations/${presentation.id}/preview_data`,
      })
        .then(function (response) {
          presentation.details = response.data;
          setDetails(presentation.details);
          console.log("get presentation preview_data successfully");
        })
        .catch(() => {
          console.log("get presentation preview_data failed");
        });
    }
  });

  if (!window.addedResizeListener) {
    console.log("add resize listner 000000000000");
    window.addEventListener("message", (event) => {
      if (event.data.type == "adjustSize" && event.data.height) {
        const loaderIframe = document.getElementById("loader-iframe");
        loaderIframe.style.height = event.data.height + "px";

        console.log(`adjusted size --------${event.data.height}`);
      }
    });

    window.addedResizeListener = true;
  }

  if (details) {
    return (
      <div id="loader-iframe-wrapper" key="loader-iframe-wrapper">
        <iframe
          id="loader-iframe"
          src={`${details.url}`}
          width="100%"
          height="100%"
          scrolling="no"
          style={{ overflow: "hidden" }}
        ></iframe>
      </div>
    );
  } else {
    return (
      <div className="spinner">
        <i className="fas fa-spinner fa-spin"></i>
      </div>
    );
  }
};

AvailablePresentationTile.propTypes = {
  content: PropTypes.object.isRequired,
  orgArea: PropTypes.bool.isRequired,
};

export default AvailablePresentationTile;
