import React from 'react';
import RowMenu from '../../util/RowMenu';
import PostLink from '../../util/PostLink';
import PropTypes from 'prop-types';

import { IntlProvider } from 'react-intl';
import { translations } from '../../util/i18n';

const AdminEventRowManual = ({ deleteEventUrl, isCritical, toggleCriticalUrl }) => {
  const options = [
    {
      title: 'admin-area.scenarios.dropdown.delete-event',
      component: PostLink,
      params: {
        url: deleteEventUrl,
        refresh: true,
        confirmMessage: 'admin-area.scenarios.delete-event.confirmation-message',
      },
    },
    {
      title: isCritical
        ? 'admin-area.scenarios.dropdown.mark-not-critical'
        : 'admin-area.scenarios.dropdown.mark-critical',
      component: PostLink,
      params: {
        url: toggleCriticalUrl,
        refresh: true,
      },
    },
  ];

  return (
    <IntlProvider
      locale={window._FLAIM_LOCALE}
      key={window._FLAIM_LOCALE}
      messages={translations[window._FLAIM_LOCALE]}
    >
      <RowMenu options={options}></RowMenu>
    </IntlProvider>
  );
};

AdminEventRowManual.propTypes = {
  deleteEventUrl: PropTypes.string.isRequired,
  isCritical: PropTypes.bool.isRequired,
  toggleCriticalUrl: PropTypes.string.isRequired,
};

export default AdminEventRowManual;
